import React, { FC, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell, { TableCellProps } from '@material-ui/core/TableCell';
import clsx from 'clsx';
import { ConfusionMatrixTooltip } from './confusion-matrix-tooltip';
import { HeaderCell, NumericCell, TableCell } from './confusion-matrix-cells';
import { useStyles } from './confusion-matrix.styles';

type Props = {
  tp: number;
  fn: number;
  tn: number;
  fp: number;
};

const TableCellHead: FC<TableCellProps> = (props) => {
  const classes = useStyles();
  return (
    <MuiTableCell
      {...props}
      classes={{ ...props.classes, root: clsx(props.classes?.root, classes.base) }}
    />
  );
};

export const ConfusionMatrix = ({ tp = 0, fn = 0, tn = 0, fp = 0 }: Props) => {
  const classes = useStyles();
  const failedCount = tp + fn;
  const normalCount = tn + fp;

  const tooltipProps = { failedCount, normalCount, tp, fn, tn, fp };

  const [failHovered, setFailHovered] = useState(false);
  const [normalHovered, setNormalHovered] = useState(false);

  const failHoveredClass = clsx({ [classes.highlighted]: failHovered });
  const normalHoveredClass = clsx({ [classes.highlighted]: normalHovered });

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCellHead
            className={classes.help}
            colSpan={2}
            rowSpan={2}
            align="left"
            padding="none">
            <ConfusionMatrixTooltip {...tooltipProps} />
          </TableCellHead>
          <TableCellHead className={classes.highlighted} colSpan={2} align="center">
            <b>Predicted</b>
          </TableCellHead>
        </TableRow>

        <TableRow>
          <HeaderCell label="Fail" tooltip="Positive" />
          <HeaderCell label="Normal" tooltip="Negative" />
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow>
          <TableCell className={classes.highlighted} rowSpan={2}>
            <b>Actual</b>
          </TableCell>

          <HeaderCell
            label="Fail"
            tooltip={`Actually failed: ${failedCount}`}
            className={failHoveredClass}
            onMouseEnter={() => setFailHovered(true)}
            onMouseLeave={() => setFailHovered(false)}
          />

          <NumericCell
            label={tp}
            tooltip={`True Positive: ${tp} of ${failedCount} alarms the model correctly classified as failed`}
            className={clsx(failHoveredClass, classes.positive)}
          />

          <NumericCell
            label={fn}
            tooltip={`False Negative: ${fn} of ${failedCount} alarms the model incorrectly classified as normal`}
            className={clsx(failHoveredClass, classes.negative)}
          />
        </TableRow>

        <TableRow>
          <HeaderCell
            label="Normal"
            tooltip={`Actually normal: ${normalCount}`}
            className={clsx(normalHoveredClass)}
            onMouseEnter={() => setNormalHovered(true)}
            onMouseLeave={() => setNormalHovered(false)}
          />

          <NumericCell
            label={fp}
            tooltip={`False Positive: ${fp} of ${normalCount} alarms the mode incorrectly classified as failed`}
            className={clsx(normalHoveredClass, classes.negative)}
          />

          <NumericCell
            label={tn}
            tooltip={`True Negative: ${tn} of ${normalCount} alarms the model correctly classified as normal`}
            className={clsx(normalHoveredClass, classes.positive)}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};
