import React from 'react';
import { FailureProbability } from 'src/store/api/pumps.api';
import GaugeChart from '../charts/gauge-chart';
import { useStyles } from './prediction-chart.styles';
import arrow from './icons/arrow.svg';
import { predictionChartConfig } from './prediction-chart.config';

interface Props {
  pump: FailureProbability;
}

export const PredictionChart: React.FC<Props> = (props) => {
  const styles = useStyles();

  const {
    failureProbability,
    failureProbabilityTrend,
    failureProbabilityAboveThresholdDuration,
  } = props.pump;
  const { tooltipMessages } = predictionChartConfig;

  const selectArrowStyle = (): string => {
    if (failureProbabilityTrend) {
      return failureProbabilityTrend > 0 ? styles.arrowUp : styles.arrowDown;
    }

    return '';
  };

  const selectTooltipMsg = (): string => {
    const { failureProbabilityStatus } = props.pump;

    return failureProbabilityStatus === 'critical'
      ? tooltipMessages.critical
      : tooltipMessages.default;
  };

  const selectLabel = () => {
    const { failureProbabilityStatus } = props.pump;

    return failureProbabilityStatus === 'critical'
      ? failureProbabilityAboveThresholdDuration
      : Math.abs(failureProbabilityTrend);
  };

  return (
    <GaugeChart
      status={props.pump.failureProbabilityStatus}
      tooltipMsg={selectTooltipMsg()}
      value={failureProbability}>
      <div className={styles.label}>
        <span>{selectLabel()}</span>
        <img
          className={[styles.arrow, selectArrowStyle()].join(' ')}
          src={arrow}
          alt=""
        />
      </div>
    </GaugeChart>
  );
};
