import React from 'react';
import Button from 'src/shared/button';
import { useStyles } from './column-dropdown-actions.styles';

interface OwnProps {
  onApply: () => void;
  onCancel: () => void;
}

export const ColumnDropdownActions: React.FC<OwnProps> = (props) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Button size="small" color="secondary" onClick={props.onCancel}>
        Cancel
      </Button>
      <Button size="small" onClick={props.onApply}>
        Apply
      </Button>
    </div>
  );
};
