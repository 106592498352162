import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      actionsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      goBackContainer: {
        margin: '16px 18px',
        color: COLORS.blue['700'],
        cursor: 'pointer',
      },
      icon: {
        fontSize: 'inherit',
        marginRight: '8px',
      },
      chartContainer: {
        width: '100%',
        minHeight: '16rem',
        zIndex: 1,
      },
    })
);
