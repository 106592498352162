import React, { FC, useEffect, useState } from 'react';
import BaseGrid from 'src/shared/base-grid';
import { ColData, StyledColDef } from 'src/shared/base-grid/base-grid';
import { createColumn } from 'src/shared/base-grid/column-creator';
import { COLORS } from 'src/styles/colors';
import ExportBtn from 'src/shared/button-export';
import { CellClassParams, RowNode } from 'ag-grid-community';
import { Diode } from 'src/store/api/pumps.api';
import { Box } from '@material-ui/core';
import { HEADER_HEIGHTS } from 'src/shared/base-grid/base-grid.config';

export interface Props {
  autoSize: boolean;
  rows: Diode[] | null;
  columns: ColData[];
  exportUrl: string;
  titleSpec?: string;
}

const PumpDiodesGrid: FC<Props> = (props: Props) => {
  const [styledColumns, setStyledColumns] = useState<StyledColDef[]>([]);
  const { autoSize, rows, columns, exportUrl, titleSpec } = props;

  const getColor = (
    channel: string,
    row1: CellClassParams,
    row2: RowNode,
    highlight = COLORS.red[300]
  ) => {
    const currentValue = row1.value;
    const comparisonValue = row2.data[channel];
    const highlightCondition =
      currentValue >= 0 && comparisonValue >= 0 && currentValue !== comparisonValue;
    return highlightCondition ? { color: highlight } : {};
  };

  useEffect(() => {
    const styledColumnData: StyledColDef[] = columns.map((col: ColData) => {
      return createColumn(col, {
        headerComponentParams: {
          dataType: col.units,
        },
        cellStyle: (params: CellClassParams) => {
          const COLS_TO_COMPARE = [
            'ch1',
            'ch2',
            'ch3',
            'ch4',
            'ch5',
            'ch6',
            'ch7',
            'ch8',
            'ch9',
          ];
          const rowIndex = params.rowIndex;
          const model = params.api.getModel();
          //row index is maintained even after sorting occurs
          const compareToRow =
            rowIndex % 2 === 0 ? model.getRow(rowIndex + 1) : model.getRow(rowIndex - 1);

          if (compareToRow) {
            for (let i = 0; i < COLS_TO_COMPARE.length; i++) {
              const currentChannel = COLS_TO_COMPARE[i];
              if (params.colDef.colId === currentChannel) {
                return getColor(currentChannel, params, compareToRow);
              }
            }
          }
          return {};
        },
      });
    });
    setStyledColumns(styledColumnData);
  }, [columns]);

  const exportBtnProps = {
    url: exportUrl,
    titleSpec,
  };
  return (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end">
        <ExportBtn {...exportBtnProps} />
      </Box>
      <BaseGrid
        autoSizeColumns={autoSize}
        rows={rows}
        colData={styledColumns}
        headerHeight={HEADER_HEIGHTS.oneRow}
      />
    </React.Fragment>
  );
};
export default PumpDiodesGrid;
