import React, { FC } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { routesConstants } from 'src/shared/routing/routes.constants';
import { COLORS } from 'src/styles/colors';
import { Location } from 'history';

type Props = {
  location: Location;
};

const MachineLearningTabs: FC<Props> = ({ location }) => (
  <Tabs
    value={location.pathname}
    indicatorColor="primary"
    style={{ backgroundColor: COLORS.white }}>
    <Tab
      label="Monitoring"
      component={Link}
      to={(location: Location) => ({
        ...location,
        pathname: routesConstants.MACHINE_LEARNING_MONITORING,
        exact: true,
      })}
      value={routesConstants.MACHINE_LEARNING_MONITORING}
    />
    <Tab
      label="Performance"
      component={Link}
      to={(location: Location) => ({
        ...location,
        pathname: routesConstants.MACHINE_LEARNING_PERFORMANCE,
        exact: true,
      })}
      value={routesConstants.MACHINE_LEARNING_PERFORMANCE}
    />
  </Tabs>
);

export default withRouter(MachineLearningTabs);
