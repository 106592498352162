import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const FileIcon: FC<Props> = (props) => {
  const { className, color } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 14 14">
      <path
        d="M10.1818 4.72727V11C10.1818 12.2055 9.20545 13.1818 8 13.1818C6.79455
        13.1818 5.81818 12.2055 5.81818 11V4.18182C5.81818 3.42909 6.42909 2.81818
        7.18182 2.81818C7.93455 2.81818 8.54545 3.42909 8.54545 4.18182V9.90909C8.54545 10.2091 8.3 10.4545
        8 10.4545C7.7 10.4545 7.45455 10.2091 7.45455 9.90909V4.72727H6.63636V9.90909C6.63636 10.6618
        7.24727 11.2727 8 11.2727C8.75273 11.2727 9.36364 10.6618 9.36364 9.90909V4.18182C9.36364
        2.97636 8.38727 2 7.18182 2C5.97636 2 5 2.97636 5 4.18182V11C5 12.6582 6.34182 14 8 14C9.65818
        14 11 12.6582 11 11V4.72727H10.1818Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default FileIcon;
