import BaseApi from '.';

const feedbacks = new BaseApi('./feedbacks');

export enum Statuses {
  normal = 'normal',
  unstable = 'unstable',
  critical = 'critical',
  failed = 'failed',
}

export enum FeedbackStatus {
  positive = 'positive',
  negative = 'negative',
  none = 'none',
}

export interface Feedback {
  id?: number;
  eb_user_id?: number;
  must_be_swapped: boolean;
  pump_state: string;
  compressor_state: string;
  controller_state: string;
  sa1_temp: boolean;
  sa2_temp: boolean;
  pump_rpm: boolean;
  pump_pressure: boolean;
  pump_heat: boolean;
  pump_vacuum: boolean;
  pump_array_contamination: boolean;
  compressor_fail: boolean;
  communication_fail: boolean;
  electronic_fail: boolean;
  he_contamination: boolean;
  reference_date: Date | null | string;
  pm_date: Date | null | string;
  created_at?: string;
  comment: string | null;
  tool_cust_fail: boolean;
  pump_id?: number;
  site_id?: number;
  feedback_status: FeedbackStatus;
}

export const feedbacksApi = {
  fetchFeedback: (params: URLSearchParams) =>
    feedbacks.get<{ data: Feedback[] }>({ params }),
  createFeedback: (data: Feedback) => feedbacks.post({ feedback: data }),
};
