import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { Checkbox, Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Close } from '@material-ui/icons';
import throttle from 'lodash/throttle';
import { AutocompleteOption } from 'src/shared/autocompletes-wtih-chips';
import { LIMIT_TAGS, isAllOption } from './autocomplete-with-infinite-loading.container';

export interface Props {
  limitTags?: number;
  label?: string;
  error?: boolean;
  helperText: string;
  handleOpen: () => void;
  handleClose: () => void;
  handleChange: (
    event: ChangeEvent<HTMLInputElement | Record<string, never>>,
    selectedValues: AutocompleteOption[]
  ) => void;
  className?: string;
  setIsScrollBottom: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOptions: AutocompleteOption[];
  options: AutocompleteOption[];
  loading: boolean;
  handleInputChange: (
    event: ChangeEvent<unknown>,
    value: string,
    reason: 'input' | 'reset' | 'clear'
  ) => void;
  inputValue: string;
}

const AutocompleteInfiniteLoading: FC<Props> = (props) => {
  const {
    label,
    error,
    helperText,
    handleOpen,
    handleClose,
    className,
    setIsScrollBottom,
    selectedOptions,
    loading,
    options,
    handleChange,
    handleInputChange,
    inputValue,
  } = props;

  const throttledFunc = useMemo(() => throttle(setIsScrollBottom, 200), [
    setIsScrollBottom,
  ]);

  const handleScroll = useCallback(
    ({ currentTarget }: React.UIEvent<HTMLUListElement>) => {
      return throttledFunc(
        currentTarget.scrollHeight - currentTarget.scrollTop <=
          currentTarget.clientHeight + currentTarget.scrollHeight * 0.1
      );
    },
    [throttledFunc]
  );

  return (
    <Autocomplete
      onOpen={handleOpen}
      onClose={handleClose}
      className={className}
      ListboxProps={{ onScroll: handleScroll }}
      value={selectedOptions}
      multiple
      disableCloseOnSelect
      clearOnBlur={false}
      loading={loading}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, selectedOption) =>
        option.value.toString() === selectedOption.value.toString()
      }
      limitTags={props.limitTags ? props.limitTags : LIMIT_TAGS}
      popupIcon={<KeyboardArrowDownIcon />}
      filterOptions={(options: AutocompleteOption[]) => options}
      renderTags={(values: AutocompleteOption[], getTagProps) =>
        values.map(
          (option: AutocompleteOption, index: number) =>
            !isAllOption(option) && (
              <Chip
                key={option.value}
                size="small"
                color="primary"
                label={option.label}
                deleteIcon={<Close />}
                {...getTagProps({ index })}
              />
            )
        )
      }
      renderOption={(option, { selected }) => (
        <>
          <Checkbox color="default" checked={selected} />
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={helperText}
          variant="filled"
          label={label}
          placeholder="..."
          error={error}
        />
      )}
    />
  );
};
export default AutocompleteInfiniteLoading;
