import React, { FC, ReactNode, memo, useCallback, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Flag, FlagOutlined } from '@material-ui/icons';
import FeedbackModal from 'src/app/feedback-modal';
import Button from 'src/shared/button';
import { useStyles } from './styles';
import { Feedback, FeedbackStatus } from 'src/store/api/feedbacks.api';

interface Props {
  toolName: string;
  pumpName: string;
  pumpId: number;
  siteId: number;
  children?: ReactNode;
  type?: 'icon' | 'button';
  status?: FeedbackStatus;
  onAfterSave?: (value: Feedback) => void;
}

const iconsMap = {
  [FeedbackStatus.positive]: Flag,
  [FeedbackStatus.negative]: Flag,
  [FeedbackStatus.none]: FlagOutlined,
};

const FeedbackAction: FC<Props> = (props) => {
  const {
    status = FeedbackStatus.none,
    toolName,
    pumpName,
    siteId,
    pumpId,
    children,
    type = 'icon',
    onAfterSave,
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const Icon = iconsMap[status];
  const title = `Feedback on ${toolName} - ${pumpName}`;
  const isIconType = type === 'icon';

  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <span className={classes.root}>
      {isIconType ? (
        <IconButton size="small" className={classes[status]} onClick={toggleDialog}>
          <Icon aria-haspopup="true" />
        </IconButton>
      ) : (
        <Button prefixIcon={<Flag fontSize="small" />} onClick={toggleDialog}>
          {children}
        </Button>
      )}

      {open && (
        <FeedbackModal
          pumpId={pumpId}
          siteId={siteId}
          title={title}
          isOpen={open}
          toggleDialog={toggleDialog}
          onAfterSave={onAfterSave}
        />
      )}
    </span>
  );
};

export default memo(FeedbackAction);
