import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { getWeekRange } from 'src/shared/date-range-filter/filters-config';
import { NotificationsHeader } from './notifications-header/notifications-header';
import { NotificationList } from './notification-list/notification-list';
import { NotificationDetailsContainer } from './notification-details';
import {
  AutoNotification,
  CategoryTypeOptions,
  ManualTypeOptions,
} from 'src/store/api/notifications.api';
import { ResizablePanels } from './resizable/resizable-panels';
import {
  getEquipmentType,
  useEquipmentIdentifiers,
} from 'src/shared/routing/useEquipmentIdentifiers';
import { GridApi, GridReadyEvent, IGetRowsParams } from 'ag-grid-community';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootDispatch } from 'src/store';

const [defaultStart, defaultEnd] = getWeekRange();

export const NotificationsContainer: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<RootDispatch>();

  const [gridApi, setGridApi] = useState<GridApi>();
  const { type } = useEquipmentIdentifiers();

  const [dates, setDates] = useState<[Date, Date]>([defaultStart, defaultEnd]);
  const [category, setCategory] = useState<CategoryTypeOptions>('all');
  const [manualType, setManualType] = useState<ManualTypeOptions>('all');

  const handleDatePickerChange = useCallback((start: Date, end: Date) => {
    setDates([start, end]);
  }, []);
  const handleCategorySelectChange = useCallback((category: CategoryTypeOptions) => {
    setCategory(category);
  }, []);
  const handleManualTypeSelectChange = useCallback((manualType: ManualTypeOptions) => {
    setManualType(manualType);
  }, []);

  const handleFetchRows = useCallback(
    (params: IGetRowsParams) => {
      dispatch.notifications.fetchNotificationList({
        ...params,
        dates,
        notificationType: category,
        manualType,
        equipmentType: getEquipmentType(type),
      });
    },
    [dispatch.notifications, type, dates, category, manualType]
  );

  const onAcknowledge = useCallback(
    (notification: AutoNotification) => {
      //always be an array with 1 element, because we have single select on grid
      const selectedRows = gridApi?.getSelectedNodes();

      if (selectedRows?.length) {
        selectedRows[0].setData(notification);
        gridApi?.refreshCells({ force: true, rowNodes: selectedRows });
      }
    },
    [gridApi]
  );

  // whenever user navigates between different sites/tools/pumps or use external filters (date range, category, etc.)
  // we have to clear/reset grid scroll position (infinite scroll params - startRow, endRow) and empty current rows
  // to achieve this we can call `setDatasource` method with the same handleFetchRows callback
  // so that way grid state will be reset and new http request will be sent to load new data
  useEffect(() => {
    gridApi?.setDatasource({ getRows: handleFetchRows });
    return () => {
      dispatch.notifications.setNotificationsListParams(null);
    };
  }, [
    location.search,
    dates,
    manualType,
    category,
    dispatch.notifications,
    gridApi,
    handleFetchRows,
  ]);

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
      <NotificationsHeader
        dates={dates}
        category={category}
        manualType={manualType}
        handleDatePickerChange={handleDatePickerChange}
        handleCategorySelectChange={handleCategorySelectChange}
        handleManualTypeSelectChange={handleManualTypeSelectChange}
      />

      <ResizablePanels>
        <NotificationList handleFetchRows={handleFetchRows} onGridReady={onGridReady} />
        <NotificationDetailsContainer onAcknowledge={onAcknowledge} />
      </ResizablePanels>
    </Box>
  );
};
