import React, { FC, memo } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  value: boolean;
  onChange?: (value: boolean) => void;
}

const Question: FC<Props> = (props) => {
  const { value, onChange } = props;
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value === 'true');
  };

  return (
    <RadioGroup
      row
      aria-label="question"
      name="question"
      value={value}
      onChange={handleChange}
      className={classes.root}>
      <Typography variant="body2" className={classes.text}>
        Pump needs to be swapped?
      </Typography>
      <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
      <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
    </RadioGroup>
  );
};

export default memo(Question);
