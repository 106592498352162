import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'form-association-polyfill';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'custom-event-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import AppRoot from 'src/root';

ReactDOM.render(<AppRoot />, document.getElementById('root'));

// Register sw in cacheable mode to speed-up long running requests
if (process.env.REACT_APP_CACHEABLE === 'true') {
  // eslint-disable-next-line
  require('./serviceWorker').register();
}
