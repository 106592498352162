import React, { FC } from 'react';
import GaugeChart from '../charts/gauge-chart';
import { useStyles } from './prediction-chart.styles';

export const EmptyPredictionChart: FC = (props) => {
  const { children } = props;
  const styles = useStyles();

  return (
    <GaugeChart value={0} status={''}>
      <div className={[styles.textCenter, styles.disabled].join(' ')}>{children}</div>
    </GaugeChart>
  );
};
