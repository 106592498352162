import { COLORS } from 'src/styles/colors';
import { makeStyles } from '@material-ui/core/styles';

const DIVIDER_HEIGHT = '5px';

// about 4 rows in grid (scrollbar visible & usable)
const TOP_PANEL_MIN_HEIGHT = '170px';

// header + few rows from content are (scrollbar visible & usable)
const BOTTOM_PANEL_MIN_HEIGHT = '150px';

export const useStyles = makeStyles({
  outerContainer: {
    position: 'relative',
    height: '100%',
  },
  innerContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  divider: {
    height: DIVIDER_HEIGHT,
    borderBottom: `2px solid ${COLORS.grey[400]}`,
    cursor: 'n-resize',
    zIndex: 1,
  },
  resizableTopPanel: {
    minHeight: TOP_PANEL_MIN_HEIGHT,
    maxHeight: `calc(100% - ${BOTTOM_PANEL_MIN_HEIGHT} - ${DIVIDER_HEIGHT})`,
  },
  resizableBottomPanel: {
    minHeight: BOTTOM_PANEL_MIN_HEIGHT,
    maxHeight: `calc(100% - ${TOP_PANEL_MIN_HEIGHT} - ${DIVIDER_HEIGHT})`,
  },
});
