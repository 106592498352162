import { connect } from 'react-redux';
import FeedbackModal from './feedback-modal';
import { RootDispatch, RootState } from '../../store';

export const mapStateToProps = (state: RootState) => ({
  feedback: state.feedback.feedbackData,
  invalidFields: state.feedback.invalidFields,
  isFetchLoading: state.loading.effects.feedback.fetchFeedback,
  isCreateLoading: state.loading.effects.feedback.createFeedback,
});

export const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchFeedback: dispatch.feedback.fetchFeedback,
  createFeedback: dispatch.feedback.createFeedback,
  setInvalidFields: dispatch.feedback.setInvalidFields,
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
