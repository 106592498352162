import BaseApi from '.';
import { FetchScoreQuery } from 'src/store/models/ml.model';

export interface Model {
  created_at: string;
  name: string;
}

interface Score {
  dataset: unknown; //Todo: Find exact type
  model: Model | string;
  scores: unknown; //Todo: Find exact type
}

export type PipelineEvent = {
  name: string;
  created_at: string;
  metadata: unknown;
};

type PipelineExecution = {
  created_at: string;
  finished_at: string | null;
  predictions_count: number;
  events: PipelineEvent[];
};

export type PipelineExecutions = PipelineExecution[];

const modelApi = new BaseApi('/prediction_models');

export const MLApi = {
  fetchModels() {
    return modelApi.get<Model[]>();
  },

  fetchScore(query?: FetchScoreQuery) {
    const { ...params } = query;
    const api = new BaseApi(`/prediction_models/${query?.model_id}/scores`);
    return api.get<Score>({ params });
  },

  fetchPipelineExecutions() {
    const api = new BaseApi('/prediction_models/pipelines');
    return api.get<PipelineExecutions>();
  },
};
