import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { ColData } from 'src/shared/base-grid/base-grid';
import { ColumnResp, viewsApi } from './../api/views.api';

interface ViewsState {
  pumps: ColumnResp | null;
  compressors: ColumnResp | null;
  controllers: ColumnResp | null;
  pumpSettings: ColumnResp | null;
}

export const views = createModel<RootModel>()({
  state: {
    pumps: null,
    compressors: null,
    pumpSettings: null,
  } as ViewsState,

  reducers: {
    setPumpView: (state: ViewsState, view: ColumnResp): ViewsState => ({
      ...state,
      pumps: view,
    }),
    updatePumpColumns: (state: ViewsState, pumps: ColData[]): ViewsState =>
      state.pumps != null
        ? {
            ...state,
            pumps: { ...state.pumps, columns: pumps },
          }
        : {
            ...state,
          }, //todo: Should there be an error thrown in case no pumps have been fetched for them to be updated?
    setCompressorsView: (state: ViewsState, view: ColumnResp | null): ViewsState => ({
      ...state,
      compressors: view,
    }),
    setControllersView: (state: ViewsState, view: ColumnResp | null): ViewsState => ({
      ...state,
      controllers: view,
    }),
    setPumpSettingsView: (state: ViewsState, view: ColumnResp | null): ViewsState => ({
      ...state,
      pumpSettings: view,
    }),
  },

  effects: (dispatch) => {
    const { views } = dispatch;

    return {
      async fetchPumpView() {
        const data = await viewsApi.fetchPumpView();
        views.setPumpView(data);
      },

      async updatePumpView(columns: ColData[], rootState) {
        if (rootState.views.pumps === null) return;
        const updateView = {
          ...rootState.views.pumps,
          columns,
        };
        views.updatePumpColumns(columns);
        await viewsApi.updatePumpView(updateView);
      },

      async fetchCompressorsView() {
        const data = await viewsApi.fetchCompressorsView();
        views.setCompressorsView(data);
      },

      async fetchControllersView() {
        const data = await viewsApi.fetchControllersView();
        views.setControllersView(data);
      },

      async fetchPumpSettingsView() {
        const data = await viewsApi.fetchPumpSettingsView();
        views.setPumpSettingsView(data);
      },
    };
  },
});
