import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      toolContainer: {
        height: '3.5rem',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: COLORS.white,
        borderBottom: `1px solid ${COLORS.grey['300']}`,
      },
      eventContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '0.45rem',
      },
      columnDropdownContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginRight: '0.45rem',
        alignItems: 'center',
      },
    })
);
