import groupBy from 'lodash/groupBy';
import { eachDayOfInterval, format, getHours } from 'date-fns';
import { getShiftedDateFromISO } from 'src/services/timezone.helper';
import { HeatMapDataObject } from 'src/shared/charts/base-chart';
import { ANOMALY_VALUE } from '../../app/pump/pump-anomalies/pump-anomalies-chart/config';
import { Anomaly } from '../api/anomalies.api';

interface DataMapperConfig {
  data: Anomaly[];
  offset: number;
  startDate: Date;
  endDate: Date;
}

/**
 * Function that prepares data for Anomalies HeatMapChart
 * @example getChartData([{
 *       "rpm": 50,
 *      "timestamp": "2020-05-18T01:00:00.000Z",
 *      "isPositiveAnomaly": true,
 *      "isNegativeAnomaly": null
 *  }],
 *  '2018-03-18T01:00:00.000Z',
 *  '2018-05-18T01:00:00.000Z'
 *  )
 * @param {DataMapperConfig} config
 * @param {Array} config.data
 * @param {String} config.startDate
 * @param {String} config.endDate
 * @return {HeatMapDataObject[]} - [{value: [x, y, value], label: (local date) (local time)}]
 * [{value: [0, 1, 70], label: 05/18/2020 1:00 PM}]
 */
export const getChartData = (config: DataMapperConfig): HeatMapDataObject[] => {
  const { data, startDate, endDate, offset } = config;
  const daysRange = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });
  const groupedData = groupBy(data, (entry) =>
    getShiftedDateFromISO(entry.timestamp, offset).toDateString()
  );

  return daysRange.reduce((acc, day, x) => {
    const formattedDay = day.toDateString();
    const dataByDay = groupedData[formattedDay];

    if (dataByDay) {
      const coords: HeatMapDataObject[] = dataByDay.map(({ rpm, timestamp, ...rest }) => {
        const dateTime = getShiftedDateFromISO(timestamp, offset);
        const y = getHours(dateTime);
        const value =
          rest.is_negative_anomaly || rest.is_positive_anomaly ? ANOMALY_VALUE : rpm;

        return {
          value: [x, y, value],
          label: format(dateTime, 'P p'),
          name: rpm.toString(),
        } as HeatMapDataObject;
      });

      acc.push(...coords);
    }

    return acc;
  }, [] as HeatMapDataObject[]);
};
