import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      base: {
        strokeDasharray: '280',
        strokeLinecap: 'round',
        strokeWidth: '11',
        transform: 'rotateZ(135deg)',
        transformOrigin: '50% 50%',
      },
      path: {
        stroke: 'currentColor',
        strokeDashoffset: '70',
      },
      bar: {
        strokeDashoffset: '280',
      },
    })
);
