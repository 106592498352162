import React, { ComponentType } from 'react';
import { useStyles } from './prediction-dropdown.styles';
import CriticalPredictionIcon from 'src/shared/icons/critical-prediction-icon';
import UnstablePredictionIcon from 'src/shared/icons/unstable-prediction-icon';
import NormalPredictionIcon from 'src/shared/icons/normal-prediction-icon';

export interface Option {
  value: string;
  label: string;
  icon?: JSX.Element;
}

interface Props {
  component: ComponentType<{ className?: string }>;
}

const Icon = (props: Props) => {
  const { component: Component } = props;
  const styles = useStyles();
  return <Component className={styles.img} />;
};

export const OPTIONS: Option[] = [
  {
    value: 'critical',
    label: 'Critical',
    icon: <Icon component={CriticalPredictionIcon} />,
  },
  {
    value: 'warning',
    label: 'Unstable',
    icon: <Icon component={UnstablePredictionIcon} />,
  },
  {
    value: 'normal',
    label: 'Normal',
    icon: <Icon component={NormalPredictionIcon} />,
  },
];
