import React, { FC, memo, useMemo } from 'react';
import HeatMapChart from 'src/shared/charts/heatmap-chart';
import { useStyles } from 'src/app/pump/pump-anomalies/pump-anomalies-chart/pump-anomalies-chart.styles';
import { chartConfig } from 'src/app/pump/pump-anomalies/pump-anomalies-chart/config';
import { VisualMap } from 'src/app/pump/pump-anomalies/visual-map';
import { Typography } from '@material-ui/core';
import { eachDayOfInterval, getDate } from 'date-fns';
import { HeatMapDataObject } from 'src/shared/charts/base-chart';

interface Props {
  data: HeatMapDataObject[];
  dateRange: [Date, Date];
}

const CHART_TITLE = 'RPM Anomalies';
const getYAxis = () => Array.from({ length: 25 }, (entry, i) => `${i}h`);
const getXAxis = (startDate: Date, endDate: Date): number[] =>
  eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map((entry: Date) => getDate(entry));

const PumpAnomaliesChart: FC<Props> = (props) => {
  const { data, dateRange } = props;
  const classes = useStyles();

  const chartData: Record<string, HeatMapDataObject[]> = { [CHART_TITLE]: data };

  const xAxis = useMemo(
    () => ({
      ...chartConfig.xAxisBase,
      data: getXAxis(...dateRange),
    }),
    [dateRange]
  );

  const yAxis = useMemo(
    () => ({
      ...chartConfig.yAxisBase,
      data: getYAxis(),
    }),
    []
  );

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2" component="h3">
        {CHART_TITLE}
      </Typography>
      <HeatMapChart {...chartConfig} data={chartData} xAxis={xAxis} yAxis={yAxis} />

      <VisualMap />
    </div>
  );
};

export default memo(
  PumpAnomaliesChart,
  (prevProps, nextProps) => prevProps.data === nextProps.data
);
