import React, { Component } from 'react';
import { DragStartedEvent, IHeaderParams } from 'ag-grid-community';
import arrow from './icons/arrow.svg';
import { WithStyles } from '@material-ui/core';
import styles from './base-header.styles';
import { StyledColDef } from '../base-grid';

type CustomParams = {
  dataType: string;
  updateSort?: (columnSorts: { colId: string; sort: unknown }[]) => void; //note: sort propertie can be of type ( string | null | undefined )
};

type SortTypes = 'ascSort' | 'descSort';

export type OrderTypes = 'asc' | 'desc' | '';

type State = {
  [key in SortTypes]: boolean;
} & {
  isColumnMoving: boolean;
  showDataType: boolean;
};

export type BaseHeaderProps = IHeaderParams & CustomParams;

export default class BaseHeader extends Component<
  BaseHeaderProps & WithStyles<typeof styles>,
  State
> {
  public state: State = {
    ascSort: false,
    descSort: false,
    isColumnMoving: false,
    showDataType: false,
  };

  public componentDidMount() {
    const { column, api } = this.props;

    const showDataType = api
      .getColumnDefs()
      .some((column: StyledColDef) => column.headerComponentParams.dataType);

    this.setState({ ...this.state, showDataType });

    api.addEventListener('dragStarted', this.onDragStart);
    api.addEventListener('dragStopped', this.onDragStop);
    column.addEventListener('sortChanged', this.onSortChanged);

    this.onSortChanged();
  }

  public componentWillUnmount() {
    const { column, api } = this.props;

    api.removeEventListener('dragStarted', this.onDragStart);
    api.removeEventListener('dragStopped', this.onDragStop);
    column.removeEventListener('sortChanged', this.onSortChanged);
  }

  private onSortChanged = (): void => {
    const { column } = this.props;

    this.setState({
      ascSort: column.isSortAscending(),
      descSort: column.isSortDescending(),
    });
  };

  private onDragStart = (ev: DragStartedEvent) => {
    this.setState({ isColumnMoving: true });
  };

  private onDragStop = (ev: DragStartedEvent) => {
    this.setState({ isColumnMoving: false });
  };

  private sort = (order: OrderTypes) => this.props.setSort(order);

  private onSortRequested = () => {
    const { column, enableSorting, columnApi, updateSort } = this.props;

    let order: OrderTypes = '';

    switch (column.getSort()) {
      case 'asc':
        order = 'desc';
        break;
      case 'desc':
        order = '';
        break;
      default:
        order = 'asc';
        break;
    }

    if (enableSorting && !this.state.isColumnMoving) {
      this.sort(order);

      const columnSorts = columnApi?.getAllColumns()?.map((el) => ({
        colId: el.getColId(),
        sort: el.getSort(),
      }));

      updateSort && columnSorts && updateSort(columnSorts);
    }
  };

  private selectIconClass = () => {
    const { ascSort, descSort } = this.state;

    if (ascSort) {
      return this.props.classes.arrowAsc;
    }

    if (descSort) {
      return '';
    }

    return this.props.classes.arrowNone;
  };

  // don't remove!!! ag-grid uses this component
  public getReactContainerClasses() {
    return [this.props.classes.headerWrapper];
  }

  render() {
    return (
      <div className={this.props.classes.headerContainer}>
        <div className={this.props.classes.sortContainer} onClick={this.onSortRequested}>
          {this.props.children ? (
            this.props.children
          ) : (
            <span>{this.props.displayName}</span>
          )}
          <img className={this.selectIconClass()} src={arrow} alt="arrow" />
        </div>
        {this.state.showDataType && (
          <div className={this.props.classes.dataType}>{this.props.dataType}</div>
        )}
      </div>
    );
  }
}
