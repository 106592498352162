import React from 'react';

interface Props {
  children: string;
}

export const Monogram: React.FunctionComponent<Props> = function Monogram(props) {
  return (
    <React.Fragment>
      {props.children.replace(/\S+\s+|\S+$/gu, (match) => match[0])}
    </React.Fragment>
  );
};
