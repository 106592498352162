import React, { ChangeEvent, FC, useCallback } from 'react';
import { useStyles } from './styles';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Close as ClearIcon, Search as SearchIcon } from '@material-ui/icons';

type ComponentProps = {
  value: string;
  placeholder?: string;
  inputClassName?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | null) => void;
};

const SearchInput: FC<ComponentProps> = (props) => {
  const classes = useStyles();
  const {
    value,
    onChange,
    placeholder = 'Search',
    inputClassName = classes.input,
  } = props;
  const handleClear = useCallback(() => {
    onChange(null);
  }, [onChange]);
  return (
    <div className={classes.container}>
      <TextField
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={inputClassName}
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.inputAdornment} position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: value && (
            <InputAdornment
              className={classes.inputAdornment}
              position="end"
              onClick={handleClear}>
              <ClearIcon />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
      />
    </div>
  );
};
export default SearchInput;
