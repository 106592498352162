import { connect } from 'react-redux';
import PumpAnomalies from 'src/app/pump/pump-anomalies/pump-anomalies';
import { RootDispatch, RootState } from 'src/store';

export const mapStateToProps = (state: RootState) => ({
  anomalies: state.anomalies.anomaliesData,
  selectedZone: state.timezone.selectedZone,
  isLoading: state.loading.effects.anomalies.fetchAnomalies,
  pump: state.pump.details,
});

export const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchData: dispatch.anomalies.fetchAnomalies,
  fetchPumpDetails: dispatch.pump.fetchPumpDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(PumpAnomalies);
