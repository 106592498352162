/**
 * Indicate whether pump should have a failure probability prediction
 */
export const isPredictable = (model = '') => {
  const models = [
    // 320x
    'On-Board IS-320FE',
    'On-Board IS-320F',
    'On-Board IS-320FX',

    // 250x
    'On-Board IS-250FE',
    'On-Board IS-250F',
    'On-Board IS-250FX',

    // 8x
    'On-Board IS-8F',
    'On-Board IS-8F XP',
    'On-Board IS-8',
  ].map((model) => model.toLowerCase());

  return models.includes(model.trim().toLowerCase());
};
