import React, { FC } from 'react';
import { useStyles } from './wrapper.styles';

type OwnProps = {
  title?: string;
};

const Wrapper: FC<OwnProps> = (props) => {
  const { children, title } = props;
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {title && <div className={styles.title}>{title}</div>}
      <div>{children}</div>
    </div>
  );
};

export default Wrapper;
