import React, { CSSProperties } from 'react';
import { Grid } from '@material-ui/core';
import { Roles } from 'src/store/api/users.api';
import { routesConstants } from 'src/shared/routing/routes.constants';
import RoutesList from 'src/shared/routing/routes-list';
import { MonitoringContainer } from './monitoring';
import { ModelPerformanceWidgetContainer } from './performance/model-performance.container';

const routes = [
  {
    path: routesConstants.MACHINE_LEARNING_MONITORING,
    component: MonitoringContainer,
    exact: true,
    allowedRoles: [Roles.admin],
  },
  {
    path: routesConstants.MACHINE_LEARNING_PERFORMANCE,
    component: ModelPerformanceWidgetContainer,
    exact: true,
    allowedRoles: [Roles.admin],
  },
];

const container: CSSProperties = {
  flexGrow: 1,
  minHeight: 0,
  flexWrap: 'nowrap',
};

const MachineLearning = () => {
  return (
    <Grid container spacing={0} style={container}>
      <RoutesList routes={routes} />
    </Grid>
  );
};

export { MachineLearning };
