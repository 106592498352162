import React, { ComponentType, FC, Fragment } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Roles } from 'src/store/api/users.api';
import { checkPermission } from 'src/services/auth';

export interface AppRoute {
  path: string;
  exact: boolean;
  component: ComponentType<any> | null;
  allowedRoles?: Roles[];
  getComponentKey?: (params: RouteComponentProps) => string | number;
}
interface Props {
  routes: AppRoute[];
  wrapper?: ComponentType;
  wrapperProps?: unknown;
}

const RoutesList: FC<Props> = (props) => {
  const { routes, wrapper: WrapperComponent = Fragment, wrapperProps } = props;
  return (
    <Switch>
      {routes.map(
        (route) =>
          route.component &&
          (!route.allowedRoles || checkPermission(route.allowedRoles)) && (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              render={(params) =>
                route.component && (
                  <WrapperComponent {...wrapperProps}>
                    <route.component key={route.getComponentKey?.(params)} {...params} />
                  </WrapperComponent>
                )
              }
            />
          )
      )}
    </Switch>
  );
};

export default RoutesList;
