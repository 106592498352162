import React from 'react';
import { getManualTypeIcon } from 'src/app/notification/notifications/notifications.helper';
import { ICellRendererParams } from 'ag-grid-community';
import { COLORS } from 'src/styles/colors';
import { useStyles } from './manual-type-styles';
import { ManualTypes } from 'src/store/api/notifications.api';
import { decamelizeAndCapitalize } from 'src/services/notifications-service';

const fillColor = COLORS.grey[700];

export const ManualTypeRenderer = (props: ICellRendererParams) => {
  const classes = useStyles();

  const renderIcon = () => {
    if (Object.values(ManualTypes).includes(props.value)) {
      return getManualTypeIcon(props.value, classes.icon, fillColor);
    }
  };

  if (!props.value) {
    return '';
  }

  return (
    <div className={classes.root} title={decamelizeAndCapitalize(props.value)}>
      {renderIcon()}
    </div>
  );
};
