import React from 'react';
import { Pump, Query } from '../predictions-dashboard';
import { useStyles } from '../predictions-dashboard.styles';

interface Props {
  entity: 'site' | 'tool' | 'pump';
  pump: Pump;
  onMoveTo?: (query: Query) => void;
}

const PumpEntityLink = (props: Props) => {
  const { entity, pump, onMoveTo } = props;
  const styles = useStyles();

  let ref = {};
  let title = '';

  switch (entity) {
    case 'site':
      ref = {
        siteId: pump.siteId,
      };
      title = pump.siteName;
      break;
    case 'tool':
      ref = {
        siteId: pump.siteId,
        toolId: pump.toolId,
      };
      title = pump.toolName;
      break;
    case 'pump':
      ref = {
        siteId: pump.siteId,
        toolId: pump.toolId,
        pumpId: pump.id,
      };
      title = pump.stationName;
      break;
    default:
      ref = {};
      title = '';
  }

  const onLinkClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onMoveTo?.(ref as Query);
  };
  return (
    <span
      className={styles.pseudoLink}
      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
        onLinkClick(event)
      }>
      {title}
    </span>
  );
};
export default PumpEntityLink;
