import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { anomaliesApi } from '../api/anomalies.api';
import { getShiftedISOFromDate } from 'src/services/timezone.helper';
import { Timezone } from './timezone.model';
import { getChartData } from '../normalize/anomalies';
import { HeatMapDataObject } from '../../shared/charts/base-chart';

export interface AnomaliesState {
  anomaliesData: HeatMapDataObject[];
}

export interface AnomaliesFetchParams {
  search: string;
  start: Date;
  end: Date;
  timezone: Timezone;
}

export const anomalies = createModel<RootModel>()({
  state: {
    anomaliesData: [],
  } as AnomaliesState,

  reducers: {
    setAnomalies: (
      state: AnomaliesState,
      anomaliesData: HeatMapDataObject[]
    ): AnomaliesState => ({
      ...state,
      anomaliesData,
    }),
  },

  effects: (dispatch) => ({
    async fetchAnomalies(params: AnomaliesFetchParams) {
      const { search, start, end, timezone } = params;
      if (!timezone || !timezone.name) {
        return;
      }

      const query = new URLSearchParams(search);

      query.set('startTime', getShiftedISOFromDate(start, timezone.offset));
      query.set('endTime', getShiftedISOFromDate(end, timezone.offset));

      const { data } = await anomaliesApi.fetchData(query);
      const normalizedData = getChartData({
        data,
        offset: timezone.offset,
        startDate: start,
        endDate: end,
      });
      dispatch.anomalies.setAnomalies(normalizedData);
    },
  }),
});
