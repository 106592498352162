import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      exportBtn: {
        '&:hover': {
          background: COLORS.grey[500],
        },
      },
      icon: {
        width: '1.5rem',
        height: '1.5rem',
      },
    })
);
