import { useMediaQuery } from '@material-ui/core';
import React, { FC } from 'react';
import DateRangeFilter from 'src/shared/date-range-filter';
import { notificationDateFilters } from 'src/shared/date-range-filter/filters-config';

interface Props {
  dates: [Date, Date];
  handleDatePickerChange: (start: Date, end: Date) => void;
}

const quickFilters = notificationDateFilters();

export const DateFilter: FC<Props> = ({ dates, handleDatePickerChange }) => {
  const isSmallScreen = useMediaQuery('(max-width:1550px)');
  return (
    <DateRangeFilter
      endDate={dates[1]}
      startDate={dates[0]}
      quickFilters={quickFilters}
      onChange={handleDatePickerChange}
      //setting up a input datepicker inputs width on small screens, so header content displayed in one row on 15,6 inch screens
      //or do not provide styles if screen is wider
      datepickerInputStyle={isSmallScreen ? { width: '150px' } : undefined}
    />
  );
};
