const baseGridSizes = {
  headerHeight: 112,
  rowHeight: 32,
  defaultBlockSize: 35,
};

//constants are representatives of maximal headers heights
//it is needed for fitting the whole header content into header
const UNITS = 16;
export const HEADER_HEIGHTS = {
  oneRow: 40,
  oneRowWithUnits: 40 + UNITS,
  twoRows: 60,
  twoRowsWithUnits: 60 + UNITS,
  threeRows: 80,
  threeRowsWithUnits: 80 + UNITS,
};

export default baseGridSizes;
