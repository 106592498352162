import React, { FC, useEffect, useMemo } from 'react';
import { WithStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { getQuerySearch } from 'src/shared/routing/routing.helper';
import baseGridSizes from 'src/shared/base-grid/base-grid.config';
import MaintenanceDashboardGrid from './maintenance-dashboard-grid';
import MaintenanceDashboardDropdowns from './maintenance-dashboard-dropdowns';
import MaintenanceDashboardByToolContainer from './tool/maintenance-dashboard-by-tool.container';
import styles from './maintenance-dashboard.styles';
import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import { TOOL } from 'src/shared/routing/with-selected-equipment';

type ConnectedProps = ReturnType<typeof mstp> & ReturnType<typeof mdtp>;

type Props = ConnectedProps & WithStyles<typeof styles>;

const actionsColInfo = {
  label: 'Actions',
  name: 'feedbackAction',
  createdAt: '',
  filterId: null,
  id: 0,
  index: null,
  sortDirection: '',
  sortOrder: '',
  type: '',
  units: '',
  viewId: null,
  visibility: true,
};

const getMinHeight = (rows?: number) => {
  // reserve space for at least 7 rows by default (if total row count not defined)
  // 7 rows were chosen because in the majority of cases there are 7 pumps for each tool
  const MIN_ROWS_TO_DISPLAY = 7;

  rows = typeof rows === 'undefined' ? MIN_ROWS_TO_DISPLAY : rows;

  // calculate minimum grid height using:
  // - header height
  // - rows to display
  const horizontalScrollHeight = 18; // win scroll 17 (mac scroll 15) + 1 pixel imprecision
  return (
    baseGridSizes.headerHeight + horizontalScrollHeight + baseGridSizes.rowHeight * rows
  );
};

const MaintenanceDashboardContainer: FC<Props> = (props) => {
  const {
    rows,
    columns,
    fetchRows,
    fetchColumns,
    search,
    predictionStatuses,
    siteScope,
    view,
    updateColumns,
    updateView,
    updateStatuses,
  } = props;

  const { type } = useEquipmentIdentifiers();

  const dashboardKey = search + siteScope.join('');

  useEffect(() => {
    if (!columns) fetchColumns();
  }, [columns, fetchColumns]);

  const columnsWithActions = useMemo(
    () => (columns?.length ? [...columns, { ...actionsColInfo }] : []),
    [columns]
  );

  const isToolSelected = useMemo(() => type === TOOL, [type]);

  const renderPumpsGrid = (displayAllRows: boolean) => {
    const renderGrid = () =>
      columns?.length && (
        <MaintenanceDashboardGrid
          displayAllRows={displayAllRows}
          predictionStatuses={predictionStatuses}
          view={view}
          columns={columnsWithActions}
          fetchRows={fetchRows}
          updateColumns={updateColumns}
          updateView={updateView}
          key={dashboardKey}
        />
      );

    return displayAllRows ? (
      <div style={{ height: getMinHeight(rows?.data.length) }}>{renderGrid()}</div>
    ) : (
      renderGrid()
    );
  };

  return (
    <div className={isToolSelected ? '' : props.classes.container}>
      {isToolSelected && <MaintenanceDashboardByToolContainer />}

      <MaintenanceDashboardDropdowns
        columns={columns || []}
        onSelectChange={updateStatuses}
        predictionStatuses={predictionStatuses}
      />

      {renderPumpsGrid(isToolSelected)}
    </div>
  );
};

const mstp = (store: RootState) => ({
  view: store.views.pumps,
  predictionStatuses: store.pumps.selectedStatuses,
  rows: store.pumps.rows,
  columns: store.views.pumps?.columns,
  siteScope: store.siteEquipment.selectedSites,
  search: getQuerySearch(),
});

const mdtp = (dispatch: RootDispatch) => ({
  fetchRows: dispatch.pumps.fetchRows,
  fetchColumns: dispatch.views.fetchPumpView,
  updateStatuses: dispatch.pumps.updateStatuses,
  updateColumns: dispatch.views.updatePumpColumns,
  updateView: dispatch.views.updatePumpView,
});

export default connect(mstp, mdtp)(MaintenanceDashboardContainer);
