import React, { ChangeEvent, Component } from 'react';
import styles from './column-dropdown.styles';
import colums from '../icons/colums.svg';
import { ColData } from 'src/shared/base-grid/base-grid';
import ColumnDropdownRows from './column-dropdown-rows';
import { ColumnDropdownActions } from './column-dropdown-actions';
import { connect } from 'react-redux';
import { RootDispatch } from 'src/store';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Divider, WithStyles } from '@material-ui/core';
import SearchInput from 'src/shared/search-input';

interface OwnProps extends WithStyles<typeof styles> {
  columns: ColData[];
}

interface State {
  isOpened: boolean;
  filteredColumns: ColData[] | null;
  filterValue: string;
  newColumns: ColData[] | null;
}

const initialState: State = {
  isOpened: false,
  filteredColumns: null,
  filterValue: '',
  newColumns: null,
};

type ComponentProps = OwnProps & ReturnType<typeof mdtp>;

class ColumnDropdown extends Component<ComponentProps, State> {
  public state: State = initialState;

  public componentDidMount(): void {
    const { columns } = this.props;
    this.setState({
      filteredColumns: columns,
      newColumns: columns,
    });
  }

  public componentDidUpdate(prevProps: Readonly<ComponentProps>): void {
    const { columns } = this.props;
    if (!prevProps.columns.length && columns.length) {
      this.setState({
        filteredColumns: columns,
        newColumns: columns,
      });
    }
  }

  private toggleDropdown = () => {
    const { isOpened } = this.state;
    if (isOpened) {
      this.onFormCancel();
    } else {
      this.setState({ isOpened: !isOpened });
    }
  };

  private onFilterChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | null
  ) => {
    event?.persist();
    const { newColumns } = this.state;

    this.setState(() => {
      const filterValue = event?.target.value || '';
      const filteredColumns =
        newColumns?.filter((el) =>
          el.label.toLowerCase().includes(filterValue.toLowerCase())
        ) || null;

      return { filterValue, filteredColumns };
    });
  };

  private updateVisibility = () => {
    const { newColumns } = this.state;
    const { updateVisibility } = this.props;

    if (newColumns) {
      updateVisibility(newColumns);
    }
  };

  private onColumnsChange = (column: ColData, value: boolean) => {
    const { newColumns, filteredColumns } = this.state;
    if (newColumns) {
      const columnsMapCallback = (el: ColData) => {
        if (el.name === column.name) {
          return {
            ...el,
            visibility: value,
          };
        }
        return el;
      };
      this.setState({
        newColumns: newColumns.map(columnsMapCallback),
        filteredColumns: filteredColumns?.map(columnsMapCallback) || null,
      });
    }
  };

  private onFormApply = () => {
    const { newColumns } = this.state;

    if (newColumns) {
      this.updateVisibility();
    }

    this.setState({
      isOpened: false,
      filterValue: '',
      filteredColumns: newColumns,
    });
  };

  private onFormCancel = () => {
    const { columns } = this.props;
    this.setState({
      isOpened: false,
      filteredColumns: columns,
      newColumns: columns,
      filterValue: '',
    });
  };

  render() {
    const { isOpened, filterValue, filteredColumns, newColumns } = this.state;
    const { columns } = this.props;
    const selectedRowsCount = newColumns?.filter((el) => el.visibility === true).length;
    const columnNotFound: boolean =
      filterValue.length > 0 && filteredColumns?.length === 0;

    return (
      <div>
        <div className={this.props.classes.select} onClick={this.toggleDropdown}>
          <img src={colums} alt="colums" />
          <div className={this.props.classes.title}>Columns</div>
          {isOpened ? <ExpandLess /> : <ExpandMore />}
        </div>
        {isOpened && (
          <div className={this.props.classes.options}>
            <SearchInput value={filterValue} onChange={this.onFilterChange} />
            <Divider />
            <ColumnDropdownRows
              onColumnsChange={this.onColumnsChange}
              filteredColumns={filteredColumns}
              selectedRowsCount={selectedRowsCount || 0}
              total={columns.length}
              colNotFound={columnNotFound}
            />
            {!!columns?.length && (
              <ColumnDropdownActions
                onApply={this.onFormApply}
                onCancel={this.onFormCancel}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mdtp = (dispatch: RootDispatch) => ({
  updateVisibility: (pumpView: ColData[]) => dispatch.views.updatePumpView(pumpView),
});

export default connect(null, mdtp)(ColumnDropdown);
