import React, { RefObject } from 'react';
import isEqual from 'lodash/isEqual';
import { Box } from '@material-ui/core';

import chart from 'echarts';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoomInside';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/legendScroll';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/heatmap';
import 'zrender/lib/svg/svg';

import defaultPalette from './palette';

export type ChartXAxisOption = chart.EChartOption.XAxis;
export type ChartYAxisOption = chart.EChartOption.YAxis;
export type ChartVisualMapOption = chart.EChartOption.VisualMap;
export type ChartTooltipOption = chart.EChartOption.Tooltip;
export type ChartGridOption = chart.EChartOption.Grid;
export type ChartSeriesOption = chart.EChartOption.Series[];
export type ChartSeries = chart.EChartOption.Series;
export type ChartSeriesLine = chart.EChartOption.SeriesLine;
export type ChartVisualMap = chart.EChartOption.VisualMap;

export type HeatMapDataObject = chart.EChartOption.SeriesHeatmap.DataObject;

export type ChartOption = chart.EChartOption;
export type TooltipFormatter = chart.EChartOption.Tooltip.Formatter;

type Renderer = 'svg' | 'canvas';

export interface BaseChartProps {
  height?: number;
  series: ChartSeriesOption;
  tooltip?: ChartTooltipOption;
  options?: chart.EChartOption;
  renderer?: Renderer;
}

export interface AxisConfig {
  xAxis: ChartXAxisOption | ChartXAxisOption[];
  yAxis: ChartYAxisOption | ChartYAxisOption[];
}

export interface BaseChartConfig {
  axisConfig: AxisConfig;
}

export class BaseChart extends React.Component<BaseChartProps> {
  static defaultProps = {
    renderer: 'svg' as Renderer | undefined,
    height: 250,
  };

  private chartContainer = React.createRef<HTMLDivElement>();

  private chart: chart.ECharts | null = null;

  componentDidMount() {
    const { current } = this.chartContainer;
    const { renderer, height } = this.props;

    this.chart = chart.init(current!, undefined, {
      renderer,
      height,
    });
    this.chart.setOption(this.getOptions());

    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: BaseChartProps) {
    if (!isEqual(prevProps, this.props) && this.chart) {
      this.chart.setOption(this.getOptions(), true);
      this.handleResize();
    }
  }

  componentWillUnmount() {
    this.chart?.dispose();

    window.removeEventListener('resize', this.handleResize);
  }

  private getOptions() {
    const { series, tooltip, options } = this.props;
    return {
      color: defaultPalette,
      series,
      tooltip: tooltip,
      ...options,
    };
  }

  private handleResize = (): void => {
    this.chart && this.chart.resize();
  };

  render() {
    return (
      <Box
        width="100%"
        height={this.props.height}
        overflow="hidden"
        /**
         *  https://github.com/mui-org/material-ui/issues/17010
         *  Types issue workaround. Should be replaced with ref prop after resolving.
         */
        {...({ ref: this.chartContainer } as { ref: RefObject<HTMLDivElement> })}
      />
    );
  }
}
