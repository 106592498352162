import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  headerRoot: {
    padding: '1rem',
  },
  selectSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    justifySelf: 'end',

    '@media(max-width:1280px)': {
      paddingTop: '1rem',
    },
  },
  select: {
    width: '150px',
  },
  icon: {
    width: '1rem',
    height: '0.9rem',
    marginRight: '0.5rem',
  },
});
