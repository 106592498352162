import React, { ReactNode, SyntheticEvent } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStyles } from './dialog.styles';
import Button from '../button';

export enum Sizes {
  small = 'xs',
  normal = 'sm',
  large = 'md',
}

interface Props {
  title?: string | number | ReactNode;
  open: boolean;
  onSubmit?: (e: SyntheticEvent<Element, Event>) => void;
  onClose?: (e: SyntheticEvent<Element, Event>) => void;
  children?: ReactNode;
  size?: Sizes | false;
  className?: string;
  isSubmitDisabled?: boolean;
  isSubmitBusy?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
}

const Dialog = (props: Props): JSX.Element => {
  const {
    open,
    children,
    title,
    onClose,
    onSubmit,
    size = Sizes.normal,
    className,
    isSubmitDisabled,
    isSubmitBusy,
    cancelLabel = 'Cancel',
    submitLabel = 'Apply',
  } = props;
  const classes = useStyles();

  return (
    <MuiDialog
      classes={{ paper: `${classes.dialog} ${className}` }}
      maxWidth={size}
      fullWidth={true}
      open={open}
      onClose={onClose}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h5">{title}</Typography>
        <IconButton
          size="small"
          aria-label="close"
          className={classes.dialogCloseBtn}
          onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        {children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="secondary"
          classes={{ button: classes.actionBtn }}
          onClick={onClose}>
          {cancelLabel}
        </Button>
        <Button
          classes={{ button: classes.actionBtn }}
          onClick={onSubmit}
          disabled={isSubmitDisabled}
          busy={isSubmitBusy}>
          {submitLabel}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
