import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const styles = (): StyleRules =>
  createStyles({
    noTransform: {
      textTransform: 'none',
    },
    menuTrigger: {
      color: 'inherit',
      height: '100%',
    },
    menuTriggerArrow: {
      fontSize: '24px',
      display: 'flex',
      width: '24px',
      height: '24px',
    },
    avatarRoot: {
      background: COLORS.red['500'],
      height: '32px',
      width: '32px',
    },
  });
