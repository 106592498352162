import React, { FC } from 'react';
import { TOOL } from 'src/shared/routing/with-selected-equipment';
import ToolLikeSummary from '../toolLikeSummary';
import { RootState, select } from 'src/store';
import { useSelector } from 'react-redux';

interface Props {
  siteId: number;
  toolId: number;
}

export const ToolSummary: FC<Props> = ({ siteId, toolId }) => {
  const tool = useSelector((state: RootState) =>
    select.siteEquipment.selectTool(state, { siteId, toolId })
  );

  return (
    <ToolLikeSummary
      siteId={siteId}
      toolId={toolId}
      //string is empty to show nothing on component loading stage
      equipmentName={tool?.name || ''}
      equipmentType={TOOL}
    />
  );
};
