import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { history } from 'src/services/history';
import { showSuccess } from 'src/services/snackbars';
import { routesConstants } from 'src/shared/routing/routes.constants';
import { Error } from '../api';
import { Credentials, authAPI } from '../api/auth.api';
import { User, UserPayload, usersAPI } from '../api/users.api';

type AuthState = {
  user: User | null;
  signInError: Error | null;
  isPasswordChangeRequired: boolean;
};

export const token = {
  get(): string | null {
    return localStorage.getItem('accessToken');
  },
  set(accessToken: string) {
    localStorage.setItem('accessToken', accessToken);
  },
  unset() {
    localStorage.removeItem('accessToken');
  },
};

export const auth = createModel<RootModel>()({
  state: {
    user: null,
    signInError: null,
    isPasswordChangeRequired: false,
  } as AuthState,

  reducers: {
    setUser(state: AuthState, user: User): AuthState {
      return { ...state, user, signInError: null };
    },
    setSignInError(state: AuthState, err: Error | null): AuthState {
      return { ...state, signInError: err };
    },
    setIsPasswordChangeRequired(
      state: AuthState,
      isPasswordChangeRequired: boolean
    ): AuthState {
      return { ...state, isPasswordChangeRequired };
    },
  },

  effects: (dispatch) => {
    const { auth } = dispatch;

    return {
      async signInRequest(payload: Credentials) {
        auth.setSignInError(null);

        token.unset();

        try {
          const response = await authAPI.create(payload);
          token.set(response.accessToken);
          const user = camelizeKeys(response.user) as User;
          auth.setUser(user);

          if (!user.passwordChangeRequired) {
            history.push(routesConstants.DASHBOARD);
          } else {
            auth.setIsPasswordChangeRequired(true);
          }
        } catch ({ response }) {
          const data = response
            ? response.data
            : {
                name: 'NotAuthenticated',
                message: 'No Response',
                code: 0,
              };
          auth.setSignInError(data);
        }
      },
      signOutRequest() {
        token.unset();
        history.replace(routesConstants.LOGIN);
        dispatch({ type: 'CLEAR_STORE' });
      },
      updatePassword(payload: UserPayload, rootState) {
        usersAPI
          .editUser(
            rootState.auth.user?.id || null,
            decamelizeKeys(payload) as UserPayload
          )
          .then(({ data }) => {
            dispatch.auth.setUser(camelizeKeys(data) as User);
            dispatch.auth.setIsPasswordChangeRequired(false);
            history.push(routesConstants.DASHBOARD);
            showSuccess('Password updated');
          });
      },
      async validateTokenRequest() {
        const accessToken = token.get();

        if (accessToken) {
          await authAPI
            .validate(accessToken)
            .then((response) => auth.setUser(camelizeKeys(response.user) as User))
            .catch(() => auth.signOutRequest());
        } else {
          await auth.signOutRequest();
        }
      },
    };
  },
});
