import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center',
      },
      value: {
        marginLeft: '0.5rem',
      },
    })
);
