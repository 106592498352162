import React from 'react';

interface WhenSpec {
  condition: boolean;
}

export const When: React.FunctionComponent<WhenSpec> = function When(props) {
  if (!props.condition) {
    return null;
  }

  return (
    <React.Fragment>
      {typeof props.children === 'function' ? props.children() : props.children}
    </React.Fragment>
  );
};
