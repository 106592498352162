import { connect } from 'react-redux';
import { ConfusionMatrix } from './confusion-matrix';
import { RootState } from 'src/store';

type DataSetType = 'hist' | 'live';

const select = ({ ml }: RootState, { type }: { type: DataSetType }) => {
  const details = ml.score.details;
  const dataSetByType = details.scores?.[type]?.thresholds || {};
  // todo: types, currently page unavailable, so types cant be grabbed
  const { binary } = Object.values(dataSetByType)[0] || ({} as any); // eslint-disable-line

  return {
    tp: binary?.true_pos,
    fn: binary?.false_neg,
    tn: binary?.true_neg,
    fp: binary?.false_pos,
  };
};

export default connect(select)(ConfusionMatrix);
