import { RouteComponentProps } from 'react-router-dom';

import { routesConstants } from 'src/shared/routing/routes.constants';
import { Roles } from 'src/store/api/users.api';

// site/tool scoped
import { SiteEquipment } from 'src/app/site-equipment';
import SiteEquipmentSummary from 'src/app/site-equipment-summary';

import DashboardContentNav from 'src/app/dashboard-content-nav';
import MaintenanceDashboard from 'src/app/maintenance-dashboard';
import PredictionsDashboardContainer from 'src/app/predictions-dashboard';
import ControllersDashboard from 'src/app/controllers-dashboard';
import CompressorsDashboard from 'src/app/compressors-dashboard';
import PumpSettingsDashboard from 'src/app/pump-settings-dashboard/index';

// tool scoped only
import ToolGCUDashboard from 'src/app/tool-specific-dashboards/tool-gcu';
import ToolAlarmConfigDashboard from 'src/app/tool-specific-dashboards/alarm-config';

import { ControllerDetails } from 'src/app/stations/controller/controller-details/controller-details';

// pump scoped
import { PumpDetailsContainer } from 'src/app/pump/pump-details';
import PumpRegenerationContainer, {
  PumpRegenerationStepsContainer,
} from 'src/app/pump/pump-regeneration';
import PumpAnomaliesContainer from 'src/app/pump/pump-anomalies';
import PumpChangeHistory from 'src/app/pump/settings-history';
import PumpChamberHistory from 'src/app/pump/chamber-history';
import PumpDiodes from 'src/app/pump/pump-diodes';
import PumpAlarmHistory from 'src/app/pump/alarm-history';

// machine learning module
import MachineLearningTabs from 'src/app/machine-learning/tabs'; // toolbar
import MachineLearningTabPanels from 'src/app/machine-learning'; // content

// chart module
import ChartsContainer, {
  ChartsListContainer,
  ChartsToolbarContainer,
} from 'src/app/charts';
import ChartsContentNavContainer from 'src/app/charts/charts-content-nav';

// admin module
import AdminContainer from 'src/app/admin';
import { AdminToolbarContainer } from 'src/app/admin/toolbar';

// common
import NotFound from 'src/shared/not-found';
import { CompressorDetails } from 'src/app/stations/compressor/compressor-details/compressor-details';

// notifications
import { NotificationRangesContainer } from 'src/app/notification/notification-ranges/';
import { NotificationsContainer } from 'src/app/notification/notifications';

export const ROUTES = {
  app: [
    {
      path: routesConstants.DASHBOARD,
      exact: true,
      allowedRoles: [Roles.cse, Roles.admin],
      component: MaintenanceDashboard,
      getComponentKey: (params: RouteComponentProps) => params.location.search,
    },
    {
      path: routesConstants.CONTROLLERS,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: ControllersDashboard,
    },
    {
      path: routesConstants.CONTROLLER,
      exact: true,
      allowedRoles: [Roles.cse, Roles.admin],
      component: ControllerDetails,
    },
    {
      path: routesConstants.COMPRESSORS,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: CompressorsDashboard,
    },
    {
      path: routesConstants.COMPRESSOR,
      exact: true,
      allowedRoles: [Roles.cse, Roles.admin],
      component: CompressorDetails,
    },
    {
      path: routesConstants.PREDICTIONS,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PredictionsDashboardContainer,
      getComponentKey: (params: RouteComponentProps) => params.location.search,
    },
    {
      path: routesConstants.NOTIFICATION_RANGES,
      exact: true,
      allowedRoles: [Roles.cse, Roles.admin],
      component: NotificationRangesContainer,
    },
    {
      path: routesConstants.NOTIFICATION_HISTORY,
      exact: true,
      allowedRoles: [Roles.cse, Roles.admin],
      component: NotificationsContainer,
    },
    {
      path: routesConstants.PUMP_SETTINGS,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PumpSettingsDashboard,
    },
    {
      path: routesConstants.GCU,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: ToolGCUDashboard,
    },
    {
      path: routesConstants.ALARM_CONFIG,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: ToolAlarmConfigDashboard,
    },
    {
      path: routesConstants.PUMP,
      exact: true,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PumpDetailsContainer,
      getComponentKey: (params: RouteComponentProps) => params.location.search,
    },
    {
      path: routesConstants.PUMP_REGENERATION,
      /* Don't use 'exact' to prevent re-fetch data when user go back from regeneration steps
       PumpRegenerationContainer should hide history table when user go to steps route (/regeneration/:id) */
      exact: true,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PumpRegenerationContainer,
    },
    {
      path: routesConstants.PUMP_REGENERATION_DETAILS,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PumpRegenerationStepsContainer,
    },
    {
      path: routesConstants.PUMP_CHAMBER_HISTORY,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PumpChamberHistory,
    },
    {
      path: routesConstants.PUMP_CHANGE_HISTORY,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PumpChangeHistory,
    },
    {
      path: routesConstants.PUMP_DIODES,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PumpDiodes,
    },
    {
      path: routesConstants.PUMP_ALARM_HISTORY,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PumpAlarmHistory,
    },
    {
      path: routesConstants.PUMP_ANOMALIES,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: PumpAnomaliesContainer,
    },
    {
      path: routesConstants.MACHINE_LEARNING,
      exact: false,
      allowedRoles: [Roles.admin],
      component: MachineLearningTabPanels,
    },
    {
      path: routesConstants.CHARTS_BASE,
      exact: false,
      allowedRoles: [Roles.cse, Roles.admin],
      component: ChartsContainer,
    },
    {
      path: routesConstants.ADMIN_PAGE,
      exact: true,
      allowedRoles: [Roles.admin],
      component: AdminContainer,
    },
    {
      path: '*',
      exact: false,
      allowedRoles: [Roles.admin, Roles.cse],
      component: NotFound,
    },
  ],

  contentNavigation: [
    {
      path: routesConstants.CHARTS_DETAILS,
      exact: false,
      component: ChartsContentNavContainer,
      allowedRoles: [Roles.admin, Roles.cse],
    },
    {
      path: routesConstants.ADMIN_PAGE,
      exact: false,
      component: null,
      allowedRoles: [Roles.admin],
    },
    {
      path: routesConstants.DASHBOARD,
      exact: false,
      component: DashboardContentNav,
      allowedRoles: [Roles.admin, Roles.cse],
    },
  ],

  pageToolbar: [
    {
      path: routesConstants.MACHINE_LEARNING,
      exact: false,
      allowedRoles: [Roles.admin],
      component: MachineLearningTabs,
    },
    {
      path: routesConstants.CHARTS_BASE,
      exact: false,
      allowedRoles: [Roles.admin, Roles.cse],
      component: ChartsToolbarContainer,
    },
    {
      path: routesConstants.DASHBOARD,
      exact: false,
      allowedRoles: [Roles.admin, Roles.cse],
      component: SiteEquipmentSummary,
    },
    {
      path: routesConstants.ADMIN_PAGE,
      exact: false,
      allowedRoles: [Roles.admin],
      component: AdminToolbarContainer,
    },
  ],

  sideBar: [
    {
      path: routesConstants.CHARTS_DETAILS,
      component: ChartsListContainer,
      exact: false,
      allowedRoles: [Roles.admin, Roles.cse],
    },
    {
      path: routesConstants.ADMIN_PAGE,
      component: null,
      exact: false,
      allowedRoles: [Roles.admin],
    },
    {
      path: routesConstants.DASHBOARD,
      component: SiteEquipment,
      exact: false,
      allowedRoles: [Roles.admin, Roles.cse],
    },
  ],
};
