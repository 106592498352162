import React from 'react';
import { EmptyPredictionChart, PredictionChart } from 'src/shared/prediction-chart';
import { ChartInfoTable } from 'src/app/pump/pump-details/chart-info';
import { LatestInfoTable } from 'src/app/pump/pump-details/latest-info';
import { RegenInfoTable } from 'src/app/pump/pump-details/regen-info';
import { PumpInfoTable } from 'src/app/pump/pump-details/pump-info';
import {
  getDefaultFilters,
  getWeekRange,
} from 'src/shared/date-range-filter/filters-config';
import DateRangeFilter from 'src/shared/date-range-filter/date-range-filter';
import { GroupedSeries } from 'src/store/api/charts.api';
import { PredictiveChart } from 'src/app/pump/pump-details/predictive-chart';
import LineChart from 'src/shared/charts/line-chart';
import { styles } from 'src/app/pump/pump-details/pump-details.styles';
import { isPredictable } from 'src/services/pump';
import { WithStyles, withStyles } from '@material-ui/core';
import { FailureProbability } from 'src/store/api/pumps.api';
import { PumpDetails as PumpDetailsInfo } from 'src/store/api/pumps.api';

interface Props extends WithStyles<typeof styles> {
  pump: PumpDetailsInfo & FailureProbability;
  lastDataChart: GroupedSeries[];
  predictiveChart: GroupedSeries[];
  onDateChange: (start: Date, end: Date) => void;
  isPredictiveChartLoading: boolean;
}

interface State {
  dates: [Date, Date];
}

class PumpDetailsComponent extends React.PureComponent<Props, State> {
  public state: State = {
    dates: getWeekRange(),
  };

  handleDateChange = (start: Date, end: Date) => {
    this.setState({ dates: [start, end] });
    this.props.onDateChange(start, end);
  };

  render() {
    const { pump, lastDataChart, predictiveChart, isPredictiveChartLoading } = this.props;
    const [start, end] = this.state.dates;

    const isPumpPredictive = isPredictable(pump.model);

    const chart = isPumpPredictive ? (
      <PredictionChart pump={pump} />
    ) : (
      <EmptyPredictionChart>{'Data is Unavailable'}</EmptyPredictionChart>
    );

    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.detailsContainer}>
          <div>
            <div className={this.props.classes.chartSection}>
              <div
                className={[this.props.classes.pxSmall, this.props.classes.ptop].join(
                  ' '
                )}>
                <div className={this.props.classes.chartSlot}>{chart}</div>
              </div>

              <ChartInfoTable pump={pump} />
            </div>
          </div>
          <div className={this.props.classes.spacer} />
          <div>
            <div className={this.props.classes.subtitle2}>Latest Data</div>

            <LatestInfoTable pump={pump} />
          </div>
          <div className={this.props.classes.spacer} />
          <div>
            <div className={this.props.classes.subtitle2}>Pump Details</div>

            <PumpInfoTable pump={pump} />
          </div>
          <div className={this.props.classes.spacer} />
          <div>
            <div className={this.props.classes.subtitle2}>Regen Parameters</div>

            <RegenInfoTable pump={pump} />
          </div>
        </div>
        <DateRangeFilter
          startDate={start}
          endDate={end}
          onChange={this.handleDateChange}
          quickFilters={getDefaultFilters()}
        />
        {isPumpPredictive && (
          <div className={this.props.classes.chartContainer}>
            <PredictiveChart
              data={predictiveChart}
              minX={start.getTime()}
              maxX={end.getTime()}
              isPredictiveChartLoading={isPredictiveChartLoading}
            />
          </div>
        )}
        <div className={this.props.classes.chartContainer}>
          <LineChart
            title="Last Data"
            data={lastDataChart}
            minX={start.getTime()}
            maxX={end.getTime()}
            hideLegendMetrics={true}
          />
        </div>
      </div>
    );
  }
}

export const PumpDetails = withStyles(styles)(PumpDetailsComponent);
