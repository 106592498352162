import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const styles = (): StyleRules =>
  createStyles({
    container: {
      padding: '1rem',
      height: '26.7rem',
      overflow: 'auto',
      borderBottom: `1px solid ${COLORS.grey['300']}`,
    },
    containerIE: {
      padding: '1rem',
      height: '11.7rem',
      overflow: 'auto',
      borderBottom: `1px solid ${COLORS.grey['300']}`,
    },
    title: {
      color: COLORS.grey['600'],
    },
  });
