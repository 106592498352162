import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { canAcknowledge } from 'src/app/notification/notifications/notifications.helper';
import { useStyles } from './active-status.styles';

export const ActiveStatusRenderer = (props: ICellRendererParams) => {
  const styles = useStyles();

  if (!props.value) {
    return '';
  }

  return canAcknowledge(props.node.data) ? <div className={styles.active} /> : null;
};
