import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import ChartsList from './charts-list';

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type Props = ConnectedProps;

const ChartsListContainer: FC<Props> = (props) => {
  const { charts, fetchCharts, setSelectedChart } = props;

  useEffect(() => {
    fetchCharts();
  }, [fetchCharts]);

  return <ChartsList charts={charts} setSelectedChart={setSelectedChart} />;
};

export const mapStateToProps = (state: RootState) => ({
  charts: state.charts.charts,
});

export const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchCharts: dispatch.charts.fetchCharts,
  setSelectedChart: dispatch.charts.setSelectedChart,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartsListContainer);
