import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { mapDispatchToProps, mapStateToProps } from './feedback-modal.container';
import Dialog from '../../shared/dialog';
import { Feedback } from 'src/store/api/feedbacks.api';
import FeedbackModalDialogContent from './feedback-modal-components';

const CenteredSpinner: FC = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <CircularProgress color="primary" size="32px" />
    </Box>
  );
};

export type FeedbackDataState = any;

interface Props
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  isOpen: boolean;
  title: string;
  pumpId: number;
  siteId: number;
  toggleDialog: (isOpen: boolean) => void;
  onAfterSave?: (value: Feedback) => void;
}

const FeedbackModal: FC<Props> = (props) => {
  const {
    fetchFeedback,
    feedback,
    isOpen,
    title,
    toggleDialog,
    pumpId,
    siteId,
    createFeedback,
    isFetchLoading,
    isCreateLoading,
    invalidFields,
    setInvalidFields,
    onAfterSave,
  } = props;
  const [feedbackData, setFeedback] = useState<FeedbackDataState | null>(null);
  const [comment, setComment] = useState('');
  const [areDatesInvalid, setDatesInvalid] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  // initial data fetch
  useEffect(() => {
    isOpen && fetchFeedback({ pumpId, siteId });
  }, [isOpen, fetchFeedback, pumpId, siteId]);

  // update default fields with received data
  useEffect(() => {
    setFeedback({
      ...feedback,
      pm_date: feedback?.pm_date,
      reference_date: feedback?.reference_date,
    });
    setComment(feedback?.comment || '');
  }, [feedback]);

  // close dialog after submit
  useEffect(() => {
    const canCloseDialog = isSubmitted && !isCreateLoading && !invalidFields;
    canCloseDialog && toggleDialog(false);
    invalidFields && setSubmitted(false);
  }, [toggleDialog, invalidFields, isCreateLoading, isSubmitted]);

  const handleClose = useCallback(() => {
    toggleDialog(!isOpen);
    setInvalidFields(null);
  }, [isOpen, setInvalidFields, toggleDialog]);

  const handleSubmit = useCallback(() => {
    createFeedback({
      formValues: {
        ...feedbackData,
        comment,
        site_id: siteId,
        pump_id: pumpId,
      },
      onAfterSave,
    });

    setSubmitted(true);
  }, [feedbackData, comment, createFeedback, siteId, pumpId, onAfterSave]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit}
      isSubmitDisabled={isFetchLoading || areDatesInvalid}
      isSubmitBusy={isCreateLoading}
      title={title}
      submitLabel="Save">
      {isFetchLoading ? (
        <CenteredSpinner />
      ) : (
        <FeedbackModalDialogContent
          feedbackData={feedbackData}
          setFeedback={setFeedback}
          setDatesInvalid={setDatesInvalid}
          invalidDates={invalidFields}
          comment={comment}
          setComment={setComment}
          setInvalidFields={setInvalidFields}
        />
      )}
    </Dialog>
  );
};

export default memo(FeedbackModal);
