import { Tab, Tabs } from '@material-ui/core';
import React, { FC } from 'react';
import { TabSubtype } from './notification-ranges-container';

interface Props {
  tabs: string[];
  value: string | undefined;
  onChange: (event: React.ChangeEvent<unknown>, newValue: TabSubtype) => void;
}

export const NotificationTabs: FC<Props> = ({ value, tabs, onChange }) => {
  return (
    <Tabs value={value} onChange={onChange} indicatorColor="primary">
      {tabs.map((tab) => (
        <Tab key={tab} label={tab} value={tab} />
      ))}
    </Tabs>
  );
};
