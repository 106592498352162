import { connect } from 'react-redux';
import DeactivateUserModal from './deactivate-user-modal';
import { RootDispatch, RootState } from 'src/store';

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: RootState) => ({
  loading: state.loading.effects.users.editUserStatus,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  editUserStatus: dispatch.users.editUserStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateUserModal);
