import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      totalInfoRow: {
        margin: '8px 0 0',
      },
      totalInfoLabel: {
        fontWeight: 500,
      },
      totalValue: {
        marginRight: '24px',
      },
      dateValue: {
        marginRight: '16px',
      },
    })
);
