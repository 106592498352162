import React from 'react';
import { CellRenderer } from 'src/shared/base-grid/base-grid';
import { useStyles } from './online-status.styles';

export const OnlineStatusRenderer: React.FC<CellRenderer<boolean>> = (props) => {
  const styles = useStyles();
  const hasValue = 'value' in props;
  return hasValue ? (
    <div className={props.value ? styles.active : styles.inactive} />
  ) : null;
};
