import { EntityType } from 'src/types';
import { RegenerationsStep } from 'src/store/api/regenerations.api';

type Numeric = number[];

export const transform = (steps: RegenerationsStep[]) => {
  const byDevice = {
    series: {
      TC: [] as Numeric,
      T1: [] as Numeric,
      T2: [] as Numeric,
      RPM: [] as Numeric,
    },
    timestamps: [] as string[],
    entity_type: EntityType.Pump,
  };
  const groupedSeries = steps
    .sort(
      (prev, next) =>
        new Date(prev.createdAt).getTime() - new Date(next.createdAt).getTime()
    )
    .reduce((memo, { tcPressure, t1, t2, rpm, createdAt }) => {
      memo.series.TC.push(tcPressure);
      memo.series.T1.push(t1);
      memo.series.T2.push(t2);
      memo.series.RPM.push(rpm);
      memo.timestamps.push(createdAt);

      return memo;
    }, byDevice);

  return [groupedSeries];
};
