import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Site } from 'src/store/api/sites.api';

import { useStyles } from './styles';

const CENTER = 'center' as const;
const BOTTOM = 'bottom' as const;
const TOP = 'top' as const;

const SELECT_ALL_VALUE = '*' as const;

type SelectedSiteIdsOrAll = Array<number | typeof SELECT_ALL_VALUE>;

type Props = {
  sites: Site[];
  selectedSites: number[];
  onChange: (value: number[], meta: void) => void;
};

const SitePicker: FC<Props> = (props) => {
  const { sites = [], selectedSites = [], onChange } = props;

  const classes = useStyles();

  const selectMenuConfig = useMemo(
    () => ({
      MenuListProps: {
        dense: true,
      },
      anchorOrigin: {
        horizontal: CENTER,
        vertical: BOTTOM,
      },
      classes: {
        paper: classes.optionsControlListWrapper,
      },
      getContentAnchorEl: null,
      transformOrigin: {
        horizontal: CENTER,
        vertical: TOP,
      },
    }),
    [classes]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const selection = event.target.value as SelectedSiteIdsOrAll;
      const previouslySelectedIds = selectedSites;
      const areAllSitesSelected =
        selection.includes(SELECT_ALL_VALUE) && previouslySelectedIds.length > 0;

      if (areAllSitesSelected) {
        onChange([]);
      } else {
        const withoutAllSymbolValue = selection.filter((id) => id !== SELECT_ALL_VALUE);
        onChange(withoutAllSymbolValue as number[]);
      }
    },
    [onChange, selectedSites]
  );

  const value = useMemo(() => {
    return selectedSites.length ? selectedSites : [SELECT_ALL_VALUE];
  }, [selectedSites]);

  const renderValue = useCallback(() => {
    if (selectedSites.length === 0) return 'All';

    return sites
      .filter((site) => selectedSites.includes(site.siteId))
      .map((site) => site.name)
      .join(', ');
  }, [selectedSites, sites]);

  return (
    <FormControl variant="filled" fullWidth={true}>
      <InputLabel id="site-ctrl-label">Site</InputLabel>
      <Select
        IconComponent={ExpandMore}
        MenuProps={selectMenuConfig}
        classes={{ iconFilled: classes.iconFilled }}
        id="site-ctrl"
        labelId="site-ctrl-label"
        multiple={true}
        onChange={handleChange}
        renderValue={renderValue}
        value={value}>
        <MenuItem value={SELECT_ALL_VALUE}>
          <div className={classes.optionCheckboxWrapper}>
            <Checkbox color="default" checked={selectedSites.length === 0} />
          </div>
          <span className={classes.optionText}>All</span>
        </MenuItem>
        {sites.map((site) => (
          <MenuItem key={site.siteId} value={site.siteId}>
            <div className={classes.optionCheckboxWrapper}>
              <Checkbox color="default" checked={selectedSites.includes(site.siteId)} />
            </div>
            <span className={classes.optionText}>{site.name}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SitePicker;
