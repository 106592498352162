import React, { useCallback } from 'react';
import { FormContextValues } from 'react-hook-form';
import { ChartsFormData } from 'src/store/models/charts-builder.model';
import { AutocompleteOption } from 'src/shared/autocompletes-wtih-chips';
import { DefaultChartValues } from 'src/app/charts/chart-form/chart-form';
import AxisHeader from './axis-headers/index';
import MertricsToolController from './entities-metrics/metrics-tool-controller';
import MertricsPumpController from './entities-metrics/metrics-pump-controller';
import MertricsCompressorController from './entities-metrics/metrics-compressor-controller';
import { useStyles } from 'src/app/charts/chart-form/chart-form.styles';

type MetricsByType = {
  Tool: AutocompleteOption[];
  Compressor: AutocompleteOption[];
  Pump: AutocompleteOption[];
};

export interface Props {
  side: 'left' | 'right';
  formContext: FormContextValues<DefaultChartValues>;
  formData?: ChartsFormData | null;
  metricsByType: MetricsByType;
}

const AxisEntitiesControls = (props: Props) => {
  const { side, formData, metricsByType, formContext } = props;
  const { getValues, clearError, setError } = formContext;
  const styles = useStyles();
  const isLeftAxis = side === 'left' ? true : false;

  const handleValidateMetrics = useCallback(
    (resourceField: string, oppositeMetricsField: string) => (
      value: AutocompleteOption[]
    ): boolean => {
      if (
        resourceField === 'entities.tools' &&
        (getValues('entities.pumps').length || getValues('entities.compressors').length)
      ) {
        return true;
      }

      if (getValues(resourceField).length) {
        return !!(value.length || getValues(oppositeMetricsField).length);
      }
      return true;
    },
    [getValues]
  );

  const handleChangeMetrics = useCallback(
    (resourceField: string, field: string, oppositeMetricsField: string) => ([
      ,
      value,
    ]: any[]): AutocompleteOption[] => {
      clearError([resourceField, field, oppositeMetricsField]);
      if (
        getValues(resourceField).length &&
        !value.length &&
        !getValues(oppositeMetricsField).length &&
        !getValues('entities.pumps').length &&
        !getValues('entities.compressors').length
      ) {
        setError([
          {
            type: 'validate',
            name: field,
          },
          {
            type: 'validate',
            name: oppositeMetricsField,
          },
        ]);
      }
      return value;
    },
    [clearError, getValues, setError]
  );

  return (
    <div className={styles.axis}>
      <span>{isLeftAxis ? 'Left Axis' : 'RightAxis'}</span>
      <AxisHeader side={side} formData={formData} formContext={formContext} />
      <MertricsToolController
        side={side}
        formContext={formContext}
        metricsByType={metricsByType}
        handleValidateMetrics={handleValidateMetrics}
        handleChangeMetrics={handleChangeMetrics}
      />
      <MertricsPumpController
        side={side}
        formContext={formContext}
        metricsByType={metricsByType}
        handleValidateMetrics={handleValidateMetrics}
        handleChangeMetrics={handleChangeMetrics}
      />

      <MertricsCompressorController
        side={side}
        formContext={formContext}
        metricsByType={metricsByType}
        handleValidateMetrics={handleValidateMetrics}
        handleChangeMetrics={handleChangeMetrics}
      />
    </div>
  );
};

export default AxisEntitiesControls;
