import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      container: {
        width: '100%',
        height: 'auto',
      },
      title: {
        margin: '1rem 0',
        fontWeight: 'bold',
      },
    })
);
