import React, { FC, ReactNode } from 'react';
import { Box, FormHelperText } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import Datepicker from '../datepicker';
import { isValid } from 'date-fns';
import { COLORS } from 'src/styles/colors';

export interface DateRangePickerConfig {
  maxDate: ParsableDate;
  minDate: ParsableDate;
}

interface Props {
  layout?: 'column' | 'row';
  startDate: Date;
  endDate: Date;
  startDateConfig?: DateRangePickerConfig;
  endDateConfig?: DateRangePickerConfig;
  onChange?: (startDate: Date, endDate: Date) => void;
  datepickerInputStyle?: React.CSSProperties;
  error?: string | null;
  onError?: (error: ReactNode) => void;
}

const today = new Date();

const DateRangePicker: FC<Props> = (props) => {
  const {
    layout,
    startDate,
    endDate,
    startDateConfig,
    endDateConfig,
    onChange,
    datepickerInputStyle,
    error,
    onError,
  } = props;

  const handleStartDateChange = (date: ParsableDate) => {
    onChange && isValid(date) && onChange(date as Date, endDate);
  };

  const handleEndDateChange = (date: ParsableDate) => {
    onChange && isValid(date) && onChange(startDate, date as Date);
  };

  const handleError = (error: ReactNode) => onError?.(error);

  const displayProp = layout === 'column' ? 'static' : 'inline-flex';

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box m={-1} display={displayProp} justifyContent="space-between">
        <Box style={datepickerInputStyle} p={1}>
          <Datepicker
            label="Start Date"
            onChange={handleStartDateChange}
            minDate={startDateConfig?.minDate}
            maxDate={startDateConfig?.maxDate || today}
            value={startDate}
            isError={!!error}
            onError={handleError}
          />
        </Box>
        <Box style={datepickerInputStyle} p={1}>
          <Datepicker
            label="End Date"
            onChange={handleEndDateChange}
            minDate={endDateConfig?.minDate}
            maxDate={endDateConfig?.maxDate || today}
            value={endDate}
            isError={!!error}
            onError={handleError}
          />
        </Box>
      </Box>
      <FormHelperText style={{ color: COLORS.red['500'] }}>
        {!!error && error}
      </FormHelperText>
    </Box>
  );
};

export default DateRangePicker;
