import React, { FC } from 'react';
import { ToolbarCreate, ToolbarEdit } from 'src/app/charts/charts-toolbar';
import { routesConstants } from 'src/shared/routing/routes.constants';
import RoutesList, { AppRoute } from 'src/shared/routing/routes-list';

const ROUTES: AppRoute[] = [
  {
    path: routesConstants.CHARTS_NEW,
    component: ToolbarCreate,
    exact: false,
  },
  {
    path: routesConstants.CHARTS_DETAILS,
    component: ToolbarEdit,
    exact: false,
  },
];

const ChartsToolbar: FC = () => <RoutesList routes={ROUTES} />;

export default ChartsToolbar;
