import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { eachDayOfInterval, isToday, subDays } from 'date-fns';
import { formatDate } from 'src/shared/date-in-selected-zone/date-formatter';
import { Model, PipelineEvent, PipelineExecutions, MLApi as api } from '../api/ml.api';

// Todo: Identify correct type
export type MlScoreDetails = any; // eslint-disable-line

export type FetchScoreQuery = {
  start_date: string;
  end_date: string;
  threshold: number;
  above_threshold_duration: number;
  site_name: string[];
  model_id: string;
};

export type Pipelines = {
  day: Date;
  createdAt?: string;
  completedAt?: string;
  predictions?: number;
  events: PipelineEvent[];
};

interface MLState {
  models: {
    list: Model[] | null;
    error: unknown;
  };
  score: {
    details: MlScoreDetails;
    error: unknown;
  };
  pipelines: Pipelines[] | null;
}

export const ml = createModel<RootModel>()({
  state: {
    models: {
      list: null,
      error: null,
    },
    score: {
      details: null,
      error: null,
    },
    pipelines: null,
  } as MLState,

  reducers: {
    failModels(state, failure: unknown | null) {
      return {
        ...state,
        models: {
          ...state.models,
          error: failure,
        },
      };
    },

    failScore(state, failure: unknown | null) {
      return {
        ...state,
        score: {
          ...state.score,
          error: failure,
        },
      };
    },

    applyModels(state, records: Model[] | null) {
      return {
        ...state,
        models: {
          ...state.models,
          list: records,
        },
      };
    },

    applyScore(state, record: unknown | null) {
      return {
        ...state,
        score: {
          ...state.score,
          details: record,
        },
      };
    },

    applyPipelineExecutions(state, rows: PipelineExecutions) {
      const today = new Date();
      const start = subDays(today, 23);

      const daysRange = eachDayOfInterval({ start, end: today });

      const pipelines = daysRange.map((dateTime) => {
        const day = formatDate(dateTime, 'yyyy-MM-dd');

        const thisDayPipelineExecutions = rows
          .filter((row) => row.created_at.startsWith(day))
          .sort(
            (a, b) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
          );

        const thisDayLastPipelineExecution = thisDayPipelineExecutions[0];

        const events = thisDayLastPipelineExecution?.events ?? [];

        const completedAt = isToday(dateTime)
          ? thisDayLastPipelineExecution?.finished_at ?? undefined
          : thisDayLastPipelineExecution?.finished_at ||
            events.slice(1).pop()?.created_at; // last but not first

        return {
          day: dateTime,
          events: thisDayLastPipelineExecution?.events ?? [],
          createdAt: thisDayLastPipelineExecution?.created_at,
          completedAt,
          predictions: thisDayLastPipelineExecution?.predictions_count,
        };
      });

      return {
        ...state,
        pipelines,
      };
    },
  },

  effects: (dispatch) => ({
    fetchModels(query: unknown, rootState): Promise<void> {
      // do not load models twice
      if (rootState.ml.models.list) return Promise.resolve();

      dispatch.ml.applyModels(null);
      dispatch.ml.failModels(null);

      return api
        .fetchModels()
        .then((response) => {
          const { data } = response;
          dispatch.ml.applyModels(data);
        })
        .catch((failure) => {
          dispatch.ml.failModels(failure);
        });
    },

    fetchScore(query: FetchScoreQuery | undefined): Promise<void> {
      dispatch.ml.applyScore(null);
      dispatch.ml.failScore(null);

      return api
        .fetchScore(query)
        .then((response) => {
          const { data } = response;
          dispatch.ml.applyScore(data);
        })
        .catch((failure) => {
          dispatch.ml.failScore(failure);
        });
    },

    async fetchPipelineExecutions() {
      const { data } = await api.fetchPipelineExecutions();
      dispatch.ml.applyPipelineExecutions(data);
    },
  }),
});
