import React, { FC, useCallback } from 'react';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { RootDispatch, RootState, select } from 'src/store';
import { SiteEquipmentTree } from './SiteEquipmentTree';
import TreeNode from './node/TreeNode';
import { useStyles } from './styles';
import { NodeIdentifiers } from 'src/shared/routing/with-selected-equipment';

const mapState = (state: RootState) => {
  const selectedSites = state.siteEquipment.selectedSites;
  return {
    sites: select.siteEquipment.preferredSites(state, selectedSites),
  };
};

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector>;

const SitesTree: FC<Props> = (props) => {
  const { sites } = props;

  const classes = useStyles();

  const {
    siteEquipment: { fetchSiteEquipment, selectEquipment },
  } = useDispatch<RootDispatch>();

  // fires on selection/deselection
  const handleSelect = useCallback(
    (node: NodeIdentifiers | null) => {
      selectEquipment(node);
    },
    [selectEquipment]
  );

  // fires on expand/collapse
  const handleChange = useCallback(
    (state: boolean, node: NodeIdentifiers) => {
      if (state) fetchSiteEquipment(node.siteId);
    },
    [fetchSiteEquipment]
  );

  return (
    <div className={classes.autoSize}>
      {sites.map((site) => (
        <TreeNode
          key={site.siteId}
          node={site}
          online={site.status}
          name={site.name}
          nesting={0}
          activeNotificationsCount={site.activeNotificationsCount}
          onSelect={handleSelect}
          onChange={handleChange}>
          <SiteEquipmentTree
            siteId={site.siteId}
            onSelect={handleSelect}
            onChange={handleChange}
          />
        </TreeNode>
      ))}
    </div>
  );
};

export default connector(SitesTree);
