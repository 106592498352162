import { RematchDispatch, RematchRootState, init } from '@rematch/core';
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading';
import selectPlugin from '@rematch/select';

import { RootModel, models } from './models';

type FullModel = ExtraModelsFromLoading<RootModel>;

export const AppStore = init<RootModel, FullModel>({
  models,
  plugins: [selectPlugin(), loadingPlugin()],
  redux: {
    reducers: {},
    rootReducers: {
      CLEAR_STORE: () => undefined,
    },
    devtoolOptions: {
      disabled: process.env.NODE_ENV === 'production',
    },
  },
});

export const { select, dispatch } = AppStore;

export type RootState = RematchRootState<RootModel, FullModel>;
export type RootDispatch = RematchDispatch<RootModel>;
