import { routesConstants } from './routes.constants';
import get from 'lodash/get';
import { history } from 'src/services/history';
import AppStore from 'src/store';

export type EquipmentIdentifiers = {
  siteId?: number | null;
  toolId?: number | null;
  pumpId?: number | null;
  controllerId?: number | null;
  compressorId?: number | null;
} | null;

const NOTIFICATIONS_ROUTES = [
  routesConstants.NOTIFICATION_HISTORY,
  routesConstants.NOTIFICATION_RANGES,
];

const SITE_LEVEL_ROUTES = [
  routesConstants.DASHBOARD,
  routesConstants.PREDICTIONS,
  routesConstants.COMPRESSORS,
  routesConstants.CONTROLLERS,
  routesConstants.PUMP_SETTINGS,
  ...NOTIFICATIONS_ROUTES,
];

const TOOL_LEVEL_ROUTES = [
  ...SITE_LEVEL_ROUTES,
  routesConstants.GCU,
  routesConstants.ALARM_CONFIG,
];

const CONTROLLER_LEVEL_ROUTES = [routesConstants.CONTROLLER, ...NOTIFICATIONS_ROUTES];

const COMPRESSOR_LEVEL_ROUTES = [routesConstants.COMPRESSOR, ...NOTIFICATIONS_ROUTES];

const PUMP_LEVEL_ROUTES = [
  routesConstants.PUMP,
  routesConstants.PUMP_REGENERATION,
  routesConstants.PUMP_ANOMALIES,
  routesConstants.PUMP_CHAMBER_HISTORY,
  routesConstants.PUMP_CHANGE_HISTORY,
  routesConstants.PUMP_DIODES,
  routesConstants.PUMP_ALARM_HISTORY,
  ...NOTIFICATIONS_ROUTES,
];

const routeByEquipmentType = new Map([
  [
    ['compressorId', 'toolId', 'siteId'],
    {
      routes: COMPRESSOR_LEVEL_ROUTES,
    },
  ],
  [
    ['controllerId', 'toolId', 'siteId'],
    {
      routes: CONTROLLER_LEVEL_ROUTES,
    },
  ],
  [
    ['pumpId', 'toolId', 'siteId'],
    {
      routes: PUMP_LEVEL_ROUTES,
    },
  ],

  [
    ['toolId', 'siteId'],
    {
      routes: TOOL_LEVEL_ROUTES,
    },
  ],

  [
    ['siteId'],
    {
      routes: SITE_LEVEL_ROUTES,
    },
  ],
]);

function getRoute(routes: string[]) {
  const currentRoute = getHash();
  return (
    [...routes].reverse().find((route) => currentRoute.startsWith(route)) || routes[0]
  );
}

/**
 * Equipment could by: site, tool or pump or null when nothing selected (means all sites)
 */
export const changeEquipmentRoute = (node: EquipmentIdentifiers) => {
  // means unselect(nothing selected), display site level routes without any filtering
  if (node === null || !node.siteId) {
    history.push({
      pathname: getRoute(SITE_LEVEL_ROUTES),
      search: '',
    });
    return;
  }

  const match = (arr: string[], node: EquipmentIdentifiers) =>
    arr.every((param) =>
      node ? param in node && get(node, param) !== undefined : false
    );

  for (const [params, { routes }] of routeByEquipmentType) {
    if (match(params, node)) {
      // set new url query search params
      const query = new URLSearchParams();

      params.forEach((param) => query.set(param, get(node, param)));

      // update route for equipment (one of allowed of default one)
      const path = getRoute(routes);

      history.push({
        pathname: path,
        search: query.toString(),
      });
      break;
    }
  }
};

// todo: check where it's used since it will not be triggered on route change
export const getScopedQuery = () => {
  const state = AppStore.getState();

  const urlSearchParams = new URLSearchParams(getQuerySearch());
  const scope = state.siteEquipment.selectedSites;

  const isFilteredBySingleSite = urlSearchParams.get('siteId');
  return isFilteredBySingleSite ? Object.fromEntries(urlSearchParams) : { siteId: scope };
};

function getHash() {
  const hash = window.location.hash.split('?')[0] || '';
  return hash.replace('#', '');
}

// todo: check where it's used since it will not be triggered on route change
export function getQuerySiteId() {
  const urlSearchParams = new URLSearchParams(getQuerySearch());
  const siteId = parseInt(urlSearchParams.get('siteId') || '', 10);
  return siteId > 0 ? siteId : null;
}

export function getQueryToolId() {
  const urlSearchParams = new URLSearchParams(getQuerySearch());
  const toolId = parseInt(urlSearchParams.get('toolId') || '', 10);
  return toolId > 0 ? toolId : undefined;
}

// todo: check where it's used since it will not be triggered on route change
export function getQuerySearch(): string | undefined {
  // empty string or #/app/dashboard or #/app/dashboard/gcu?toolId=511&siteId=12141
  const hashWithQuery = window.location.hash;

  return hashWithQuery.split('?')[1];
}

export type ScopedQueryParams = {
  siteId?: number[] | string;
  toolId?: string;
  pumpId?: string;
  controllerId?: string;
  compressorId?: string;
};
