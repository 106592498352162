import React, { FC, useCallback, useState } from 'react';
import Button from 'src/shared/button';
import { Box, Typography } from '@material-ui/core';
import AddEditModal from '../add-edit-modal';

const Toolbar: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialog = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Box p="24px 0" display="flex" justifyContent="space-between">
      <Typography variant="h5">Admin</Typography>
      <Button onClick={toggleDialog}>Add user</Button>
      {isOpen && <AddEditModal isOpen={isOpen} toggleDialog={toggleDialog} />}
    </Box>
  );
};

export default Toolbar;
