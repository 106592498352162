import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const CloseBoxIcon: FC<Props> = (props) => {
  const { className, color } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 16 16">
      <path d="M0 0h16v16H0V0z" fill={color} />
      <path
        d="M13 4.502L11.498 3 8 6.498 4.502 3 3 4.502 6.498 8 3
         11.498 4.502 13 8 9.502 11.498 13 13 11.498 9.502 8 13 4.502z"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default CloseBoxIcon;
