import { createModel } from '@rematch/core';
import { RootModel } from '.';

import {
  AlarmConfigObj,
  ApiToolParams,
  GcuInfoType,
  toolApi,
} from 'src/store/api/tool.api';

export interface ToolState {
  alarmConfigData: AlarmConfigObj[] | null;
  gcuData: GcuInfoType | null;
  error: unknown | null;
}
const { fetchAlarmConfiguration, fetchGCU } = toolApi;

export const tool = createModel<RootModel>()({
  state: {
    alarmConfigData: [],
    gcuData: null,
    error: null,
  } as ToolState,

  reducers: {
    setAlarmConfig(state: ToolState, alarmConfigData: AlarmConfigObj[]): ToolState {
      return {
        ...state,
        alarmConfigData: alarmConfigData,
      };
    },
    setGcu(state: ToolState, gcu: GcuInfoType): ToolState {
      return {
        ...state,
        gcuData: gcu,
      };
    },
    setGcuError(state: ToolState, error: unknown): ToolState {
      return {
        ...state,
        gcuData: null,
        error: error,
      };
    },
    setAlarmConfigError(state: ToolState, error: unknown): ToolState {
      return {
        ...state,
        alarmConfigData: [],
        error: error,
      };
    },
  },

  effects: (dispatch) => ({
    async fetchToolAlarmConfig(params: ApiToolParams) {
      try {
        const { data } = await fetchAlarmConfiguration(params);
        dispatch.tool.setAlarmConfig(data as AlarmConfigObj[]);
      } catch (err) {
        dispatch.tool.setAlarmConfigError(err); // todo: proper error handling
      }
    },
    async fetchGcu(params: ApiToolParams) {
      try {
        const { data } = await fetchGCU(params);
        dispatch.tool.setGcu(data as GcuInfoType);
      } catch (err) {
        dispatch.tool.setGcuError(err); //todo: proper error handling
      }
    },
  }),
});
