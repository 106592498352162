import React, { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { ManualNotificationFlow } from 'src/shared/manual-notification';
// import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import {
  COMPRESSOR,
  CONTROLLER,
  EquipmentType,
  // PUMP,
} from 'src/shared/routing/with-selected-equipment';
import { RootState, select } from 'src/store';
import { formatTimezone } from './format-timezone';
import { useStyles } from './site-equipment-summary.styles'; // todo: revisit

interface Props {
  siteId: number;
  toolId: number;
  equipmentName?: string;
  equipmentType: EquipmentType;
}

const ToolLikeSummary: FC<Props> = ({ siteId, toolId, equipmentName, equipmentType }) => {
  const classes = useStyles();

  // const identifiers = useEquipmentIdentifiers();

  const site = useSelector((state: RootState) =>
    select.siteEquipment.selectSite(state, { siteId })
  );

  const tool = useSelector((state: RootState) =>
    select.siteEquipment.selectTool(state, { siteId, toolId })
  );

  const controller = useSelector((state: RootState) =>
    select.siteEquipment.selectController(state, { siteId, toolId })
  );

  // const manualNotificationFlow = () => {
  //   const isEquipment =
  //     equipmentType === PUMP ||
  //     equipmentType === CONTROLLER ||
  //     equipmentType === COMPRESSOR;

  //   if (isEquipment) {
  //     return (
  //       <ManualNotificationFlow
  //         siteName={site?.name}
  //         toolName={tool?.name}
  //         equipmentName={equipmentName}
  //         identifiers={identifiers}
  //       />
  //     );
  //   }

  //   return (
  //     <ManualNotificationFlow
  //       siteName={site?.name}
  //       toolName={tool?.name}
  //       identifiers={identifiers}
  //     />
  //   );
  // };

  const summaryTitle = useMemo(() => {
    if (equipmentType === CONTROLLER || equipmentType === COMPRESSOR) {
      return `${tool?.name}/${equipmentName}`;
    }

    return equipmentName;
  }, [equipmentName, equipmentType, tool?.name]);

  return (
    <div className={classes.summaryRoot}>
      <div className={classes.summarySlot}>
        <h1 className={[classes.typoH5, classes.summaryCaption].join(' ')}>
          {summaryTitle}
        </h1>
      </div>
      <div className={classes.freeSpace} />
      <div className={classes.summarySlot}>
        <span className={classes.subtitle}>Timezone: </span>
        <span className={classes.content}>{formatTimezone(site?.actualGmtOffset)}</span>
      </div>
      <div className={classes.summarySlot}>
        <span className={classes.subtitle}>Tool Address: </span>
        <span className={classes.content}>{controller?.ip}</span>
      </div>
      <div className={classes.summarySlot}>
        <span className={classes.subtitle}>Tool ID: </span>
        <span className={classes.content}>{toolId}</span>
      </div>
      <div className={classes.summarySlot}>
        <span className={classes.subtitle}>Pumps: </span>
        <span className={classes.content}>{site?.pumpCount}</span>
      </div>
      {/* {manualNotificationFlow()} */}
    </div>
  );
};

export default memo(ToolLikeSummary);
