import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  row: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',

    paddingRight: '0.25em',

    color: 'inherit',
    fontSize: '1rem',

    '&:hover': {
      background: 'rgba(0,0,0, 0.05)',
    },
  },
  selectedRow: {
    background: 'rgba(0,0,0, 0.05)',
  },
  // root level (0 level)
  site: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    fontWeight: 500,
    height: '3em',
  },
  // 1st level
  tool: {
    height: '2.25em',
  },
  // 2nd level (leaf node)
  equipment: {
    height: '2em',
  },
  col: {
    fontSize: '1em',
    display: 'flex',
    alignItems: 'center',
    padding: '0.25em',
  },
  textCol: {
    fontSize: '0.875em',
    overflow: 'hidden',
  },
  spacer: {
    margin: 'auto',
  },
  textLine: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  nodeButton: {
    fontSize: '1.5em',
    margin: '-4px',
    padding: '4px',
    color: 'black',
  },
  equipmentCounter: {
    marginRight: '0.5rem',
  },
});
