import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const PumpReplacementIcon: FC<Props> = (props) => {
  const { className, color } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00049 7C4.00049 6.44772 4.4482 6 5.00049 6H7.60041C7.82132
        6 8.00041 6.17909 8.00041 6.4V6.99774H16.0255C16.5778 6.99774 17.0255 7.44546 17.0255
        7.99774V8.99274H18.0011C18.2772 8.99274 18.5011 9.21659 18.5011 9.49274V11.5H19.5004V10.9963C19.5004
        10.7202 19.7243 10.4963 20.0004 10.4963C20.2766 10.4963 20.5004 10.7202 20.5004
        10.9963V13.0036C20.5004 13.2798 20.2766 13.5036 20.0004 13.5036C19.7243 13.5036
        19.5004 13.2798 19.5004 13.0036V12.5H18.5011V14.5073C18.5011 14.7834 18.2772 15.0073 18.0011
        15.0073H17.0255V16.022C17.0255 16.5743 16.5778 17.022 16.0255 17.022H8.00041V17.6C8.00041
        17.8209 7.82132 18 7.60041 18H5.00049C4.4482 18 4.00049 17.5523 4.00049 17V7ZM8.00041
        9.49872V10.5011H13.4984C13.7752 10.5011 13.9996 10.2767 13.9996 9.99993C13.9996 9.72312
        13.7752 9.49872 13.4984 9.49872H8.00041ZM8.00041 11.5036V12.506H15.5112C15.788 12.506 16.0124
        12.2816 16.0124 12.0048C16.0124 11.728 15.788 11.5036 15.5112 11.5036H8.00041ZM8.00041
        13.5084V14.5109H13.5008C13.7776 14.5109 14.002 14.2864 14.002 14.0096C14.002
        13.7328 13.7776 13.5084 13.5008 13.5084H8.00041Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default PumpReplacementIcon;
