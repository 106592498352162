import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

const baseGridStyles = (): StyleRules =>
  createStyles({
    gridContainer: {
      width: '100%',
      height: '100%',
      '& > * .ag-row-selected': {
        backgroundColor: COLORS.grey['200'],
      },
    },
    defaultCell: {
      border: `1px solid ${COLORS.grey['300']}`,
      borderTop: 'none',
      borderLeft: 'none',
      lineHeight: '2rem',
      padding: '0 0.5rem',
      fontSize: '0.875rem',
      outline: 'none',
      userSelect: 'text',

      '&::selection': {
        backgroundColor: COLORS.grey['300'],
      },
    },
    defaultHeader: {
      borderBottom: `1px solid ${COLORS.grey['300']}`,
      fontWeight: 'bold',
      fontSize: '0.75rem',
      whiteSpace: 'normal',
      height: '100%',
      cursor: 'default',
      outline: 'none',
      overflow: 'visible',
    },
  });
export default baseGridStyles;
