import { ScopedQueryParams } from 'src/shared/routing/routing.helper';
import { FetchBySearchTerm } from 'src/store/api/search.api';
import { SearchEntityType, fetchBySearchTerm } from 'src/store/api/search.api';
import BaseApi, { PaginateableRes } from '.';

const compressors = new BaseApi('./compressors');

export interface CompressorRow {
  ageInHours: number;
  current: number;
  description: string | null;
  differentialPressure: number;
  heliumTotalAvailable: number;
  heliumTotalConsumption: number;
  id: number | string | null;
  lineFrequency: number;
  lineVoltage: number;
  model: string;
  modulePartNumber: string | null;
  moduleSerialNumber: string | null;
  motorStatus: number;
  online: string;
  partNumber: string | null;
  power: number;
  returnPressure: number;
  serialNumber: string | null;
  siteId: number;
  softwareVersion: string;
  staticPressure: number;
  supplyPressure: number;
  toolId: number;
  toolName: string;
  updatedAt: string;
  waterFlow: number;
  waterIn: number;
  waterOut: number;
}

type SearchableCompressorResp = PaginateableRes<{
  compressorId: number;
  compressorName: string;
  siteId: number;
  toolId: number;
  toolName: string;
}>;

export const compressorsApi = {
  //params will be identified by the return type of the getScopedQuery function located in routing.helper.ts
  fetchRows: (params: ScopedQueryParams) => compressors.get<CompressorRow[]>({ params }),

  async fetchBySearchTerm(params: FetchBySearchTerm) {
    const { data, ...rest } = await fetchBySearchTerm<SearchableCompressorResp>(
      SearchEntityType.compressors,
      params
    );

    // prepare autocomplete items
    const items = data.map(({ compressorId, compressorName, toolName, ...rest }) => ({
      value: compressorId,
      label: `${toolName} - ${compressorName}`,
      extraFields: rest,
    }));

    return { ...rest, data: items };
  },

  fetchCompressor: async (params: ScopedQueryParams) => {
    const compressorEndpoint = new BaseApi(
      `./sites/${params.siteId}/compressors/${params.compressorId}`
    );

    const { data } = await compressorEndpoint.get<CompressorRow>();

    return data;
  },
};
