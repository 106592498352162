import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as NotFoundIcon } from 'src/shared/icons/not-found.svg';
import { useStyles } from './styles';
import Button from 'src/shared/button';
import { useHistory } from 'react-router-dom';

const NotFound: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = () => history.goBack();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%">
      <Box mb={3}>
        <NotFoundIcon />
      </Box>
      <Box mb={3}>
        <Typography className={classes.text}>Sorry! Page Not Found</Typography>
      </Box>
      <Button onClick={handleClick}>Go Back</Button>
    </Box>
  );
};

export default NotFound;
