import React, { Component } from 'react';
import { styles } from './column-dropdown-rows.styles';
import { ColData } from 'src/shared/base-grid/base-grid';
import { Box, Checkbox, FormControlLabel, WithStyles } from '@material-ui/core';
import { Spinner } from 'src/shared/spinner';

interface OwnProps extends WithStyles<typeof styles> {
  filteredColumns: ColData[] | null;
  selectedRowsCount: number;
  total: number;
  onColumnsChange: (column: ColData, value: boolean) => void;
  colNotFound: boolean;
}

export default class ColumnDropdownRows extends Component<OwnProps> {
  private renderRows = () =>
    this.props.filteredColumns?.map((el) => (
      <div key={el.id}>
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              checked={el.visibility}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>, value: boolean) =>
                this.props.onColumnsChange(el, value)
              }
            />
          }
          label={el.label}
        />
      </div>
    ));

  render() {
    const { total, selectedRowsCount, classes, colNotFound } = this.props;
    const isIE = !!window.navigator?.msSaveOrOpenBlob;

    if (!total) {
      return (
        <Box display="flex" justifyContent="center" p="24px 8px 8px">
          <Spinner size={32} />
        </Box>
      );
    }

    return (
      <div
        className={
          isIE && window.innerHeight < 800 ? classes.containerIE : classes.container
        }>
        <div className={classes.title}>
          {colNotFound
            ? 'Column not found'
            : `${selectedRowsCount} selected from ${total}`}
        </div>
        {this.renderRows()}
      </div>
    );
  }
}
