import React, { CSSProperties, FC } from 'react';
import { useStyles } from './gauge-arc.styles';
import clsx from 'clsx';

interface Props {
  value: number;
  status: string;
}

// How it works: https://glennmccomb.com/articles/building-a-pure-css-animated-svg-spinner/
export const GaugeArc: FC<Props> = (props) => {
  const classes = useStyles();
  const { value, status } = props;

  const MIN = 280;
  const MAX = 70;

  const statuses = {
    normal: 'url(#normal)',
    warning: 'url(#warning)',
    critical: 'url(#critical)',
  };

  const bar: CSSProperties = {
    stroke:
      status in statuses ? statuses[status as keyof typeof statuses] : statuses.normal,
    strokeDashoffset: MIN + ((MAX - MIN) / 100) * value,
    visibility: isNaN(value) || value === 0 ? 'hidden' : 'visible',
  };

  return (
    <svg viewBox="-3 -10 101 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="critical" gradientTransform="rotate(45)">
          <stop offset="50%" stopColor="#960007" />
          <stop offset="100%" stopColor="#CF202E" />
        </linearGradient>

        <linearGradient id="warning" gradientTransform="rotate(45)">
          <stop offset="50%" stopColor="#E65100" />
          <stop offset="100%" stopColor="#FF9800" />
        </linearGradient>

        <linearGradient id="normal" gradientTransform="rotate(45)">
          <stop offset="50%" stopColor="#2E7D32" />
          <stop offset="100%" stopColor="#4CAF50" />
        </linearGradient>
      </defs>

      <circle
        cx="50"
        cy="50"
        r="44.6"
        className={clsx(classes.base, classes.path)}
        transform="rotate(135, 50, 45)" // IE fix. This property will work only in IE and would be handled by styles in other browsers
      />

      <circle
        cx="50"
        cy="50"
        r="44.6"
        className={clsx(classes.base, classes.bar)}
        transform="rotate(135, 50, 45)" // IE fix. This property will work only in IE and would be handled by styles in other browsers
        style={bar}
      />
    </svg>
  );
};
