import React, { FC, Ref, useCallback } from 'react';
import { TextFieldProps } from '@material-ui/core';
import { FieldErrors, FieldValues, ValidationOptions } from 'react-hook-form';
import { PassField } from '../index';

type PasswordConfig = {
  name: string;
  label: string;
  helperText?: string;
};

type ComponentProps = {
  config: {
    password: PasswordConfig;
    confirmPassword: PasswordConfig;
  };
  register: (validationOptions: ValidationOptions) => Ref<HTMLInputElement>;
  errors: FieldErrors<FieldValues>;
  clearError: (name: string) => void;
  getValues: (name: string) => string;
} & TextFieldProps;

const ResetPassFields: FC<ComponentProps> = (props) => {
  const { config, register, errors, clearError, getValues, ...restProps } = props;

  const handlePasswordChange = useCallback(() => {
    clearError(config.password.name);
    clearError(config.confirmPassword.name);
  }, [clearError, config.confirmPassword.name, config.password.name]);

  const handleValidatePassword = useCallback(
    (value: string) =>
      value === getValues(config.password.name) || "Error: Passwords don't match",
    [config.password.name, getValues]
  );

  return (
    <>
      <PassField
        name={config.password.name}
        label={config.password.label}
        onChange={handlePasswordChange}
        error={errors[config.password.name]}
        helperText={config.password.helperText}
        inputRef={register({
          required: true,
          minLength: {
            value: 6,
            message: 'Error: At least 6 characters',
          },
          maxLength: 300,
        })}
        {...restProps}
      />
      <PassField
        name={config.confirmPassword.name}
        label={config.confirmPassword.label}
        onChange={handlePasswordChange}
        error={errors[config.confirmPassword.name]}
        helperText={config.confirmPassword.helperText}
        inputRef={register({
          required: true,
          validate: handleValidatePassword,
        })}
        {...restProps}
      />
    </>
  );
};

export default ResetPassFields;
