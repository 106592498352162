import { OptionsObject } from 'notistack';
import store from '../store';

const enqueueSnackbar = (message: string, options?: OptionsObject) =>
  store.dispatch({
    type: 'snackbars/enqueueSnackbar',
    payload: {
      message,
      options,
      key: Math.random().toString(16).substring(2, 15),
    },
  });

export const showError = (message: string, options?: OptionsObject) =>
  enqueueSnackbar(message, {
    variant: 'error',
    persist: true,
    preventDuplicate: true,
    ...options,
  });

export const showSuccess = (message: string, options?: OptionsObject) =>
  enqueueSnackbar(message, {
    variant: 'success',
    persist: false,
    ...options,
  });

export const showInfo = (message: string, options?: OptionsObject) =>
  enqueueSnackbar(message, {
    variant: 'info',
    preventDuplicate: true,
    ...options,
  });

export const showWarning = (message: string, options?: OptionsObject) =>
  enqueueSnackbar(message, {
    variant: 'warning',
    ...options,
  });

export const closeSnackbar = (key?: string) =>
  store.dispatch({ type: 'snackbars/closeSnackbar', payload: { key } });
