export interface Issue {
  label: string;
  value: string | number;
}

const issues: Issue[] = [
  { label: '1st Stage Temperature', value: 'sa1_temp' },
  { label: '2nd Stage Temperature', value: 'sa2_temp' },
  { label: 'Cryopump RPM', value: 'pump_rpm' },
  { label: 'Cryopump Collapsing Differential Pressure', value: 'pump_pressure' },
  { label: "Cryopump Can't Handle Heat Load", value: 'pump_heat' },
  { label: 'Cryopump Vacuum Leak', value: 'pump_vacuum' },
  { label: 'Helium Contamination', value: 'he_contamination' },
  { label: 'Cryopump Array Contaminated', value: 'pump_array_contamination' },
  { label: 'Compressor Fails To Stay On', value: 'compressor_fail' },
  { label: 'Communication Issue', value: 'communication_fail' },
  { label: 'Electronics Issue', value: 'electronic_fail' },
  { label: 'Tool / Customer issue', value: 'tool_cust_state' },
];

export default issues;
