import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

const datePickerStyles = (): StyleRules =>
  createStyles({
    root: {
      background: COLORS.grey['200'],
    },
    input: {
      paddingBottom: '7px',
    },
    calendarIcon: {
      padding: '8px',
      marginTop: '14px',
    },
  });
export default datePickerStyles;
