import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { ApiToolParams } from 'src/store/api/tool.api';
import { useStyles } from './styles';
import EmptyState from 'src/shared/empty-state/index';
import Spinner from 'src/shared/spinner';
import { getQuerySearch } from 'src/shared/routing/routing.helper';

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const ToolGCUContainer: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { search, fetchGcu, gcuData, isGcuLoading } = props;

  const params = new URLSearchParams(search);
  const toolId = params.getAll('toolId')[0];
  const siteId = params.getAll('siteId')[0];

  const gcuArr = [
    { id: 'site_name', title: 'Site', data: gcuData?.site_name },
    { id: 'tool_name', title: 'Tool', data: gcuData?.tool_name },
    { id: 'ip_address', title: 'IP Address', data: gcuData?.ip_address },
    { id: 'serial_number', title: 'Serial Number', data: gcuData?.serial_number },
    {
      id: 'software_revision',
      title: 'Software Revision',
      data: gcuData?.software_revision,
    },
    { id: 'elapsed_hours', title: 'Elapsed Hours', data: gcuData?.elapsed_hours },
    { id: 'tcp_port', title: 'TCP Connect Port', data: gcuData?.tcp_port },
    { id: 'tcp_speed', title: 'TCP Speed', data: gcuData?.tcp_speed },
    { id: 'tcp_timeout', title: 'TCP Timeout', data: gcuData?.tcp_timeout },
    { id: 'subnet_mask', title: 'Subnet Mask', data: gcuData?.subnet_mask },
    { id: 'default_gateway', title: 'Default Gateway', data: gcuData?.default_gateway },
    { id: 'gcu_model', title: 'GCU Model', data: gcuData?.gcu_model },
    { id: 'tool_id', title: 'Tool ID', data: toolId },
  ];

  useEffect(() => {
    fetchGcu(toolId.toString(), siteId);
  }, [toolId, siteId, fetchGcu]);

  if (isGcuLoading) {
    return (
      <Box className={classes.spinnerContainer}>
        <Spinner />
      </Box>
    );
  }
  if (!gcuData) {
    return <EmptyState>No rows to show</EmptyState>;
  }
  return (
    <Box className={classes.rootContainer}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableBody>
            {gcuData &&
              gcuArr.map((el) => (
                <TableRow key={el.id}>
                  <TableCell className={classes.label}>{el.title}</TableCell>
                  <TableCell>{el.data}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const mapState = (state: RootState) => {
  const { tool } = state;
  return {
    gcuData: tool.gcuData,
    search: getQuerySearch(),
    isGcuLoading: state.loading.effects.tool.fetchGcu,
  };
};

const mapDispatch = (dispatch: RootDispatch) => {
  const { tool } = dispatch;
  return {
    fetchGcu: (toolId: string, siteId: string) =>
      tool.fetchGcu({ toolId, siteId } as ApiToolParams),
  };
};

export default connect(mapState, mapDispatch)(ToolGCUContainer);
