import { getTimeSeries } from './../../services/chart-builder-service';
import { createModel } from '@rematch/core';
import { RootModel } from '.';

import {
  AxesAttribute,
  ChartData,
  ChartListItem,
  CreateChartPayload,
  Metadata,
  Metric,
  Side,
  chartsBuilderAPI,
} from '../api/charts-builder.api';
import { AutocompleteOption } from 'src/shared/autocompletes-wtih-chips';
import groupBy from 'lodash/groupBy';
import { EntityType } from 'src/types';
import { routesConstants } from 'src/shared/routing/routes.constants';
import { showError, showSuccess } from 'src/services/snackbars';
import { camelizeKeys } from 'humps';
import { history } from 'src/services/history';

export interface ChartsFormData {
  title?: string;
  sites?: AutocompleteOption[];
  groupedEntities?: Record<EntityType, AutocompleteOption[]>;
  groupedMetrics?: Record<string, AutocompleteOption[]>;
  groupedAxes?: Record<string, string | number | null>;
  axesBySide?: Record<Side, AxesAttribute[]> | null;
}

interface ChartsState {
  selectedChart: ChartListItem | null;
  charts: ChartListItem[];
  metrics: Record<EntityType, AutocompleteOption[]> | null;
  chartData: ChartData | null;
}

const getLabel = (type: EntityType, metadata: Metadata): string => {
  switch (type) {
    case EntityType.Tool:
      return metadata.toolName as string;
    case EntityType.Pump:
      return `${metadata.toolName} - ${metadata.stationName}`;
    case EntityType.Compressor:
      return `${metadata.toolName} - ${metadata.compressorName}`;
  }
};

export const charts = createModel<RootModel>()({
  state: {
    selectedChart: null,
    charts: [],
    metrics: null,
    chartData: null,
  } as ChartsState,

  reducers: {
    set(state: ChartsState, charts: ChartListItem[]): ChartsState {
      return {
        ...state,
        charts,
      };
    },
    addChartToList(state: ChartsState, chart: ChartListItem): ChartsState {
      return {
        ...state,
        charts: [...state.charts, chart],
      };
    },
    editChartList(state: ChartsState, chart: ChartListItem): ChartsState {
      return {
        ...state,
        charts: state.charts.map((entry) => {
          return entry.id === chart.id ? { ...chart } : entry;
        }),
      };
    },
    setSelectedChart(state: ChartsState, selectedChart: ChartListItem): ChartsState {
      return {
        ...state,
        selectedChart,
        chartData: null,
      };
    },
    setMetrics(
      state: ChartsState,
      metrics: Record<EntityType, AutocompleteOption[]>
    ): ChartsState {
      return {
        ...state,
        metrics,
      };
    },
    setChartData(state: ChartsState, chartData: ChartData): ChartsState {
      return {
        ...state,
        chartData,
      };
    },
  },

  selectors: (slice) => ({
    selectedChartFormData() {
      return slice(({ chartData }) => {
        return {
          title: chartData?.name,
          sites: chartData?.sites.map(({ label, id }) => ({ label, value: id })) ?? [],
          groupedEntities: chartData?.time_series_entities.reduce(
            (memo, { metadata, entity_id, entity_type, ...rest }) => {
              memo[entity_type] = [
                ...(memo[entity_type] ?? []),
                {
                  label: metadata ? getLabel(entity_type, metadata) : '',
                  value: entity_id,
                  extraFields: camelizeKeys(rest),
                },
              ];

              return memo;
            },
            {} as Record<EntityType, AutocompleteOption[]>
          ),
          groupedMetrics: chartData?.time_series.reduce(
            (memo, { label, eb_metric_id, entity_type, axis, ...rest }) => {
              memo[`${axis}.${entity_type}`] = [
                ...(memo[`${axis}.${entity_type}`] ?? []),
                {
                  label: label,
                  value: eb_metric_id,
                  extraFields: camelizeKeys(rest),
                },
              ];

              return memo;
            },
            {} as Record<string, AutocompleteOption[]>
          ),
          groupedAxes: chartData?.axes.reduce((memo, entry) => {
            memo[`axes.${entry.side}-${entry.id}.title`] = entry.name;
            memo[`axes.${entry.side}-${entry.id}.min`] = entry.min;
            memo[`axes.${entry.side}-${entry.id}.max`] = entry.max;

            return memo;
          }, {} as Record<string, string | number | null>),

          axesBySide: chartData
            ? (groupBy(chartData.axes, 'side') as Record<Side, AxesAttribute[]>)
            : null,
        };
      });
    },
  }),

  effects: (dispatch) => ({
    fetchCharts(): Promise<void> {
      return chartsBuilderAPI.fetchCharts().then(({ data }) => {
        dispatch.charts.set(data);
      });
    },

    createChart(payload: CreateChartPayload): Promise<void> {
      return chartsBuilderAPI.createChart(payload).then(({ data: { id, name } }) => {
        dispatch.charts.addChartToList({ id, name });
        dispatch.charts.setSelectedChart({ id, name });
        history.push(`${routesConstants.BASE}/charts/${id}`);
      });
    },

    async removeChart(id: number, state): Promise<void> {
      try {
        await chartsBuilderAPI.removeChart(id);
        const charts = state.charts.charts.filter((chart) => chart.id !== id);

        dispatch.charts.set(charts);
        history.push(routesConstants.CHARTS_NEW);
        showSuccess('Chart deleted');
      } catch (error) {
        showError(error.message);
      }
    },

    async updateChart(payload: CreateChartPayload, state): Promise<void> {
      try {
        const id = state.charts.chartData?.id;

        if (state.charts.chartData) {
          const { time_series } = state.charts.chartData;
          const { time_series_attributes } = payload;

          payload.time_series_attributes = getTimeSeries(
            time_series,
            time_series_attributes
          );
        }

        if (id) {
          const resp = await chartsBuilderAPI.updateChart({ ...payload, id });
          history.push(`${routesConstants.BASE}/charts/${id}`);
          dispatch.charts.setChartData(resp.data);
          dispatch.charts.editChartList({ id: resp.data.id, name: resp.data.name });
          showSuccess('Saved!');
        }
      } catch (error) {
        showError(error.message);
      }
    },

    async fetchMetrics(): Promise<void> {
      const { data } = await chartsBuilderAPI.fetchMetrics();
      const normalizedData = groupBy<AutocompleteOption>(
        data.map(
          ({ id, label, ...rest }: Metric): AutocompleteOption => ({
            value: id,
            label,
            extraFields: rest,
          })
        ),
        (entry) => entry.extraFields?.entity_type
      );
      dispatch.charts.setMetrics(
        normalizedData as Record<EntityType, AutocompleteOption[]>
      );
    },

    async fetchChartById(chartId: number): Promise<void> {
      const { data } = await chartsBuilderAPI.fetchChartById(chartId);
      dispatch.charts.setChartData(data);
    },
  }),
});
