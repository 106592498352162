import { getScopedQuery } from 'src/shared/routing/routing.helper';
import isArray from 'lodash/isArray';

export const isDefaultLevel = () => {
  const { siteId } = getScopedQuery();

  if (isArray(siteId)) return !siteId.length;

  return !siteId;
};
