import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

const TEXT_SIZE = '11px';
const BOX_SIZE = {
  width: '32px',
  height: '16px',
};

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      container: {
        display: 'flex',
        alignSelf: 'flex-end',
        justifyContent: 'space-between',
        width: '50%',
      },
      colorsLabel: {
        color: COLORS.grey['600'],
        fontSize: TEXT_SIZE,
        marginRight: '8px',
      },
      colorBox: {
        ...BOX_SIZE,
        position: 'relative',
        '&:nth-of-type(1)': {
          backgroundColor: COLORS.success,
        },
        '&:nth-of-type(2)': {
          backgroundColor: COLORS.warning,
        },
        '&:nth-of-type(3)': {
          backgroundColor: COLORS.danger,
        },
        '& span': {
          color: COLORS.grey['600'],
          fontSize: TEXT_SIZE,
          position: 'absolute',
          marginTop: '5px',
          '&:nth-child(1)': {
            top: '100%',
            right: '100%',
          },
          '&:nth-child(2)': {
            top: '100%',
            right: '0',
          },
        },
      },
      anomaliesBox: {
        ...BOX_SIZE,
        backgroundColor: COLORS.red['500'],
      },
      anomaliesLabel: {
        marginLeft: '8px',
        color: COLORS.grey['600'],
        fontSize: TEXT_SIZE,
      },
    })
);
