import BaseApi from '.';

type status = 'online' | 'warning' | 'offline';
export interface Site {
  id: number;
  company: string;
  siteId: number;
  name: string;
  status: status;
  actualGmtOffset: number;
  toolCount: number;
  pumpCount: number;
  offlineCount: number;
  updatedAt: string;
  activeNotificationsCount: number;
}

interface BaseIdentifiers {
  siteId: number;
  toolId: number;
}

export interface Tool extends BaseIdentifiers {
  name: string;
  oemName: string;
  status: status;
  activeNotificationsCount: number;
  pumps: Pump[];
  compressors: Compressor[];
  controller: Controller;
}

export interface Pump extends BaseIdentifiers {
  pumpId: number;
  pumpAddress: string;
  stationName: string;
  online: boolean;
  activeNotificationsCount: number;
  type: 'cryopump' | 'waterpump';
}

export interface Compressor extends BaseIdentifiers {
  compressorId: number;
  compressorName: string;
  online: boolean;
  activeNotificationsCount: number;
}

export interface Controller extends BaseIdentifiers {
  controllerId: number;
  ip: string;
  serialNumber: string;
  online: boolean;
  activeNotificationsCount: number;
}

const sites = new BaseApi('/sites');

export const sitesApi = {
  /**
   * Fetch all sites
   */
  fetchSites() {
    return sites.get<Site[]>().then(({ data }) => data);
  },

  /**
   * Fetch specific site equipment (all tools with their pumps)
   */
  async fetchSiteEquipment(siteId: number) {
    const siteEquipment = new BaseApi(`sites/${siteId}/equipment`);

    const { data } = await siteEquipment.get<Tool[]>();

    return data;
  },
};
