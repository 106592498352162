import React, { FC, useCallback, useEffect, useState } from 'react';
import { ConnectedProps } from './admin.container';
import BaseGrid from 'src/shared/base-grid';
import { StyledColDef } from 'src/shared/base-grid/base-grid';
import { createColumn } from 'src/shared/base-grid/column-creator';
import { columns } from './column.config';
import PageWrapper from 'src/shared/page-wrapper';
import { COLORS } from 'src/styles/colors';
import ExportButton from 'src/shared/button-export';
import { HEADER_HEIGHTS } from 'src/shared/base-grid/base-grid.config';

const Admin: FC<ConnectedProps> = (props) => {
  const { fetchList, list } = props;
  const [colData, setColData] = useState<StyledColDef[]>([]);

  const getRowStyle = useCallback(
    (params) => ({
      color: params.data.deactivated ? COLORS.grey['500'] : COLORS.black,
    }),
    []
  );

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    const colData: StyledColDef[] = columns.map((el) => createColumn(el));

    setColData(colData);
  }, [list]);

  if (!list.length) {
    return null;
  }

  return (
    <PageWrapper title="Users list">
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ExportButton url={'/users.xlsx'} />
        </div>
        <BaseGrid
          autoSizeColumns={true}
          colData={colData}
          rows={list}
          getRowStyle={getRowStyle}
          headerHeight={HEADER_HEIGHTS.oneRow}
        />
      </div>
    </PageWrapper>
  );
};

export default Admin;
