import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

const toggleButtonStyles = (): StyleRules =>
  createStyles({
    selected: {
      backgroundColor: COLORS.grey['400'],
      boxShadow: 'none',
    },
    button_hover: {
      backgroundColor: COLORS.grey['400'],
      boxShadow: 'none',
    },
  });
export default toggleButtonStyles;
