import React, { FC, useEffect, useState } from 'react';
import BaseGrid from 'src/shared/base-grid';
import { ColData, StyledColDef } from 'src/shared/base-grid/base-grid';
import { createColumn } from 'src/shared/base-grid/column-creator';
import ExportBtn from 'src/shared/button-export';
import { PumpSetting } from 'src/store/api/pumps.api';
import { Box } from '@material-ui/core';
import { HEADER_HEIGHTS } from 'src/shared/base-grid/base-grid.config';

export interface Props {
  autoSize: boolean;
  rows: PumpSetting[] | null;
  columns: ColData[];
  exportUrl: string;
  titleSpec?: string;
}

const SettingsHistoryGrid: FC<Props> = (props: Props) => {
  const [styledColumns, setStyledColumns] = useState<StyledColDef[]>([]);
  const { autoSize, rows, columns, exportUrl, titleSpec } = props;

  useEffect(() => {
    const styledColumnData: StyledColDef[] = columns.map((col: ColData) => {
      const isNoSortCol = col.name === 'old_value' || col.name === 'new_value';
      return createColumn(col, {
        headerComponentParams: {
          dataType: col.units,
          enableSorting: isNoSortCol ? false : true,
        },
      });
    });
    setStyledColumns(styledColumnData);
  }, [columns]);

  const exportBtnProps = {
    url: exportUrl,
    titleSpec,
  };
  return (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end">
        <ExportBtn {...exportBtnProps} />
      </Box>
      <BaseGrid
        autoSizeColumns={autoSize}
        rows={rows}
        colData={styledColumns}
        headerHeight={HEADER_HEIGHTS.oneRow}
      />
    </React.Fragment>
  );
};

export default SettingsHistoryGrid;
