import { formatNotApplicable } from 'src/shared/base-grid/value-formatters';

type Row = {
  label: string;
  value: unknown;
};

export const formatRowsToNA = (rows: Row[]) => {
  return rows.map((row) => ({
    label: row.label,
    value:
      row.value === null || typeof row.value !== 'object'
        ? formatNotApplicable(row)
        : row.value,
  }));
};
