import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { getQuerySearch } from 'src/shared/routing/routing.helper';
import ChamberHistoryGrid from 'src/app/pump/chamber-history/chamber-history-grid/chamber-history-grid';
import { chamberHistoryColumns } from 'src/app/pump/chamber-history/chamber-history-grid/columns';

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PumpChamberHistory: FC<Props> = (props) => {
  const {
    chamberHistoryData: data,
    fetchChamberData,
    search,
    exportUrl,
    titleSpec,
  } = props;

  useEffect(() => {
    const params = new URLSearchParams(search);
    const pumpId = params.getAll('pumpId')[0];
    const toolId = params.getAll('toolId')[0];
    const siteId = params.getAll('siteId')[0];
    fetchChamberData(pumpId, toolId, siteId);
  }, [search, fetchChamberData]);

  return (
    <ChamberHistoryGrid
      exportUrl={exportUrl}
      rows={data}
      columns={chamberHistoryColumns}
      titleSpec={titleSpec}
    />
  );
};

const mapState = (state: RootState) => {
  const { pump } = state;
  return {
    chamberHistoryData: pump.chamberHistory,
    search: getQuerySearch(),
    exportUrl: '/chamber_changes.xlsx',
    titleSpec: 'chamber-history.xlsx',
  };
};

const mapDispatch = (dispatch: RootDispatch) => {
  const { pump } = dispatch;
  return {
    fetchChamberData: (pumpId: string, toolId: string, siteId: string) =>
      pump.fetchPumpChamberHistory({ pumpId, toolId, siteId }),
  };
};
export default connect(mapState, mapDispatch)(PumpChamberHistory);
