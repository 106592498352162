import { COLORS } from 'src/styles/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  notFound: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.grey[500],
  },
  headerRoot: {
    height: '4.5rem',
    padding: '0.5rem 1rem',
    borderBottom: `1px solid ${COLORS.grey[300]}`,
  },
  contentRoot: {
    padding: '1rem',
    height: 'calc(100% - 4.5rem)',
    overflow: 'auto',
  },
});
