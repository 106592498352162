import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const OtherIcon: FC<Props> = (props) => {
  const { className, color } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 20 20">
      <path
        d="M18.3505 5.59424H7.15049C6.38049 5.59424 5.75749 6.22424 5.75749 6.99424L5.75049 19.5942L8.55049
 16.7942H18.3505C19.1205 16.7942 19.7505 16.1642 19.7505 15.3942V6.99424C19.7505 6.22424 19.1205 5.59424
  18.3505 5.59424ZM10.6505 11.8942H9.25049V10.4942H10.6505V11.8942ZM13.4505
   11.8942H12.0505V10.4942H13.4505V11.8942ZM16.2505 11.8942H14.8505V10.4942H16.2505V11.8942Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default OtherIcon;
