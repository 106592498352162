import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './visual-map.styles';

const VisualMap: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Box display="flex" alignItems="center">
        <div className={classes.colorsLabel}>RPM</div>
        <Box display="flex">
          <div className={classes.colorBox}>
            <span>40</span>
          </div>
          <div className={classes.colorBox}>
            <span>60</span>
          </div>
          <div className={classes.colorBox}>
            <span>70</span>
            <span>80</span>
          </div>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <div className={classes.anomaliesBox} />
        <div className={classes.anomaliesLabel}>Anomalies</div>
      </Box>
    </div>
  );
};

export default VisualMap;
