import { IGetRowsParams } from 'ag-grid-community';
import { decamelize } from 'humps';
import { EquipmentType } from 'src/shared/routing/with-selected-equipment';
import { ScopedQueryParams, getScopedQuery } from 'src/shared/routing/routing.helper';
import { downloadFile } from 'src/services/download-service';
import { toNotificationFormData } from 'src/services/notifications-service';
import BaseApi, { PaginateableRes, Sort, getPagination, getSort } from '.';
import {
  getEquipmentSubtypeOrType,
  getMappedPayload,
  getRangesWithSubtype,
} from 'src/services/ranges-service';

export enum ManualTypes {
  COMPRESSOR_REPLACEMENT = 'compressor_replacement',
  PUMP_REPLACEMENT = 'pump_replacement',
  PERFORMANCE_ADVISORY = 'performance_advisory',
  OTHER = 'other',
}

export const devices = {
  Compressors: 'compressor',
  Controllers: 'controller',
  Cryopumps: 'cryopump',
  Waterpumps: 'waterpump',
};

export type Devices = typeof devices;
export type DeviceSubtype = typeof devices[keyof Devices];

export type CategoryTypeOptions = 'all' | 'auto' | 'manual';
export type ManualTypeOptions = 'all' | ManualTypes;

export type SubscriptionLevel = 'site' | 'tool' | null;

const excludeAllOption = (option: string) => (option === 'all' ? undefined : option);

// exclude `all` option from request since be not support such an option
export const getCategoryType = (option: CategoryTypeOptions) => excludeAllOption(option);

// exclude any manual category subtypes when category type is `auto`, additionaly exclude `all` from manual sub type
export const getManualCategorySubtype = (
  category: CategoryTypeOptions,
  option: ManualTypeOptions
) => {
  return category !== 'auto' ? excludeAllOption(option) : undefined;
};

export const getEquipmentIdentifiers = () => {
  const {
    siteId,
    toolId,
    pumpId,
    controllerId,
    compressorId,
  }: ScopedQueryParams = getScopedQuery();
  return {
    site_id: siteId,
    tool_id: toolId,
    equipment_id: pumpId || compressorId || controllerId,
  };
};

interface EquipmentIdentifiers {
  siteId: number;
  toolId?: number;
  equipmentId?: number;
  equipmentType?: string;
}

export interface AttachmentIdentifiers {
  siteId: number;
  notificationId: number;
  attachmentId: number;
  filename: string;
}

export interface NotificationIdentifiers {
  siteId: number;
  notificationId: number;
}

export interface FetchNotificationListParams extends IGetRowsParams {
  dates: [Date, Date];
  equipmentType: EquipmentType | null;
  notificationType: CategoryTypeOptions;
  manualType: ManualTypeOptions;
}
export interface Range {
  name: string;
  inheritedMinValue: number;
  inheritedMaxValue: number;
  notificationMinimum: string | null;
  notificationMaximum: string | null;
  level: string;
  updatedAt: string | null;
  updatedBy: string | null;
  subscription: boolean | null;
  subscriptionLevel: SubscriptionLevel;
}

export type RangesWithSubtype = Record<DeviceSubtype, Range[]>;

export interface RangeOverriddenValues {
  name: string;
  min_value?: number | null;
  max_value?: number | null;
  subscription?: boolean | null;
  subscription_level?: SubscriptionLevel;
}

export interface RangePayload {
  inherited_min_value: number;
  inherited_max_value: number;
  min_value: number | null;
  max_value: number | null;
  level: string;
  updated_at: string | null;
  updated_by: string | null;
  subscription: boolean | null;
  subscription_level: SubscriptionLevel;
}

type RangesResponseByDeviceType = Record<string, RangePayload>;
export type RangesResponse = Record<DeviceSubtype, RangesResponseByDeviceType>;

export interface Attachment {
  id: number;
  filename: string;
}

export interface Notification extends EquipmentIdentifiers {
  notificationId: number;
  active: boolean;
  acknowledged: boolean | null;
  attachments: Attachment[];
  sender: string;
  subject: string;
  recipients: string[];
  siteName: string;
  toolName: string | null;
  stationName: string | null;
  createdAt: string;
  updatedAt: string;
  notificationType: string;
  manualType: string | null;
  comments: string | null;
  recommendations: string | null;
}

export interface AcknowledgeUser {
  fullName: string;
  email: string;
}

export interface SwitchHistory {
  value: number;
  minValue: number;
  maxValue: number;
  outOfRange: boolean;
  loggedAt: string;
}

export interface AutoNotification extends Notification {
  acknowledgedAt: string | null;
  acknowledgedBy: AcknowledgeUser;
  switchHistories: SwitchHistory[];
}

export type NotificationRowResponse = PaginateableRes<Notification>;

export interface NotificationPayload {
  subject: string;
  recommendations: string;
  comments: string;
  email_recipients: string[];
  notification_type: ManualTypes;
  attachments: File[];
  equipment_type: EquipmentType | null;
  site_id: number;
  tool_id?: number | null;
  equipment_id?: number | null;
}

interface MetricOverridePayload {
  min_value: number | null;
  max_value: number | null;
  subscription: boolean | null;
}

export type RangeOverridePayload = Record<string, MetricOverridePayload>;

export const notificationsApi = {
  fetchNotificationList: async (fetchParams: FetchNotificationListParams) => {
    const equipIdentifiers = getEquipmentIdentifiers();
    const notificationsApi = new BaseApi('/notifications');

    const {
      dates: [start_date, end_date],
      startRow,
      endRow,
      sortModel,
      notificationType,
      manualType,
      equipmentType,
    } = fetchParams;

    const params = {
      start_date,
      end_date,
      ...equipIdentifiers,
      equipment_type: equipmentType,
      notification_type: getCategoryType(notificationType),
      manual_type: getManualCategorySubtype(notificationType, manualType),
      ...getPagination(startRow, endRow),
      sort: getSort(
        sortModel.map((model: Sort) => ({ ...model, colId: decamelize(model.colId) }))
      ),
    };

    const { data } = await notificationsApi.get<NotificationRowResponse>({
      params,
    });

    return data;
  },

  fetchRanges: async (subtype?: DeviceSubtype) => {
    const rangesApi = new BaseApi('/metric_ranges');

    // use either passed equipment sub type or extract from url equipment type, but not both
    const params = {
      ...getEquipmentIdentifiers(),
      ...getEquipmentSubtypeOrType(subtype),
    };

    const { data } = await rangesApi.get<RangesResponse>({ params });

    return getRangesWithSubtype(data);
  },
  overrideRanges: async (
    subtype: DeviceSubtype,
    overriddenRangesPayload: RangeOverriddenValues[]
  ) => {
    const map = getMappedPayload(subtype, overriddenRangesPayload);

    const params = {
      ...getEquipmentIdentifiers(),
      equipment_subtype: subtype,
    };

    const rangesApi = new BaseApi('/metric_ranges/override');
    const { data } = await rangesApi.post<unknown, RangesResponse>({ map }, { params });

    return getRangesWithSubtype(data);
  },
  getEmails: async ({ siteId, toolId }: ScopedQueryParams) => {
    const emailsApi = new BaseApi(`/sites/${siteId}/notifications/emails`);
    return await emailsApi.get<string[]>({ params: { tool_id: toolId } });
  },
  createNotification: async (notification: NotificationPayload) => {
    const manualNotificationApi = new BaseApi(
      `/sites/${notification.site_id}/notifications`
    );

    return manualNotificationApi.post(toNotificationFormData(notification));
  },
  downloadAttachment: async ({
    siteId,
    notificationId,
    attachmentId,
    filename,
  }: AttachmentIdentifiers) => {
    const attachmentApi = new BaseApi(
      `/sites/${siteId}/notifications/${notificationId}/attachments/${attachmentId}/download`,
      { responseType: 'arraybuffer' }
    );
    attachmentApi.get<ArrayBuffer>().then(({ data }) => downloadFile(data, filename));
  },
  getAutoNotification: async ({ siteId, notificationId }: NotificationIdentifiers) => {
    const autoNotificationApi = new BaseApi(
      `/sites/${siteId}/notifications/${notificationId}`
    );

    const { data } = await autoNotificationApi.get<AutoNotification>();
    return data;
  },
  acknowledge: async ({ siteId, notificationId }: NotificationIdentifiers) => {
    const acknowledgeApi = new BaseApi(
      `/sites/${siteId}/notifications/${notificationId}/acknowledge`
    );
    const { data } = await acknowledgeApi.post<unknown, AutoNotification>({});
    return data;
  },
};
