import {
  ChartOption,
  ChartSeriesOption,
  TooltipFormatter,
} from 'src/shared/charts/base-chart';

type TotalPumpCountMap = {
  [key: string]: number;
};

export type ChartType = 'normal_vs_swapped' | 'swap_reason' | 'engineer_feedback';

export type DataProp = {
  thresholds: {
    [key: string]: {
      alarm_class: {
        [key: string]: {
          // todo: types, currently page unavailable, so types cant be grabbed
          [key: string]: Record<string, any>; // eslint-disable-line
        };
      };
      binary: {
        [key: string]: number;
      };
    };
  };
};

type FormattedChartData = {
  [key: string]: {
    value: [string, number];
    name: string;
  }[];
};

export type PaletteMap = {
  [key: string]: string;
};

export const normalSwappedPaletteMap: PaletteMap = {
  Normal: '#4CAF50',
  Swapped: '#CF202E',
};

export const engineerFeedbackPaletteMap: PaletteMap = {
  Normal: '#4CAF50',
  Unstable: '#FF9800',
  Critical: '#CF202E',
  Failed: '#616161',
  'No Feedback': '#BDBDBD',
};

// PrepareChartData

const xAxisMap: { [key: string]: string } = {
  '0_Normal': 'Normal',
  '1_Warning': 'Unstable',
  '2_Replace': 'Critical',
};

export const prepareChartData = (
  data: DataProp,
  type: ChartType,
  paletteMap?: PaletteMap
): {
  series: ChartSeriesOption;
  totalPumpCountMap: TotalPumpCountMap;
  legendData: string[];
} => {
  const selectedData =
    data.thresholds?.[Object.keys(data.thresholds)[0]].alarm_class[type];
  if (!selectedData) {
    return {
      series: [],
      totalPumpCountMap: {},
      legendData: [],
    };
  }

  const chartData: FormattedChartData = {};
  const totalPumpCountMap: TotalPumpCountMap = {};
  Object.entries(selectedData).forEach((column) => {
    totalPumpCountMap[xAxisMap[column[0]]] = 0;
    Object.entries(column[1]).forEach((bar) => {
      chartData[bar[0]] = [
        ...(chartData[bar[0]] || []),
        {
          value: [xAxisMap[column[0]], bar[1].pumps_per_day],
          name: bar[0],
        },
      ];
      totalPumpCountMap[xAxisMap[column[0]]] += bar[1].pumps_per_day;
    });
  });
  const legendData: string[] = [];
  const series = Object.entries(chartData).map((el) => {
    legendData.push(el[0]);
    return {
      name: el[0],
      data: el[1],
      type: 'bar',
      stack: 'one',
      itemStyle: {
        color: paletteMap?.[el[0]],
      },
      barWidth: 64,
    };
  });
  return {
    series,
    totalPumpCountMap,
    legendData,
  };
};

// CalculateOptions
const formatter = (totalPumpCountMap: TotalPumpCountMap): TooltipFormatter => (
  params
) => {
  let str = '';
  if (Array.isArray(params)) {
    params.forEach((item) => {
      str += `<div style="display: flex; height: 20px; align-items: center">
        <div style="width: 16px; height: 16px; margin: 0 8px 0 4px; background-color: ${
          item.color
        }"></div>
        <span>
            ${item.data.name} ${item.data.value[1]} /
            ${(
              (item.data.value[1] / totalPumpCountMap[item.data.value[0]]) *
              100
            ).toFixed(2)}%
        </span>
      </div>`;
    });
  }
  return str;
};

export const calculateChartOptions = (
  legendData: string[],
  totalPumpCountMap: TotalPumpCountMap
): ChartOption => ({
  dataZoom: [],
  xAxis: {
    data: ['Normal', 'Unstable', 'Critical'],
    splitLine: {
      show: true,
      lineStyle: { color: '#E0E0E0' },
    },
    axisLine: {
      lineStyle: { color: '#E0E0E0' },
    },
    axisLabel: { color: '#757575' },
  },
  yAxis: { show: false },
  legend: {
    type: 'scroll',
    data: legendData,
    top: 'bottom',
    icon: 'rect',
    textStyle: { color: '#757575' },
    pageIcons: {
      horizontal: [
        '<path d="M7.4 10.6L2.8 6L7.4 1.4L6 0L0 6L6 12L7.4 10.6Z" fill="black"/>',
        '<path d="M0.599609 10.6L5.19961 6L0.599609 1.4L1.99961 0L7.99961 6L1.99961 12L0.599609 10.6Z" fill="black"/>',
      ],
    },
    pageIconColor: '#616161',
    pageIconSize: 10,
    pageTextStyle: {
      color: '#616161',
      fontFamily: 'Roboto',
    },
  },
  grid: {
    top: 10,
    height: 216,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      shadowStyle: {
        color: '#EEEEEE',
        opacity: 0.25,
      },
    },
    confine: true,
    formatter: formatter(totalPumpCountMap),
  },
});
