import React, { FC } from 'react';
import { ArrowBack } from '@material-ui/icons';
import PumpRegenerationGrid from 'src/app/pump/pump-regeneration/pump-regeneration-grid/pump-regeneration-grid';
import { columns } from 'src/app/pump/pump-regeneration/pump-regeneration-steps/columns';
import PumpRegenerationChart from 'src/app/pump/pump-regeneration/pump-regeneration-steps/pump-regeneration-chart';
import { useStyles } from 'src/app/pump/pump-regeneration/pump-regeneration-steps/pump-regeneration-steps.styles';
import ExportBtn from 'src/shared/button-export';
import { RegenerationsStep } from 'src/store/api/regenerations.api';

interface OwnProps {
  steps: RegenerationsStep[] | null;
  backToHistory: () => void;
  actionsId?: string;
}

const PumpRegenerationSteps: FC<OwnProps> = (props) => {
  const { steps, backToHistory, actionsId } = props;
  const styles = useStyles();

  const exportParams = {
    url: `/regenerations/${actionsId}.xlsx`,
    position: 'relative',
  };

  return (
    <div className={styles.container}>
      <div className={styles.actionsContainer}>
        <div className={styles.goBackContainer} onClick={backToHistory}>
          <ArrowBack className={styles.icon} />
          <span>Back to Regeneration History</span>
        </div>
        {actionsId && <ExportBtn {...exportParams} />}
      </div>
      <div className={styles.chartContainer}>
        {steps && <PumpRegenerationChart data={steps} />}
      </div>
      <PumpRegenerationGrid columns={columns} rows={steps} autoSize={false} />
    </div>
  );
};

export default PumpRegenerationSteps;
