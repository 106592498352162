import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const NormalPredictionIcon: FC<Props> = (props) => {
  const { className, color = '#4CAF50' } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 16 16">
      <path d="M0 0H16V16H0V0Z" fill={color} />
      <path
        d="M5.81774 10.1949L3.33483 7.58599L2 8.98859L5.81775
        13L14 4.40254L12.6652 3L5.81774 10.1949Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default NormalPredictionIcon;
