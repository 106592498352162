import { ColData } from 'src/shared/base-grid/base-grid';

const UNITS = {
  min: 'min',
  mT: 'mT',
  hrs: 'hrs',
  kelvin: 'K',
  rpm: 'rpm',
};

export const columns: ColData[] = [
  {
    name: 'createdAt',
    label: 'Time Completed',
    sortDirection: 'desc',
    units: null,
    visibility: true,
    type: 'date',
  },
  {
    name: 'elapsedTime',
    label: 'Elapsed Time',
    units: UNITS.min,
    visibility: true,
    type: 'number',
  },
  {
    name: 't1',
    label: 'T1',
    units: UNITS.kelvin,
    visibility: true,
    type: 'number',
  },
  {
    name: 't2',
    label: 'T2',
    units: UNITS.kelvin,
    visibility: true,
    type: 'number',
  },
  {
    name: 'tcPressure',
    label: 'TC',
    units: UNITS.mT,
    visibility: true,
    type: 'number',
    minWidth: 65,
  },
  {
    name: 'rpm',
    label: 'RPM',
    units: UNITS.rpm,
    visibility: true,
    type: 'number',
  },
  {
    name: 'stepId',
    label: 'Step Number',
    units: null,
    visibility: true,
    type: 'number',
    minWidth: 60,
  },
  {
    name: 'step',
    label: 'Regen Step',
    units: null,
    visibility: true,
    type: 'text',
    minWidth: 100,
    flex: 1,
  },
  {
    name: 'stepExplanation',
    label: 'Explanation',
    units: null,
    visibility: true,
    type: 'text',
    minWidth: 100,
    flex: 1,
  },
];
