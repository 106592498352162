import React from 'react';
import { Box, Container, Grid, Paper } from '@material-ui/core';
import RoutesList from 'src/shared/routing/routes-list';
import { ROUTES } from 'src/shared/routing/routes';
import { useStyles } from './basic-layout.styles';
import HeaderLayout from './header-layout';

interface Props {
  header?: React.ReactNode;
  persona?: React.ReactNode;
  timezone?: React.ReactNode;
  footer?: React.ReactNode;
}

export const BasicLayout: React.FC<Props> = (props) => {
  const styles = useStyles();

  return (
    <div className={[styles.rows, styles.fitSpace].join(' ')}>
      <HeaderLayout
        header={props.header}
        persona={props.persona}
        timezone={props.timezone}
      />
      <div className={styles.autoFill}>
        <Container maxWidth={false} disableGutters classes={{ root: styles.fitHeight }}>
          <Grid container classes={{ root: styles.fitSpace }}>
            {/*Sidebar*/}
            <RoutesList
              routes={ROUTES.sideBar}
              wrapper={Grid}
              wrapperProps={{
                item: true,
                xs: 2,
                classes: {
                  root: `${styles.fitHeight} ${styles.minw232} ${styles.sidebar}`,
                },
              }}
            />

            <Grid item xs classes={{ root: styles.fitHeight }}>
              <Box p={2} className={styles.fitHeightSimple}>
                <div className={[styles.rows, styles.fitSpaceSimple].join(' ')}>
                  {/*Page toolbar*/}
                  <div className={styles.noAuto}>
                    <Box mt={-1} position="relative">
                      <RoutesList routes={ROUTES.pageToolbar} />
                    </Box>
                  </div>

                  {/*Content page*/}
                  <div className={styles.auto}>
                    <Paper square className={styles.fitHeightSimple}>
                      <div className={[styles.rows, styles.fitSpaceSimple].join(' ')}>
                        {/*Navigation*/}
                        <div className={styles.noAuto}>
                          <RoutesList routes={ROUTES.contentNavigation} />
                        </div>

                        {/*Content*/}
                        <div className={[styles.auto, styles.frame].join(' ')}>
                          <div className={styles.frameViewport}>{props.children}</div>
                        </div>
                      </div>
                    </Paper>
                  </div>
                  <div className={styles.noAuto}>{props.footer}</div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
