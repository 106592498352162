import { decamelizeAndCapitalize } from 'src/services/notifications-service';

interface ErrorField {
  error: string;
}

/**
 * Input: {
 *  email_recipients: [{error: "blank"}]
 * }
 *
 *
 * Output: Email Recipients: blank
 *
 */
export const stringifyError = (data: Record<string, ErrorField[]>) => {
  let errorsString = '';

  for (const key of Object.keys(data)) {
    if (data[key]) {
      const label = key === '' ? 'Unknown' : key;

      const errors = data[key]
        .map(({ error }) => (error === '' ? 'unknown error' : error))
        .join(', ');

      if (errors.length) {
        errorsString += `${decamelizeAndCapitalize(label)}: ${errors} \n`;
      }
    }
  }

  return errorsString;
};
