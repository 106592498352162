import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { useStyles } from './styles';
import DeactivateUserModal from 'src/app/admin/deactivate-user-modal';
import { ICellRendererParams } from 'ag-grid-community';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';

type Props = ICellRendererParams &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AdminCheckboxCell: FC<Props> = (props) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const isCurrentUser = props.data.id === props.currentUser?.id;

  const handleUserActivation = useCallback(() => {
    props.node.setDataValue(props.column.getColId(), false);
  }, [props.column, props.node]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isCurrentUser) {
        return;
      }

      if (props.value) {
        props.editUserStatus({
          user: props.data,
          deactivated: true,
          onSuccess: handleUserActivation,
        });
      } else {
        setOpen(true);
      }
    },
    [handleUserActivation, isCurrentUser, props]
  );

  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    props.node.setDataValue(props.column.getColId(), true);
    setOpen(!isOpen);
  };

  return (
    <>
      <Checkbox
        disabled={isCurrentUser}
        classes={{
          root: classes.root,
        }}
        checked={!props.value}
        onChange={handleChange}
        color="default"
      />
      {isOpen && (
        <DeactivateUserModal
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
          user={props.data}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.auth.user,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  editUserStatus: dispatch.users.editUserStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminCheckboxCell);
