import React, { FC } from 'react';
import { useStyles } from '../styles';
import clsx from 'clsx';

export interface Props {
  name: string;
}
const NodeName: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.col, classes.textCol)}>
      <div className={classes.textLine}>
        <span>{props.name}</span>
      </div>
    </div>
  );
};

export default NodeName;
