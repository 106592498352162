import React, { FC } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';
import { Paper } from '@material-ui/core';
import { ChartInfoTable } from 'src/app/pump/pump-details/chart-info';
import { PredictionChart } from 'src/shared/prediction-chart';
import { Feedback } from 'src/store/api/feedbacks.api';
import ToolbarSlot from './prediction-dashboard-components/toolbar-slot';
import EquipmentLink from './prediction-dashboard-components/pump-entity-link';
import { selectCellStyles } from 'src/shared/base-grid/cell-styles';
import { useStyles } from './predictions-dashboard.styles';
import { WithSelectedEquipmentProps } from 'src/shared/routing/with-selected-equipment';
import { Pump as PumpModel } from 'src/store/api/pumps.api';

// todo: exported type from api should be used instead
export type Pump = PumpModel;
export type Query = WithSelectedEquipmentProps;

interface Props {
  collection: Pump[];
  onMoveTo?: (query: Query) => void;
  updateFeedbackStatus: (feedback: Feedback) => void;
}

export const PredictionsDashboard: FC<Props> = (props) => {
  const pumps = props.collection;
  const styles = useStyles();

  const handleCollectionUpdate = (value: Feedback) => {
    props.updateFeedbackStatus(value);
  };

  const renderItem = (pump: Pump, index: number) => (
    <Paper key={index} square={true} elevation={0} variant="outlined">
      <div className={styles.pDouble}>
        <ToolbarSlot pump={pump} handleCollectionUpdate={handleCollectionUpdate} />
        <div className={styles.pySmall} />
        <div
          className={styles.textLineCenter}
          style={selectCellStyles('toolName').default}>
          <EquipmentLink entity="site" pump={pump} onMoveTo={props.onMoveTo} />
        </div>
        <div
          className={styles.textLineCenter}
          style={selectCellStyles('stationName').default}>
          <EquipmentLink entity="tool" pump={pump} onMoveTo={props.onMoveTo} />
          <span className={styles.pxSmall}>{'-'}</span>
          <EquipmentLink entity="pump" pump={pump} onMoveTo={props.onMoveTo} />
        </div>
        <div className={styles.chartSlot}>
          <div className={styles.chart}>
            <PredictionChart pump={pump} />
          </div>
        </div>
        <div className={styles.mxSmallNegative}>
          <ChartInfoTable pump={pump} />
        </div>
      </div>
    </Paper>
  );

  return (
    <VirtuosoGrid
      className={styles.root}
      listClassName={styles.container}
      itemClassName={styles.slot}
      totalCount={pumps.length}
      itemContent={(index: number) => renderItem(pumps[index], index)}
    />
  );
};
