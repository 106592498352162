import { createModel } from '@rematch/core';
import { RootModel } from '.';

import {
  ChamberHistoryItem,
  Diode,
  PumpAlarmItem,
  PumpDetails,
  PumpHistoryQueryParams as PumpQueryParams,
  PumpSetting,
  pumpsApi,
} from 'src/store/api/pumps.api';

import { FetchSeriesParams, GroupedSeries, chartApi } from './../api/charts.api';

interface PumpState {
  details: PumpDetails | null;
  lastDataChart: GroupedSeries[] | null;
  predictiveChart: GroupedSeries[] | null;
  chamberHistory: ChamberHistoryItem[] | null;
  settingHistory: PumpSetting[] | null;
  alarmHistory: PumpAlarmItem[] | null;
  diodes: Diode[] | null;
  error: unknown;
}

/**
 * Represent single pump state
 * Pump state includes next data:
 * - last pump metrics (t1, t2, etc.), it's controller metrics(dp, rp, etc.), pump information (model, software version, etc.)
 * - failure prediction data series (timeline)
 * - t1, t2, rpm data series (timeline)
 * - pump settings history
 * - chamber history of previously installed pumps
 */
export const pump = createModel<RootModel>()({
  state: {
    error: null,
    details: null,
    lastDataChart: null,
    predictiveChart: null,
    chamberHistory: null,
    settingHistory: null,
    alarmHistory: null,
    diodes: null,
  } as PumpState,

  reducers: {
    fail(state: PumpState, failure: unknown) {
      return {
        ...state,
        error: failure,
      };
    },

    fetch(state: PumpState, data: PumpDetails | null) {
      return {
        ...state,
        details: data,
      };
    },

    fetchChamberHistory(state: PumpState, chamberData: ChamberHistoryItem[]) {
      return {
        ...state,
        chamberHistory: chamberData,
      };
    },

    fetchDiodes(state: PumpState, diodes: Diode[]) {
      return {
        ...state,
        diodes,
      };
    },

    fetchPumpSettings(state: PumpState, settingsData: PumpSetting[]) {
      return {
        ...state,
        settingHistory: settingsData,
      };
    },

    fetchPumpAlarms(state: PumpState, alarmsData: PumpAlarmItem[]) {
      return {
        ...state,
        alarmHistory: alarmsData,
      };
    },

    setLastDataCharts(state: PumpState, lastDataChart: GroupedSeries[] | null) {
      return {
        ...state,
        lastDataChart,
      };
    },

    setPredictiveCharts(state: PumpState, predictiveChart: GroupedSeries[] | null) {
      return {
        ...state,
        predictiveChart,
      };
    },

    clearCharts(state: PumpState) {
      return {
        ...state,
        lastDataChart: null,
        predictiveChart: null,
      };
    },
  },

  effects: (dispatch) => {
    const { pump } = dispatch;

    return {
      async fetchPumpDetails(search: string) {
        const params = new URLSearchParams(search);
        const query = Object.fromEntries(params);
        pump.fail(null);

        try {
          const { data } = await pumpsApi.fetchPumpDetails(query);
          pump.fetch(data);
        } catch (err) {
          pump.fetch(null);
          pump.fail(err);
        }
      },

      async fetchPumpChamberHistory(params: PumpQueryParams) {
        try {
          const { data } = await pumpsApi.fetchChamberHistoryData(params);
          pump.fetchChamberHistory(data as ChamberHistoryItem[]);
        } catch (err) {
          pump.fail(err);
        }
      },

      async fetchPumpSettingsHistory(params: PumpQueryParams) {
        try {
          const { data } = await pumpsApi.fetchHistorySettings(params);
          pump.fetchPumpSettings(data as PumpSetting[]);
        } catch (err) {
          pump.fail(err);
        }
      },

      async fetchPumpDiodes(params: PumpQueryParams) {
        try {
          const { data } = await pumpsApi.fetchPumpDiodes(params);
          pump.fetchDiodes(data as Diode[]);
        } catch (err) {
          pump.fail(err);
        }
      },

      async fetchPumpAlarmHistory(params: PumpQueryParams) {
        try {
          const { data } = await pumpsApi.fetchPumpAlarmHistory(params);
          pump.fetchPumpAlarms(data as PumpAlarmItem[]);
        } catch (err) {
          pump.fail(err);
        }
      },

      /**
       * todo: rename me
       * Fetch pump time series for t1, t2, rpm and controller diff pressure by pump id
       * Method fetch specific pump series + it's associated controller series
       */
      async fetchPumpCharts(params: FetchSeriesParams, { timezone }) {
        try {
          const charts = await chartApi.fetchPumpsCommonMetrics(
            params,
            timezone.selectedZone.offset
          );
          pump.setLastDataCharts(charts);
        } catch (err) {
          pump.setLastDataCharts(null);
        }
      },

      /**
       * todo: rename me
       * Fetch pump failure probability percentage and failure probability status
       */
      async fetchPredictiveCharts(params: FetchSeriesParams, { timezone }) {
        try {
          const charts = await chartApi.fetchPumpFailureProbability(
            params,
            timezone.selectedZone.offset
          );
          pump.setPredictiveCharts(charts);
        } catch (err) {
          pump.setPredictiveCharts(null);
        }
      },
    };
  },
});
