import { axiosInstance } from './';
import { EntityType } from 'src/types';

export type ChartListItem = {
  id: number;
  name: string;
};
export type Side = 'left' | 'right';

export interface Metadata {
  pumpId?: number;
  siteId?: number;
  stationName?: string;
  compressorName?: string;
  toolId?: number;
  toolName?: string;
  compressorID?: number;
}

export interface EntitiesAttribute {
  id: number;
  site_id: number;
  entity_type: EntityType;
  entity_id: number;
  metadata?: Metadata;
}

type MetricsAttribute = {
  id: number;
  entity_type?: EntityType;
  label: string;
  eb_metric_id: number | string;
  axis: string;
};

export interface TimeSeries {
  id: number | undefined;
  eb_metric_id: number | string;
  axis: string;
}

export type AxesAttribute = {
  id?: string | number;
  name: string;
  side: Side;
  min: number | null;
  max: number | null;
};

export type CreateChartPayload = {
  name: string;
  site_ids: number[];
  time_series_attributes: TimeSeries[];
  time_series_entities_attributes: EntitiesAttribute[];
  axes_attributes: AxesAttribute[];
};

export type ChartPayload = {
  id: number;
} & CreateChartPayload;

export interface Metric {
  id: number;
  label: string;
  units: string;
  entity_type: EntityType;
}

export interface Site {
  id: number;
  label: string;
}

export interface ChartData {
  id: number;
  name: string;
  axes: AxesAttribute[];
  sites: Site[];
  time_series: MetricsAttribute[];
  time_series_entities: EntitiesAttribute[];
}

export const chartsBuilderAPI = {
  fetchCharts: () => axiosInstance.get<ChartListItem[]>('/charts'),
  fetchChartById: (id: number) => axiosInstance.get<ChartData>(`/charts/${id}`),
  createChart: (payload: CreateChartPayload) =>
    axiosInstance.post<ChartPayload>('/charts', { chart: payload }),
  removeChart: (id: number) => axiosInstance.delete(`/charts/${id}`),
  updateChart: ({ id, ...rest }: ChartPayload) =>
    axiosInstance.patch<ChartData>(`/charts/${id}`, { chart: rest }),
  fetchMetrics: () => axiosInstance.get<Metric[]>('/charts/metrics'),
};
