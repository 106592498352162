import React, { FC } from 'react';
import DateRangePicker from 'src/shared/date-range-filter/date-range-picker';
import { SitePicker } from 'src/shared/site-picker';
import ModelPicker from '../model-picker';
import { Model } from 'src/store/api/ml.api';
import { Box, Typography } from '@material-ui/core';
import { Site } from 'src/store/api/sites.api';

export interface Props {
  startDate: Date;
  endDate: Date;
  onDateRangeChange: (start: Date, end: Date) => void;
  sites: Site[];
  selectedSiteIds: number[];
  onSitesChange: (idList: number[]) => void;
  models: Model[];
  modelId: string;
  pendingModels: boolean;
  onModelChange: (value: string) => void;
}

const DateSiteModelPickers: FC<Props> = (props) => {
  const {
    startDate,
    endDate,
    onDateRangeChange,
    sites,
    selectedSiteIds,
    onSitesChange,
    models,
    modelId,
    pendingModels,
    onModelChange,
  } = props;

  const startDateConfig = { minDate: new Date(0), maxDate: endDate };

  const endDateConfig = { minDate: startDate, maxDate: new Date() };
  return (
    <Box px={2} py={1}>
      <Box py={1} />
      <Typography variant="subtitle2">Evaluate Model Performance:</Typography>
      <Box py={1}>
        <DateRangePicker
          layout="column"
          startDate={startDate}
          startDateConfig={startDateConfig}
          endDate={endDate}
          endDateConfig={endDateConfig}
          onChange={onDateRangeChange}
        />
      </Box>
      <Box py={1}>
        <SitePicker
          sites={sites}
          selectedSites={selectedSiteIds}
          onChange={onSitesChange}
        />
      </Box>
      <Box py={1}>
        <ModelPicker
          models={models}
          value={modelId}
          pendingModels={pendingModels}
          onChange={onModelChange}
        />
      </Box>
    </Box>
  );
};

export default DateSiteModelPickers;
