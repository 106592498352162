import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import ViewBasedGrid from 'src/shared/view-based-grid';
import { HEADER_HEIGHTS } from 'src/shared/base-grid/base-grid.config';

const Controllers: FC = () => {
  const dispatch = useDispatch<RootDispatch>();
  const rows = useSelector(({ controllers }: RootState) => controllers.rows);
  const columns = useSelector(({ views }: RootState) => views.controllers?.columns);
  const { siteId, toolId } = useEquipmentIdentifiers();

  const exportUrl = '/controllers.xlsx';
  const refetch = [siteId, toolId].join(':');

  const unsetRows = useCallback(() => dispatch.controllers.setRows(null), [
    dispatch.controllers,
  ]);

  return (
    <ViewBasedGrid
      fetchRows={dispatch.controllers.fetchRows}
      fetchColumns={dispatch.views.fetchControllersView}
      onCleanup={unsetRows}
      rows={rows}
      columns={columns}
      search={refetch}
      exportUrl={exportUrl}
      headerHeight={HEADER_HEIGHTS.threeRowsWithUnits}
    />
  );
};

export default Controllers;
