import React, { FC } from 'react';
import { styles } from './prediction-dropdown.styles';
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { OPTIONS, Option } from './prediction-dropdown.config';
import intersectionWith from 'lodash/intersectionWith';

interface Props extends WithStyles<typeof styles> {
  onChange: (value: string[]) => void;
  value: string[];
}

export const ALL_OPTION = {
  value: 'all',
  label: 'All Events',
};

const withoutAllOption = (list: string[]) =>
  list.filter((entry) => entry !== ALL_OPTION.value);

// TODO: Create generic select component
const PredictionDropdown: FC<Props> = (props) => {
  const { value, onChange } = props;
  const options: Option[] = [ALL_OPTION, ...OPTIONS];
  const selectedOptions: Option[] = intersectionWith(
    options,
    value.length ? value : [ALL_OPTION.value],
    (a, b) => a.value === b
  );
  const selectedOptionsLabels = selectedOptions.map(({ label }) => label).join(', ');

  const handleChange = (event: React.BaseSyntheticEvent): void => {
    const selection = event.target.value;
    const newValue =
      selection.includes(ALL_OPTION.value) && withoutAllOption(value).length
        ? []
        : withoutAllOption(selection);

    onChange(newValue);
  };

  return (
    <div className={props.classes.container}>
      <FormControl variant="filled" fullWidth classes={{ root: props.classes.formRoot }}>
        <Select
          multiple
          onChange={handleChange}
          value={value.length ? value : [ALL_OPTION.value]}
          renderValue={() => selectedOptionsLabels}
          MenuProps={{
            MenuListProps: {
              dense: true,
            },
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            getContentAnchorEl: null,
            transformOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          }}
          IconComponent={ExpandMore}
          classes={{
            select: props.classes.select,
            filled: props.classes.filled,
          }}>
          {options.map((option) => (
            <MenuItem
              key={option.label}
              value={option.value}
              classes={{ root: props.classes.menuItemRoot }}>
              <Checkbox
                color="default"
                checked={selectedOptions.some((el) => el.value === option.value)}
              />
              <div className={props.classes.labelContainer}>
                {option.icon}
                <Typography variant="body2" component="span">
                  {option.label}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default PredictionDropdown;
