import BaseApi, { PaginateableReq } from '.';

export interface FetchBySearchTerm extends PaginateableReq {
  term?: string;
  siteId: number[];
  toolId?: number[];
}

export enum SearchEntityType {
  tools = 'controllers',
  compressors = 'compressors',
  pumps = 'pumps',
}

export function fetchBySearchTerm<T>(
  equipmentType: SearchEntityType,
  searchBy: FetchBySearchTerm
) {
  const api = new BaseApi(`/${equipmentType}/search/`);

  const { term, ...rest } = searchBy;

  const params = {
    q: term,
    ...rest,
  };

  return api
    .get<T>({ params })
    .then(({ data }) => data);
}
