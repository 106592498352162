import React, { FC, useCallback } from 'react';
import { ButtonGroup as MuiButtonGroup } from '@material-ui/core';
import ToggleButton from './toggle-button';
import { useStyles } from './toggle-button-group.styles';

export type ToggleGroupItemValue = string | Date;

export interface ToggleGroupItem {
  label: string;
  value: ToggleGroupItemValue;
  isSelected?: boolean;
  getEndDateMax: (date: Date) => Date;
  calculateMinDate: (date: Date) => Date;
}

interface Props {
  values: ToggleGroupItem[];
  onChange?: (value: ToggleGroupItem[]) => void;
  multiple?: boolean;
}

const ToggleButtonGroup: FC<Props> = (props) => {
  const { values, multiple, onChange } = props;
  const classes = useStyles();

  const handleClick = useCallback(
    (value: ToggleGroupItemValue) => {
      const updatedOptions = values.map((option) => {
        if (multiple) {
          return {
            ...option,
            isSelected: option.value === value ? !option.isSelected : option.isSelected,
          };
        }

        return {
          ...option,
          isSelected: option.value === value,
        };
      });

      onChange && onChange(updatedOptions);
    },
    [values, multiple, onChange]
  );

  return (
    <MuiButtonGroup variant="contained" size="medium" className={classes.root}>
      {values.map(({ value, label, isSelected }) => (
        <ToggleButton
          key={`${value}`}
          value={value}
          isSelected={isSelected}
          onClick={handleClick}>
          {label}
        </ToggleButton>
      ))}
    </MuiButtonGroup>
  );
};

export default ToggleButtonGroup;
