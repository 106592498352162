import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { Roles, User, UserErrors, UserPayload, usersAPI } from '../api/users.api';
import { showError, showSuccess } from 'src/services/snackbars';

interface UsersState {
  list: User[];
  errors: UserErrors;
}
type EditUserStatusParams = {
  user: User;
  deactivated: boolean;
  onSuccess: () => void;
};

export const ROLE_LABEL_MAP = {
  [Roles.admin]: 'Admin',
  [Roles.cse]: 'CSE',
};

export const users = createModel<RootModel>()({
  state: {
    list: [],
    errors: {},
  } as UsersState,

  reducers: {
    setUsers(state: UsersState, newUsers: User[]) {
      return { ...state, list: newUsers };
    },
    addUserToList(state: UsersState, user: User) {
      return { ...state, list: [...state.list, user] };
    },
    editUserInList(state: UsersState, user: User) {
      return {
        ...state,
        list: state.list.map((item) => (item.id === user.id ? user : item)),
      };
    },
    setErrors(state: UsersState, errors: UserErrors) {
      return { ...state, errors };
    },
  },

  effects: (dispatch) => ({
    fetchList: () => {
      usersAPI.get().then((resp) =>
        dispatch.users.setUsers(
          camelizeKeys(
            resp.data.map((entry) => ({
              ...entry,
              roleLabel: ROLE_LABEL_MAP[entry.role],
            }))
          ) as User[]
        )
      );
    },

    async addUser(payload: UserPayload) {
      try {
        const { user, onSuccess } = payload;
        const { data } = await usersAPI.addUser({ user: decamelizeKeys(user) });
        const roleLabel = ROLE_LABEL_MAP[data.role];
        dispatch.users.setErrors({});
        dispatch.users.addUserToList(camelizeKeys({ ...data, roleLabel }) as User);
        if (onSuccess) onSuccess();
        showSuccess('User added');
      } catch (err) {
        if (err.response.status === 422) {
          // validation
          const errorData = err.response.data as UserErrors;
          dispatch.users.setErrors(camelizeKeys(errorData) as UserErrors);
        } else {
          throw err;
        }
      }
    },

    editUser: (payload: UserPayload): Promise<void> => {
      const { id, user, onSuccess } = payload;
      return usersAPI
        .editUser(id || null, { user: decamelizeKeys(user) })
        .then(({ data }) => {
          const roleLabel = ROLE_LABEL_MAP[data.role];
          dispatch.users.setErrors({});
          dispatch.users.editUserInList(camelizeKeys({ ...data, roleLabel }) as User);

          if (onSuccess) onSuccess();
          user.password ? showSuccess('Password updated') : showSuccess('User saved');
        })
        .catch((error) => {
          if (error.response.status === 422) {
            // validation
            const errorData = error.response.data as UserErrors;
            dispatch.users.setErrors(camelizeKeys(errorData) as UserErrors);
          } else {
            throw error;
          }
        });
    },

    async editUserStatus({
      user,
      deactivated,
      onSuccess,
    }: EditUserStatusParams): Promise<void> {
      try {
        const method = deactivated ? 'activateUser' : 'deactivateUser';
        await usersAPI[method](user.id);
        user.deactivated = !deactivated;
        dispatch.users.editUserInList(user);
        onSuccess();
        showSuccess(deactivated ? 'User activated' : 'User deactivated');
      } catch (error) {
        showError(error.message || error);
      }
    },
  }),
});
