import { FetchBySearchTerm } from 'src/store/api/search.api';
import { SearchEntityType, fetchBySearchTerm } from './search.api';
import { PaginateableRes } from '.';

type SearchableToolResp = PaginateableRes<{
  siteId: number;
  toolId: number;
  toolName: string;
}>;

export const toolsApi = {
  async fetchBySearchTerm(params: FetchBySearchTerm) {
    const { data, ...rest } = await fetchBySearchTerm<SearchableToolResp>(
      SearchEntityType.tools,
      params
    );

    // prepare autocomplete items
    const items = data.map(({ toolId, toolName, ...rest }) => ({
      value: toolId,
      label: toolName,
      extraFields: rest,
    }));

    return { ...rest, data: items };
  },
};
