const PRIMARY_COLOR = '#CF202E';
const SECONDARY_COLOR = '#E0E0E0';

export const COLORS = {
  primary: PRIMARY_COLOR,
  secondary: SECONDARY_COLOR,

  black: '#000000',
  white: '#ffffff',
  success: '#A5D6A7',
  warning: '#FFCC80',
  danger: '#FF8F8C',

  grey: {
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: SECONDARY_COLOR,
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },

  red: {
    50: '#FF8F8C',
    200: '#FF5C58',
    300: '#FF4440',
    500: PRIMARY_COLOR,
    700: '#960007',
  },

  orange: {
    200: '#FFCC80',
    300: '#FFB74D',
    500: '#FF9800',
    900: '#E65100',
  },

  green: {
    200: '#A5D6A7',
    300: '#81C784',
    500: '#4CAF50',
    800: '#2E7D32',
  },

  blue: {
    200: '#81D4FA',
    300: '#4FC3F7',
    500: '#03A9F4',
    700: '#0288D1',
    800: '#0277BD',
  },
};
