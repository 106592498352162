import React from 'react';
import { RootDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';
import PumpRegenerationGrid from 'src/app/pump/pump-regeneration/pump-regeneration-grid/pump-regeneration-grid';
import { RouteComponentProps } from 'react-router';
import { columns } from 'src/app/pump/pump-regeneration/pump-regeneration-grid/columns';

type ConnectedProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ComponentProps = ConnectedProps &
  DispatchProps &
  RouteComponentProps<{ id?: string }>;

class PumpRegenerationContainer extends React.Component<ComponentProps> {
  public componentDidMount() {
    const {
      location: { search },
      match,
      fetchRegenerations,
    } = this.props;
    if (match.isExact) {
      fetchRegenerations(search);
    }
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    const {
      location: { search },
      fetchRegenerations,
      rows,
    } = this.props;
    const {
      location: { search: prevSearch },
      match: prevMatch,
    } = prevProps;

    const isEmptyRows = !rows && !prevMatch.isExact;
    if (search !== prevSearch || isEmptyRows) {
      fetchRegenerations(search);
    }
  }

  public componentWillUnmount() {
    this.props.clearRegenerations();
  }

  render() {
    const { rows, match } = this.props;
    /*
     * Hide PumpRegenerationGrid when user go to steps route
     * Don't unmount PumpRegenerationContainer to prevent re-fetch regenerations
     */
    if (!match.isExact) {
      return null;
    }
    return (
      <PumpRegenerationGrid
        isExact={match.isExact}
        columns={columns}
        rows={rows}
        autoSize={true}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  rows: state.regenerations.rows,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchRegenerations: dispatch.regenerations.fetchRegenerations,
  clearRegenerations: dispatch.regenerations.clear,
});

export default connect(mapStateToProps, mapDispatchToProps)(PumpRegenerationContainer);
