const BASE = '/app';
const DASHBOARD = 'dashboard';
const ML = 'ml';
const NOTIFICATION = 'notification';

const PUMP = 'pump';
const CONTROLLER = 'controller';
const COMPRESSOR = 'compressor';

export const CHARTS_CREATION_MARKER = 'new';
export const CHARTS_SETTINGS_MARKER = 'settings';

export const routesConstants = {
  // no-auth required
  LOGIN: '/login',

  // auth required routes

  BASE,

  // site/fab/tool level routes (scoped to selected fab/site/tool)
  DASHBOARD: `${BASE}/${DASHBOARD}`,
  CONTROLLERS: `${BASE}/${DASHBOARD}/controllers`,
  COMPRESSORS: `${BASE}/${DASHBOARD}/compressors`,
  PREDICTIONS: `${BASE}/${DASHBOARD}/predictions`,
  PUMP_SETTINGS: `${BASE}/${DASHBOARD}/settings`,
  NOTIFICATION_RANGES: `${BASE}/${DASHBOARD}/${NOTIFICATION}/ranges`,
  NOTIFICATION_HISTORY: `${BASE}/${DASHBOARD}/${NOTIFICATION}/history`,

  // tool level routes (scoped to selected tool only)
  GCU: `${BASE}/${DASHBOARD}/gcu`,
  ALARM_CONFIG: `${BASE}/${DASHBOARD}/alarm-config`,

  // pump level routes (scoped to selected pump)
  PUMP: `${BASE}/${DASHBOARD}/${PUMP}`,
  PUMP_ANOMALIES: `${BASE}/${DASHBOARD}/${PUMP}/anomalies`,
  PUMP_REGENERATION: `${BASE}/${DASHBOARD}/${PUMP}/regeneration`,
  PUMP_REGENERATION_DETAILS: `${BASE}/${DASHBOARD}/${PUMP}/regeneration/:id`,
  PUMP_CHANGE_HISTORY: `${BASE}/${DASHBOARD}/${PUMP}/change-history`,
  PUMP_CHAMBER_HISTORY: `${BASE}/${DASHBOARD}/${PUMP}/chamber-history`,
  PUMP_ALARM_HISTORY: `${BASE}/${DASHBOARD}/${PUMP}/alarm-history`,
  PUMP_DIODES: `${BASE}/${DASHBOARD}/${PUMP}/diodes`,

  CONTROLLER: `${BASE}/${DASHBOARD}/${CONTROLLER}`,

  COMPRESSOR: `${BASE}/${DASHBOARD}/${COMPRESSOR}`,

  // charts
  CHARTS_BASE: `${BASE}/charts`,
  CHARTS_DETAILS: `${BASE}/charts/:id/:${CHARTS_SETTINGS_MARKER}?`,
  CHARTS_NEW: `${BASE}/charts/${CHARTS_CREATION_MARKER}`,

  // admin
  ADMIN_PAGE: `${BASE}/admin`,

  // machine learning
  MACHINE_LEARNING: `${BASE}/${ML}`,
  MACHINE_LEARNING_PERFORMANCE: `${BASE}/${ML}/performance`,
  MACHINE_LEARNING_MONITORING: `${BASE}/${ML}/monitoring`,
};
