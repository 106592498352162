import React, { FC } from 'react';
import ProfileMenuContainer from '../profile-menu';
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { useStyles } from './styles';
import { ExitToApp } from '../../shared/icons';
import { mapDispatch } from './persona.container';

type Props = ReturnType<typeof mapDispatch>;

const Persona: FC<Props> = (props) => {
  const { onSignOut } = props;
  const styles = useStyles();

  return (
    <ProfileMenuContainer>
      <MenuItem onClick={onSignOut}>
        <ListItemIcon classes={{ root: styles.profileMenuIcon }}>
          <ExitToApp fontSize="inherit" />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </ProfileMenuContainer>
  );
};

export default Persona;
