import BaseApi from '.';
import { ColData } from 'src/shared/base-grid/base-grid';
import { AxiosRequestConfig } from 'axios';

export interface ColumnResp {
  columns: ColData[];
  createdAt: string;
  deletedAt: string;
  id: number;
  kind: string;
  userID: number;
}

const views = new BaseApi('./views');

const pumpsView: AxiosRequestConfig = { params: { kind: 'pump' } };
const compressorsView: AxiosRequestConfig = { params: { kind: 'compressor' } };
const controllersView: AxiosRequestConfig = { params: { kind: 'controller' } };
const pumpSettingsView: AxiosRequestConfig = { params: { kind: 'pump_setting' } };

export const viewsApi = {
  fetchPumpView: () => views.get<ColumnResp[]>(pumpsView).then(({ data }) => data[0]),
  updatePumpView: (view: ColumnResp) => views.post(view, pumpsView),

  fetchCompressorsView: () =>
    views.get<ColumnResp[]>(compressorsView).then(({ data }) => data[0]),
  fetchControllersView: () =>
    views.get<ColumnResp[]>(controllersView).then(({ data }) => data[0]),
  fetchPumpSettingsView: () => {
    return views.get<ColumnResp[]>(pumpSettingsView).then(({ data }) => data[0]);
  },
};
