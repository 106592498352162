import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from 'src/store';
import { SitesTree } from './site-equipment-tree';
import { SiteSelector } from './site-selector';
import { useStyles } from './styles';

const SiteEquipment: FC = () => {
  const classes = useStyles();
  const { siteEquipment } = useDispatch<RootDispatch>();

  useEffect(() => {
    // restore tree state on mount (filter sites by preferred, expand tree state)
    siteEquipment.restoreTree();

    // on unmount reset expanded global state
    return () => {
      siteEquipment.addExpandIdentifiers(null);
    };
  }, [siteEquipment]);

  return (
    <div className={classes.layout}>
      <div className={classes.noAutoSize}>
        <div className={classes.companyControlSlot}>
          <SiteSelector />
        </div>
      </div>

      <div className={classes.autoSize}>
        <SitesTree />
      </div>
    </div>
  );
};

export default SiteEquipment;
