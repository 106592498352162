import React, { useMemo } from 'react';
import format from 'date-fns/format';
import { getShiftedDateFromISO } from '../../services/timezone.helper';
import { Timezone } from '../../store/models/timezone.model';
import { DateFormatterProps } from './date-in-selected-zone';

export interface Props extends DateFormatterProps {
  selectedZone: Timezone;
}

export const DATE_YEAR_MASK = 'MM/dd/yy';
export const TIME_MASK = 'hh:mm a';

export const FORMAT_MAP = {
  short: DATE_YEAR_MASK,
  full: `${DATE_YEAR_MASK} ${TIME_MASK}`,
};

export const formatDate = (date: Date | number, mask = FORMAT_MAP.full) => {
  return format(date, mask);
};

const DateFormatter = (props: Props) => {
  const {
    children,
    selectedZone,
    formatType = 'full',
    format: dateFormat = FORMAT_MAP[formatType],
  } = props;
  const shiftedDate = useMemo(
    () => getShiftedDateFromISO(children, selectedZone.offset),
    [children, selectedZone.offset]
  );
  const value = shiftedDate ? formatDate(shiftedDate, dateFormat) : children;

  return <>{value}</>;
};

export default DateFormatter;
