import { createModel } from '@rematch/core';
import { RootModel } from '.';

import {
  RegenerationsRow,
  RegenerationsStep,
  regenerationsAPI,
} from '../api/regenerations.api';

interface RegenerationsState {
  rows: RegenerationsRow[] | null;
  steps: RegenerationsStep[] | null;
}

export const regenerations = createModel<RootModel>()({
  state: {
    rows: null,
    steps: null,
  } as RegenerationsState,

  reducers: {
    set(state: RegenerationsState, rows: RegenerationsRow[]): RegenerationsState {
      return {
        ...state,
        rows,
      };
    },
    setSteps(state: RegenerationsState, steps: RegenerationsStep[]): RegenerationsState {
      const firsStep = new Date(steps[0]?.createdAt).getTime();
      const getElapseTime = (isoTimeString: string) => {
        const diffInMs = new Date(isoTimeString).getTime() - firsStep;
        return Math.round(diffInMs / 1000 / 60);
      };

      return {
        ...state,
        steps: steps.map((step) => ({
          ...step,
          elapsedTime: getElapseTime(step.createdAt),
        })),
      };
    },
    clear(state: RegenerationsState): RegenerationsState {
      return {
        ...state,
        rows: null,
      };
    },
    clearSteps(state: RegenerationsState): RegenerationsState {
      return {
        ...state,
        steps: null,
      };
    },
  },

  effects: (dispatch) => ({
    fetchRegenerations: (search: string): Promise<void> => {
      dispatch.regenerations.clear();
      const params = new URLSearchParams(search);
      return regenerationsAPI.fetchRegenerations(params).then(({ data }) => {
        dispatch.regenerations.set(data);
      });
    },

    fetchRegenerationSteps: ({
      id,
      search,
    }: {
      id: string;
      search: string;
    }): Promise<void> => {
      const params = new URLSearchParams(search);
      return regenerationsAPI.fetchRegenerationSteps(id, params).then(({ data }) => {
        dispatch.regenerations.setSteps(data);
      });
    },
  }),
});
