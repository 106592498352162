import { AutoNotification, NotificationPayload } from 'src/store/api/notifications.api';

export const sortSwitchHistoriesByLoggedAt = (autoNotification: AutoNotification) => {
  autoNotification.switchHistories.sort((a, b) => (b.loggedAt > a.loggedAt ? 1 : -1));
  return autoNotification;
};

const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const toFormData = <T>(obj: T, rootKey: string) => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    key = `${rootKey}[${key}]`;

    if (Array.isArray(value)) {
      value.forEach((item) => formData.append(`${key}[]`, item));
    } else if (value) {
      formData.append(key, value);
    }
  });

  return formData;
};

export const toNotificationFormData = (notification: NotificationPayload) => {
  return toFormData(notification, 'notification');
};

export const decamelizeAndCapitalize = (type: string) => {
  return type
    .split('_')
    .map((word) => capitalize(word))
    .join(' ');
};
