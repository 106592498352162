import { ToggleGroupItem } from '../toggle-button-group';
import { addDays, addMonths, subDays, subMonths } from 'date-fns';

const todayBeginningOfTheDay = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

const todayEndingOfTheDay = () => {
  const today = new Date();
  today.setHours(23, 59, 0, 0);
  return today;
};

export enum DefaultFilters {
  oneDay = '1 day',
  oneWeek = '1 week',
  oneMonth = '1 month',
  threeMonth = '3 months',
  sixMonth = '6 months',
}

type StartEndDates = [Date, Date];

export const getDefaultFilters = (): ToggleGroupItem[] => {
  return [
    {
      label: DefaultFilters.oneDay,
      value: todayBeginningOfTheDay(),
      getEndDateMax: (date: Date) => addDays(date, 1), // todo: getEndDateMax looks like not used, usage is not very obvious
      calculateMinDate: (date: Date) => subDays(date, 1),
    },
    {
      label: DefaultFilters.oneWeek,
      // since we include end/start dates use add/sub only 6 days for 1 week
      value: subDays(todayBeginningOfTheDay(), 6),
      getEndDateMax: (date: Date) => addDays(date, 6),
      calculateMinDate: (date: Date) => subDays(date, 6),
      isSelected: true,
    },
    {
      label: DefaultFilters.oneMonth,
      value: subMonths(todayBeginningOfTheDay(), 1),
      getEndDateMax: (date: Date) => addMonths(date, 1),
      calculateMinDate: (date: Date) => subMonths(date, 1),
    },
    {
      label: DefaultFilters.threeMonth,
      value: subMonths(todayBeginningOfTheDay(), 3),
      getEndDateMax: (date: Date) => addMonths(date, 3),
      calculateMinDate: (date: Date) => subMonths(date, 3),
    },
    {
      label: DefaultFilters.sixMonth,
      value: subMonths(todayBeginningOfTheDay(), 6),
      getEndDateMax: (date: Date) => addMonths(date, 6),
      calculateMinDate: (date: Date) => subMonths(date, 6),
    },
  ];
};

export const notificationDateFilters = () => {
  return getDefaultFilters().filter((filter) => filter.label !== DefaultFilters.oneDay);
};

// 6  months
export const getDefaultRange = () => {
  return getStartEndDates(getDefaultFilters()[4]);
};

// 1 week (today minus 6 days)
export const getWeekRange = () => {
  return getStartEndDates(getDefaultFilters()[1]);
};

export const getStartEndDates = (range: ToggleGroupItem): StartEndDates => {
  const start = range.value as Date;
  const end = todayEndingOfTheDay();
  return [start, end];
};
