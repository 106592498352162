import { TextField } from '@material-ui/core';
import { ICellRendererParams } from 'ag-grid-community';
import React, { useCallback, useMemo } from 'react';

export const NotificationInputRenderer = (props: ICellRendererParams) => {
  const { inheritedMinValue, inheritedMaxValue, isInvalid } = props.data;
  const columnName = props.column.getColId();

  const placeholder = useMemo(
    () => (columnName === 'notificationMinimum' ? inheritedMinValue : inheritedMaxValue),
    [columnName, inheritedMaxValue, inheritedMinValue]
  );

  const areInputsInvalid = useCallback(() => {
    const { notificationMinimum, notificationMaximum } = props.data;

    const minimum = Number(notificationMinimum || inheritedMinValue);
    const maximum = Number(notificationMaximum || inheritedMaxValue);

    const minOverMax = minimum > maximum;
    const isNegative = minimum < 0 || maximum < 0;

    return minOverMax || isNegative;
  }, [inheritedMaxValue, inheritedMinValue, props.data]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.target.value))) {
      //dataset mutation
      props.setValue(event.target.value);
    }
  };

  const onBlur = () => {
    props.node.setData({ ...props.data, isInvalid: areInputsInvalid() });

    //refresh input cells, because we should highlight invalid cells
    //also rerender subscription to persist data
    props.api.refreshCells({
      rowNodes: [props.node],
      columns: ['notificationMinimum', 'notificationMaximum', 'subscription'],
      force: true,
    });
  };

  return (
    <TextField
      variant="standard"
      type="text"
      inputProps={{
        style: {
          fontSize: '0.875rem',
        },
      }}
      error={isInvalid}
      placeholder={String(placeholder)}
      value={props.value || ''}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
