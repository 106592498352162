import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      root: {
        fontSize: '18px',
        color: COLORS.green['500'],
        display: 'inline-flex',
      },
      warning: {
        color: COLORS.orange['500'],
      },
      unknown: {
        color: COLORS.grey['600'],
      },
    })
);
