import React, { FC } from 'react';
import DateInSelectedZone from 'src/shared/date-in-selected-zone';
import PumpDetailsTableView from './pump-details-table';
import { formatRowsToNA } from './table-ui-formatters';
import { PumpDetails } from 'src/store/api/pumps.api';

interface Props {
  pump: PumpDetails;
}

export const LatestInfoTable: FC<Props> = (props) => {
  const { pump } = props;

  const rows = [
    {
      label: 'Last Data',
      value: <DateInSelectedZone>{pump.updatedAt}</DateInSelectedZone>,
    },
    {
      label: 'Heater 1',
      value: pump.heater1,
    },
    {
      label: 'Heater 2',
      value: pump.heater2,
    },
    {
      label: 'TC',
      value: pump.tcPressure,
    },
    {
      label: 'Since Last Full',
      value: pump.hoursSinceLastFullRegeneration,
    },
    {
      label: 'Since Last Fast',
      value: pump.hoursSinceLastFastRegeneration,
    },
    {
      label: 'Last Regen Step',
      value: pump.regenStep,
    },
    {
      label: 'Pump Status',
      value: pump.motorStatus,
    },
    {
      label: 'Rough Status',
      value: pump.roughStatus,
    },
    {
      label: 'Purge Status',
      value: pump.purgeStatus,
    },
    {
      label: 'Total Regens',
      value: pump.regenCount,
    },
    {
      label: 'T1 Control',
      value: pump.t1,
    },
    {
      label: 'T2 Control',
      value: pump.t2,
    },
    {
      label: 'Power Fail Temp',
      value: pump.powerFailTemp,
    },
    {
      label: 'Power Fail Recovery',
      value: pump.powerFailFlag,
    },
    {
      label: 'Keypad Lockout',
      value: pump.keypadLockout.toString(),
    },
    {
      label: 'Param Lockout',
      value: pump.paramLockout.toString(),
    },
  ];

  const formattedRows = formatRowsToNA(rows);
  return <PumpDetailsTableView rows={formattedRows} />;
};
