import React, { FC } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { SwitchHistory } from 'src/store/api/notifications.api';
import { SwitchHistoryRow } from './switch-history-row';

interface Props {
  switchHistories: SwitchHistory[];
}

export const SwitchHistoryTable: FC<Props> = ({ switchHistories }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Value</TableCell>
            <TableCell>Min Value</TableCell>
            <TableCell>Max Value</TableCell>
            <TableCell>Out of range</TableCell>
            <TableCell>Logged At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {switchHistories.map((switchHistory, index) => (
            <SwitchHistoryRow
              key={`${index}${switchHistory.loggedAt}`}
              switchHistory={switchHistory}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
