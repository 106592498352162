import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      textField: {
        width: 'calc(50% - 8px)',
        marginBottom: '16px',
      },
      inputLabel: {
        fontSize: '0.875rem',
      },
    })
);
