import React, { FC, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  CHARTS_CREATION_MARKER,
  CHARTS_SETTINGS_MARKER,
  routesConstants,
} from 'src/shared/routing/routes.constants';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import RoutingTabs from 'src/shared/routing/routing-tabs';
import { Roles } from 'src/store/api/users.api';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const disallowedMarkers = [CHARTS_CREATION_MARKER, CHARTS_SETTINGS_MARKER];

const ChartsContentNav: FC<Props> = (props) => {
  const { selectedChart, setSelectedChart, charts } = props;
  const match = useRouteMatch<{ id: string }>(routesConstants.CHARTS_DETAILS);
  const matchedId = match?.params.id;
  const chartId = selectedChart?.id;
  const tabs = useMemo(
    () => [
      {
        label: 'Chart',
        path: `${routesConstants.CHARTS_BASE}/${chartId}`,
        exact: false,
        allowedRoles: [Roles.admin, Roles.cse],
      },
      {
        label: 'Settings',
        path: `${routesConstants.CHARTS_BASE}/${chartId}/${CHARTS_SETTINGS_MARKER}`,
        exact: false,
        allowedRoles: [Roles.admin, Roles.cse],
      },
    ],
    [chartId]
  );

  useEffect(() => {
    if (match) {
      if (!selectedChart) {
        const chart = charts.find((entry) => entry.id === +match.params.id);
        if (chart) setSelectedChart(chart);
      }
    }
  }, [charts, match, selectedChart, setSelectedChart]);

  if (!chartId || disallowedMarkers.includes(matchedId ?? '')) {
    return null;
  }

  return <RoutingTabs value={match?.url || false} tabs={tabs} />;
};

const mapStateToProps = (state: RootState) => ({
  charts: state.charts.charts,
  selectedChart: state.charts.selectedChart,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  setSelectedChart: dispatch.charts.setSelectedChart,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartsContentNav);
