import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      text: {
        color: COLORS.grey['500'],
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '28px',
      },
    })
);
