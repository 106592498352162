import React from 'react';
import { CellRenderer } from 'src/shared/base-grid/base-grid';
import FileIcon from 'src/shared/icons/file-icon';

interface IFile {
  id: number;
  filename: string;
}

export const FilesRenderer: React.FC<CellRenderer<IFile[]>> = (props) => {
  return props.value?.length ? <FileIcon /> : null;
};
