import React, { FC, ReactNode } from 'react';
import { Avatar, Button, Menu, Tooltip, WithStyles, withStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Monogram } from 'src/shared/monogram';
import { styles } from './profile-menu.styles';
import { User } from 'src/store/api/users.api';

const MAX_PROFILE_NAME_LENGTH = 11;

interface ProfileUser extends User {
  avatarUrl?: string;
}

interface State {
  anchorEl: (EventTarget & Element) | null;
}

interface Props extends WithStyles<typeof styles> {
  children: ReactNode;
  user: ProfileUser | null;
}

const Arrow: FC<{ className: string; isOpen: boolean }> = (props) =>
  props.isOpen ? (
    <ExpandLess fontSize="inherit" className={props.className} />
  ) : (
    <ExpandMore fontSize="inherit" className={props.className} />
  );

class ProfileMenuComponent extends React.Component<Props, State> {
  state = {
    anchorEl: null,
  };

  render() {
    const { children, user } = this.props;
    const { anchorEl } = this.state;
    const fullName = `${user?.firstName} ${user?.lastName}`;
    const nameDisplay =
      fullName.length > MAX_PROFILE_NAME_LENGTH
        ? `${fullName.substring(0, MAX_PROFILE_NAME_LENGTH - 3)}...`
        : fullName;

    if (!user) {
      return null;
    }

    const avatar = (
      <Avatar
        src={user.avatarUrl}
        component="span"
        classes={{ root: this.props.classes.avatarRoot }}>
        <Monogram>{user.firstName || ''}</Monogram>
      </Avatar>
    );

    return (
      <React.Fragment>
        <Tooltip title={fullName} placement="top-start">
          <Button
            classes={{ root: this.props.classes.menuTrigger }}
            endIcon={
              <Arrow
                className={this.props.classes.menuTriggerArrow}
                isOpen={!!anchorEl}
              />
            }
            fullWidth={true}
            onClick={this.onOpen}
            startIcon={avatar}>
            <span title={fullName} className={this.props.classes.noTransform}>
              {nameDisplay}
            </span>
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          getContentAnchorEl={null}
          onClose={this.onClose}
          open={Boolean(anchorEl)}
          transformOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}>
          {children}
        </Menu>
      </React.Fragment>
    );
  }

  onOpen = (event: React.SyntheticEvent) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  onClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
}

export const ProfileMenu = withStyles(styles)(ProfileMenuComponent);
