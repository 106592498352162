import React from 'react';
import ChartFormContainer from './chart-form/chart-form.container';
import ChartDetailsContainer from 'src/app/charts/chart-details';
import { routesConstants } from 'src/shared/routing/routes.constants';
import RoutesList, { AppRoute } from 'src/shared/routing/routes-list';
import { Roles } from 'src/store/api/users.api';

const ROUTES: AppRoute[] = [
  {
    path: routesConstants.CHARTS_NEW,
    component: ChartFormContainer,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    path: routesConstants.CHARTS_DETAILS,
    component: ChartDetailsContainer,
    exact: true,
    allowedRoles: [Roles.admin, Roles.cse],
  },
];

const Charts = () => <RoutesList routes={ROUTES} />;

export default Charts;
