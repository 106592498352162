import React, { FC, useCallback, useEffect, useState } from 'react';
import BaseGrid from 'src/shared/base-grid';
import { createColumn } from 'src/shared/base-grid/column-creator';
import { useStyles } from './styles';
import { columns } from './columns';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { Box } from '@material-ui/core';
import { StyledColDef } from 'src/shared/base-grid/base-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState, select } from 'src/store';
import { useLocation } from 'react-router';
import Button from 'src/shared/button';
import { HEADER_HEIGHTS } from 'src/shared/base-grid/base-grid.config';
import { Range, getEquipmentIdentifiers } from 'src/store/api/notifications.api';
import { TabSubtype } from './notification-ranges-container';
import cloneDeep from 'lodash/cloneDeep';
import { getSubtype } from 'src/services/ranges-service';
import ExportBtn from 'src/shared/button-export';
import { exportXlsxCallback } from 'src/services/xlsx-export';
import { showError } from 'src/services/snackbars';

interface Props {
  tabSubtype?: TabSubtype;
}

type Row = Range & { isInvalid: boolean };

const exportUrl = '/metric_ranges/export.xlsx';

export const NotificationRanges: FC<Props> = ({ tabSubtype }) => {
  const dispatch = useDispatch<RootDispatch>();
  const location = useLocation();
  const classes = useStyles();

  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const ranges = useSelector((state: RootState) =>
    //we need ranges deep copy, because the dataset is changed by ag-grid in custom cells
    cloneDeep(select.notifications.selectRanges(state)).map((range) => ({
      ...range,
      isInvalid: false,
    }))
  );

  useEffect(() => {
    dispatch.notifications.fetchRanges(getSubtype(tabSubtype));
  }, [dispatch.notifications, location.search, tabSubtype]);

  const colData: StyledColDef[] = columns.map((column) => {
    const isResizable =
      column.name !== 'defaultMinValue' && column.name !== 'defaultMaxValue';
    return createColumn(column, {
      resizable: isResizable,
      suppressKeyboardEvent: (params) => {
        const event = params.event;
        const key = event.key;

        return key === 'Tab';
      },
    });
  });

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  const handleExport = (url: string, title: string) => {
    return exportXlsxCallback(url, { ...getEquipmentIdentifiers() }, title);
  };

  const getAllRows = useCallback(() => {
    const allRows: Row[] = [];

    gridApi?.forEachNode((node) => allRows.push(node.data));
    return allRows;
  }, [gridApi]);

  const onSave = useCallback(() => {
    if (!gridApi) return;

    if (getAllRows().some((row) => row.isInvalid)) {
      showError('Please set correct values for Ranges');
      return;
    }

    const allRanges = getAllRows().map((gridRange) => {
      const { isInvalid, ...range } = gridRange;
      return range;
    });

    dispatch.notifications.overrideRanges(allRanges);
  }, [getAllRows, dispatch.notifications, gridApi]);

  return ranges.length ? (
    <>
      <Box display="flex" justifyContent="flex-end">
        <ExportBtn url={exportUrl} onClick={handleExport} />
      </Box>
      <BaseGrid
        autoSizeColumns={true}
        colData={colData}
        rows={ranges}
        onGridReady={onGridReady}
        headerHeight={HEADER_HEIGHTS.oneRow}
      />
      <Box className={classes.footer}>
        <Button onClick={onSave} color="primary">
          Save changes
        </Button>
      </Box>
    </>
  ) : null;
};
