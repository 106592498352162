import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const CompressorReplacementIcon: FC<Props> = (props) => {
  const { className, color } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 5C11.2239 5 11 5.22386 11 5.5V6.5C11 6.77614 11.2239 7 11.5
        7H16.5C16.7761 7 17 6.77614 17 6.5V5.5C17 5.22386 16.7761 5 16.5 5H11.5ZM4 12C4 9.79086 5.79086 8 8 8H16C18.2091
        8 20 9.79086 20 12C20 14.2091 18.2091 16 16
        16H9.94999C9.71836 14.8589 8.70948 14 7.5 14C6.64797 14 5.8955 14.4262 5.44416 15.0771C4.56177 14.3434 4 13.2373
        4 12ZM13 17.4961C13 17.22 13.2239 16.9961 13.5 16.9961H16.5C16.7761 16.9961 17 17.22 17 17.4961V17.6885C17
        17.9647 16.7761 18.1885 16.5 18.1885H13.5C13.2239 18.1885 13 17.9647 13 17.6885V17.4961ZM7.51862 18.188C8.45246
        18.188 9.20949 17.4309 9.20949 16.4971C9.20949 15.5633 8.45246 14.8062 7.51862 14.8062C6.58478 14.8062 5.82775
        15.5633 5.82775 16.4971C5.82775 17.4309 6.58478 18.188 7.51862 18.188Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default CompressorReplacementIcon;
