import React from 'react';
import { BaseChart } from '../../../../shared/charts/base-chart';
import {
  ChartType,
  DataProp,
  PaletteMap,
  calculateChartOptions,
  prepareChartData,
} from './config';

type ComponentProps = {
  data: DataProp;
  type: ChartType;
  paletteMap?: PaletteMap;
};

const height = 282;

const BarChart: React.FC<ComponentProps> = ({ data, type, paletteMap }) => {
  const { series, totalPumpCountMap, legendData } = prepareChartData(
    data,
    type,
    paletteMap
  );

  return (
    <BaseChart
      height={height}
      series={series}
      options={calculateChartOptions(legendData, totalPumpCountMap)}
      renderer="canvas"
    />
  );
};

export default BarChart;
