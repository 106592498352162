import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

const loginFormStyles = (): StyleRules =>
  createStyles({
    loginForm: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-end',
    },
    formTitle: {
      alignSelf: 'baseline',
      fontSize: '24px',
      lineHeight: '28px',
      marginBottom: '24px',
    },
    textField: {
      height: '3.5rem',
      marginTop: '8px',
      marginBottom: '16px',
    },
    inputLabel: {
      fontSize: '0.875rem',
    },
    helperText: {
      paddingLeft: '0.5rem',
      fontSize: '0.656rem',
    },
  });

export default loginFormStyles;
