import BaseApi from '.';

const anomalies = new BaseApi('./anomalies');

export interface Anomaly {
  rpm: number;
  timestamp: string;
  is_positive_anomaly: null | boolean;
  is_negative_anomaly: null | boolean;
}

export const anomaliesApi = {
  fetchData: (params: URLSearchParams) => anomalies.get<Anomaly[]>({ params }),
};
