import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Button from 'src/shared/button';
import DateSiteModelPicker from './model-selector-components/date-site-model-picker-section';
import FailureProbabilityThreshold from './model-selector-components/failure-probability-threshold';
import AboveThresholdDuration from './model-selector-components/above-threshold-duration';
import { Model } from 'src/store/api/ml.api';
import { Site } from 'src/store/api/sites.api';
import { FetchScoreQuery } from 'src/store/models/ml.model';

interface Props {
  sites: Site[];
  models: Model[];
  pendingScore: boolean;
  pendingModels: boolean;
  onChange?: (query: FetchScoreQuery) => void;
}

export class ModelSelector extends React.Component<Props> {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    siteIds: [] as number[],
    modelId: '',
    threshold: 55,
    aboveThresholdDuration: 5,
  };

  render() {
    const {
      startDate,
      endDate,
      siteIds,
      modelId,
      threshold,
      aboveThresholdDuration,
    } = this.state;
    const { sites, models, pendingModels } = this.props;

    return (
      <form action="#" onSubmit={this.onFormSubmit}>
        <DateSiteModelPicker
          startDate={startDate}
          endDate={endDate}
          onDateRangeChange={this.onDateRangeChange}
          sites={sites}
          selectedSiteIds={siteIds}
          onSitesChange={this.onSitesChange}
          models={models}
          modelId={modelId}
          pendingModels={pendingModels}
          onModelChange={this.onModelChange}
        />
        <Box px={2} py={1}>
          <Typography variant="subtitle2">Fine-Tune Alarm Classes:</Typography>
          <FailureProbabilityThreshold
            threshold={threshold}
            onFailureProbabilityChange={this.onFailureProbabilityChange}
          />
          <AboveThresholdDuration
            aboveThresholdDuration={aboveThresholdDuration}
            onDurationChange={this.onAboveThresholdDurationChange}
          />
        </Box>
        <Box px={2} py={1}>
          <Box textAlign="right">
            <Button color="primary" type={this.hasValidState() ? 'submit' : 'button'}>
              Apply
            </Button>
          </Box>
        </Box>
      </form>
    );
  }

  onDateRangeChange = (start: Date, end: Date) => {
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  onSitesChange = (idList: number[]) => {
    this.setState({
      siteIds: idList,
    });
  };

  onModelChange = (value: string) => {
    this.setState({
      modelId: value,
    });
  };

  onFailureProbabilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(event.target.value);

    if (value < 0 || isNaN(value)) {
      value = 0;
    }

    if (value > 100) {
      value = 100;
    }

    this.setState({
      threshold: value,
    });
  };

  onAboveThresholdDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(event.target.value);

    if (isNaN(value)) {
      value = 0;
    }

    this.setState({
      aboveThresholdDuration: value,
    });
  };

  onFormSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!this.canSendRequest()) {
      return;
    }

    const params = this.state;

    const sites = this.props.sites.filter((site: Site) =>
      params.siteIds.includes(site.id)
    );

    const query = {
      start_date: params.startDate.toJSON().slice(0, 10),
      end_date: params.endDate.toJSON().slice(0, 10),
      threshold: params.threshold / 100,
      above_threshold_duration: params.aboveThresholdDuration,
      site_name: sites.map((site) => site.name),
      model_id: params.modelId,
    };

    this.props.onChange?.(query);
  };

  hasValidState() {
    const rules = [
      Boolean(this.state.startDate),
      Boolean(this.state.endDate),
      Boolean(this.state.siteIds),
      Boolean(this.state.modelId),
      this.state.threshold >= 0,
      this.state.threshold <= 100,
      this.state.aboveThresholdDuration >= 0,
    ];
    return rules.every((val) => val === true);
  }

  canSendRequest() {
    return !this.props.pendingScore && this.hasValidState();
  }
}
