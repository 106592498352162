import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  noAutoSize: {
    flex: 'none',
  },
  autoSize: {
    flex: '1',
    overflow: 'auto',
  },
  companyControlSlot: {
    padding: '12px 16px',
  },
});
