import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        height: '300px',
        margin: '25px 0',
      },
    })
);
