import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      listItem: {
        padding: '2px 8px',
        fontSize: '0.875rem',
        color: COLORS.black,
        '&:hover': {
          backgroundColor: COLORS.grey['100'],
        },
      },
    })
);
