import React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useStyles from './password-security-button.styles';

interface OwnProps {
  isShown: boolean;
  onClick: () => void;
}

export const PasswordSecurityBtn: React.FC<OwnProps> = (props) => {
  const styles = useStyles();
  return (
    <InputAdornment position="end" classes={{ ...styles }}>
      <IconButton edge="end" onClick={props.onClick}>
        {props.isShown ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};
