import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  labelIcon: {
    width: 'auto',
    padding: 0,
    margin: 0,
    minHeight: 0,
  },
  wrapper: {
    flexDirection: 'row-reverse',
  },
});
