import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { ControllerRow, controllersApi } from '../api/controllers.api';
import { getScopedQuery } from 'src/shared/routing/routing.helper';

interface ControllersState {
  rows: ControllerRow[] | null;
}

export const controllers = createModel<RootModel>()({
  state: {
    rows: null,
  } as ControllersState,

  reducers: {
    setRows: (
      state: ControllersState,
      rows: ControllerRow[] | null
    ): ControllersState => ({
      ...state,
      rows,
    }),
  },

  effects: (dispatch) => {
    const { controllers } = dispatch;
    return {
      async fetchRows() {
        const { data } = await controllersApi.fetchRows(getScopedQuery());
        controllers.setRows(data);
      },
    };
  },
});
