import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

const columnDropdownStyles = (): StyleRules =>
  createStyles({
    select: {
      width: '18.75rem',
      padding: '0.5rem',
      height: '2rem',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      fontSize: '0.875rem',
      cursor: 'pointer',
    },
    title: {
      margin: '0 0.25rem',
    },
    options: {
      position: 'absolute',
      padding: '0 0 1rem',
      top: '3.5rem',
      width: '18.75rem',
      zIndex: 5,
      backgroundColor: COLORS.white,
      boxShadow: '0rem 0.5rem 1.125rem rgba(0, 0, 0, 0.25)',
    },
  });
export default columnDropdownStyles;
