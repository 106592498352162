import { TableCell, TableRow } from '@material-ui/core';
import React, { FC } from 'react';
import DateInSelectedZone from 'src/shared/date-in-selected-zone';
import { SwitchHistory } from 'src/store/api/notifications.api';

interface Props {
  switchHistory: SwitchHistory;
}

export const SwitchHistoryRow: FC<Props> = ({ switchHistory }) => {
  return (
    <TableRow>
      <TableCell>{switchHistory.value}</TableCell>
      <TableCell>{switchHistory.minValue}</TableCell>
      <TableCell>{switchHistory.maxValue}</TableCell>
      <TableCell>{switchHistory.outOfRange.toString()}</TableCell>
      <TableCell>
        <DateInSelectedZone>{switchHistory.loggedAt}</DateInSelectedZone>
      </TableCell>
    </TableRow>
  );
};
