import BaseApi from './index';

const gcu = new BaseApi('/controllers/gcu_info');
const alarmConfig = new BaseApi('/controllers/alarm_settings');

export interface GcuInfoType {
  site_name: string;
  tool_name: string;
  ip_address: string;
  serial_number: string;
  software_revision: string;
  elapsed_hours: number;
  tcp_port: number;
  tcp_speed: string;
  tcp_timeout: number;
  subnet_mask: string;
  default_gateway: string;
  gcu_model: string | null;
  tool_id: number;
}

export interface AlarmConfigObj {
  alarm: string;
  enabled: boolean;
}

export type StrOrNum = string | number;

export type ApiToolParams = {
  toolId: StrOrNum;
  siteId: StrOrNum;
};

export const toolApi = {
  fetchAlarmConfiguration: (params: ApiToolParams) =>
    alarmConfig.get<AlarmConfigObj[]>({ params }),
  fetchGCU: (params: ApiToolParams) => gcu.get<GcuInfoType>({ params }),
};
