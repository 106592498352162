import { Box } from '@material-ui/core';
import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootDispatch, RootState, select } from 'src/store';
import { AutoNotificationContent } from './auto-notification/auto-notification-content';
import { ManualNotificationContent } from './manual-notification-content';
import { NotificationHeader } from './notification-header';
import { NotificationNotFound } from './notification-not-found';
import { AutoNotification } from 'src/store/api/notifications.api';
import { useStyles } from './styles';

interface Props {
  onAcknowledge: (notification: AutoNotification) => void;
}

export const NotificationDetailsContainer: FC<Props> = ({ onAcknowledge }) => {
  const classes = useStyles();
  const dispatch = useDispatch<RootDispatch>();
  const location = useLocation();

  const notification = useSelector((state: RootState) =>
    select.notifications.currentNotification(state)
  );

  useEffect(() => {
    dispatch.notifications.setCurrentNotification(null);
    return () => {
      dispatch.notifications.setCurrentNotification(null);
    };
  }, [dispatch.notifications, location.search]);

  const notificationComponent = useMemo(() => {
    if (!notification) return;

    return notification.notificationType === 'auto' ? (
      <AutoNotificationContent
        notification={notification as AutoNotification}
        onAcknowledge={onAcknowledge}
      />
    ) : (
      <ManualNotificationContent notification={notification} />
    );
  }, [notification, onAcknowledge]);

  return notification ? (
    <>
      <NotificationHeader notification={notification} />
      <Box className={classes.contentRoot}>{notificationComponent}</Box>
    </>
  ) : (
    <NotificationNotFound />
  );
};
