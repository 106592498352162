import { ColData } from 'src/shared/base-grid/base-grid';

export const settingsHistoryColumns: ColData[] = [
  {
    name: 'changed_at',
    label: 'Date Changed',
    sortDirection: 'desc',
    units: null,
    visibility: true,
    type: 'date',
  },
  {
    name: 'changed_field',
    label: 'Setting Changed',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'string',
    flex: 3,
  },
  {
    name: 'old_value',
    label: 'Old Value',
    units: null,
    sortDirection: null,
    visibility: true,
    type: 'string',
    flex: 1,
  },
  {
    name: 'new_value',
    label: 'New Value',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'string',
    flex: 1,
  },
];
