import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export default makeStyles(
  (): StyleRules =>
    createStyles({
      header: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        height: '4.5rem',
        backgroundColor: COLORS.grey['900'],
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)',
      },
    })
);
