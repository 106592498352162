import React, { FC } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  title: string;
}

const PageWrapper: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box px={2} py={1.75}>
        <Typography variant="subtitle2" className={classes.title}>
          {props.title}
        </Typography>
      </Box>
      <Divider />
      {props.children}
    </Box>
  );
};

export default PageWrapper;
