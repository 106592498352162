import React, { FC, useMemo } from 'react';
import { DataProp as ScoresProp } from '../bar-chart/config';
import { useStyles } from './metrics-score.styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import clsx from 'clsx';

type ComponentProps = {
  scores: ScoresProp;
};

const formatValue = (value: number): string => {
  if (!value) {
    return '';
  }
  return `${(value * 100).toFixed(2)}%`;
};

const extractScores = (scores: ScoresProp) => {
  const metricsScores = scores.thresholds?.[Object.keys(scores.thresholds)[0]].binary;

  const { accuracy, precision, recall, f1 } = metricsScores || {};

  return {
    accuracy: formatValue(accuracy),
    precision: formatValue(precision),
    recall: formatValue(recall),
    f1: formatValue(f1),
  };
};

const TableCell: FC<TableCellProps> = (props) => {
  const classes = useStyles();
  return (
    <MuiTableCell
      {...props}
      classes={{ ...props.classes, root: clsx(props.classes?.root, classes.base) }}
    />
  );
};

const TableCellHead: FC<TableCellProps> = (props) => {
  const classes = useStyles();
  return (
    <TableCell
      {...props}
      classes={{ ...props.classes, root: clsx(props.classes?.root, classes.th) }}
    />
  );
};

const MetricsScore: FC<ComponentProps> = ({ scores }) => {
  const metricsScores = useMemo(() => extractScores(scores), [scores]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellHead>Accuracy</TableCellHead>
          <TableCellHead>Precision</TableCellHead>
          <TableCellHead>Recall</TableCellHead>
          <TableCellHead>F1</TableCellHead>
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow>
          <TableCell>{metricsScores.accuracy}</TableCell>
          <TableCell>{metricsScores.precision}</TableCell>
          <TableCell>{metricsScores.recall}</TableCell>
          <TableCell>{metricsScores.f1}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default MetricsScore;
