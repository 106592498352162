import { NotificationCounterIcon } from './tabs-icons/notificattion-counter-icon';
import { routesConstants } from 'src/shared/routing/routes.constants';
import { Roles } from 'src/store/api/users.api';
import { RoutingTab } from 'src/shared/routing/routing-tabs/routing-tabs';

const BASE_DASHBOARD_TABS = [
  {
    label: 'Maintenance',
    path: routesConstants.DASHBOARD,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Predictive',
    path: routesConstants.PREDICTIONS,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Compressors',
    path: routesConstants.COMPRESSORS,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Controllers',
    path: routesConstants.CONTROLLERS,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Settings',
    path: routesConstants.PUMP_SETTINGS,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
];

const NOTIFICATION_TABS: RoutingTab[] = [
  {
    label: 'Notification ranges',
    path: routesConstants.NOTIFICATION_RANGES,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Notifications',
    icon: NotificationCounterIcon,
    path: routesConstants.NOTIFICATION_HISTORY,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
];

export const SITE_TABS = [...BASE_DASHBOARD_TABS, ...NOTIFICATION_TABS];

export const TOOL_TABS = [
  ...BASE_DASHBOARD_TABS,
  {
    label: 'GCU',
    path: routesConstants.GCU,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Alarm Configuration',
    path: routesConstants.ALARM_CONFIG,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  ...NOTIFICATION_TABS,
];

export const PUMP_TABS = [
  {
    label: 'Details',
    path: routesConstants.PUMP,
    exact: true,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Regeneration',
    path: routesConstants.PUMP_REGENERATION,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Anomalies',
    path: routesConstants.PUMP_ANOMALIES,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Change History',
    path: routesConstants.PUMP_CHANGE_HISTORY,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Chamber History',
    path: routesConstants.PUMP_CHAMBER_HISTORY,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Diodes',
    path: routesConstants.PUMP_DIODES,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  {
    label: 'Alarm History',
    path: routesConstants.PUMP_ALARM_HISTORY,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  ...NOTIFICATION_TABS,
];

export const CONTROLLER_TABS = [
  {
    label: 'Details',
    path: routesConstants.CONTROLLER,
    exact: true,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  ...NOTIFICATION_TABS,
];

export const COMPRESSOR_TABS = [
  {
    label: 'Details',
    path: routesConstants.COMPRESSOR,
    exact: true,
    allowedRoles: [Roles.admin, Roles.cse],
  },
  ...NOTIFICATION_TABS,
];
