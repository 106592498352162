import React, { FC, useMemo } from 'react';
import Wrapper from 'src/shared/charts/line-chart/wrapper';
import BaseChart from 'src/shared/charts/base-chart';
import { WEEK_LENGTH, getConfig } from './predictive-chart.config';
import { GroupedSeries } from 'src/store/api/charts.api';
import round from 'lodash/round';

// todo: get colors from constants
const colors = {
  normal: 'rgba(165, 214, 167, 0.35)',
  warning: 'rgba(255, 152, 0, 0.35)',
  critical: 'rgba(207, 32, 46, 0.35)',
};

type Status = keyof typeof colors;

type OwnProps = {
  data: GroupedSeries[];
  seriesValuesKey: string;
  seriesStatusesKey: string;
  height?: number;
  title: string;
  minX: number;
  maxX: number;
  isPredictiveChartLoading: boolean;
};

const getBarSeriesData = (
  data: GroupedSeries,
  seriesValuesKey: string,
  seriesStatusesKey: string
) => {
  const statuses = data?.series[seriesStatusesKey];
  const timestamps = data?.timestamps;

  return data?.series[seriesValuesKey]?.map((value, index) => {
    const status = (statuses[index] as unknown) as Status;
    const timestamp = timestamps[index];

    return {
      name: seriesValuesKey,
      value: [
        timestamp, // x
        round(value, 1), // y
      ],
      itemStyle: {
        color: colors[status],
      },
    };
  });
};

const PredictiveChart: FC<OwnProps> = (props) => {
  const {
    data,
    seriesValuesKey,
    seriesStatusesKey,
    height,
    title,
    minX,
    maxX,
    isPredictiveChartLoading,
  } = props;

  const config = useMemo(() => {
    return getConfig(minX, maxX);
  }, [maxX, minX]);

  const ticks = useMemo(() => {
    const timestamps = data[0]?.timestamps || [];
    if (timestamps.length <= WEEK_LENGTH) {
      return { splitNumber: timestamps.length };
    }
    return {};
  }, [data]);

  const series = useMemo(() => {
    const baseConfig = {
      barMaxWidth: 60,
      type: 'bar',
    };

    if (isPredictiveChartLoading) {
      return { ...baseConfig, data: [] };
    } else {
      return {
        ...baseConfig,
        data: getBarSeriesData(data[0], seriesValuesKey, seriesStatusesKey),
      };
    }
  }, [data, isPredictiveChartLoading, seriesStatusesKey, seriesValuesKey]);

  return (
    <Wrapper title={title}>
      <BaseChart
        options={{
          ...config,
          xAxis: { ...config.xAxis, ...ticks },
        }}
        series={[series]}
        height={height}
        renderer="canvas"
      />
    </Wrapper>
  );
};

export default React.memo(PredictiveChart);
