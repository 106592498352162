import React, { FC } from 'react';
import { useEquipmentIdentifiers } from './useEquipmentIdentifiers';

export const SITE = 'site';
export const TOOL = 'tool';
export const PUMP = 'pump';
export const CONTROLLER = 'controller';
export const COMPRESSOR = 'compressor';

export type EquipmentType =
  | typeof PUMP
  | typeof SITE
  | typeof TOOL
  | typeof CONTROLLER
  | typeof COMPRESSOR
  | 'unknown';

export interface NodeIdentifiers {
  siteId: number;
  toolId?: number;
  pumpId?: number;
  controllerId?: number;
  compressorId?: number;
}

export interface WithSelectedEquipmentProps {
  type: EquipmentType;
  siteId: number;
  toolId: number;
  pumpId: number;
  controllerId: number;
  compressorId: number;
}

export function withSelectedEquipmentIdentifiers<T extends WithSelectedEquipmentProps>(
  Component: FC<T>
) {
  // memoize component so it's rendered only when one of equipment identifiers changes
  const MemoizedComponent = React.memo(Component);

  return function WithSelectedEquipment(
    props: Omit<T, keyof WithSelectedEquipmentProps>
  ) {
    const {
      type,
      siteId,
      toolId,
      pumpId,
      controllerId,
      compressorId,
    } = useEquipmentIdentifiers();

    return (
      <MemoizedComponent
        {...(props as T)}
        type={type}
        siteId={siteId}
        toolId={toolId}
        pumpId={pumpId}
        controllerId={controllerId}
        compressorId={compressorId}
      />
    );
  };
}
