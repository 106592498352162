export const defaultPalette: string[] = [
  '#4CAF50', // Green/500
  '#CF202E', // Red/500
  '#FF9800', // Orange/500
  '#03A9F4', // Blue/500
  '#A5D6A7', // Green/200
  '#FF8F8C', // Red/50
  '#FFCC80', // Orange/200
  '#81D4FA', // Blue/200
  '#2E7D32', // Green/800
  '#960007', // Red/700
  '#E65100', // Orange/900
  '#0277BD', // Indigo/500
];

export const without = (withoutColor: string) =>
  defaultPalette.filter((color) => color !== withoutColor);

export default defaultPalette;
