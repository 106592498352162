import React, { FC, useCallback, useMemo } from 'react';
import { ChartsFormData } from 'src/store/models/charts-builder.model';
import { TextField } from '@material-ui/core';
import { DefaultChartValues } from 'src/app/charts/chart-form/chart-form';
import { FormContextValues } from 'react-hook-form';
import { useStyles } from 'src/app/charts/chart-form/chart-form.styles';

export interface Props {
  side: 'left' | 'right';
  formContext: FormContextValues<DefaultChartValues>;
  formData?: ChartsFormData | null;
}

const ChartFormTitle: FC<Props> = (props: Props) => {
  const { formData, side, formContext } = props;
  const { register, errors, clearError, getValues } = formContext;
  const styles = useStyles();

  const leftAxisId = useMemo(() => formData?.axesBySide?.left[0].id || 'new', [formData]);
  const rightAxisId = useMemo(() => formData?.axesBySide?.right[0].id || 'new', [
    formData,
  ]);
  const handleValidateMinMax = useCallback(
    (min: string, max: string) => (): boolean => {
      clearError([min, max]);
      return (
        getValues(min) === '' ||
        getValues(max) === '' ||
        +getValues(min) < +getValues(max)
      );
    },
    [getValues, clearError]
  );

  const isLeftAxis = side === 'left' ? true : false;
  const axisMin = isLeftAxis
    ? `axes.left-${leftAxisId}.min`
    : `axes.right-${rightAxisId}.min`;
  const axisMax = isLeftAxis
    ? `axes.left-${leftAxisId}.max`
    : `axes.right-${rightAxisId}.max`;

  return (
    <div className={styles.axisHeader}>
      <TextField
        key={isLeftAxis ? leftAxisId : rightAxisId}
        id={isLeftAxis ? 'left_axis-title' : 'right_axis-title'}
        className={`${styles.input} ${styles.axisTitleInput}`}
        label="Vertical Axis Title"
        variant="filled"
        name={
          isLeftAxis ? `axes.left-${leftAxisId}.title` : `axes.right-${rightAxisId}.title`
        }
        inputRef={register({ maxLength: 300 })}
        error={
          isLeftAxis
            ? !!errors.axes?.[`left-${leftAxisId}`]?.title
            : !!errors.axes?.[`right-${rightAxisId}`]?.title
        }
        autoComplete="off"
      />
      <TextField
        id={isLeftAxis ? 'left_axis-min' : 'right_axis-min'}
        className={`${styles.input} ${styles.axisMinMaxInput}`}
        label="Min"
        variant="filled"
        name={axisMin}
        type="number"
        inputProps={{ step: 'any' }}
        inputRef={register({
          validate: handleValidateMinMax(axisMin, axisMax),
        })}
        error={
          isLeftAxis
            ? !!errors.axes?.[`left-${leftAxisId}`]?.min
            : !!errors.axes?.[`right-${rightAxisId}`]?.min
        }
        autoComplete="off"
      />
      <TextField
        id={isLeftAxis ? 'left_axis-max' : 'right_axis-max'}
        className={`${styles.input} ${styles.axisMinMaxInput}`}
        label="Max"
        variant="filled"
        name={axisMax}
        type="number"
        inputProps={{ step: 'any' }}
        inputRef={register({
          validate: handleValidateMinMax(axisMin, axisMax),
        })}
        error={
          isLeftAxis
            ? !!errors.axes?.[`left-${leftAxisId}`]?.max
            : !!errors.axes?.[`right-${rightAxisId}`]?.max
        }
        autoComplete="off"
      />
    </div>
  );
};
export default ChartFormTitle;
