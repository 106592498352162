import React, { FC, useMemo } from 'react';
import { useStyles } from 'src/app/charts/chart-form/chart-form.styles';
import { Controller, FormContextValues } from 'react-hook-form';
import { Autocomplete } from 'src/shared/autocompletes-wtih-chips';
import { createLabel, formatSites } from 'src/app/charts/chart-form/utils';
import { Site } from 'src/store/api/sites.api';
import { DefaultChartValues } from 'src/app/charts/chart-form/chart-form';

export interface Props {
  sites: Site[];
  formContext: FormContextValues<DefaultChartValues>;
}

const ChartFormResourceControllers: FC<Props> = (props: Props) => {
  const styles = useStyles();
  const { sites, formContext } = props;
  const { watch, control } = formContext;
  const selectedSitesOptions = watch('sites');
  const formattedSites = useMemo(() => (sites ? formatSites(sites) : []), [sites]);

  return (
    <Controller
      as={Autocomplete}
      name="sites"
      control={control}
      className={styles.input}
      options={formattedSites}
      value={[]}
      onChange={([, value]) => value}
      label={createLabel('Site', selectedSitesOptions?.length)}
      limitTags={5}
    />
  );
};
export default ChartFormResourceControllers;
