import React from 'react';
import { CellRenderer } from 'src/shared/base-grid/base-grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { routesConstants } from 'src/shared/routing/routes.constants';

type ComponentProps = CellRenderer<never> & RouteComponentProps;

const GoToActionRenderer: React.FC<ComponentProps> = (props) => (
  <IconButton
    size="small"
    component={Link}
    to={{
      pathname: `${routesConstants.PUMP_REGENERATION}/${props.data.id}`,
      search: props.location.search,
    }}>
    <ArrowForwardIcon />
  </IconButton>
);
export default withRouter(GoToActionRenderer);
