import React, { FC } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useStyles } from './pump-details.styles';

type PumpDetailsRow = {
  label: string;
  value: unknown;
};

interface Props {
  rows: PumpDetailsRow[];
  //applyTableRootStyle:  Adds fixed tableLayout for the chart-info table only.
  applyTableRootStyle?: boolean; //todo: See if its possible to re-style such that all tables share the same styling. Or neither of the tables require it
}

const ChartInfoTable: FC<Props> = (props) => {
  const { rows, applyTableRootStyle } = props;
  const styles = useStyles();

  return (
    <Table size="small" classes={applyTableRootStyle ? { root: styles.tableRoot } : {}}>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.label}>
            <TableCell padding="none" classes={{ root: styles.headerCell }}>
              <span className={styles.textLinePxSmall}>{row.label}</span>
            </TableCell>
            <TableCell padding="none" classes={{ root: styles.tableCell }}>
              <span className={styles.textLinePxSmall}>{row.value as string}</span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default ChartInfoTable;
