import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import { RootState } from 'src/store';
import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import NodeOnlineStatus from './node-display-features/online-status';
import NodeName from './node-display-features/node-name';
import NodeToggleButton from './node-display-features/node-button';
import { useStyles } from './styles';
import {
  COMPRESSOR,
  CONTROLLER,
  NodeIdentifiers,
  PUMP,
  SITE,
  TOOL,
} from 'src/shared/routing/with-selected-equipment';
import { NotificationsCounter } from 'src/shared/notifications-counter/notifications-counter';

const OFFSET = 26;

export type TreeNodeProps = {
  node: NodeIdentifiers;
  online: 'online' | 'offline' | 'warning';
  name: string;
  nesting: 0 | 1 | 2;
  activeNotificationsCount: number;
  expand?: boolean;
  scroll?: boolean;
  onSelect: (node: NodeIdentifiers | null) => void; // fires on selection
  onChange: (state: boolean, node: NodeIdentifiers) => void; // fires on expand/collapse
};

const Node: FC<TreeNodeProps & { selected: boolean }> = React.memo((props) => {
  const {
    onChange,
    onSelect,
    nesting = 0,
    expand,
    scroll,
    online,
    name,
    node,
    activeNotificationsCount,
    selected,
  } = props;

  const isNodeEquipment = nesting === 2;
  const isNodeTool = nesting === 1;
  const isNodeSite = nesting === 0;

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const nodeEl = useRef<HTMLDivElement>(null);

  const row = clsx(classes.row, {
    [classes.selectedRow]: selected,
    [classes.site]: isNodeSite,
    [classes.tool]: isNodeTool,
    [classes.equipment]: isNodeEquipment,
  });

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      setExpanded((state) => {
        const toggled = !state;
        onChange(toggled, node);
        return toggled;
      });
    },
    [setExpanded, onChange, node]
  );

  const handleSelect = useCallback(() => {
    const to = {
      siteId: node.siteId,
      toolId: node.toolId,
      pumpId: node.pumpId,
      controllerId: node.controllerId,
      compressorId: node.compressorId,
    };
    onSelect(!selected ? to : null);
  }, [node, selected, onSelect]);

  useEffect(() => {
    if (expand === true) {
      setExpanded(() => {
        if (scroll) {
          window.requestAnimationFrame(() => {
            setTimeout(() => nodeEl.current?.scrollIntoView(), 200);
          });
        }
        return true;
      });
    }
  }, [expand, scroll]);

  return (
    <>
      <div
        className={row}
        style={{ paddingLeft: OFFSET * nesting }}
        onClick={handleSelect}
        ref={nodeEl}>
        <NodeOnlineStatus status={online} />
        <NodeName name={name} />
        <div className={classes.spacer} />

        <div className={isNodeEquipment ? classes.equipmentCounter : ''}>
          <NotificationsCounter count={activeNotificationsCount} />
        </div>

        {!isNodeEquipment && (
          <NodeToggleButton onChange={handleChange} expanded={expanded} />
        )}
      </div>

      <Collapse
        in={expanded}
        mountOnEnter={!selected && !expanded}
        unmountOnExit={isNodeTool}>
        {props.children}
      </Collapse>
    </>
  );
});

const TreeNode: FC<TreeNodeProps> = (props) => {
  const { nesting, node } = props;

  const expanded = useSelector(({ siteEquipment }: RootState) => siteEquipment.expanded);
  const {
    type,
    siteId,
    toolId,
    pumpId,
    controllerId,
    compressorId,
  } = useEquipmentIdentifiers();

  const selected = useMemo(() => {
    switch (type) {
      case SITE:
        return nesting === 0 && siteId === node.siteId;
      case TOOL:
        return nesting === 1 && siteId === node.siteId && toolId === node.toolId;
      case CONTROLLER:
        return (
          nesting === 2 &&
          siteId === node.siteId &&
          toolId === node.toolId &&
          controllerId === node.controllerId
        );
      case COMPRESSOR:
        return (
          nesting === 2 &&
          siteId === node.siteId &&
          toolId === node.toolId &&
          compressorId === node.compressorId
        );
      case PUMP:
        return (
          nesting === 2 &&
          siteId === node.siteId &&
          toolId === node.toolId &&
          pumpId === node.pumpId
        );
      default:
        return false;
    }
  }, [type, siteId, toolId, pumpId, nesting, node, controllerId, compressorId]);

  const expand =
    nesting === 0
      ? expanded?.siteId === node.siteId
      : nesting === 1 &&
        expanded?.siteId === node.siteId &&
        expanded?.toolId === node.toolId;
  const scroll =
    nesting === 1 && expanded?.siteId === node.siteId && expanded?.toolId === node.toolId;

  return <Node {...props} selected={selected} expand={expand} scroll={scroll} />;
};

export default TreeNode;
