import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      icon: {
        fontSize: '16px',
        marginLeft: '8px',
      },
      table: {
        maxWidth: '100%',
      },
      normal: {
        color: COLORS.green['500'],
      },
      unstable: {
        color: COLORS.orange['500'],
      },
      critical: {
        color: COLORS.red['500'],
      },
      failed: {
        color: COLORS.grey['700'],
      },
      cell: {
        borderLeft: `1px solid ${COLORS.grey['300']}`,
        padding: '0 6px',
        '&:last-child': {
          paddingRight: '6px',
          borderRight: `1px solid ${COLORS.grey['300']}`,
        },
      },
      headerCell: {
        padding: '6px',
        borderTop: `1px solid ${COLORS.grey['300']}`,
      },
    })
);
