import React from 'react';
import CompressorReplacementIcon from 'src/shared/icons/compressor-replacement-icon';
import OtherIcon from 'src/shared/icons/other-icon';
import PerformanceAdvisoryIcon from 'src/shared/icons/performance-advisory-icon';
import PumpReplacementIcon from 'src/shared/icons/pump-replacement-icon';
import { ManualTypes } from 'src/store/api/notifications.api';
import { COLORS } from 'src/styles/colors';
import { Notification } from 'src/store/api/notifications.api';

const fillColor = COLORS.grey[700];

export const getManualTypeIcon = (
  type: ManualTypes,
  style: string,
  color: string = fillColor
) => {
  if (type === ManualTypes.COMPRESSOR_REPLACEMENT)
    return <CompressorReplacementIcon className={style} color={color} />;

  if (type === ManualTypes.PUMP_REPLACEMENT)
    return <PumpReplacementIcon className={style} color={color} />;

  if (type === ManualTypes.PERFORMANCE_ADVISORY)
    return <PerformanceAdvisoryIcon className={style} color={color} />;

  return <OtherIcon className={style} color={color} />;
};

export const canAcknowledge = (notification: Notification | undefined) =>
  notification?.active && !notification?.acknowledged;
