import React from 'react';
import { RootDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PumpRegenerationSteps from 'src/app/pump/pump-regeneration/pump-regeneration-steps/pump-regeneration-steps';
import { routesConstants } from 'src/shared/routing/routes.constants';

type ConnectedProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ComponentProps = ConnectedProps &
  DispatchProps &
  RouteComponentProps<{ id?: string }>;

class PumpRegenerationStepsContainer extends React.Component<ComponentProps> {
  public componentDidMount() {
    const {
      location: { search },
      match: {
        params: { id },
      },
      fetchRegenerationSteps,
    } = this.props;

    if (id !== undefined) fetchRegenerationSteps({ id, search });
  }

  public componentDidUpdate(prevProps: ComponentProps) {
    const {
      location: { search },
      match: {
        params: { id },
      },
      fetchRegenerationSteps,
    } = this.props;
    const {
      location: { search: prevSearch },
      match: {
        params: { id: prevId },
      },
    } = prevProps;

    if (search !== prevSearch) {
      this.backToHistory();
    }
    if (id !== undefined && id !== prevId) {
      fetchRegenerationSteps({ id, search });
    }
  }

  public componentWillUnmount() {
    this.props.clearRegenerationSteps();
  }

  private backToHistory = () => {
    const { history, location } = this.props;
    history.push(`${routesConstants.PUMP_REGENERATION}${location.search}`);
  };

  render() {
    const { steps, match } = this.props;

    return (
      <PumpRegenerationSteps
        actionsId={match.params.id}
        backToHistory={this.backToHistory}
        steps={steps}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  steps: state.regenerations.steps,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchRegenerationSteps: dispatch.regenerations.fetchRegenerationSteps,
  clearRegenerationSteps: dispatch.regenerations.clearSteps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PumpRegenerationStepsContainer);
