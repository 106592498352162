import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { MaintenanceDashboardByTool } from './maintenance-dashboard-by-tool';

const mapState = (state: RootState) => ({
  seriesData: state.pumps.series,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  fetchData: dispatch.pumps.fetchPumpsSeriesByToolId,
  onClean: () => dispatch.pumps.replaceSeries(null),
});

export default connect(mapState, mapDispatch)(MaintenanceDashboardByTool);
