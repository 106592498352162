import { ColData } from 'src/shared/base-grid/base-grid';

export const columns: ColData[] = [
  {
    name: 'name',
    label: 'Name',
    sortDirection: 'asc',
    units: null,
    visibility: true,
    type: 'string',
  },
  {
    name: 'notificationMinimum',
    label: 'Minimum',
    units: null,
    visibility: true,
    type: 'number',
  },
  {
    name: 'notificationMaximum',
    label: 'Maximum',
    units: null,
    visibility: true,
    type: 'number',
  },
  {
    name: 'updatedAt',
    label: 'Last Update',
    units: null,
    visibility: true,
    type: 'Date',
  },
  {
    name: 'updatedBy',
    label: 'Updated By',
    units: null,
    visibility: true,
    type: 'string',
    minWidth: 150,
  },
  {
    name: 'subscription',
    label: 'Subscription',
    units: null,
    visibility: true,
    type: null,
  },
];
