import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';
import { RootDispatch, RootState, select } from 'src/store';
import ChartDetails from './chart-details';
import { Box } from '@material-ui/core';
import ChartForm from 'src/app/charts/chart-form/chart-form';
import { Spinner } from 'src/shared/spinner';
import toNumber from 'lodash/toNumber';

interface OwnProps {
  match: match<{
    id: string;
    settings?: string;
  }>;
}

type ConnectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
type Props = OwnProps & ConnectedProps;

const toId = (str: string) => {
  const id = toNumber(str);
  return Number.isNaN(id) ? null : id;
};

const ChartDetailsContainer = (props: Props) => {
  const {
    series,
    fetchSeries,
    setSeries,
    fetchChartById,
    formData,
    match,
    sites,
    metrics,
    charts,
    isLoading,
    updateChart,
    fetchSites,
    fetchMetrics,
    chartData,
  } = props;

  const [chartId, setChartId] = useState<number | null>(null);

  useEffect(() => {
    const id = toId(match.params.id);
    if (!chartData && id !== null) {
      setChartId(id);
      fetchChartById(id);
    }
  }, [chartData, fetchChartById, match]);

  useEffect(() => {
    if (!sites.length) {
      fetchSites();
    }
  }, [fetchSites, sites]);

  useEffect(() => {
    if (!metrics) {
      fetchMetrics();
    }
  }, [fetchMetrics, metrics]);

  if (chartId === null) return null;

  if (isLoading) {
    return (
      <Box pt={8} textAlign="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box pt={4} pb={3} px={2}>
      {match.params.settings ? (
        <ChartForm
          formData={formData}
          sites={sites}
          charts={charts}
          onSave={updateChart}
          metrics={metrics}
        />
      ) : (
        <ChartDetails
          series={series}
          fetchSeries={fetchSeries}
          setSeries={setSeries}
          chartId={chartId}
          chartData={chartData}
          timezoneOffset={props.timezone.offset}
        />
      )}
    </Box>
  );
};

const mapState = (state: RootState, ownProps: OwnProps) => {
  return {
    sites: state.siteEquipment.sites,
    metrics: state.charts.metrics,
    charts: state.charts.charts,
    series: state.chartSeries.series,
    formData: select.charts.selectedChartFormData(state),
    chartData: state.charts.chartData,
    match: ownProps.match,
    timezone: state.timezone.selectedZone,
    isLoading:
      state.loading.effects.charts.fetchChartById ||
      state.loading.effects.charts.updateChart,
  };
};

const mapDispatch = (dispatch: RootDispatch) => ({
  fetchSeries: dispatch.chartSeries.fetchChartSeries,
  setSeries: dispatch.chartSeries.set,
  fetchChartById: dispatch.charts.fetchChartById,
  updateChart: dispatch.charts.updateChart,
  fetchSites: dispatch.siteEquipment.fetchSites,
  fetchMetrics: dispatch.charts.fetchMetrics,
});

export default connect(mapState, mapDispatch)(ChartDetailsContainer);
