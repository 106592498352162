import React from 'react';
import PredictiveChartComponent from './predictive-chart';
import { GroupedSeries } from 'src/store/api/charts.api';

interface OwnProps {
  data: GroupedSeries[];
  minX: number;
  maxX: number;
  isPredictiveChartLoading: boolean;
}

export const PredictiveChart: React.FC<OwnProps> = (props) => {
  const { data, minX, maxX, isPredictiveChartLoading } = props;

  return (
    <PredictiveChartComponent
      title="Failure Probability"
      data={data}
      seriesValuesKey="Failure probability"
      seriesStatusesKey="Status"
      minX={minX}
      maxX={maxX}
      isPredictiveChartLoading={isPredictiveChartLoading}
    />
  );
};
