import round from 'lodash/round';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { getShiftedDateFromISO } from 'src/services/timezone.helper';

import { GroupedSeries, SeriesMetadata } from 'src/store/api/charts.api';
import { EntityType } from 'src/types';

export interface GetColor {
  (
    index: number,
    seriesName?: string,
    deviceType?: EntityType,
    deviceId?: number,
    siteId?: number
  ): Color;
}

export type Color = {
  color: string;
  type?: string;
};

export type DataPoint = {
  name: string;
  deviceType: EntityType;
  metadata?: SeriesMetadata;
  value: [string, number];
};

export type SingleTransformedLineSeries = {
  seriesName: string;
  uid: string;
  deviceId?: number;
  siteId?: number;
  deviceType?: EntityType;
  color?: Color;
  metadata?: SeriesMetadata;
  data: DataPoint[];
};

export type TransformedLineSeries = SingleTransformedLineSeries[];

const shiftTimestamps = (ts: string[], offset: number): string[] =>
  ts.map((time) => getShiftedDateFromISO(time, offset).toISOString());

const makeLineSeries = (
  deviceType: EntityType,
  deviceId: number | undefined,
  siteId: number | undefined,
  seriesName: string, // metric/series name: rpm, t2, etc.

  values: number[],
  timestamps: string[],
  index: number,
  getColor?: GetColor,
  metadata?: SeriesMetadata // device identifiers (tool name, station name, etc.) - depends on device/series type
): SingleTransformedLineSeries => {
  // unique series identifier (tooltip coloring require series name to be unique)
  const uid = `${deviceId}__${siteId}__${seriesName}`;

  return {
    seriesName,
    uid,
    deviceId,
    siteId,
    deviceType,
    color: getColor && getColor(index, seriesName, deviceType, deviceId, siteId),
    metadata,
    data: values.map((value: number, index: number) => ({
      // ["2020-06-03T06:18:51.937Z", 100]
      value: [
        timestamps[index], // x
        round(value, 1), // y
      ],
      name: seriesName,
      deviceType,
      metadata,
    })),
  };
};

export const transformLineSeries = (
  groupedSeries: GroupedSeries[],
  timeZoneOffset: number,
  getColor: GetColor
) => {
  groupedSeries = groupedSeries || [];
  const transformedSeries: TransformedLineSeries = [];

  // iterate through series grouped by device type and/or specific metrics with the same timestamps
  // all series inside one group has same timestamps
  groupedSeries.forEach(
    ({ series, timestamps, entity_type, entity_id, site_id, metadata }) => {
      // shift time zone to currently selected timezone
      const shiftedTimestamps = shiftTimestamps(timestamps, timeZoneOffset);

      // like rpm, t1, t2, etc.
      const seriesNames = Object.keys(series);

      seriesNames.forEach((seriesName) => {
        const seriesValues = series[seriesName];

        transformedSeries.push(
          makeLineSeries(
            entity_type,
            entity_id,
            site_id,
            seriesName,
            seriesValues,
            shiftedTimestamps,
            transformedSeries.length,
            getColor,
            metadata
          )
        );
      });
    }
  );

  return transformedSeries;
};

const compareStrings = (a: string, b: string) => a.localeCompare(b);

// array of used metrics inside chart (t1, t2, etc.)
export const groupSeriesByMetrics = (items: TransformedLineSeries) => {
  return uniqBy(items, 'seriesName')
    .map(({ seriesName }) => ({ name: seriesName, key: seriesName }))
    .sort((a, b) => compareStrings(a.name, b.name));
};

const buildDeviceName = ({ deviceType, metadata }: SingleTransformedLineSeries) => {
  switch (deviceType) {
    case EntityType.Pump:
      return [metadata?.tool_name, metadata?.station_name];
    case EntityType.Tool:
      return [metadata?.tool_name];
    case EntityType.Compressor:
      return [metadata?.tool_name, metadata?.compressor_name];
    default:
      return [];
  }
};

const getDeviceName = (series: SingleTransformedLineSeries) => {
  const arr = buildDeviceName(series).filter(Boolean);
  return arr.length ? arr.join(' - ') : 'All';
};

// device specific legend
export const groupSeriesByDevices = (items: TransformedLineSeries) => {
  // group by device type (compressor, controller, pump)
  const byDeviceType = groupBy(items, 'deviceType');

  return Object.entries(byDeviceType).flatMap(([type, series]) => {
    // group same device type by device id
    const byDevice = Object.values(
      groupBy(series, (item) => `${item.siteId}${item.deviceId}`)
    );

    return orderBy(byDevice, [(device) => getDeviceName(device[0])], ['asc']).map(
      (items) => {
        const device = items[0];

        // make item for specific device + all it's metrics
        return {
          name: getDeviceName(device),
          key: getDeviceName(device),
          metadata: device.metadata,

          siteId: device.siteId,
          deviceId: device.deviceId,

          metrics: items
            .map((metric) => ({
              name: metric.seriesName,
              key: metric.uid,
              metadata: metric.metadata,
              color: metric.color,
            }))
            .sort((a, b) => compareStrings(a.name, b.name)),
        };
      }
    );
  });
};

export const getUniqueIdentifers = (dataSeries: TransformedLineSeries) => {
  return dataSeries.map((item) => item.uid).sort(compareStrings);
};
