import React from 'react';
import { FiberManualRecord, FiberManualRecordOutlined, Help } from '@material-ui/icons';
import { useStyles } from './online-offline-status.styles';

interface Props {
  status: string;
}

// eslint-disable-next-line no-warning-comments
// Todo: 2 online-status components exists, refactor to leave only one
export const OnlineOfflineStatus: React.FC<Props> = (props) => {
  let content: React.ReactElement | null = null;
  const styles = useStyles();

  switch (props.status) {
    case 'offline':
      content = <FiberManualRecordOutlined fontSize="inherit" />;
      break;

    case 'online':
      content = <FiberManualRecord fontSize="inherit" />;
      break;

    case 'warning':
      content = <FiberManualRecord fontSize="inherit" className={styles.warning} />;
      break;

    default:
      content = (
        <Help fontSize="inherit" viewBox="-1 -1 26 26" className={styles.unknown} />
      );
      break;
  }

  return <span className={styles.root}>{content}</span>;
};
