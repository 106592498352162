import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      label: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        lineHeight: '0.875rem',
      },
      arrow: {
        marginLeft: '0.125rem',
        display: 'none',
        transform: 'rotate(0deg)',
      },
      arrowUp: {
        display: 'block',
      },
      arrowDown: {
        display: 'block',
        transform: 'rotate(180deg)',
      },
      textCenter: {
        textAlign: 'center',
      },
      disabled: {
        color: COLORS.grey['500'],
      },
    })
);
