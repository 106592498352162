import { axiosInstance } from '.';

export type UserPayload = {
  id?: number;
  user: {
    firstName?: string;
    lastName?: string;
    password?: string;
    currentPassword?: string;
    role?: string;
    email?: string;
  };
  onSuccess?: () => void;
};

export enum Roles {
  admin = 'admin',
  cse = 'cse',
}

export type User = {
  createdAt: string;
  currentSignInAt: string;
  currentSignInIp: string;
  deactivated: boolean;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  passwordChangeRequired: boolean;
  signInCount: number;
  role: Roles;
  roleLabel?: Roles;
};

type Error = {
  error: string;
  value?: string;
  count?: number;
};
export type UserErrors = {
  email?: Error[];
  password?: Error[];
  firstName?: Error[];
  lastName?: Error[];
};

export const usersAPI = {
  get: () => axiosInstance.get<User[]>('/users'),
  addUser: (payload: UserPayload) => axiosInstance.post<User>('/users', payload),
  editUser: (id: number | null, payload: UserPayload) =>
    axiosInstance.patch<User>(`/users/${id}`, payload),
  deactivateUser: (id: number) => axiosInstance.delete<User>(`/users/${id}`),
  activateUser: (id: number) => axiosInstance.post<User>(`/users/${id}/restore`),
};
