import { ICellRendererParams } from 'ag-grid-community';
import React, { useMemo, useState } from 'react';
import isNull from 'lodash/isNull';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';
import SubscribedIcon from '@material-ui/icons/NotificationsActive';
import UnsubscribedIcon from '@material-ui/icons/NotificationsOff';
import InheritedIcon from '@material-ui/icons/ArrowUpward';
import { SubscriptionLevel } from 'src/store/api/notifications.api';
import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import {
  COMPRESSOR,
  CONTROLLER,
  PUMP,
  SITE,
} from 'src/shared/routing/with-selected-equipment';

const iconStyles = {
  width: '1rem',
  height: '0.8rem',
};

const unsubscribedStyles = {
  ...iconStyles,
  color: 'red',
};

const subscribedStyles = {
  ...iconStyles,
  color: 'green',
};

const subscribedIcon = <SubscribedIcon style={subscribedStyles} />;
const unsubscribedIcon = <UnsubscribedIcon style={unsubscribedStyles} />;
const inheritedIcon = <InheritedIcon style={iconStyles} />;

type SubscribeStatus = 'Subscribed' | 'Unsubscribed' | 'Inherited';

const getSubscribeStatus = (
  value: boolean | null,
  subscriptionLevel?: SubscriptionLevel,
  currentLevel?: string
) => {
  if ((isNull(value) || currentLevel !== subscriptionLevel) && currentLevel !== SITE) {
    return 'Inherited';
  }
  if (!value) return 'Unsubscribed';
  return 'Subscribed';
};

const getValue = (status: SubscribeStatus) => {
  if (status === 'Inherited') return null;
  if (status === 'Unsubscribed') return false;
  return true;
};

const getOptionIcon = (status: SubscribeStatus) => {
  if (status === 'Inherited') return inheritedIcon;
  if (status === 'Unsubscribed') return unsubscribedIcon;
  return subscribedIcon;
};

const getSelectedIcon = (subscriptionLevel: SubscriptionLevel, value: boolean | null) => {
  if (isNull(subscriptionLevel) || !value) return unsubscribedIcon;
  return subscribedIcon;
};

const getTooltip = (subscriptionLevel: SubscriptionLevel, currentLevel: string) => {
  if (subscriptionLevel === currentLevel) return '';

  if (isNull(subscriptionLevel)) return 'Inherited from default';

  return `Inherited from ${subscriptionLevel}`;
};

export const SubscriptionRenderer = (props: ICellRendererParams) => {
  const classes = useStyles();
  const { type } = useEquipmentIdentifiers();

  const {
    value: subscription,
    setValue,
    data: { subscriptionLevel },
  } = props;

  const currentLevel = useMemo(() => {
    const isEquipment = type === PUMP || type === COMPRESSOR || type === CONTROLLER;
    if (isEquipment) return 'equipment';
    return type;
  }, [type]);

  const optionValues = useMemo(
    () => (type === SITE ? [true, false] : [true, false, null]),
    [type]
  );

  const [selectedStatus, setSelectedStatus] = useState<SubscribeStatus>(
    getSubscribeStatus(subscription, subscriptionLevel, currentLevel)
  );
  const [selectedIcon, setSelectedIcon] = useState(
    getSelectedIcon(subscriptionLevel, subscription)
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const subscribeStatus = event.target.value as SubscribeStatus;

    //change subscription level, so we can watching subscription status
    if (subscriptionLevel !== currentLevel) {
      props.node.setData({ ...props.data, subscriptionLevel: currentLevel });
    }

    setSelectedStatus(subscribeStatus);
    setSelectedIcon(getOptionIcon(subscribeStatus));

    //dataset mutation
    setValue(getValue(subscribeStatus));
  };

  const options = optionValues.map((value, index) => {
    const status = getSubscribeStatus(value);

    const isEqualStatus = status === selectedStatus;

    return (
      <MenuItem key={index} value={status} disabled={isEqualStatus}>
        {getOptionIcon(status)}
        {status}
      </MenuItem>
    );
  });

  return (
    <FormControl className={classes.select}>
      <InputLabel shrink={false}>
        {selectedIcon}
        {selectedStatus}
      </InputLabel>
      {/* empty value to hide displayed selected value (input label overlaps select) */}
      <Select
        title={getTooltip(subscriptionLevel, currentLevel)}
        value=""
        onChange={handleChange}>
        {options}
      </Select>
    </FormControl>
  );
};
