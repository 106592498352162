import React, { FC } from 'react';
import OnlineOfflineStatus from 'src/shared/online-offline-status';
import { useStyles } from '../styles';

export interface Props {
  status: 'online' | 'offline' | 'warning';
}

const NodeOnlineStatus: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.col}>
      <OnlineOfflineStatus status={props.status} />
    </div>
  );
};
export default NodeOnlineStatus;
