import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootDispatch, RootState } from 'src/store';
import { CompressorRow } from 'src/store/api/compressors.api';
import { DetailsPage } from '../../details-page';

const getData = (compressor: CompressorRow | null) => {
  if (!compressor) {
    return;
  }

  return [
    {
      label: 'Age in hours',
      value: compressor.ageInHours,
    },
    {
      label: 'Current time',
      value: compressor.current,
    },
    {
      label: 'Differential pressure',
      value: compressor.differentialPressure,
    },
    {
      label: 'Total helium available',
      value: compressor.heliumTotalAvailable,
    },
    {
      label: 'Total helium consumption',
      value: compressor.heliumTotalConsumption,
    },
    {
      label: 'Line frequency',
      value: compressor.lineFrequency,
    },
    {
      label: 'Line voltage',
      value: compressor.lineVoltage,
    },
    {
      label: 'Model',
      value: compressor.model,
    },
    {
      label: 'Module part number',
      value: compressor.modulePartNumber,
    },
    {
      label: 'Module serial number',
      value: compressor.moduleSerialNumber,
    },
    {
      label: 'Motor status',
      value: compressor.motorStatus,
    },
    {
      label: 'Power',
      value: compressor.power,
    },
    {
      label: 'Return pressure',
      value: compressor.returnPressure,
    },
    {
      label: 'Software version',
      value: compressor.softwareVersion,
    },
    {
      label: 'Static pressure',
      value: compressor.staticPressure,
    },
    {
      label: 'Supply pressure',
      value: compressor.supplyPressure,
    },
    {
      label: 'Tool name',
      value: compressor.toolName,
    },
    {
      label: 'Water flow',
      value: compressor.waterFlow,
    },
    {
      label: 'Water in',
      value: compressor.waterIn,
    },
    {
      label: 'Water out',
      value: compressor.waterOut,
    },
  ];
};

export const CompressorDetails: React.FC = () => {
  const dispatch = useDispatch<RootDispatch>();
  const location = useLocation();

  const compressor = useSelector((state: RootState) => state.compressors.current);

  const siteName = useSelector((state: RootState) => {
    return state.siteEquipment.sites.find((site) => compressor?.siteId === site.id)?.name;
  });

  useEffect(() => {
    dispatch.compressors.fetchCompressor();
  }, [dispatch.compressors, location]);

  const compressorData = getData(compressor);

  return (
    <DetailsPage
      stationDataRows={compressorData}
      siteName={siteName}
      toolName={compressor?.toolName}
      equipmentName={`Compressor/${compressor?.id}`}
    />
  );
};
