import React, { ChangeEvent, SyntheticEvent } from 'react';
import { TextField, WithStyles } from '@material-ui/core';
import Button from 'src/shared/button';
import styles from './login-form.styles';
import { PassField } from 'src/shared/password-fields';

interface OwnProps extends WithStyles<typeof styles> {
  onSubmit: (formData: LoginFields) => void;
  error: boolean;
  loading: boolean;
}

export interface LoginFields {
  email: string;
  password: string;
}

type State = {
  passwordShown: boolean;
} & LoginFields;

export default class LoginForm extends React.Component<OwnProps, State> {
  public state: State = {
    email: '',
    password: '',
    passwordShown: false,
  };

  private onFormSubmit = (event: SyntheticEvent): void => {
    const { email, password } = this.state;
    const { onSubmit } = this.props;

    event.preventDefault();
    onSubmit({ email, password });
  };

  private handleLoginChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ email: event.target.value });
  };

  private handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ password: event.target.value });
  };

  render() {
    const { email, password } = this.state;
    const { error, loading } = this.props;

    return (
      <form className={this.props.classes.loginForm} onSubmit={this.onFormSubmit}>
        <TextField
          className={this.props.classes.textField}
          fullWidth
          label="Login"
          name="email"
          onChange={this.handleLoginChange}
          type="text"
          value={email}
          error={error}
          InputLabelProps={{ classes: { root: this.props.classes.inputLabel } }}
        />
        <PassField
          name="password"
          label="Password"
          fullWidth
          className={this.props.classes.textField}
          value={password}
          onChange={this.handlePasswordChange}
          error={error}
        />
        <Button disabled={Boolean(loading)} type="submit">
          LOG IN
        </Button>
      </form>
    );
  }
}
