import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      input: {
        marginTop: '16px',
      },
      axisContainer: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      axis: {
        width: 'calc(50% - 8px)',
        '& > span': {
          display: 'block',
          marginTop: '16px',
          fontSize: '0.875rem',
          fontWeight: 500,
        },
      },
      axisHeader: {
        display: 'flex',
      },
      axisTitleInput: {
        flex: '3',
      },
      axisMinMaxInput: {
        flex: '1',
        marginLeft: '16px',
      },
    })
);
