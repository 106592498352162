import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles({
  root: {
    padding: '1rem',
  },
  footer: {
    width: '100%',
    padding: '1rem',
    textAlign: 'right',
    borderTop: `1px solid ${COLORS.grey[300]}`,
  },
});
