import React, { ElementType, FC, ReactEventHandler, ReactNode, memo } from 'react';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { Box, WithStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './styles';

export interface Props extends WithStyles<typeof styles> {
  children: ReactNode;
  prefixIcon?: ReactNode;
  busy?: boolean;
  disabled?: boolean;
  onClick?: React.ReactEventHandler;
  color?: 'primary' | 'secondary';
  type?: 'button' | 'submit';
  size?: 'small' | 'medium' | 'large';
  form?: string;
  component?: string;
  componentProps?: Record<string, string | number>;
}

const componentsMap: Record<string, ElementType> = {
  link: Link,
};

const Button: FC<Props> = (props): JSX.Element => {
  const {
    children,
    busy,
    disabled,
    size = 'medium',
    type = 'button',
    color = 'primary',
    onClick,
    prefixIcon,
    form,
    component = 'button',
    componentProps,
    classes,
  } = props;
  const handleClick: ReactEventHandler = (event) => onClick && onClick(event);

  return (
    <MuiButton
      {...componentProps}
      component={componentsMap[component]}
      form={form}
      onClick={handleClick}
      className={clsx(classes.button, {
        [classes[`${color}`]]: color,
        [classes.disabled]: disabled || busy,
      })}
      disableElevation
      type={type}
      variant="contained"
      size={size}
      disabled={disabled || busy}
      color={color}>
      {busy && <CircularProgress className={classes.busy} size={20} color="inherit" />}
      <span
        className={clsx(classes.content, {
          [classes.hidden]: busy,
        })}>
        {!!prefixIcon && (
          <Box mr={1} component="span" display="inherit">
            {prefixIcon}
          </Box>
        )}

        {children}
      </span>
    </MuiButton>
  );
};

export default memo(Button);
