import React, { FC } from 'react';
import { useStyles } from 'src/app/charts/chart-form/chart-form.styles';
import { Controller, FormContextValues } from 'react-hook-form';
import {
  AutocompleteOption,
  AutocompleteWithInfiniteLoading,
} from 'src/shared/autocompletes-wtih-chips';
import { ResourceField, createLabel } from 'src/app/charts/chart-form/utils';
import { DefaultChartValues } from '../../chart-form';
import { pumpsApi } from 'src/store/api/pumps.api';
import { compressorsApi } from 'src/store/api/compressors.api';
import { toolsApi } from 'src/store/api/tools.api';

export interface Props {
  entityType: 'Tool' | 'Compressor' | 'Pump';
  formContext: FormContextValues<DefaultChartValues>;
  filters: {
    siteId: number[];
    toolId: number[];
  };
  handleValidateResources: (
    field: ResourceField,
    otherResourcesFields: string[]
  ) => (value: AutocompleteOption[]) => boolean;
  handleChangeResources: (
    field: ResourceField,
    otherResourcesFields: string[]
  ) => ([value]: any[]) => AutocompleteOption[];
}

const ChartFormResourceControllers: FC<Props> = (props: Props) => {
  const styles = useStyles();
  const {
    handleValidateResources,
    handleChangeResources,
    formContext,
    entityType,
    filters,
  } = props;
  const { watch, control, errors } = formContext;
  const entities = watch('entities');

  //defaultParams assume Tool to be the default selection
  let controllerParams = {
    curEntity: 'entities.tools' as ResourceField,
    otherEntities: ['entities.pumps', 'entities.compressors'] as ResourceField[],
    labelParam: entities?.tools.length,
    errorParam: !!errors.entities?.tools,
    controllerOnFetch: toolsApi.fetchBySearchTerm,
    controllerExtraFetch: { siteId: filters.siteId },
  };
  if (entityType === 'Pump') {
    controllerParams = {
      curEntity: 'entities.pumps',
      otherEntities: ['entities.tools', 'entities.compressors'],
      labelParam: entities?.pumps.length,
      errorParam: !!errors.entities?.pumps,
      controllerOnFetch: pumpsApi.fetchBySearchTerm,
      controllerExtraFetch: filters,
    };
  }
  if (entityType === 'Compressor') {
    controllerParams = {
      curEntity: 'entities.compressors',
      otherEntities: ['entities.tools', 'entities.pumps'],
      labelParam: entities?.compressors.length,
      errorParam: !!errors.entities?.compressors,
      controllerOnFetch: compressorsApi.fetchBySearchTerm,
      controllerExtraFetch: filters,
    };
  }

  const {
    curEntity,
    otherEntities,
    labelParam,
    errorParam,
    controllerOnFetch,
    controllerExtraFetch,
  } = controllerParams;

  return (
    <Controller
      as={AutocompleteWithInfiniteLoading}
      name={curEntity}
      control={control}
      onChange={handleChangeResources(curEntity, otherEntities)}
      rules={{
        validate: handleValidateResources(curEntity, otherEntities),
      }}
      className={styles.input}
      label={createLabel(entityType, labelParam)}
      error={errorParam}
      onFetchMore={controllerOnFetch}
      extraFetchParams={controllerExtraFetch}
      limitTags={5}
    />
  );
};
export default ChartFormResourceControllers;
