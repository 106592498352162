import React from 'react';
import get from 'lodash/get';
import BaseGrid from 'src/shared/base-grid';
import { ColData, StyledColDef } from 'src/shared/base-grid/base-grid';
import { createColumn } from 'src/shared/base-grid/column-creator';
import {
  ColumnApi,
  ColumnMovedEvent,
  DragStartedEvent,
  GridApi,
  GridReadyEvent,
} from 'ag-grid-community';
import TaskHook from './../../../shared/task-hook/TaskHook';
import { ColumnResp } from 'src/store/api/views.api';
import isEqual from 'lodash/isEqual';
import { FetchByParams } from 'src/store/api/pumps.api';

interface Props {
  displayAllRows?: boolean;
  columns: ColData[];
  fetchRows: (params: FetchByParams, meta: void) => Promise<void>;
  view: ColumnResp | null;
  updateColumns: (columns: ColData[]) => void;
  updateView: (columns: ColData[], meta: void) => Promise<void>;
  predictionStatuses: string[];
}

interface State {
  queryParams: FetchByParams | null;
  colData: StyledColDef[];
}

class MaintenanceDashboardGrid extends React.Component<Props, State> {
  private columnApi: ColumnApi | null = null;
  private gridApi: GridApi | null = null;

  public state: State;

  constructor(props: Props) {
    super(props);

    const colData: StyledColDef[] = props.columns.map((el) =>
      createColumn(el, {
        headerComponentParams: {
          updateSort: this.updateSort,
        },
      })
    );
    this.state = {
      queryParams: null,
      colData,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    const { predictionStatuses } = this.props;
    const { queryParams } = this.state;

    if (queryParams && !isEqual(prevProps.predictionStatuses, predictionStatuses)) {
      // reset datasource to trigger data fetching
      this.gridApi?.setDatasource({ getRows: this.handleFetchRows });
    }
  }

  private handleFetchRows = (query: FetchByParams) => {
    const { predictionStatuses } = this.props;

    this.setState({ queryParams: query });

    this.props.fetchRows({
      ...query,
      statuses: predictionStatuses,
    });
  };

  private updateVisibility = () => {
    const { view, columns } = this.props;

    if (this.columnApi && view) {
      columns.forEach((el) => this.columnApi?.setColumnVisible(el.name, el.visibility));
    }
  };

  private updateSort = (sortedColumns: { colId: string; sort: string }[]) => {
    const { view, updateView } = this.props;

    // update sort direction inside each column
    // only one column should have sort direction set
    if (view && view.columns) {
      const columns: ColData[] = view.columns.map((column) => {
        const sortedColumn = sortedColumns.find(({ colId }) => colId === column.name);
        return {
          ...column,
          sortDirection: get(sortedColumn, 'sort', null),
        };
      });
      updateView(columns);
    }
  };

  private onGridReady = (ev: GridReadyEvent) => {
    this.columnApi = ev.columnApi;
    this.gridApi = ev.api;
  };

  private updateColumnPositions = (ev: ColumnMovedEvent) => {
    const { view, updateColumns } = this.props;
    const { toIndex, column } = ev;

    if (view && toIndex && column) {
      const cols = [...view.columns];

      const prevPos = cols.findIndex((col) => col.name === column.getColId());
      const nextPos = toIndex - 1;

      const target = cols.splice(prevPos, 1);

      const outArr = [...cols.slice(0, nextPos), ...target, ...cols.slice(nextPos)];

      updateColumns(outArr);
    }
  };

  private onDragStopped = (ev: DragStartedEvent) => {
    const { view, updateView } = this.props;
    const gridColumns = [...ev.columnApi.getColumnState()];

    const actions = gridColumns.splice(
      gridColumns.findIndex((el) => el.colId === 'actions'),
      1
    );
    gridColumns.push(...actions);

    if (view) {
      const nextCols = [...view.columns];

      gridColumns.forEach((el, i) => {
        const target = nextCols.findIndex((col) => col.name === el.colId);

        const targetCol = { ...nextCols[target] };

        targetCol.index = i;

        nextCols[target] = targetCol;
      });

      updateView(nextCols);
    }
  };

  render() {
    const { columns, displayAllRows = false } = this.props;
    const { colData } = this.state;
    const visibilityKey = columns.map((el) => Number(el.visibility)).join('');

    return (
      <React.Fragment>
        <TaskHook onInit={this.updateVisibility} key={visibilityKey} />
        <BaseGrid
          context={this.context}
          displayAllRows={displayAllRows}
          autoSizeColumns={false}
          colData={colData}
          fetchRows={this.handleFetchRows}
          onGridReady={this.onGridReady}
          onColumnMoved={this.updateColumnPositions}
          onDragStopped={this.onDragStopped}
        />
      </React.Fragment>
    );
  }
}

export default MaintenanceDashboardGrid;
