import { RangeOverriddenValues } from './../store/api/notifications.api';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { TabSubtype } from 'src/app/notification/notification-ranges';
import { ScopedQueryParams, getScopedQuery } from 'src/shared/routing/routing.helper';
import { COMPRESSOR, CONTROLLER, PUMP } from 'src/shared/routing/with-selected-equipment';
import {
  DeviceSubtype,
  Range,
  RangePayload,
  RangesResponse,
  devices,
} from 'src/store/api/notifications.api';

export const getSubtype = (tabSubtype?: TabSubtype): DeviceSubtype | undefined => {
  if (tabSubtype) return devices[tabSubtype];
};

const getEquipmentType = () => {
  const { pumpId, controllerId, compressorId }: ScopedQueryParams = getScopedQuery();

  if (pumpId) return PUMP;
  if (controllerId) return CONTROLLER;
  if (compressorId) return COMPRESSOR;
};

export function getEquipmentSubtypeOrType(subtype?: DeviceSubtype) {
  if (subtype) return { equipment_subtype: subtype };
  if (getEquipmentType()) return { equipment_type: getEquipmentType() };
  throw new Error(
    'equipmentSubtype or equipmentType should be set but none of them are present.'
  );
}

export const generatePayload = (range: RangeOverriddenValues) => {
  //some of values might be undefined, because we dont know what user actually changed
  const { name, ...overriddenValues } = range;

  //get only actual values
  const existingValues = omitBy(overriddenValues, isUndefined);

  if (!name || isEmpty(existingValues)) return {};

  return {
    [name]: {
      ...existingValues,
    },
  };
};

export const getMappedPayload = (
  subtype: DeviceSubtype,
  ranges: RangeOverriddenValues[]
) => {
  return {
    [subtype]: ranges.reduce((acc, range) => {
      return { ...acc, ...generatePayload(range) };
    }, {}),
  };
};

export const isValueEmpty = (value: string | null) => !value;

export const getRangesValue = (value: string | null) =>
  isValueEmpty(value) ? null : Number(value);

export const extractOverriddenValues = (range: Range) => ({
  name: range.name,
  min_value: getRangesValue(range.notificationMinimum),
  max_value: getRangesValue(range.notificationMaximum),
  subscription: range.subscription,
  subscription_level: range.subscriptionLevel,
});

//* Leaves only name and values that user might change
export const getRangesOverriddenValues = (ranges: Range[]) =>
  ranges.map((range) => extractOverriddenValues(range));

const getDifferences = (
  oldRange: RangeOverriddenValues,
  newRange: RangeOverriddenValues
) => {
  return Object.entries(newRange).reduce(
    (acc, [key, value]) => {
      const rangesKey = key as keyof RangeOverriddenValues;
      const valueDiffers = !isEqual(value, oldRange[rangesKey]);

      //if subscription level is changed - change subscription status
      if (valueDiffers && key === 'subscription_level') {
        return { ...acc, subscription: newRange.subscription };
      }

      if (valueDiffers) {
        return { ...acc, [key]: value };
      }

      return acc;
    },
    { name: newRange.name }
  );
};

/**
 * Returns ranges payloads with name and changed values only
 * @output [{'T1', [payload_key]?: value}, {'T2', [payload_key]?: value},, ...]
 */
export const getRangesOverriddenPayload = (
  oldRanges: RangeOverriddenValues[],
  newRanges: RangeOverriddenValues[]
) => {
  return newRanges.reduce((acc, newRange, index) => {
    return [...acc, getDifferences(oldRanges[index], newRange)];
  }, [] as RangeOverriddenValues[]);
};

export const isEmptyOverriddenPayload = (ranges: RangeOverriddenValues[]) =>
  isEmpty(ranges.filter(({ name, ...values }) => !isEmpty(values)));

/**
 * Extract ranges array from nested map
 * @input {controller: {T1: {...}, T2: {..}, ...}, compressor: {...}, ...}
 * @output [['T1', {...}], ['T2', {...}], ...]
 */
export const extractRanges = (data: RangesResponse) => {
  if (typeof data === 'object') {
    const rangesByEquipmentSubtype = Object.entries(data);
    if (rangesByEquipmentSubtype.length === 1) {
      const [subtype, rangesWithNameAsKey] = rangesByEquipmentSubtype[0];
      return { subtype, ranges: Object.entries(rangesWithNameAsKey) };
    }
  }

  throw new Error('Notification ranges in  unexpected format.');
};

export const rangesResponseToRow = (name: string, rangePayload: RangePayload): Range => {
  const { min_value, max_value } = rangePayload;
  return {
    name,
    inheritedMinValue: rangePayload.inherited_min_value,
    inheritedMaxValue: rangePayload.inherited_max_value,
    notificationMinimum: isNull(min_value) ? null : min_value.toString(),
    notificationMaximum: isNull(max_value) ? null : max_value.toString(),
    level: rangePayload.level,
    updatedAt: rangePayload.updated_at,
    updatedBy: rangePayload.updated_by,
    subscription: rangePayload.subscription,
    subscriptionLevel: rangePayload.subscription_level,
  };
};

export const getRangesWithSubtype = (data: RangesResponse) => {
  // parsing ranges response, either way we have only one grouped entity
  // (grouped by equipment_type or equipment_subtype)
  const { subtype, ranges } = extractRanges(data);

  return {
    subtype,
    ranges: ranges.map(([name, range]) => rangesResponseToRow(name, range)),
  };
};
