import React, { FC } from 'react';
import MuiTableCell, { TableCellProps } from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from 'src/app/machine-learning/performance/confusion-matrix/confusion-matrix.styles';
import clsx from 'clsx';

interface ConcreteCell extends TableCellProps {
  label: string | number;
  tooltip: string;
}

export const TableCell: FC<TableCellProps> = (props) => {
  const classes = useStyles();

  return (
    <MuiTableCell
      {...props}
      classes={{
        ...props.classes,
        root: clsx(props.classes?.root, classes.base, classes.td),
      }}
    />
  );
};

export const HeaderCell = ({ label, tooltip, ...cellProps }: ConcreteCell) => (
  <TableCell {...cellProps}>
    <Tooltip title={tooltip}>
      <div>
        <b>{label}</b>
      </div>
    </Tooltip>
  </TableCell>
);

export const NumericCell = ({ label, tooltip, ...cellProps }: ConcreteCell) => (
  <TableCell {...cellProps}>
    <Tooltip title={tooltip}>
      <span>{label}</span>
    </Tooltip>
  </TableCell>
);
