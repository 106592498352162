import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import DateRangePicker from './date-range-picker';
import Button from '../button';
import ToggleButtonGroup, { ToggleGroupItem } from '../toggle-button-group';
import { Box } from '@material-ui/core';
import { getDefaultFilters, getDefaultRange } from './filters-config';
import { useStyles } from './styles';
import { isAfter } from 'date-fns';

interface Props {
  children?: ReactNode;
  startDate?: Date;
  endDate?: Date;
  onChange?: (startDate: Date, endDate: Date) => void;
  quickFilters?: ToggleGroupItem[];
  isLoading?: boolean;
  datepickerInputStyle?: React.CSSProperties;
}

const DateRangeFilter: FC<Props> = (props) => {
  const [defaultStartDate, defaultEndDate] = getDefaultRange();
  const styles = useStyles();

  const {
    startDate = defaultStartDate,
    endDate = defaultEndDate,
    onChange,
    quickFilters = getDefaultFilters(),
    isLoading,
    children,
    datepickerInputStyle,
  } = props;

  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [dates, setDates] = useState<[Date, Date]>([startDate, endDate]);
  const [filters, setFilters] = useState(quickFilters);

  const handleClick = () => {
    onChange && onChange(...dates);
    setIsDisabled(true);
  };

  const maxFilter = useMemo(() => quickFilters[quickFilters.length - 1], [quickFilters]);

  const checkErrors = useCallback(
    (start: Date, end: Date) => {
      setIsDisabled(false);
      setError(null);

      //calculate minimal possible date considering quick filters
      const minDate = maxFilter.calculateMinDate(end);
      minDate.setHours(0, 0, 0, 0);

      if (isAfter(start, end)) {
        setError('Start date should be before End date.');
        setIsDisabled(true);
      }

      if (isAfter(minDate, start)) {
        setError(`Difference between dates should not exceed ${maxFilter.label}.`);
        setIsDisabled(true);
      }
    },
    [maxFilter]
  );

  const handleDateChange = (start: Date, end: Date) => {
    setDates([start, end]);

    checkErrors(start, end);

    const unselected = filters.map((entry) => ({ ...entry, isSelected: false }));
    setFilters(unselected);
  };

  const handleToggleChange = (selectedOptions: ToggleGroupItem[]) => {
    const selectedOption = selectedOptions.find(({ isSelected }) => isSelected);

    setFilters(selectedOptions);

    if (!selectedOption) return;

    const newDates: [Date, Date] = [new Date(selectedOption.value), defaultEndDate];

    checkErrors(...newDates);
    setIsDisabled(true);

    setDates(newDates);
    onChange && onChange(...newDates);
  };

  const handleError = () => setIsDisabled(true);

  return (
    <Box className={styles.root}>
      <Box className={styles.datePickerBox}>
        <DateRangePicker
          startDate={dates[0]}
          endDate={dates[1]}
          onChange={handleDateChange}
          datepickerInputStyle={datepickerInputStyle}
          error={error}
          onError={handleError}
        />
        <Box px={2}>
          <Button
            onClick={handleClick}
            busy={isLoading}
            disabled={isDisabled}
            size="small">
            Apply
          </Button>
          {children}
        </Box>
      </Box>
      <Box mt={2}>
        <ToggleButtonGroup values={filters} onChange={handleToggleChange} />
      </Box>
    </Box>
  );
};

export default DateRangeFilter;
