import React from 'react';

interface OwnProps {
  onInit: () => void;
}

class TaskHook extends React.Component<OwnProps> {
  componentDidMount() {
    this.props.onInit();
  }

  render() {
    return null;
  }
}

export default TaskHook;
