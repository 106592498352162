import React, { forwardRef } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  fontSize?: 'inherit' | 'default' | 'small' | 'large';
}

const UpdatePasswordIcon = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { className, fontSize = 'default', ...other } = props;
  return (
    <SvgIcon
      {...other}
      ref={ref}
      className={className}
      color="inherit"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      fontSize={fontSize}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 8h1c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H6c-1.1 0-2-.9-2-2V10c0-1.1.9-2
          2-2h1V6c0-2.8 2.2-5 5-5s5 2.2 5 5v2zM8.9 6v2h6.2V6c0-1.7-1.4-3.1-3.1-3.1-1.7
          0-3.1 1.4-3.1 3.1zm4.736 9l2.182-2.25L18 15h-1.636c0 2.486-1.953 4.5-4.364
          4.5a4.23 4.23 0 01-2.324-.698l.797-.82c.452.252.976.393 1.527.393 1.806 0
          3.273-1.513 3.273-3.375h-1.637zM12 11.625c-1.806 0-3.273 1.513-3.273
          3.375h1.637l-2.182 2.25L6 15h1.636c0-2.486 1.953-4.5 4.364-4.5a4.23 4.23
          0 012.324.697l-.797.822A3.125 3.125 0 0012 11.625z"
      />
    </SvgIcon>
  );
});

UpdatePasswordIcon.displayName = 'UpdatePasswordIcon';

export default UpdatePasswordIcon;
