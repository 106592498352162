import React, { ChangeEvent, FC, memo, useCallback, useMemo } from 'react';
import { Radio, Table, TableContainer } from '@material-ui/core';
import { useStyles } from './styles';
import { Statuses } from 'src/store/api/feedbacks.api';
import StatusTableBody from './status-table-body';
import StatusTableHeader from './status-table-header';

export enum Entity {
  pumpState = 'pump_state',
  controllerState = 'controller_state',
  compressorState = 'compressor_state',
}

interface Props {
  pumpState?: string;
  compressorState?: string;
  controllerState?: string;
  onChange?: (type: Entity, value: Statuses) => void;
}

const getRows = (
  label: string,
  value: string,
  handler?: (event: React.ChangeEvent<HTMLInputElement>) => void
) => ({
  label,
  normal: (
    <Radio
      color="primary"
      value="normal"
      name={label}
      onChange={handler}
      checked={value === Statuses.normal}
    />
  ),
  unstable: (
    <Radio
      color="primary"
      value="unstable"
      name={label}
      onChange={handler}
      checked={value === Statuses.unstable}
    />
  ),
  critical: (
    <Radio
      color="primary"
      value="critical"
      name={label}
      onChange={handler}
      checked={value === Statuses.critical}
    />
  ),
  failed: (
    <Radio
      color="primary"
      value="failed"
      name={label}
      onChange={handler}
      checked={value === Statuses.failed}
    />
  ),
});
const StatusTable: FC<Props> = (props) => {
  const {
    pumpState = 'normal',
    controllerState = 'normal',
    compressorState = 'normal',
    onChange,
  } = props;
  const classes = useStyles();

  const handleChange = useCallback(
    (type: Entity, value: string) => onChange?.(type, value as Statuses),
    [onChange]
  );
  const rows = useMemo(
    () => [
      getRows('Cryopump', pumpState, (event: ChangeEvent<HTMLInputElement>) =>
        handleChange(Entity.pumpState, event.target.value)
      ),
      getRows('Controller', controllerState, (event: ChangeEvent<HTMLInputElement>) =>
        handleChange(Entity.controllerState, event.target.value)
      ),
      getRows('Compressor', compressorState, (event: ChangeEvent<HTMLInputElement>) =>
        handleChange(Entity.compressorState, event.target.value)
      ),
    ],
    [pumpState, controllerState, compressorState, handleChange]
  );

  return (
    <TableContainer>
      <Table size="small" aria-label="states table" className={classes.table}>
        <StatusTableHeader />
        <StatusTableBody rows={rows} />
      </Table>
    </TableContainer>
  );
};

export default memo(StatusTable);
