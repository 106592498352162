import { IGetRowsParams } from 'ag-grid-community';
import { ColData } from 'src/shared/base-grid/base-grid';
import {
  FetchBySearchTerm,
  SearchEntityType,
  fetchBySearchTerm,
} from 'src/store/api/search.api';
import { getScopedQuery } from 'src/shared/routing/routing.helper';
import BaseApi, { PaginateableReq, PaginateableRes, getPagination, getSort } from '.';

export interface PumpSetting {
  changed_at: string;
  changed_field: string;
  new_value: string;
  old_value: string;
  pump_id: number;
  site_id: number;
}

export type FailureProbability = {
  failureProbability: number;
  failureProbabilityStatus: 'critical' | 'normal' | 'warning';
  failureProbabilityPredicatedAt: string;
  failureProbabilityTrend: number;
  failureProbabilityAboveThresholdDuration: number;
};

export type Pump = {
  ageInHours: number;
  avgCooldown: number | null;
  avgDiffPressure: number;
  avgReturnPressure: number;
  commFailCount: number;
  differentialPressure: number;
  failedRegenCount: number;
  feedbackStatus: number | string | null;
  heater1: number;
  heater1Max24h: number;
  heater2: number;
  heater2Max24h: number;
  hoursSinceLastFastRegeneration: number;
  hoursSinceLastFullRegeneration: number;
  id: number;
  model: string;
  modelTypeId: number;
  motorStatus: string;
  online: boolean;
  partNumber: string;
  partNumberDescription: string;
  regenCount: number;
  regenStep: number;
  rpm: number;
  rpmMax24h: number;
  serialNumber: string;
  siteId: number;
  siteName: string;
  softwareVersion: string;
  station: number;
  stationName: string;
  t1: number;
  t1ControllerSetpoint: number;
  t1Max24h: number;
  t2: number;
  t2ControllerSetpoint: number;
  t2Max24h: number;
  tcPressure: number;
  toolId: number;
  toolName: string;
  uid: string;
  updatedAt: string;
} & FailureProbability;

export type PumpDetails = {
  avgSupplyPressure: number;
  basePressure: number;
  controllerIpAddress: string;
  controllerSerialNumber: string;
  controllerSoftwareRev: string;
  fastRoughTest: number;
  keypadLockout: boolean;
  modulePassword: string;
  pDelayRestart: number;
  pDelayStart: number;
  pExtendedPurge: number;
  pRepurgeCycles: number;
  pRepurgeTime: number;
  pRoughValveInterlock: boolean;
  paramLockout: boolean;
  powerFailFlag: number;
  powerFailTemp: number;
  purgeStatus: string;
  regenStepExplanation: string;
  rorCycles: number;
  rorLimit: number;
  roughStatus: string;
} & Pump;

export interface ChamberHistoryItem {
  end_date: string;
  pump_age: number;
  pump_id: number;
  serial_number: string;
  site_id: number;
  start_date: string;
}

export interface Diode {
  created_at: string;
  serial_number: string;
  stage: number;
  type: string;
  ch1: number;
  ch2: number;
  ch3: number;
  ch4: number;
  ch5: number;
  ch6: number;
  ch7: number;
  ch8: number;
  ch9: number;
}

export interface PumpAlarmItem {
  alarm: string;
  created_at: string;
}

type FilterBy = {
  siteId?: number | number[];
  toolId?: number;
  failureProbabilityStatus?: string[];
  $predictable?: boolean;
};

// fetch by sort params(ag-grid) and pagination params(ag-grid)
export interface FetchByParams extends IGetRowsParams {
  statuses?: string[];
}

export type DashboardRowResp = PaginateableRes<Pump>;

type SearchablePumpResp = PaginateableRes<{
  pumpId: number;
  siteId: number;
  stationName: string;
  toolId: number;
  toolName: string;
}>;

interface DashboardColumnResp {
  columns: ColData[];
  createdAt: string;
  deletedAt: string;
  id: number;
  kind: string;
  userID: number;
}
export interface PumpHistoryQueryParams {
  pumpId: string | number;
  siteId: string | number;
  toolId?: string | number;
}

const pumpsInstance = new BaseApi('./pumps');
const views = new BaseApi('./views');

const settingsHistory = new BaseApi('./setting_changes');
const chamberHistory = new BaseApi('./chamber_changes');
const diodesInstance = new BaseApi('./pumps/diodes');
const alarmInstance = new BaseApi('./pumps/alarms');

const fetchPumps = (
  filterBy: FilterBy,
  paginateBy?: PaginateableReq,
  sort?: string[]
) => {
  const filtersBySiteOrTool = getScopedQuery();

  const filters = {
    ...filtersBySiteOrTool,
    ...filterBy,
  };

  return pumpsInstance
    .getLatest<DashboardRowResp>({
      params: {
        ...filters,
        ...paginateBy,
        sort,
      },
    })
    .then(({ data }) => data);
};

export const pumpsApi = {
  /**
   * Fetch pumps previously installed in the chamber
   */
  fetchChamberHistoryData: (params: PumpHistoryQueryParams) =>
    chamberHistory.get<ChamberHistoryItem[]>({ params }),

  /**
   * Fetch pump setting history
   */
  fetchHistorySettings: (params: PumpHistoryQueryParams) =>
    settingsHistory.get<PumpSetting[]>({ params }),

  /**
   * Fetch pump diodes
   */
  fetchPumpDiodes: (params: PumpHistoryQueryParams) =>
    diodesInstance.get<Diode[]>({ params }),

  /**
   * Fetch pump alarm history
   */
  fetchPumpAlarmHistory: (params: PumpHistoryQueryParams) =>
    alarmInstance.get<PumpAlarmItem[]>({ params }),

  /**
   * Fetch pumps using pagination to displays them inside table
   */
  fetchRows: (params: FetchByParams) => {
    const { startRow, endRow, sortModel, statuses } = params;

    return fetchPumps(
      { failureProbabilityStatus: statuses },
      { ...getPagination(startRow, endRow) },
      getSort(sortModel)
    );
  },

  /**
   * Fetch pumps without pagination to display them as cards on predictions dashboard
   */
  fetchPredictions() {
    const pagination = { limit: 1000 };
    return fetchPumps({ $predictable: true }, pagination);
  },

  fetchPumpDetails(query: { [k: string]: string }) {
    const api = new BaseApi('/pumps/' + query.pumpId);
    return api.get<PumpDetails>({
      params: {
        siteId: query.siteId,
      },
    });
  },

  fetchColumns() {
    return views
      .get<DashboardColumnResp[]>({ params: { kind: 'pump' } })
      .then(({ data }) => {
        const columns = data?.[0]?.columns ?? [];
        return { data: columns };
      });
  },

  async fetchBySearchTerm(params: FetchBySearchTerm) {
    const { data, ...rest } = await fetchBySearchTerm<SearchablePumpResp>(
      SearchEntityType.pumps,
      params
    );

    // prepare autocomplete items
    const items = data.map((row) => {
      const { pumpId, stationName, toolName, ...rest } = row;
      return {
        value: pumpId,
        label: `${toolName} - ${stationName}`,
        extraFields: rest,
      };
    });

    return { ...rest, data: items };
  },
};
