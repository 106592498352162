import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import muiTheme from 'src/styles/theme';
import { CssBaseline } from '@material-ui/core';
import store from 'src/store';
import SnackbarProvider from 'src/app/snackbar-provider';
import RootRoutes from 'src/app';
import { Provider as StoreProvider } from 'react-redux';

const AppRoot = () => (
  <ThemeProvider theme={muiTheme}>
    <CssBaseline />
    <StoreProvider store={store}>
      <SnackbarProvider>
        <RootRoutes />
      </SnackbarProvider>
    </StoreProvider>
  </ThemeProvider>
);

export default AppRoot;
