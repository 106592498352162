import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { NodeIdentifiers } from 'src/shared/routing/with-selected-equipment';
import { RootState, select } from 'src/store';
import { Compressor, Controller, Pump, Tool } from 'src/store/api/sites.api';
import TreeNode from './node/TreeNode';

interface Props {
  siteId: number;
  onSelect: (node: NodeIdentifiers | null) => void;
  onChange: (state: boolean, node: NodeIdentifiers) => void;
}
interface EquipmentNode {
  siteId: number;
  toolId: number;
  controllerId?: number;
  compressorId?: number;
  pumpId?: number;
}

// equipment per one site (tools & pumps)
export const SiteEquipmentTree: FC<Props> = ({ siteId, onSelect, onChange }) => {
  const tools = useSelector((state: RootState) =>
    select.siteEquipment.selectTools(state, { siteId })
  );

  const getTreeNode = (
    key: number,
    node: EquipmentNode,
    online: boolean,
    name: string,
    activeNotificationsCount: number
  ) => (
    <TreeNode
      key={key}
      node={node}
      online={online ? 'online' : 'offline'}
      name={name}
      nesting={2}
      activeNotificationsCount={activeNotificationsCount}
      onSelect={onSelect}
      onChange={onChange}
    />
  );

  const getControllerNode = (controller: Controller) =>
    getTreeNode(
      controller.controllerId,
      controller,
      controller.online,
      'Controller',
      controller.activeNotificationsCount
    );

  const getCompressorNodes = (compressors: Compressor[]) =>
    compressors.map((compressor) =>
      getTreeNode(
        compressor.compressorId,
        compressor,
        compressor.online,
        compressor.compressorName,
        compressor.activeNotificationsCount
      )
    );

  const getPumpNodes = (pumps: Pump[]) =>
    pumps.map((pump) =>
      getTreeNode(
        pump.pumpId,
        pump,
        pump.online,
        pump.stationName,
        pump.activeNotificationsCount
      )
    );

  const renderToolNodes = (tool: Tool) => [
    ...getPumpNodes(tool.pumps),
    ...getCompressorNodes(tool.compressors),
    getControllerNode(tool.controller),
  ];

  return (
    <>
      {tools &&
        tools.map((tool: Tool) => (
          <TreeNode
            key={tool.toolId}
            node={tool}
            online={tool.status}
            name={`${tool.name}: ${tool.oemName}`}
            activeNotificationsCount={tool.activeNotificationsCount}
            nesting={1}
            onSelect={onSelect}
            onChange={onChange}>
            {renderToolNodes(tool)}
          </TreeNode>
        ))}
    </>
  );
};
