import React, { FC, useEffect } from 'react';
import formatDistance from 'date-fns/formatDistance';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import BaseGrid from 'src/shared/base-grid';
import { ColData } from 'src/shared/base-grid/base-grid';
import { createColumn } from 'src/shared/base-grid/column-creator';
import { FORMAT_MAP, formatDate } from 'src/shared/date-in-selected-zone/date-formatter';
import { COLORS } from 'src/styles/colors';
import { Pipelines } from 'src/store/models/ml.model';

type Event = {
  name: string;
  created_at: string;
};

type Row = {
  events?: Event[];
};

interface IPropsMonitoring {
  onMount: () => void;
  pipelines: Pipelines[] | null;
}

const getDuration = (date1: Date, date2: Date) => {
  return formatDistanceStrict(date1, date2, { addSuffix: true });
};

const getStepStatus = (step: string, data: Row) => {
  const events = data?.events ?? [];

  const [started, completed] = events.filter((event) => event.name.startsWith(step));

  const isStepCompleted = started && completed;

  const isStepFailed = ['failed', 'exception'].some((pattern) =>
    completed?.name.endsWith(pattern)
  );

  const status = isStepFailed ? 'Failed' : isStepCompleted ? 'Completed' : '-';

  return { status, started, completed };
};

const stepValueGetter = (step: string, data: Row): string => {
  const { status, started, completed } = getStepStatus(step, data);

  const dates = [new Date(completed?.created_at), new Date(started?.created_at)].filter(
    (date) => !isNaN(date.valueOf())
  );

  const duration = dates.length === 2 ? getDuration(dates[0], dates[1]) : '';

  return `${status} ${duration}`;
};

const columns: ColData[] = [
  {
    name: 'day',
    label: 'Date',
    sortDirection: 'desc',
    units: null,
    visibility: true,
    type: 'date',
    valueFormatter: ({ value }) =>
      value instanceof Date ? formatDate(value, FORMAT_MAP.short) : '',
    flex: 2,
  },
  {
    name: 'completedAt',
    label: 'Completed On',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'date',
  },
  {
    name: 'duration',
    label: 'Duration',
    units: null,
    visibility: true,
    type: 'string',
    valueGetter: (column) => {
      if (!column.data.completedAt || !column.data.createdAt) return 0;

      const completed = new Date(column.data.completedAt).valueOf();
      const started = new Date(column.data.createdAt).valueOf();
      return completed - started;
    },
    valueFormatter: (column) => {
      if (column.value === 0) return '';

      return formatDistance(
        new Date(column.data?.completedAt),
        new Date(column.data?.createdAt)
      );
    },
  },
  {
    name: 'predictions',
    label: 'Predictions',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 2,
  },
  {
    name: 'etl',
    label: 'ETL',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'string',
    flex: 3,
    valueGetter: (column) => {
      return stepValueGetter('PipelineSteps::Etl', column.data);
    },
  },
  {
    name: 'ad',
    label: 'AD',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'string',
    flex: 3,
    valueGetter: (column) => {
      return stepValueGetter('PipelineSteps::Ad', column.data);
    },
  },
  {
    name: 'ml',
    label: 'ML',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'string',
    flex: 3,
    valueGetter: (column) => {
      const { status, started } = getStepStatus('PipelineSteps::Ml', column.data);

      const pipelineCompletedAt = column.data?.completedAt;

      const stepStatus = pipelineCompletedAt ? status : '-';

      const duration =
        stepStatus !== '-'
          ? getDuration(new Date(pipelineCompletedAt), new Date(started.created_at))
          : '';

      return `${stepStatus} ${duration}`;
    },
  },
];

const gridColumns = columns.map((el) =>
  createColumn(el, {
    headerComponentParams: {
      dataType: el.units,
    },
    cellStyle: (params) => {
      const color = COLORS.red['500'];
      const highlight = `${params.value}`.startsWith('Failed') || params.value === 0;

      return highlight ? { color } : {};
    },
  })
);

const Monitoring: FC<IPropsMonitoring> = (props) => {
  const { onMount, pipelines } = props;

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <BaseGrid
      autoSizeColumns={false}
      rows={pipelines}
      colData={gridColumns}
      headerHeight={80}
    />
  );
};

export default Monitoring;
