import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      container: {
        width: '100%',
        padding: '1rem',
      },
      input: {
        width: '100%',
        padding: '0 0.5rem',
        backgroundColor: COLORS.grey['200'],
        border: 'none',
        borderRadius: '0.25rem',
        '&:hover, &:focus-within': {
          backgroundColor: COLORS.grey['300'],
        },
      },
      inputAdornment: {
        margin: 0,
        '&:last-child': {
          cursor: 'pointer',
        },
      },
    })
);
