import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react';
import union from 'lodash/union';
import without from 'lodash/without';
import xor from 'lodash/xor';
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import {
  SingleTransformedLineSeries,
  TransformedLineSeries,
  getUniqueIdentifers,
  groupSeriesByDevices,
  groupSeriesByMetrics,
} from './series-transformer';
import { useStyles } from './legend.styles';
import clsx from 'clsx';
import { COLORS } from 'src/styles/colors';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
} from '@material-ui/icons';

const ALL_SERIES = {
  key: 'ALL',
  name: 'All',
};

interface HandleChange {
  (value: MetricOrDeviceValue, event: React.ChangeEvent<HTMLInputElement>): void;
}

type MetricOrDeviceValue = {
  key: string;
  siteId?: number;
  deviceId?: number;
  name: string;
  color?: {
    color: string;
    type?: string;
  };
};

type CheckboxOwnProps = {
  name: string;
  checked: boolean;
  indeterminate?: boolean;
  value: MetricOrDeviceValue;
  handleChange: HandleChange;
};

type ColorCheckboxOwnProps = CheckboxOwnProps & { classes: Record<string, string> };

const TextCheckbox = ({
  name,
  checked,
  indeterminate,
  value,
  handleChange,
}: CheckboxOwnProps) => {
  const classes = useStyles();
  const handleSeriesChange = useCallback((event) => handleChange(value, event), [
    handleChange,
    value,
  ]);

  return (
    <FormControlLabel
      classes={{
        root: classes.checkboxLabel,
      }}
      control={
        <Checkbox
          classes={{
            root: classes.checkboxRoot,
          }}
          indeterminateIcon={<IndeterminateCheckBox className={classes.checkboxIcon} />}
          checkedIcon={<CheckBoxIcon className={classes.checkboxIcon} />}
          icon={<CheckBoxOutlineBlank className={classes.checkboxIcon} />}
          size="small"
          color="default"
          checked={checked}
          indeterminate={indeterminate}
          value={name}
        />
      }
      onChange={handleSeriesChange}
      label={name}
    />
  );
};

const getColorBoxStyles = (color = '', style = '', checked: boolean) => {
  color = checked ? color : COLORS.grey[400];
  const styledLine = style && style !== 'solid';

  if (styledLine) {
    return {
      borderTopColor: color,
      borderTopStyle: style,
    } as CSSProperties;
  }

  return {
    backgroundColor: color,
    height: '16px',
  };
};

const ColorCheckbox = ({
  name,
  checked,
  value,
  classes,
  handleChange,
}: ColorCheckboxOwnProps) => {
  const handleDeviceMetricChange = useCallback((event) => handleChange(value, event), [
    handleChange,
    value,
  ]);
  const color = value.color?.color;
  const type = value.color?.type;
  const style = getColorBoxStyles(color, type, checked);

  return (
    <FormControlLabel
      classes={{
        root: clsx(classes.deviceMetricColoredBox, classes.checkboxLabel),
        label: !checked ? classes.deviceMetricColoredBoxLabelUnchecked : '',
      }}
      control={<span style={style} className={classes.deviceMetricColoredBoxColor} />}
      checked={checked}
      onClick={handleDeviceMetricChange}
      label={name}
    />
  );
};

const toggle = (toggle: boolean, items: string[], itemsToToggle: string[]) => {
  return toggle ? union(items, itemsToToggle) : without(items, ...itemsToToggle);
};

const getOnlyUid = ({ uid }: SingleTransformedLineSeries) => uid;

type OwnProps = {
  onChange: (values: string[]) => void;
  dataSeries: TransformedLineSeries;
  unselectedSeries: string[];
  hideMetrics?: boolean;
};

type State = {
  metrics: ReturnType<typeof groupSeriesByMetrics>;
  devices: ReturnType<typeof groupSeriesByDevices>;
};

const Legend: FC<OwnProps> = (props) => {
  const { dataSeries, unselectedSeries, onChange, hideMetrics } = props;
  const styles = useStyles();
  // todo: should work with unselected series instead of selected, fix that component to not depend on selected ones
  const selectedSeries = xor(dataSeries.map(getOnlyUid), unselectedSeries);

  const [{ metrics, devices }, setLegendItems] = useState<State>({
    metrics: groupSeriesByMetrics(dataSeries),
    devices: groupSeriesByDevices(dataSeries),
  });

  useEffect(() => {
    const metrics = groupSeriesByMetrics(dataSeries);
    const devices = groupSeriesByDevices(dataSeries);
    setLegendItems({ metrics, devices });
  }, [dataSeries]);

  // unselect all or select all
  const handleAllChange: HandleChange = (value, event) => {
    const checked = event.target.checked;
    const newCheckedSeries = checked ? getUniqueIdentifers(dataSeries) : [];
    onChange(newCheckedSeries);
  };

  // unselect all devices specific metric
  const handleMetricChange: HandleChange = (value, event) => {
    const checked = event.target.checked;
    const seriesToToggle = dataSeries
      .filter((item) => item.seriesName === value.key)
      .map(getOnlyUid);

    const newCheckedSeries = toggle(checked, selectedSeries, seriesToToggle);
    onChange(newCheckedSeries);
  };

  // unselect all metrics in specific device
  const handleDeviceChange: HandleChange = (value, event) => {
    const checked = event.target.checked;
    const seriesToToggle = dataSeries
      .filter((item) => item.deviceId === value.deviceId && item.siteId === value.siteId)
      .map(getOnlyUid);

    const newCheckedSeries = toggle(checked, selectedSeries, seriesToToggle);
    onChange(newCheckedSeries);
  };

  // unselect or select only single device metric
  const handleDeviceMetricChange: HandleChange = (value) => {
    const newCheckedSeries = xor(selectedSeries, [value.key]);
    onChange(newCheckedSeries);
  };

  const showMetrics = devices.length > 1 && !hideMetrics;

  const getCheckboxState = (checked: boolean, indeterminate: () => boolean) => ({
    checked,
    ...(!checked && { indeterminate: indeterminate() }),
  });

  const getAllState = () => {
    const checked = dataSeries.every(({ uid }) => selectedSeries.includes(uid));
    return getCheckboxState(checked, () =>
      dataSeries.some(({ uid }) => selectedSeries.includes(uid))
    );
  };

  const getMetricOrDeviceState = (series: string[]) => {
    const checked = series.every((uid) => selectedSeries.includes(uid));
    return getCheckboxState(checked, () =>
      series.some((uid) => selectedSeries.includes(uid))
    );
  };

  const getMetricState = (name: string) => {
    return getMetricOrDeviceState(
      dataSeries.filter(({ seriesName }) => seriesName === name).map(getOnlyUid)
    );
  };

  const getDeviceState = (series: { key: string }[]) => {
    return getMetricOrDeviceState(series.map(({ key }) => key));
  };

  return (
    <div className={styles.root}>
      {showMetrics && (
        <Grid container justify="center" className={styles.metricsContainer}>
          {devices.length && (
            <TextCheckbox
              name={ALL_SERIES.name}
              value={ALL_SERIES}
              handleChange={handleAllChange}
              {...getAllState()}
            />
          )}
          {metrics.map((metric) => (
            <TextCheckbox
              key={metric.key}
              name={metric.name}
              value={metric}
              handleChange={handleMetricChange}
              {...getMetricState(metric.key)}
            />
          ))}
        </Grid>
      )}

      <Grid container>
        {devices.map((device, index) => {
          const columns = devices.length > 1 ? 6 : 12;
          const alignment = columns === 12 ? 'center' : undefined;
          return (
            <Grid
              key={index}
              item
              xs={columns}
              classes={{ item: styles.deviceMetricColumn }}>
              <Box
                className={clsx({ [styles.checkboxWrapper]: devices.length > 2 })}
                justifyContent={alignment}
                display="flex"
                flexWrap="wrap">
                <TextCheckbox
                  name={device.name}
                  value={device}
                  handleChange={handleDeviceChange}
                  {...getDeviceState(device.metrics)}
                />

                {device.metrics.map((deviceMetric) => (
                  <ColorCheckbox
                    classes={styles}
                    key={deviceMetric.key}
                    name={deviceMetric.name}
                    checked={selectedSeries.includes(deviceMetric.key)}
                    value={deviceMetric}
                    handleChange={handleDeviceMetricChange}
                  />
                ))}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Legend;
