import React, { FC, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState, select } from 'src/store';
import {
  COMPRESSOR,
  CONTROLLER,
  EquipmentType,
  PUMP,
  SITE,
  TOOL,
} from 'src/shared/routing/with-selected-equipment';
import { useStyles } from './styles';

type Props = {
  type: EquipmentType;
  siteId: number;
  toolId: number;
};

type To = {
  siteId?: number;
  toolId?: number;
} | null;

const Breadcrumbs: FC<Props> = (props) => {
  const { type, siteId, toolId } = props;

  const classes = useStyles();

  const { siteEquipment } = useDispatch<RootDispatch>();

  const site = useSelector((state: RootState) =>
    select.siteEquipment.selectSite(state, { siteId })
  );

  const tool = useSelector((state: RootState) =>
    select.siteEquipment.selectTool(state, { siteId, toolId })
  );

  const breadcrumbs = [];

  const isEquipment = type === PUMP || type === COMPRESSOR || type === CONTROLLER;

  // site route and at least one site is selected
  if (type === SITE && siteId) {
    // Status Dashboard
    breadcrumbs.push({
      name: 'Status Dashboard',
      to: null,
    });
  } else if (type === TOOL) {
    // Status Dashboard / Site 1
    breadcrumbs.push({
      name: 'Status Dashboard',
      to: null,
    });
    breadcrumbs.push({
      name: site?.name,
      to: {
        siteId: site?.siteId,
      },
    });
  } else if (isEquipment) {
    // Status Dashboard / Site 1 / Tool 1
    breadcrumbs.push({
      name: 'Status Dashboard',
      to: null,
    });
    breadcrumbs.push({
      name: site?.name,
      to: {
        siteId: site?.siteId,
      },
    });
    breadcrumbs.push({
      name: tool?.name,
      to: {
        siteId: site?.siteId,
        toolId: tool?.toolId,
      },
    });
  }

  const handleBreadcrumbClick = useCallback(
    (to: To) => () => {
      siteEquipment.navigateToEquipment(to);
    },
    [siteEquipment]
  );

  return (
    <div className={classes.root}>
      {breadcrumbs.map(({ name, to }, index) => (
        <Fragment key={index}>
          <span className={classes.slot} onClick={handleBreadcrumbClick(to)}>
            <span className={classes.body2}>{name}</span>
          </span>
          {index !== breadcrumbs.length - 1 && (
            <span className={classes.separator}>
              <span className={classes.body2}>{' / '}</span>
            </span>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
