import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  optionsControlListWrapper: {
    /* https://github.com/mui-org/material-ui/issues/12208#issuecomment-406844582 */
    maxHeight: '300px',
  },
  optionCheckboxWrapper: {
    margin: '-7px 0 -7px -9px',
  },
  optionText: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '150px',
  },
  iconFilled: {
    margin: '-2px 0 0',
    top: '50%',
  },
});
