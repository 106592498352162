import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      title: {
        lineHeight: '20px',
        textTransform: 'uppercase',
      },
    })
);
