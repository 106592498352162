import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Button from '../../../shared/button';

const ToolbarCreate = () => (
  <Box p="24px 0" display="flex" justifyContent="space-between">
    <Typography variant="h5">Create Chart</Typography>
    <Button form="chart-form" type="submit">
      Save
    </Button>
  </Box>
);

export default ToolbarCreate;
