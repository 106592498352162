import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles({
  rootContainer: {
    padding: '1rem',
    height: '100vh',
  },
  tableContainer: {
    width: '25rem',
    maxWidth: '100%%',
  },
  label: {
    width: '14rem',
    fontWeight: 'bold',
    backgroundColor: COLORS.grey[100],
  },
});
