import React from 'react';
import DateInSelectedZone from 'src/shared/date-in-selected-zone/date-in-selected-zone';
import { ICellRendererParams } from 'ag-grid-community';

export const DatetimeRenderer = (cell: ICellRendererParams) => {
  if (cell.value) {
    return <DateInSelectedZone>{cell.value}</DateInSelectedZone>;
  }

  return '';
};
