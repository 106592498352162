import React, { FC } from 'react';
import DateRangeFilter from 'src/shared/date-range-filter';
import ExportBtn from 'src/shared/button-export';
import { format } from 'date-fns';
import { getDefaultFilters } from 'src/shared/date-range-filter/filters-config';
import { Box } from '@material-ui/core';

interface Props {
  dates: [Date, Date];
  handleChange: (start: Date, end: Date) => void;
  chartId: number;
  query: {
    dates: {
      start: Date;
      end: Date;
    };
    timezoneOffset: number;
  };
}

const quickFilters = getDefaultFilters();

export const ChartDetailsHeader: FC<Props> = ({
  dates: [start, end],
  handleChange,
  chartId,
  query,
}) => {
  const exportProps = {
    url: `/charts/${chartId}/data.xlsx`,
    params: query,
    position: 'relative',
    titleSpec: `(${format(start, 'MM/dd/yyy')})-(${format(end, 'MM/dd/yyy')}).xlsx`,
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <DateRangeFilter
        quickFilters={quickFilters}
        startDate={start}
        endDate={end}
        onChange={handleChange}
      />

      <Box display="flex" alignItems="flex-end">
        <ExportBtn style={{ height: 'fit-content' }} {...exportProps} />
      </Box>
    </Box>
  );
};
