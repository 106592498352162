import { Tool } from 'src/store/api/sites.api';
import { createModel } from '@rematch/core';
import { showError } from 'src/services/snackbars';
import { storage } from 'src/services/storage';
import {
  EquipmentIdentifiers,
  changeEquipmentRoute,
  getQuerySiteId,
  getQueryToolId,
} from 'src/shared/routing/routing.helper';
import { RootModel } from '.';

import { Site, sitesApi as api } from '../api/sites.api';
import { NodeIdentifiers } from 'src/shared/routing/with-selected-equipment';

type State = {
  // keep list of sites
  sites: Site[];

  // selected (preferred) sites, used to filter displayed sites
  selectedSites: number[];

  // keep site equipment by site id (tools & pumps)
  equipment: Record<string, Tool[]>;

  // way to keep the equipment expand state globally to have an ability to force change
  // global expand state synced with local TreeNode state
  expanded: EquipmentIdentifiers;
};

// todo: incorporate in all models
async function safely<T>(fetcher: Promise<T>) {
  try {
    const response = await fetcher;
    return response;
  } catch (err) {
    const msg = err.message || 'Oops, something went wrong. Try again.';
    showError(msg);
    throw err;
  }
}

export const siteEquipment = createModel<RootModel>()({
  state: {
    sites: [],
    equipment: {},
    selectedSites: [],
    expanded: null,
  } as State,

  selectors: (slice, createSelector) => ({
    // todo: might be executed more times than expected
    // todo: (selectedSites stay the same, but selector returns new value)
    preferredSites() {
      return createSelector(
        slice,
        (state: unknown, selectedSites: number[]) => selectedSites,
        (slice, selectedSites: number[]) => {
          return selectedSites.length
            ? slice.sites.filter(({ siteId }) => selectedSites.includes(siteId))
            : slice.sites;
        }
      );
    },

    selectSite() {
      return createSelector(
        slice,
        (state: unknown, props: NodeIdentifiers) => props,
        (slice, { siteId }) => {
          return slice.sites.find((site: Site) => site.siteId === siteId);
        }
      );
    },

    selectTools() {
      return createSelector(
        slice,
        (state: unknown, props: NodeIdentifiers) => props,
        (slice, { siteId }) => {
          return slice.equipment[siteId];
        }
      );
    },

    selectTool() {
      return createSelector(
        slice,
        (state: unknown, props: NodeIdentifiers) => props,
        (slice, { siteId, toolId }) => {
          return slice.equipment[siteId]?.find((tool: Tool) => tool.toolId === toolId);
        }
      );
    },

    selectPump() {
      return createSelector(
        slice,
        (state: unknown, props: NodeIdentifiers) => props,
        (slice, { siteId, toolId, pumpId }) => {
          return slice.equipment[siteId]
            ?.find((tool: Tool) => tool.toolId === toolId)
            ?.pumps.find((pump) => pump?.pumpId === pumpId);
        }
      );
    },

    selectCompressor() {
      return createSelector(
        slice,
        (state: unknown, props: NodeIdentifiers) => props,
        (slice, { siteId, toolId, compressorId }) => {
          return slice.equipment[siteId]
            ?.find((tool: Tool) => tool.toolId === toolId)
            ?.compressors.find((compressor) => compressor?.compressorId === compressorId);
        }
      );
    },

    selectController() {
      return createSelector(
        slice,
        (state: unknown, props: NodeIdentifiers) => props,
        (slice, { siteId, toolId }) => {
          return slice.equipment[siteId]?.find((tool: Tool) => tool.toolId === toolId)
            ?.controller;
        }
      );
    },
  }),

  reducers: {
    addSites: (state, payload: Site[]) => {
      return {
        ...state,
        sites: payload,
      };
    },

    addEquipment: (
      state,
      { siteId, equipment }: { siteId: number; equipment: Tool[] }
    ) => {
      return {
        ...state,
        equipment: {
          ...state.equipment,
          [siteId]: equipment,
        },
      };
    },

    addSelectedSites: (state, payload: number[]) => {
      return { ...state, selectedSites: payload };
    },

    addExpandIdentifiers: (state, payload: EquipmentIdentifiers) => {
      return { ...state, expanded: payload };
    },
  },

  effects: (dispatch) => {
    const { siteEquipment } = dispatch;

    return {
      async restoreTree() {
        const siteId = getQuerySiteId();
        const toolId = getQueryToolId();

        // restore selected/preferred sites in top dropdown
        siteEquipment.restoreSelectedSites();

        // fetch all sites & selected site equipment(tools & pumps) in parallel
        const wait = [];
        wait.push(siteEquipment.fetchSites());
        if (siteId) wait.push(siteEquipment.fetchSiteEquipment(siteId));
        await Promise.all(wait);

        // restore tree state by expanding selected tool
        if (siteId && toolId) {
          siteEquipment.addExpandIdentifiers({ siteId, toolId });
        }
      },

      async fetchSites() {
        const sites = await safely(api.fetchSites());
        siteEquipment.addSites(sites);
      },

      async fetchSiteEquipment(siteId: number, state) {
        if (state.siteEquipment.equipment[siteId]) return;

        const equipment = await safely(api.fetchSiteEquipment(siteId));

        siteEquipment.addEquipment({ siteId, equipment });
      },

      /**
       * Change route to selected equipment & update equipment tree state by expanding appropriate node
       */
      async navigateToEquipment(node: EquipmentIdentifiers) {
        changeEquipmentRoute(node);
        if (node?.siteId) await siteEquipment.fetchSiteEquipment(node?.siteId);
        siteEquipment.addExpandIdentifiers(node);
      },

      async selectEquipment(node: EquipmentIdentifiers) {
        changeEquipmentRoute(node);
        if (node?.siteId) await siteEquipment.fetchSiteEquipment(node?.siteId);
        siteEquipment.addExpandIdentifiers(null);
      },

      restoreSelectedSites() {
        const sites = storage.getPreferredSites();
        siteEquipment.addSelectedSites(sites);
      },

      changeSelectedSites(siteIds: number[]) {
        siteEquipment.addSelectedSites(siteIds);
        storage.setPreferredSites(siteIds);
      },
    };
  },
});
