import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  MenuProps,
  Select,
  WithStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Spinner } from 'src/shared/spinner';
import DateInSelectedZone from 'src/shared/date-in-selected-zone';
import styles from './styles';
import clsx from 'clsx';
import { Model } from 'src/store/api/ml.api';

interface Props extends WithStyles<typeof styles> {
  models: Model[];
  value: string;
  pendingModels: boolean;
  onChange?: (value: string) => void;
}

// TODO: Probably could be merged with sitePicker?
export class ModelPicker extends React.Component<Props> {
  menuConfig = {
    MenuListProps: {
      dense: true,
    },
    anchorOrigin: {
      horizontal: 'center',
      vertical: 'bottom',
    },
    classes: {
      paper: this.props.classes.optionsControlListWrapper,
    },
    getContentAnchorEl: null,
    transformOrigin: {
      horizontal: 'center',
      vertical: 'top',
    },
  };

  selectClasses = {
    root: this.props.classes.root,
    iconFilled: this.props.classes.iconFilled,
  };

  render() {
    const { models, value, pendingModels } = this.props;

    return (
      <FormControl variant="filled" fullWidth={true} required>
        <InputLabel
          id="site-ctrl-label"
          classes={{ filled: this.props.classes.inputLabel }}>
          {value ? 'Model' : 'Select Model'}
        </InputLabel>
        <Select
          IconComponent={ExpandMore}
          MenuProps={this.menuConfig as Partial<MenuProps>}
          classes={this.selectClasses}
          id="model-ctrl"
          labelId="model-ctrl-label"
          multiple={false}
          onChange={this.onValueChange}
          value={value}>
          {pendingModels && (
            <Box display="flex" justifyContent="center" p={2}>
              <Spinner size={32} />
            </Box>
          )}
          {models.map((model: Model) => (
            <MenuItem key={model.name} value={model.name}>
              <div className={this.props.classes.optionMultiline}>
                <span
                  className={clsx(
                    this.props.classes.optionText,
                    this.props.classes.optionTextLong
                  )}>
                  {model.name}
                </span>
                <span
                  className={clsx(
                    this.props.classes.optionText,
                    this.props.classes.optionTextLong
                  )}>
                  <DateInSelectedZone format="MM/dd/yy">
                    {model.created_at}
                  </DateInSelectedZone>
                </span>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  onValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selection = event.target.value;
    this.props.onChange?.(selection as string);
  };
}
