import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

const RESET_COLOR = {
  color: 'inherit',
  borderColor: 'inherit',
};

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      pickerRoot: {
        ...RESET_COLOR,
        width: '100%',
        '& *': {
          ...RESET_COLOR,
          '&:before': {
            ...RESET_COLOR,
          },
        },
      },
      typoBody2: {
        fontSize: '14px',
      },
      controlUnderline: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    })
);
