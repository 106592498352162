import {
  ChartOption,
  ChartTooltipOption,
  ChartXAxisOption,
  ChartYAxisOption,
} from '../base-chart';
import format from 'date-fns/format';
import toArray from 'lodash/toArray';
import orderBy from 'lodash/orderBy';
import head from 'lodash/head';
import get from 'lodash/get';
import {
  DATE_YEAR_MASK,
  TIME_MASK,
  formatDate,
} from 'src/shared/date-in-selected-zone/date-formatter';
import { DataPoint } from './series-transformer';
import { EntityType } from 'src/types';
import { COLORS } from 'src/styles/colors';
import { endOfHour } from 'date-fns';

export interface YAxisLabelStyle {
  fontSize: number;
  color: string;
  margin?: number;
}

export const lineSeriesConfig = {
  type: 'line',
  showSymbol: false,
  smooth: false,
};

// todo: why PascalCase?
export const LineChartGridOptions = {
  z: 10,
  top: 10,
  bottom: 30, // reserved place to draw X axis ticks
  left: 40,
  right: 40,
};

const normalizeMax = (max: number) => {
  const now = new Date().getTime();
  const presentTime = max > now ? now : max;
  const endOfCurrentHour = endOfHour(presentTime).getTime() + 1;
  return endOfCurrentHour;
};

const getXAxisConfig = (min?: number, max?: number): ChartXAxisOption => {
  return {
    type: 'time',
    axisLabel: {
      fontSize: 11,
      color: COLORS.grey['600'],
      formatter(date: number) {
        return [format(date, DATE_YEAR_MASK), format(date, TIME_MASK)].join('\n');
      },
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      lineStyle: {
        width: 1,
        color: COLORS.grey['200'],
      },
    },
    min: min || 'dataMin',
    max: max ? normalizeMax(max) : 'dataMax',
  };
};

export const yAxisLabelDefault: YAxisLabelStyle = {
  fontSize: 11,
  color: COLORS.grey['600'],
};

// todo: why PascalCase ?
export const LineChartYAxis: ChartYAxisOption[] = [
  {
    min: 'dataMin',
    axisLabel: yAxisLabelDefault,
    axisLine: {
      lineStyle: {
        color: COLORS.grey['200'],
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: COLORS.grey['200'],
      },
    },
    axisTick: {
      show: false,
    },
    nameGap: 30,
    nameLocation: 'middle',
    nameTextStyle: {
      lineHeight: 16,
      fontSize: 11,
      color: COLORS.grey['700'],
    },
  },
];

const getDeviceName = ({ deviceType, metadata }: DataPoint) => {
  switch (deviceType) {
    case EntityType.Pump:
      return [metadata?.tool_name, metadata?.station_name];
    case EntityType.Tool:
      return [metadata?.tool_name];
    case EntityType.Compressor:
      return [metadata?.tool_name, metadata?.compressor_name];
    default:
      return [];
  }
};

const getName = (dataPoint: DataPoint) => {
  return [...getDeviceName(dataPoint), dataPoint.name].filter(Boolean).join(' - ');
};

const getValue = (series: any) => get(series, 'value[1]');

export const tooltipConfig = {
  trigger: 'axis',
  padding: 8,
  appendToBody: true,
  formatter: (series) => {
    series = toArray(series);

    const sortedSeries = orderBy(series, getValue, 'desc');
    const firstSeries = head(sortedSeries) || {};
    const legendItems = sortedSeries
      .map((series) => {
        return `${series.marker}${getName(series.data)}: ${getValue(series)}`;
      })
      .join('<br/>');
    return firstSeries
      ? `${formatDate(firstSeries.axisValue as number)}<br/>${legendItems}`
      : '';
  },
} as ChartTooltipOption;

export const getConfig = (minX?: number, maxX?: number): ChartOption => {
  return {
    tooltip: tooltipConfig,
    grid: LineChartGridOptions,
    xAxis: getXAxisConfig(minX, maxX),
    yAxis: LineChartYAxis,
  };
};
