import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const ExportIcon: FC<Props> = (props) => {
  const { className, color } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.991 2H5.99698C4.89781 2 3.99848 2.9 3.99848
    4V11.008H2.00256V18.0149H3.99848V20C3.99848 21.1 4.88781 22 5.98699 22H17.988C19.0872 22 19.9865
    21.1 19.9865 20V18.0149H22.0026V11.008H19.9865V8L13.991 2ZM17.988 18.0149V20H5.99698V18.0149H17.988ZM17.988
    11.008V9H12.9917V4H5.99698V11.008H17.988ZM13.5191 16.8171C12.9789 16.8171 12.4456 16.6839 12.1789
    16.5439L12.3961 15.7036C12.6841 15.8435 13.1264 15.9834 13.5822 15.9834C14.0741 15.9834 14.3334
    15.7905 14.3334 15.4964C14.3334 15.2168 14.1088 15.0565 13.5402 14.8632C12.7541 14.6033 12.2416
    14.19 12.2416 13.5362C12.2416 12.7693 12.9153 12.1826 14.0319 12.1826C14.565 12.1826 14.9587
    12.289 15.2389 12.4093L15.0005 13.2297C14.8107 13.143 14.4738 13.016 14.0104 13.016C13.5472
    13.016 13.3228 13.216 13.3228 13.4499C13.3228 13.7365 13.5896 13.8632 14.2002 14.0831C15.0356
    14.3769 15.4284 14.7899 15.4284 15.4234C15.4285 16.177 14.8177 16.8171 13.5191 16.8171ZM6.53572 15.9372L6.96396
    16.751H8.20006L6.79601 14.4495L8.12961 12.2559H6.90848L6.508 13.0766C6.38886 13.3362 6.29784 13.5362 6.18537
    13.823H6.17149C6.05903 13.5694 5.95359 13.3497 5.81336 13.0766L5.39918 12.2559H4.17067L5.49037
    14.4764L4.12128 16.751H5.34254L5.73564 15.9372C5.76977 15.8646 5.80239 15.7963 5.83359 15.7309L5.83359
    15.7309L5.8336 15.7309C5.93719 15.5139 6.02513 15.3298 6.10062 15.13H6.11468C6.14891 15.1967 6.18174
    15.2617 6.2146 15.3267L6.21463 15.3268L6.21465 15.3268L6.21468 15.3269L6.21479 15.3271C6.30991 15.5153
6.40531 15.7042 6.53572 15.9372ZM8.77422 16.751H11.7297V15.8968H9.84826V12.2559H8.77422V16.751ZM18.2146 15.9372L18.643
    16.751H19.8787L18.4748 14.4495L19.8085 12.2559H18.5871L18.187 13.0766C18.0675
    13.3362 17.9761 13.5362 17.864 13.823H17.8498C17.7375 13.5694 17.6326 13.3497 17.4923 13.0766L17.0778
    12.2559H15.8492L17.169 14.4764L15.8001 16.751H17.0214L17.4145 15.9372C17.4488 15.8642 17.4816 15.7956
    17.513 15.73L17.5131 15.7299C17.6166 15.5134 17.7046 15.3294 17.7798 15.13H17.7934C17.8292
15.1997 17.8635 15.2675 17.8979 15.3355C17.9917 15.5209 18.0861 15.7076 18.2146 15.9372Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default ExportIcon;
