import React, { Component } from 'react';
import BaseHeader, { BaseHeaderProps } from 'src/shared/base-grid/base-header';
import { WithStyles } from '@material-ui/core';
import styles from './online-status.styles';

interface Props extends BaseHeaderProps, WithStyles<typeof styles> {}

export default class OnlineStatusHeader extends Component<Props> {
  // don't remove!!! ag-grid use this method for applying styles
  public getReactContainerStyle() {
    return {
      width: '100%',
      height: '100%',
    };
  }

  render() {
    const { classes, ...restProps } = this.props;
    return (
      <BaseHeader {...restProps}>
        <div className={classes.status} />
      </BaseHeader>
    );
  }
}
