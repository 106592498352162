import React, { FC } from 'react';
import {
  Box,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import warningIcon from 'src/shared/icons/unstable.svg';

export interface Props {
  onFailureProbabilityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  threshold: number;
}

const FailurePribabilityThreshold: FC<Props> = (props) => {
  const { onFailureProbabilityChange, threshold } = props;

  const percentageSign = (
    <Box mt={2}>
      <InputAdornment position="end">%</InputAdornment>
    </Box>
  );

  const warningSign = (
    <InputAdornment position="start">
      <img src={warningIcon} alt="warning state" />
    </InputAdornment>
  );
  return (
    <Box py={1}>
      <FormControl
        variant="filled"
        fullWidth={true}
        onChange={onFailureProbabilityChange}>
        <InputLabel htmlFor="threshold-ctrl" shrink>
          Failure Probability Threshold
        </InputLabel>
        <FilledInput
          endAdornment={percentageSign}
          id="threshold-ctrl"
          inputProps={{
            min: 0,
            max: 100,
            step: 'any',
          }}
          startAdornment={warningSign}
          type="number"
          defaultValue={threshold}
        />
      </FormControl>
    </Box>
  );
};
export default FailurePribabilityThreshold;
