import { connect } from 'react-redux';
import { RootState } from 'src/store';
import SiteEquipmentComponent from './SiteEquipment';

const mapState = ({ siteEquipment }: RootState) => ({ sites: siteEquipment.sites });
const mergeProps = (state: ReturnType<typeof mapState>) => state;

// ignore props from upper component since it pass location related props
// because whenever location changes it leads to component re-rendering
const connector = connect(mapState, null, mergeProps);

export const SiteEquipment = connector(SiteEquipmentComponent);
