import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

const errorWarnContent = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 1.125rem',
  borderRadius: '0.15rem',
};

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      errorContent: {
        ...errorWarnContent,
        backgroundColor: COLORS.red['500'],
      },
      warnContent: {
        ...errorWarnContent,
        backgroundColor: COLORS.orange['500'],
      },
      messageContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      msg: {
        marginLeft: '0.625rem',
        color: 'white',
        fontSize: '0.875rem',
      },
      closeBtn: {
        color: 'white',
        cursor: 'pointer',
      },
      icon: {
        color: 'white',
      },
    })
);
