import toString from 'lodash/toString';

type Value = {
  value: unknown;
};

// -1 means no value applicable on purpose
export const NOT_APPLICABLE = -1;

const isCountable = (value: unknown): value is number => {
  return value !== NOT_APPLICABLE && typeof value === 'number' && Number.isFinite(value);
};

// Example: 54.99 -> 55.0, 54 -> 54.0, '54' -> 'n/a'
const toFixed = (value: unknown) => {
  return isCountable(value) ? value.toFixed(1) : formatNotApplicable({ value });
};

export const formatNotApplicable = ({ value }: Value) => {
  return value === NOT_APPLICABLE || value === NOT_APPLICABLE.toString()
    ? 'n/a'
    : toString(value); // an empty string is returned for null and undefined values. The sign of -0 is preserved.
};

export const formatToFixed = ({ value }: Value) => toFixed(value);
