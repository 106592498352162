import React, { FC, useCallback, useEffect } from 'react';
import { User, UserErrors, UserPayload } from 'src/store/api/users.api';
import { Box, WithStyles, withStyles } from '@material-ui/core';
import Dialog from 'src/shared/dialog';
import { useForm } from 'react-hook-form';
import styles from './add-edit-modal.styles';
import isMatch from 'lodash/isMatch';
import { showSuccess } from 'src/services/snackbars';
import EditModalFormFields from './edit-modal-form-components/edit-modal-form-fields';
import EditModalFormControls from './edit-modal-form-components/edit-modal-form-controls';

const ERRORS_MAP = {
  taken: 'This email is already in use',
} as {
  [key: string]: string;
};

interface ComponentProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  toggleDialog: (isOpen: boolean) => void;
  user?: User;
  onSave: (payload: UserPayload, meta: void) => Promise<void>;
  loading: boolean;
  errors: UserErrors;
  clearErrors: () => void;
}

const AddEditModal: FC<ComponentProps> = (props) => {
  const {
    isOpen,
    toggleDialog,
    user,
    onSave,
    classes,
    loading,
    errors: responseErrors,
    clearErrors: clearResponseErrors,
  } = props;

  const formContext = useForm();
  const { setError, handleSubmit } = formContext;

  useEffect(() => {
    const responseErrorMessage =
      responseErrors.email && ERRORS_MAP[responseErrors.email[0].error];
    if (responseErrorMessage) {
      setError('email', {
        type: 'manual',
        message: responseErrorMessage,
      });
    }
  }, [responseErrors.email, setError]);

  const handleClose = useCallback(() => {
    clearResponseErrors();
    toggleDialog(!isOpen);
  }, [clearResponseErrors, isOpen, toggleDialog]);

  const onSubmit = useCallback(
    (data: Record<string, void>) => {
      if (user && isMatch(user, data)) {
        clearResponseErrors();
        toggleDialog(!isOpen);
        showSuccess('User saved');
        return;
      }
      onSave({
        id: user?.id || null,
        user: data,
        onSuccess: () => toggleDialog(!isOpen),
      } as UserPayload);
    },
    [clearResponseErrors, isOpen, onSave, toggleDialog, user]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitBusy={loading}
      title={user ? 'Edit User' : 'Add User'}
      submitLabel="Save">
      <Box className={classes.dialogContent}>
        <EditModalFormFields user={user} formContext={formContext} />
        <EditModalFormControls user={user} formContext={formContext} />
      </Box>
    </Dialog>
  );
};

export default withStyles(styles)(AddEditModal);
