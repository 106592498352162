import React, { FC, ReactNode, memo } from 'react';
import { Button, WithStyles } from '@material-ui/core';
import clsx from 'clsx';
import styles from './styles';
import { ToggleGroupItemValue } from 'src/shared/toggle-button-group/toggle-button-group';

interface Props extends WithStyles<typeof styles> {
  children: ReactNode | string;
  value: ToggleGroupItemValue;
  isSelected?: boolean;
  onClick?: (value: ToggleGroupItemValue) => void;
  className?: string;
}

const ToggleButton: FC<Props> = (props) => {
  const { isSelected, classes } = props;
  const { children, value, onClick, className: groupClasses } = props;
  const handleClick = () => {
    onClick && onClick(value);
  };

  return (
    <Button
      variant="contained"
      size="small"
      className={clsx(classes.button, groupClasses, {
        [classes.selected]: isSelected,
      })}
      onClick={handleClick}>
      {children}
    </Button>
  );
};

export default memo(ToggleButton);
