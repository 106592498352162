import React from 'react';
import { CellRenderer } from 'src/shared/base-grid/base-grid';
import { useStyles } from './failure-prediction.styles';
import CriticalPredictionIcon from './../../../icons/critical-prediction-icon';
import UnstablePredictionIcon from 'src/shared/icons/unstable-prediction-icon';
import NormalPredictionIcon from 'src/shared/icons/normal-prediction-icon';

export const ProbabilityStatus = {
  critical: 'critical',
  warning: 'warning',
  normal: 'normal',
};

export const FailurePredictionRenderer: React.FC<CellRenderer<number>> = (props) => {
  const styles = useStyles();
  const renderIcon = () => {
    const { data } = props;
    const { critical, warning } = ProbabilityStatus;

    if (data && data.failureProbabilityStatus) {
      switch (data.failureProbabilityStatus) {
        case critical:
          return <CriticalPredictionIcon />;

        case warning:
          return <UnstablePredictionIcon />;

        default:
          return <NormalPredictionIcon />;
      }
    }

    return null;
  };

  const isNumeric = (value: unknown): boolean => typeof value === 'number';

  return (
    <div className={styles.container}>
      {isNumeric(props.value) ? (
        <React.Fragment>
          {renderIcon()}
          <span className={styles.value}>{props.value.toFixed(1)}</span>
        </React.Fragment>
      ) : null}
    </div>
  );
};
