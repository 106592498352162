import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

const onlineStatusStyles = (): StyleRules =>
  createStyles({
    status: {
      width: '0.75rem',
      height: '0.75rem',
      marginBottom: '0.125rem',
      borderRadius: '50%',
      outline: 'none',
      border: `1px solid ${COLORS.green['500']}`,
      backgroundColor: COLORS.green['500'],
    },
  });
export default onlineStatusStyles;
