import {
  getDefaultFilters,
  getStartEndDates,
} from 'src/shared/date-range-filter/filters-config';
import { ToggleGroupItem } from 'src/shared/toggle-button-group';

export const getFilters = (): ToggleGroupItem[] => {
  const ranges = ['1 week', '1 month', '3 months'];

  return getDefaultFilters()
    .filter(({ label }) => ranges.includes(label))
    .map((range, index) => ({ ...range, isSelected: index === ranges.length - 1 }));
};

// 3 months
export const getDefaultDateRange = () => {
  const filters = getFilters();
  const [start, end] = getStartEndDates(filters[filters.length - 1]);
  return { start, end };
};
