import { useLocation } from 'react-router-dom';
import { routesConstants } from './routes.constants';
import {
  COMPRESSOR,
  CONTROLLER,
  EquipmentType,
  PUMP,
  SITE,
  TOOL,
} from './with-selected-equipment';

function getType(pathname: string, search: URLSearchParams): EquipmentType {
  if (!pathname.startsWith(routesConstants.DASHBOARD)) return 'unknown';

  if (search.get('pumpId')) return PUMP;

  if (search.get('controllerId')) return CONTROLLER;

  if (search.get('compressorId')) return COMPRESSOR;

  if (search.get('toolId')) return TOOL;

  return SITE;
}

export const getEquipmentType = (type: EquipmentType | null) => {
  const isEquipment = type === PUMP || type === COMPRESSOR || type === CONTROLLER;

  if (isEquipment) {
    return type;
  }
  return null;
};

const toInt = (search: URLSearchParams, key: string) => {
  const value = search.get(key);
  return value !== null ? parseInt(value, 10) : null;
};

export function useEquipmentIdentifiers() {
  const { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const type = getType(pathname, searchParams);
  const siteId = toInt(searchParams, 'siteId'); // todo: site might be equal to number[]
  const toolId = toInt(searchParams, 'toolId');
  const pumpId = toInt(searchParams, 'pumpId');
  const controllerId = toInt(searchParams, 'controllerId');
  const compressorId = toInt(searchParams, 'compressorId');

  return { type, siteId, toolId, pumpId, controllerId, compressorId };
}
