import React from 'react';
import Info from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from './confusion-matrix.styles';

type TooltipProps = {
  failedCount: number;
  normalCount: number;
  tp: number;
  fn: number;
  tn: number;
  fp: number;
};

const makeHelpString = ({ failedCount, normalCount, tp, fn, tn, fp }: TooltipProps) => `
      The preceding confusion matrix shows that of the ${failedCount} samples that actually had failed state,
      the model correctly classified ${tp} as failed (${tp} true positives),
      and incorrectly classified ${fn} as normal (${fn} false negative).

      Similarly, of ${normalCount} samples that actually normal,
      ${tn} were correctly classified (${tn} true negatives) and
      ${fp} were incorrectly classified (${fp} false positives).
    `;

export const ConfusionMatrixTooltip = (props: TooltipProps) => {
  const classes = useStyles();

  // If any of counts is NaN it means no data is present
  const isEmpty = Number.isNaN(props.failedCount) || Number.isNaN(props.normalCount);

  if (isEmpty) {
    return null;
  }

  return (
    <Tooltip
      title={makeHelpString(props)}
      placement="top"
      enterDelay={200}
      leaveDelay={1000}
      classes={{ tooltip: classes.tooltip }}>
      <Info classes={{ root: classes.icon }} />
    </Tooltip>
  );
};
