import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

const loginStyles = (): StyleRules =>
  createStyles({
    loginContainer: {
      height: 'auto',
    },
    errorContainer: {
      width: '27rem',
      height: '3.5rem',
      margin: '1.5rem auto 0',
    },
    formContainer: {
      width: '30rem',
      padding: '1.5rem',
      boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.25)',
      backgroundColor: 'white',
      marginBottom: '3rem',
      marginTop: '3rem',
    },
  });
export default loginStyles;
