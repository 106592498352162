import React, { FC, useCallback, useMemo } from 'react';
import { FormContextValues } from 'react-hook-form';
import { AutocompleteOption } from 'src/shared/autocompletes-wtih-chips';
import { ResourceField, resourceMetricsMap } from 'src/app/charts/chart-form/utils';
import { DefaultChartValues } from 'src/app/charts/chart-form/chart-form';
import ChartFormEntities from './entity-controller-view';

export interface Props {
  entityType: 'Tool' | 'Compressor' | 'Pump';
  formContext: FormContextValues<DefaultChartValues>;
}

const getEntitiesIds = (list: AutocompleteOption[] = []) =>
  list.map(({ value }) => value) as number[];

const ChartFormResourceControllers: FC<Props> = (props: Props) => {
  const { formContext, entityType } = props;
  const { watch, getValues, clearError, setError } = formContext;
  const entities = watch('entities');
  const selectedSitesOptions = watch('sites');

  const filters = useMemo(
    () => ({
      siteId: getEntitiesIds(selectedSitesOptions),
      toolId: getEntitiesIds(entities?.tools),
    }),
    [entities, selectedSitesOptions]
  );

  const handleValidateResources = useCallback(
    (field: ResourceField, otherResourcesFields: string[]) => (
      value: AutocompleteOption[]
    ): boolean => {
      const validByOtherResourceOrMetrics =
        otherResourcesFields.some((field) => !!getValues(field).length) &&
        !resourceMetricsMap[field].some((metricField) => !!getValues(metricField).length);
      return !!value.length || validByOtherResourceOrMetrics;
    },
    [getValues]
  );

  const handleChangeResources = useCallback(
    (field: ResourceField, otherResourcesFields: string[]) => ([
      value,
    ]: any[]): AutocompleteOption[] => {
      clearError([
        'entities.tools',
        'entities.pumps',
        'entities.compressors',
        ...(!value.length ? resourceMetricsMap[field] : []),
        ...(field === 'entities.pumps' || field === 'entities.compressors'
          ? resourceMetricsMap['entities.tools']
          : []),
      ]);
      if (
        !value.length &&
        otherResourcesFields.every((field) => !getValues(field).length)
      ) {
        setError([
          { type: 'validate', name: 'entities.tools' },
          { type: 'validate', name: 'entities.pumps' },
          { type: 'validate', name: 'entities.compressors' },
        ]);
      }
      return value;
    },
    [clearError, getValues, setError]
  );
  return (
    <ChartFormEntities
      entityType={entityType}
      formContext={formContext}
      filters={filters}
      handleValidateResources={handleValidateResources}
      handleChangeResources={handleChangeResources}
    />
  );
};
export default ChartFormResourceControllers;
