import { createModel } from '@rematch/core';
import { RootModel } from '.';
import timezones from 'src/services/timezones.json';

export interface Timezone {
  name: string;
  offset: number;
}

interface State {
  selectedZone: Timezone;
}

export const timezoneStorage = {
  get(): string {
    return localStorage.getItem('tz') || '';
  },
  set(name: string) {
    localStorage.setItem('tz', name || '');
  },
  unset() {
    localStorage.removeItem('tz');
  },
};

// todo: save on backend user timezone instead of client side
const setSavedOrDefaultTimeZone = () => {
  const offset = new Date().getTimezoneOffset();
  const savedZoneName = timezoneStorage.get();
  const zone = timezones.find((item) =>
    savedZoneName ? savedZoneName === item.name : item.offset === -offset
  ) as Timezone; // todo: the find function by default returns undefined if value not found.
  if (savedZoneName === '') timezoneStorage.set(zone.name); // To remove type cast the state type has to be changed.

  return zone;
};

export const timezone = createModel<RootModel>()({
  state: {
    selectedZone: setSavedOrDefaultTimeZone(),
  } as State,

  baseReducer(state, action) {
    return state === undefined || action.type === 'CLEAR_STORE'
      ? { selectedZone: setSavedOrDefaultTimeZone() }
      : state;
  },

  reducers: {
    setTimezone(state, zone: Timezone) {
      return {
        selectedZone: zone,
      };
    },
  },

  effects: (dispatch) => ({
    updateTimezone(zone: Timezone) {
      return Promise.resolve().then(() => {
        timezoneStorage.set(zone.name);
        dispatch.timezone.setTimezone(zone);
      });
    },
  }),
});
