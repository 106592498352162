import { Box, Grid, MenuItem } from '@material-ui/core';
import React, { FC } from 'react';
import {
  CategoryTypeOptions,
  ManualTypeOptions,
  ManualTypes,
  getCategoryType,
  getEquipmentIdentifiers,
  getManualCategorySubtype,
} from 'src/store/api/notifications.api';
import { NotificationsSelect } from './notifications-select';
import { getManualTypeIcon } from '../notifications.helper';
import { DateFilter } from './date-filter';
import { useStyles } from './styles';
import ExportBtn from 'src/shared/button-export';
import { decamelizeAndCapitalize } from 'src/services/notifications-service';
import { exportXlsxCallback } from 'src/services/xlsx-export';
import { decamelizeKeys } from 'humps';

const categories: CategoryTypeOptions[] = ['all', 'auto', 'manual'];
const manualTypes: ManualTypeOptions[] = [
  'all',
  ...Object.values<ManualTypes>(ManualTypes),
];

const exportUrl = '/notifications.xlsx';

interface Props {
  category: CategoryTypeOptions;
  manualType: ManualTypeOptions;
  dates: [Date, Date];
  handleDatePickerChange: (start: Date, end: Date) => void;
  handleCategorySelectChange: (category: CategoryTypeOptions) => void;
  handleManualTypeSelectChange: (manualType: ManualTypeOptions) => void;
}

export const NotificationsHeader: FC<Props> = ({
  category,
  manualType,
  dates,
  handleDatePickerChange,
  handleCategorySelectChange,
  handleManualTypeSelectChange,
}) => {
  const classes = useStyles();

  const categoryOptions = categories.map((category) => (
    <MenuItem key={category} value={category}>
      {decamelizeAndCapitalize(category)}
    </MenuItem>
  ));

  const manualTypeOptions = manualTypes.map((type) => (
    <MenuItem key={type} value={type}>
      {type !== 'all' && getManualTypeIcon(type, classes.icon)}
      {decamelizeAndCapitalize(type)}
    </MenuItem>
  ));

  const handleExport = (url: string, title: string) => {
    const params = {
      ...getEquipmentIdentifiers(),
      startDate: dates[0],
      endDate: dates[1],
      notificationType: getCategoryType(category),
      manualType: getManualCategorySubtype(category, manualType),
    };
    return exportXlsxCallback(url, decamelizeKeys(params), title);
  };

  return (
    <Grid className={classes.headerRoot} container wrap="wrap" justify="space-between">
      <Grid item>
        <DateFilter dates={dates} handleDatePickerChange={handleDatePickerChange} />
      </Grid>
      <Grid item style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Box className={classes.selectSection}>
          <NotificationsSelect
            label="Category"
            style={{ marginRight: '1rem' }}
            value={category}
            options={categoryOptions}
            onChange={handleCategorySelectChange}
          />

          <NotificationsSelect
            label="Manual type"
            disabled={category === 'auto'}
            value={manualType}
            options={manualTypeOptions}
            onChange={handleManualTypeSelectChange}
          />
        </Box>
        <ExportBtn url={exportUrl} onClick={handleExport} />
      </Grid>
    </Grid>
  );
};
