import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  color?: string;
  className?: string;
}

const CheckboxIcon: FC<Props> = (props) => {
  const { color, className } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 16 16">
      <path d="M0 0h16v16H0V0z" fill={color} />
      <path
        d="M5.818 10.195L3.335 7.586 2 8.989 5.818 13 14 4.403 12.665 3l-6.847 7.195z"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default CheckboxIcon;
