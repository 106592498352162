import React, { FC, useCallback, useState } from 'react';
import { Edit } from '@material-ui/icons';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { CellClassParams } from 'ag-grid-community';
import UpdatePasswordIcon from 'src/shared/icons/update-password-icon';
import AddEditModal from 'src/app/admin/add-edit-modal';
import ResetPassModal from 'src/app/admin/reset-pass-modal';

const AdminActionRenderer: FC<CellClassParams> = (props) => {
  const { data } = props;
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenResetPass, setIsOpenResetPass] = useState(false);

  const toggleEditDialog = useCallback(() => {
    setIsOpenEdit(!isOpenEdit);
  }, [isOpenEdit]);

  const toggleResetPassDialog = useCallback(() => {
    setIsOpenResetPass(!isOpenResetPass);
  }, [isOpenResetPass]);

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Edit">
        <IconButton color="inherit" size="small" onClick={toggleEditDialog}>
          <Edit aria-haspopup="true" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Reset Password">
        <IconButton color="inherit" size="small" onClick={toggleResetPassDialog}>
          <UpdatePasswordIcon aria-haspopup="true" />
        </IconButton>
      </Tooltip>
      {isOpenEdit && (
        <AddEditModal isOpen={isOpenEdit} toggleDialog={toggleEditDialog} user={data} />
      )}
      {isOpenResetPass && (
        <ResetPassModal
          isOpen={isOpenResetPass}
          toggleDialog={toggleResetPassDialog}
          userId={data.id}
        />
      )}
    </Box>
  );
};

export default AdminActionRenderer;
