export const downloadFile = async (data: ArrayBuffer, title: string) => {
  const isIE = !!window.navigator?.msSaveOrOpenBlob;

  const blob = new Blob([data]);

  if (isIE) {
    window.navigator.msSaveOrOpenBlob(blob, title);
  } else {
    const href = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', title);
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(link.href);
    link.remove();
  }
};
