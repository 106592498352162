import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import Dialog from 'src/shared/dialog';
import { ConnectedProps } from './deactivate-user-modal.container';
import { Sizes } from 'src/shared/dialog/dialog';
import { User } from 'src/store/api/users.api';

interface Props extends ConnectedProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  user: User;
}

const DeactivateUserModal: FC<Props> = (props) => {
  const { loading, isOpen, onClose, onSubmit, editUserStatus, user } = props;

  const handleClose = () => onClose();
  const handleSubmit = () => {
    editUserStatus({ user, deactivated: false, onSuccess: onSubmit });
  };

  return (
    <Dialog
      open={isOpen}
      size={Sizes.small}
      title="Deactivate User?"
      submitLabel="Deactivate"
      isSubmitBusy={loading}
      onClose={handleClose}
      onSubmit={handleSubmit}>
      <Typography variant="body2" component="span">
        Do you want to deactivate this user?
      </Typography>
    </Dialog>
  );
};

export default DeactivateUserModal;
