import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import ViewBasedGrid from 'src/shared/view-based-grid';
import { HEADER_HEIGHTS } from 'src/shared/base-grid/base-grid.config';

const PumpSettings: FC = () => {
  const dispatch = useDispatch<RootDispatch>();
  const rows = useSelector(({ pumpSettings }: RootState) => pumpSettings.rows);
  const columns = useSelector(({ views }: RootState) => views.pumpSettings?.columns);
  const { siteId, toolId } = useEquipmentIdentifiers();

  const exportUrl = '/pumps/settings.xlsx';
  const refetch = [siteId, toolId].join(':');

  const unsetRows = useCallback(() => dispatch.pumpSettings.setRows(null), [
    dispatch.pumpSettings,
  ]);

  return (
    <ViewBasedGrid
      fetchRows={dispatch.pumpSettings.fetchRows}
      fetchColumns={dispatch.views.fetchPumpSettingsView}
      onCleanup={unsetRows}
      rows={rows}
      columns={columns}
      search={refetch}
      exportUrl={exportUrl}
      headerHeight={HEADER_HEIGHTS.twoRows}
    />
  );
};

export default PumpSettings;
