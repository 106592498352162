import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import { GaugeArc } from './gauge-arc';
import { useStyles } from './gauge-chart.styles';

export interface GaugeChartProps {
  value: number;
  status: string;
  tooltipMsg?: string;
}

export const GaugeChart: FC<GaugeChartProps> = (props) => {
  const { status, tooltipMsg, value } = props;
  const styles = useStyles();

  return (
    <div className={styles.gaugeChartContainer}>
      <div className={styles.gaugeChart}>
        <GaugeArc value={value} status={status} />
      </div>
      <Tooltip title={tooltipMsg || ''} classes={{ tooltip: styles.tooltip }}>
        <div className={styles.gaugeChartLabel}>{props.children}</div>
      </Tooltip>
    </div>
  );
};
