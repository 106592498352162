import React, { FC, useCallback } from 'react';
import { ChartListItem } from 'src/store/api/charts-builder.api';
import { ChartsFormData } from 'src/store/models/charts-builder.model';
import { TextField } from '@material-ui/core';
import { DefaultChartValues } from 'src/app/charts/chart-form/chart-form';
import { FormContextValues } from 'react-hook-form';

export interface Props {
  formContext: FormContextValues<DefaultChartValues>;
  formData?: ChartsFormData | null;
  charts: ChartListItem[];
}

const ChartFormTitle: FC<Props> = (props: Props) => {
  const { formData, charts, formContext } = props;
  const { register, errors, clearError } = formContext;

  const handleValidateTitle = useCallback(
    (value: string) => {
      if (value !== formData?.title || !formData.title) {
        return charts.every((entry) => entry.name.toLowerCase() !== value.toLowerCase());
      }
    },
    [charts, formData]
  );

  const handleTitleChange = useCallback(() => clearError('chartTitle'), [clearError]);

  let helperText = '';
  if (errors.chartTitle) {
    if (errors.chartTitle.type === 'validate') {
      helperText = 'A chart with this title already exists';
    }
    if (errors.chartTitle.type === 'required') {
      helperText = 'This field is required for submission';
    }
  }

  return (
    <TextField
      key={formData?.title}
      id="chart-title"
      label="Chart Title"
      variant="filled"
      name="chartTitle"
      inputRef={register({
        required: true,
        maxLength: 300,
        validate: handleValidateTitle,
      })}
      error={!!errors.chartTitle}
      fullWidth
      autoComplete="off"
      onChange={handleTitleChange}
      helperText={helperText}
    />
  );
};
export default ChartFormTitle;
