import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

const baseHeaderStyles = (): StyleRules =>
  createStyles({
    agReactContainer: {
      width: '100%',
      height: '100%',
    },
    headerContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-end',
    },
    headerWrapper: {
      width: '100%',
      height: '100%',
    },
    sortContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexFlow: 'row',
      alignItems: 'flex-end',
      padding: '0.5rem',
      cursor: 'pointer',
    },
    arrowAsc: {
      transform: 'rotate(180deg)',
    },
    arrowNone: {
      visibility: 'hidden',
    },
    dataType: {
      borderTop: `1px solid ${COLORS.grey['300']}`,
      color: COLORS.grey['600'],
      minHeight: '1.5rem',
      maxHeight: '1.5rem',
      width: '100%',
      padding: '0.25rem 0.5rem',
      fontWeight: 'lighter',
    },
  });
export default baseHeaderStyles;
