import { createModel } from '@rematch/core';
import { RootModel } from '.';

import get from 'lodash/get';
import { showError } from 'src/services/snackbars';
import { GroupedSeries, StartEndDates, chartApi } from '../api/charts.api';

type Series = GroupedSeries[];

export type FetchChartSeriesParams = {
  id: number;
  dates: StartEndDates;
};

interface ChartSeriesState {
  series: Series;
}

export const chartSeries = createModel<RootModel>()({
  state: {
    series: [],
  } as ChartSeriesState,

  reducers: {
    set(state: ChartSeriesState, series: Series): ChartSeriesState {
      return {
        ...state,
        series,
      };
    },
  },

  effects: (dispatch) => ({
    fetchChartSeries({ id, dates }: FetchChartSeriesParams, { timezone }): Promise<void> {
      return chartApi
        .fetchSeriesById(id, dates, timezone.selectedZone.offset)
        .then((series) => {
          dispatch.chartSeries.set(series);
        })
        .catch((err) => {
          if (!err.__CANCEL__) {
            showError(get(err, 'response.data.error'));
            dispatch.chartSeries.set([]);
          }
        });
    },
  }),
});
