import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import { RootDispatch, RootState } from 'src/store';
import { When } from 'src/shared/when';
import { Spinner } from 'src/shared/spinner';
import Button from 'src/shared/button';
import { PredictionsDashboard } from './predictions-dashboard';
import { getQuerySearch } from 'src/shared/routing/routing.helper';
import EmptyState from 'src/shared/empty-state';

type Props = ReturnType<typeof mapDispatch> & ReturnType<typeof mapState>;

class PredictionsDashboardContainer extends React.Component<Props> {
  render() {
    const collectionExists = Boolean(this.props.collection);

    return (
      <React.Fragment>
        <When condition={collectionExists}>{() => this.renderDataSection()}</When>
      </React.Fragment>
    );
  }

  renderMetaSection() {
    const { failed, pending, collection } = this.props;
    const isEmpty = collection.length === 0 && !pending;

    return (
      <Box>
        <When condition={failed || isEmpty}>
          <EmptyState />
          <Box p={1} textAlign="center">
            <Button color="secondary" onClick={this.handleFetchCollection}>
              Refresh
            </Button>
          </Box>
        </When>

        <When condition={pending}>
          <Box p={1} textAlign="center">
            <Spinner />
          </Box>
        </When>
      </Box>
    );
  }

  renderDataSection() {
    const matches = this.props.collection;

    return (
      <React.Fragment>
        {this.renderMetaSection()}

        <When condition={matches.length > 0}>
          <PredictionsDashboard
            onMoveTo={this.props.navigateToEquipment}
            collection={matches}
            updateFeedbackStatus={this.props.updateFeedbackStatus}
          />
        </When>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.handleFetchCollection();
  }

  handleFetchCollection = () => {
    this.props.fetchCollection();
  };
}

const mapState = (state: RootState) => ({
  collection: state.predictions.collection,
  failed: Boolean(state.predictions.error),
  pending: state.loading.effects.predictions.fetchPredictions,
  search: getQuerySearch(),
});

const mapDispatch = (dispatch: RootDispatch) => ({
  fetchCollection: dispatch.predictions.fetchPredictions,
  updateFeedbackStatus: dispatch.predictions.updateFeedbackStatus,
  navigateToEquipment: dispatch.siteEquipment.navigateToEquipment,
});

export default connect(mapState, mapDispatch)(PredictionsDashboardContainer);
