import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

const buttonStyles = (): StyleRules =>
  createStyles({
    button: {
      boxShadow: 'none',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
    },
    primary: {
      backgroundColor: COLORS.primary,
      '&:hover': {
        backgroundColor: COLORS.red['700'],
      },
      '&:disabled': {
        backgroundColor: COLORS.red['50'],
        color: COLORS.white,
      },
    },
    secondary: {
      backgroundColor: COLORS.secondary,
      color: COLORS.black,
      '&:hover': {
        backgroundColor: COLORS.grey['400'],
      },
    },
    hidden: {
      visibility: 'hidden',
    },
    busy: {
      position: 'absolute',
      margin: 'auto',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
  });
export default buttonStyles;
