import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import ChartForm from './chart-form';
import { Box } from '@material-ui/core';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ChartFormContainer: FC<Props> = (props) => {
  const {
    sites,
    fetchSites,
    createChart,
    fetchMetrics,
    metrics,
    isLoading,
    charts,
  } = props;

  useEffect(() => {
    if (!sites.length) {
      fetchSites();
    }
  }, [fetchSites, sites]);

  useEffect(() => {
    if (!metrics) {
      fetchMetrics();
    }
  }, [fetchMetrics, metrics]);

  return (
    <Box py={3} px={2}>
      <ChartForm
        sites={sites}
        charts={charts}
        onSave={createChart}
        metrics={metrics}
        isLoading={isLoading}
      />
    </Box>
  );
};

export const mapStateToProps = (state: RootState) => ({
  sites: state.siteEquipment.sites,
  metrics: state.charts.metrics,
  charts: state.charts.charts,
  isLoading:
    state.loading.effects.charts.createChart ||
    state.loading.effects.charts.fetchCharts ||
    state.loading.effects.siteEquipment.fetchSites,
});

export const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchSites: dispatch.siteEquipment.fetchSites,
  fetchMetrics: dispatch.charts.fetchMetrics,
  createChart: dispatch.charts.createChart,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartFormContainer);
