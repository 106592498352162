import React, { FC } from 'react';
import FeedbackAction from 'src/shared/feedback-action';
import { OnlineOfflineStatus } from 'src/shared/online-offline-status';
import { useStyles } from '../predictions-dashboard.styles';
import { Feedback, FeedbackStatus } from 'src/store/api/feedbacks.api';
import { Pump } from '../predictions-dashboard';

type PumpFeedbackStatus = FeedbackStatus | null;

export interface Props {
  pump: Pump;
  handleCollectionUpdate: (value: Feedback) => void;
}

const ToolbarSlot: FC<Props> = (props) => {
  const styles = useStyles();
  const { pump, handleCollectionUpdate } = props;

  return (
    <div className={styles.toolbarSlot}>
      <div>
        <OnlineOfflineStatus status={pump.online ? 'online' : 'offline'} />
      </div>
      <div className={styles.mAuto} />
      <div className={styles.actionIcons}>
        <FeedbackAction
          status={(pump.feedbackStatus as PumpFeedbackStatus) || undefined} //TODO: in type Pump, pump.feedbackStatus is of type string| number | null should be of type FeedbackStatus | null
          toolName={pump.toolName}
          pumpName={pump.stationName}
          pumpId={pump.id}
          siteId={pump.siteId}
          onAfterSave={handleCollectionUpdate}
        />
      </div>
    </div>
  );
};
export default ToolbarSlot;
