import React, { FC } from 'react';
import PumpDetailsTableView from './pump-details-table';
import { formatRowsToNA } from './table-ui-formatters';
import { PumpDetails } from 'src/store/api/pumps.api';

interface Props {
  pump: PumpDetails;
}

export const PumpInfoTable: FC<Props> = (props) => {
  const { pump } = props;

  const rows = [
    {
      label: 'GLS ID',
      value: pump.siteId,
    },
    {
      label: 'Tool Address',
      value: pump.controllerIpAddress,
    },
    {
      label: 'Controller Serial Number',
      value: pump.controllerSerialNumber,
    },
    {
      label: 'Controller SW Revision',
      value: pump.controllerSoftwareRev,
    },
    {
      label: 'Pump ID',
      value: pump.id,
    },
    {
      label: 'Pump Address',
      value: pump.station,
    },
    {
      label: 'Serial Number',
      value: pump.serialNumber,
    },
    {
      label: 'SW Revision',
      value: pump.softwareVersion,
    },
    {
      label: 'Password',
      value: pump.modulePassword,
    },
    {
      label: 'Model',
      value: pump.model,
    },
    {
      label: 'Part Number',
      value: pump.partNumber,
    },
    {
      label: 'Description',
      value: pump.partNumberDescription,
    },
  ];

  const formattedRows = formatRowsToNA(rows);
  return <PumpDetailsTableView rows={formattedRows} />;
};
