import React, { ChangeEvent, ReactNode, useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import Dates, { FeedbackDates } from './dates';
import { FeedbackValidationErrors } from 'src/store/models/feedback.model';
import Comments from './comments';
import Question from './question';
import StatusTable, { Entity } from './status-table';
import { FeedbackDataState } from '../feedback-modal';
import { Statuses } from 'src/store/api/feedbacks.api';
import issues, { Issue } from '../issues';
import AutocompleteWithChips from 'src/shared/autocompletes-wtih-chips/basic'; //todo: naming
import { Action } from '@rematch/core';

type SetInvalidFieldsProp = ((
  payload: FeedbackValidationErrors
) => Action<FeedbackValidationErrors, void>) & { isEffect: false };

export interface Props {
  feedbackData: FeedbackDataState;
  invalidDates: FeedbackValidationErrors;
  comment: string;
  setFeedback: React.Dispatch<FeedbackDataState>;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  setDatesInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  setInvalidFields: SetInvalidFieldsProp;
}

const findNewIssue = (feedbackData: FeedbackDataState, values: Issue[]) =>
  issues.reduce(
    (acc, next) => ({
      ...acc,
      [next.value]: values.includes(next),
    }),
    feedbackData
  );

const FeedbackModalDialogContent = (props: Props) => {
  const {
    feedbackData,
    setFeedback,
    setDatesInvalid,
    setInvalidFields,
    invalidDates,
    setComment,
    comment,
  } = props;

  const selectedIssues = useMemo(
    () => (feedbackData ? issues.filter((issue) => feedbackData[issue.value]) : []),
    [feedbackData]
  );
  const handleStatusChange = useCallback(
    (type: Entity, value: Statuses) => setFeedback({ ...feedbackData, [type]: value }),
    [feedbackData, setFeedback]
  );

  const handleQuestionChange = useCallback(
    (value: boolean) => setFeedback({ ...feedbackData, must_be_swapped: value }),
    [feedbackData, setFeedback]
  );

  const handleDatesChange = useCallback(
    (value: FeedbackDates) => {
      setFeedback({ ...feedbackData, ...value });
      setInvalidFields(null);
      setDatesInvalid(!value.reference_date);
    },
    [feedbackData, setInvalidFields, setFeedback, setDatesInvalid]
  );

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | Record<string, never>>, values: Issue[]) => {
      const newValue = findNewIssue(feedbackData, values);
      setFeedback({ ...feedbackData, ...newValue });
    },
    [feedbackData, setFeedback]
  );

  const handleCommentsChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value),
    [setComment]
  );

  const handleDatesErr = useCallback((err: ReactNode) => setDatesInvalid(!!err), [
    setDatesInvalid,
  ]);

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={2}>
        <StatusTable
          onChange={handleStatusChange}
          pumpState={feedbackData?.pump_state}
          controllerState={feedbackData?.controller_state}
          compressorState={feedbackData?.compressor_state}
        />
      </Box>
      <Box mb={2}>
        <Dates
          onChange={handleDatesChange}
          onError={handleDatesErr}
          pmDate={feedbackData?.pm_date}
          referenceDate={feedbackData?.reference_date}
          invalidFields={invalidDates}
        />
      </Box>
      <Box mb={2}>
        <AutocompleteWithChips
          options={issues}
          value={selectedIssues}
          onChange={handleSelectChange}
          label="Issues"
        />
      </Box>
      <Box mb={2}>
        <Question
          value={Boolean(feedbackData?.must_be_swapped)}
          onChange={handleQuestionChange}
        />
      </Box>
      <Box>
        <Comments comment={comment} onChange={handleCommentsChange} />
      </Box>
    </Box>
  );
};
export default FeedbackModalDialogContent;
