import React, { FC } from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import Button from 'src/shared/button';
import styles from './login-form.styles';
import { useForm } from 'react-hook-form';
import { ResetPassFields } from 'src/shared/password-fields';

interface Props extends WithStyles<typeof styles> {
  onSubmit: (newPassword: string) => void;
  loading: boolean;
}

const UpdatePassForm: FC<Props> = (props) => {
  const { loading, classes, onSubmit } = props;
  const { register, errors, clearError, getValues, handleSubmit } = useForm();

  const onFormSubmit = (data: Record<string, string>) => {
    onSubmit(data.newPassword);
  };

  return (
    <form className={classes.loginForm} onSubmit={handleSubmit(onFormSubmit)}>
      <span className={classes.formTitle}>Update Password</span>
      <ResetPassFields
        className={classes.textField}
        fullWidth
        config={{
          password: {
            name: 'newPassword',
            label: 'New Password',
            helperText: 'At least 6 characters',
          },
          confirmPassword: {
            name: 'confirmedPassword',
            label: 'Confirm Password',
          },
        }}
        errors={errors}
        register={register}
        clearError={clearError}
        getValues={getValues}
      />
      <Button disabled={Boolean(loading)} type="submit">
        SAVE
      </Button>
    </form>
  );
};

export default withStyles(styles)(UpdatePassForm);
