/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  Column,
  ColumnApi,
  ColumnMovedEvent,
  DragStoppedEvent,
  GetRowNodeIdFunc,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IGetRowsParams,
  RowClassParams,
  ValueFormatterParams,
  ValueGetterParams,
  _,
} from 'ag-grid-community';
import styles from './base-grid.styles';
import { CSSProperties, WithStyles } from '@material-ui/core/styles/withStyles';
import BaseHeader from './base-header';
import baseGridSizes from './base-grid.config';
import { formatNotApplicable } from './value-formatters';
import { CellClassParams } from 'ag-grid-community';
import EmptyState from 'src/shared/empty-state';

export interface CellRenderer<V> extends ICellRendererParams {
  value: V;
}

export interface StyledColDef extends ColDef {
  cellStyle?: CSSProperties | ((params: CellClassParams) => CSSProperties);
}

export interface ColData {
  valueGetter?: ((params: ValueGetterParams) => unknown) | string;
  valueFormatter?: ((params: ValueFormatterParams) => string) | string;
  filterId?: string | number | null;
  id?: number;
  index?: number | null;
  label: string;
  name: string;
  sortDirection?: string | null;
  sortOrder?: string | null;
  type: string | string[] | null;
  units: string | null;
  viewId?: number | null;
  visibility: boolean;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  flex?: number;
}

interface OwnProps extends WithStyles<typeof styles> {
  context?: Record<string, string | never>; // Todo: after testing all grids values for context are either '{}' or 'undefined'.
  headerHeight?: number;
  autoSizeColumns: boolean;
  colData: StyledColDef[];
  rows?: unknown[] | null;
  displayAllRows?: boolean;
  fetchRows?: (params: IGetRowsParams, gridApi: GridApi | null) => void;
  onGridReady?: (ev: GridReadyEvent) => void;
  onColumnMoved?: (ev: ColumnMovedEvent) => void;
  onDragStopped?: (ev: DragStoppedEvent) => void;
  getRowNodeId?: GetRowNodeIdFunc;
  getRowStyle?: (params: RowClassParams) => CSSProperties | undefined;
}

export default class BaseGrid extends PureComponent<OwnProps> {
  private defaultColDef: StyledColDef = {
    headerComponentFramework: BaseHeader,
    headerClass: this.props.classes.defaultHeader,

    cellClass: this.props.classes.defaultCell,
    valueFormatter: formatNotApplicable,
    comparator: (a, b, nodeA, nodeB, isInverted) => {
      if (a === -1 || b === -1) {
        if (typeof a === 'string' || typeof b === 'string') {
          return typeof a === 'string' ? -1 : 1;
        }
      }
      return _.defaultComparator(a, b, isInverted);
    },
  };

  private gridApi: GridApi | null = null;

  private columnApi: ColumnApi | null = null;

  private sizeColumnsToFit = () => {
    this.gridApi?.sizeColumnsToFit();
  };

  handleGridReady = (params: GridReadyEvent) => {
    const { onGridReady } = this.props;

    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    if (this.props.autoSizeColumns) {
      window.addEventListener('resize', this.sizeColumnsToFit);
    }

    if (onGridReady) {
      onGridReady(params);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.sizeColumnsToFit);
  };

  handleModelUpdate = () => {
    if (this.props.autoSizeColumns) {
      this.sizeColumnsToFit();
    }
  };

  handleFirstDataRendered = () => {
    if (this.props.autoSizeColumns) {
      const skipHeaderOnAutoSize = true;

      const keys = this.props.colData
        .filter((col) => !col.suppressSizeToFit)
        .map(({ field }) => field);

      const colApi = this.columnApi as ColumnApi;
      const grdApi = this.gridApi as GridApi;

      colApi.autoSizeColumns(keys as (string | Column)[]);

      skipHeaderOnAutoSize && grdApi.sizeColumnsToFit();
    }
  };

  private handleColumnMove = (ev: ColumnMovedEvent) => {
    const { onColumnMoved } = this.props;

    onColumnMoved && onColumnMoved(ev);
  };

  private onDragStopped = (ev: DragStoppedEvent) => {
    const { onDragStopped } = this.props;

    onDragStopped && onDragStopped(ev);
  };

  render() {
    const { context, fetchRows, rows, colData } = this.props;

    const adjustedHeaderHeight = this.props.headerHeight
      ? this.props.headerHeight
      : baseGridSizes.headerHeight;

    let rowsProps = {};
    if (fetchRows) {
      rowsProps = {
        gridOptions: {
          rowModelType: 'infinite',
          datasource: {
            getRows: fetchRows,
          },
        },
      };
    } else {
      rowsProps = {
        // [null] mimic loading behavior for client side row model
        // whenever rows set to null/undefined it means new rows are loading now
        rowData: rows || [{}],
      };
    }

    return (
      <div className={this.props.classes.gridContainer}>
        <AgGridReact
          context={context}
          cacheBlockSize={baseGridSizes.defaultBlockSize}
          maxConcurrentDatasourceRequests={1}
          componentWrappingElement="div"
          animateRows={false}
          columnDefs={colData}
          headerHeight={adjustedHeaderHeight}
          rowHeight={baseGridSizes.rowHeight}
          defaultColDef={this.defaultColDef}
          enableBrowserTooltips={true}
          onGridReady={this.handleGridReady}
          onModelUpdated={this.handleModelUpdate}
          onFirstDataRendered={this.handleFirstDataRendered}
          skipHeaderOnAutoSize={true}
          onColumnMoved={this.handleColumnMove}
          onDragStopped={this.onDragStopped}
          getRowNodeId={this.props.getRowNodeId}
          getRowStyle={this.props.getRowStyle}
          suppressDragLeaveHidesColumns={true}
          noRowsOverlayComponentFramework={EmptyState}
          {...rowsProps}
        />
      </div>
    );
  }
}
