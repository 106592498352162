import { ColData } from 'src/shared/base-grid/base-grid';

export const columns: ColData[] = [
  {
    name: 'active',
    label: '',
    units: null,
    visibility: true,
    type: null,
  },
  {
    name: 'attachments',
    label: '',
    units: null,
    visibility: true,
    type: null,
  },
  {
    name: 'sender',
    label: 'From',
    units: null,
    visibility: true,
    type: 'string',
  },
  {
    name: 'subject',
    label: 'Subject',
    units: null,
    visibility: true,
    type: 'string',
  },
  {
    name: 'toolName',
    label: 'Tool',
    units: null,
    visibility: true,
    type: 'string',
  },
  {
    name: 'stationName',
    label: 'Device',
    units: null,
    visibility: true,
    type: 'string',
  },
  {
    name: 'createdAt',
    label: 'Created At',
    units: null,
    visibility: true,
    type: 'string',
  },
  {
    name: 'updatedAt',
    label: 'Updated At',
    units: null,
    visibility: true,
    type: 'string',
  },
  {
    name: 'notificationType',
    label: 'Category',
    maxWidth: 100,
    units: null,
    visibility: true,
    type: 'string',
  },
  {
    name: 'manualType',
    label: 'Manual Type',
    units: null,
    visibility: true,
    type: 'string',
  },
];
