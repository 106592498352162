import React from 'react';
import { useStyles } from './maintenance-dashboard-dropdowns.styles';
import ColumnDropdown from './column-dropdown';
import { ColData } from 'src/shared/base-grid/base-grid';
import PredictionDropdown from './prediction-dropdown';
import ExportBtn from 'src/shared/button-export';

interface OwnProps {
  columns: ColData[];
  onSelectChange: (values: string[]) => void;
  predictionStatuses: string[];
  exportUrl?: { url: string };
}

export const MaintenanceDashboardDropdowns: React.FC<OwnProps> = (props) => {
  const styles = useStyles();

  const exportBtnProps = {
    url: '/pumps.xlsx',
    position: 'relative',
  };

  return (
    <div className={styles.toolContainer}>
      <div className={styles.eventContainer}>
        <PredictionDropdown
          onChange={props.onSelectChange}
          value={props.predictionStatuses}
        />
      </div>
      <div className={styles.columnDropdownContainer}>
        <ColumnDropdown columns={props.columns} />
        <ExportBtn {...exportBtnProps} />
      </div>
    </div>
  );
};
