import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NotificationsCounter } from 'src/shared/notifications-counter/notifications-counter';
import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import {
  COMPRESSOR,
  CONTROLLER,
  PUMP,
  SITE,
  TOOL,
} from 'src/shared/routing/with-selected-equipment';
import { RootState, select } from 'src/store';

interface Props {
  style: React.CSSProperties;
}

export const NotificationCounterIcon: FC<Props> = ({ style }) => {
  const { type, siteId, toolId, pumpId, compressorId } = useEquipmentIdentifiers();

  const site = useSelector((state: RootState) => {
    if (siteId) {
      return select.siteEquipment.selectSite(state, { siteId });
    }
  });

  const tool = useSelector((state: RootState) => {
    if (siteId && toolId) {
      return select.siteEquipment.selectTool(state, { siteId, toolId });
    }
  });

  const pump = useSelector((state: RootState) => {
    if (siteId && toolId && pumpId) {
      return select.siteEquipment.selectPump(state, { siteId, toolId, pumpId });
    }
  });

  const controller = useSelector((state: RootState) => {
    if (siteId && toolId) {
      return select.siteEquipment.selectController(state, { siteId, toolId });
    }
  });

  const compressor = useSelector((state: RootState) => {
    if (siteId && toolId && compressorId) {
      return select.siteEquipment.selectCompressor(state, {
        siteId,
        toolId,
        compressorId,
      });
    }
  });

  const count: number | undefined = useMemo(() => {
    switch (type) {
      case SITE:
        return site?.activeNotificationsCount;
      case TOOL:
        return tool?.activeNotificationsCount;
      case PUMP:
        return pump?.activeNotificationsCount;
      case CONTROLLER:
        return controller?.activeNotificationsCount;
      case COMPRESSOR:
        return compressor?.activeNotificationsCount;
    }
  }, [
    compressor?.activeNotificationsCount,
    controller?.activeNotificationsCount,
    pump?.activeNotificationsCount,
    site?.activeNotificationsCount,
    tool?.activeNotificationsCount,
    type,
  ]);

  return <NotificationsCounter style={style} count={count} />;
};
