function getLocalStorageItem<T>(key: string, defaultValue: T): T {
  const value = localStorage.getItem(key);
  if (value === null) return defaultValue;

  try {
    return JSON.parse(value);
  } catch (err) {
    console.error('[getLocalStorageItem]', err.name);
    return defaultValue;
  }
}

function setLocalStorageItem<T>(key: string, value: T) {
  try {
    const stringified = JSON.stringify(value);
    localStorage.setItem(key, stringified);
  } catch (err) {
    console.error('[setLocalStorageItem]', err.name);
    throw err;
  }
}

const PREFERRED_SITES_KEY = 'resource_scope';

export const storage = {
  getPreferredSites() {
    return getLocalStorageItem<number[]>(PREFERRED_SITES_KEY, []);
  },
  setPreferredSites(sites: number[]) {
    setLocalStorageItem(PREFERRED_SITES_KEY, sites);
  },
};
