import BaseApi from '.';
import { AxiosResponse } from 'axios';
import { User } from './users.api';

export type Credentials = {
  email: string;
  password: string;
};

export interface Response {
  accessToken: string;
  user: User;
}

const authInstance = new BaseApi('/authentication');

const getResponse = (response: AxiosResponse<Response>): Response => {
  const accessToken = response.data.accessToken || response.headers.authorization;
  const user = response.data.user || response.data;
  return {
    user,
    accessToken,
  };
};

export const authAPI = {
  create(credentials: Credentials): Promise<Response> {
    const payload = {
      strategy: 'local',
      user: credentials,
    };

    return authInstance
      .post<typeof payload, Response>(payload)
      .then((response: AxiosResponse<Response>) => getResponse(response));
  },

  validate(accessToken: string): Promise<Response> {
    const payload = {
      strategy: 'jwt',
      accessToken,
    };

    return authInstance
      .post<typeof payload, Response>(payload)
      .then((response) => getResponse(response));
  },

  delete() {
    return authInstance.delete();
  },
};
