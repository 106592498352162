import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

const resetPassModalStyles = (): StyleRules =>
  createStyles({
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '-16px',
    },
    textField: {
      marginBottom: '16px',
    },
  });

export default resetPassModalStyles;
