import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles({
  root: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
  },
  slot: {
    color: COLORS.grey['600'],
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  separator: {
    color: COLORS.grey['600'],
  },
  body2: {
    fontSize: '14px',
  },
});
