import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from 'src/services/history';
import { RootDispatch, RootState } from 'src/store';
import { routesConstants } from 'src/shared/routing/routes.constants';
import Login from './login';
import App from './app';

interface Props {
  validateToken: () => void;
  user: boolean;
}

class RootRoutes extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.props.validateToken();
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path={routesConstants.LOGIN} component={Login} />

          <Route path={routesConstants.BASE} render={this.renderProtectedRoute} />

          <Redirect to={routesConstants.LOGIN} />
        </Switch>
      </Router>
    );
  }

  renderProtectedRoute = () => {
    if (!this.props.user) {
      return null;
    }

    return <App />;
  };
}

const mapState = (state: RootState) => ({ user: Boolean(state.auth.user) });

const mapDispatch = (store: RootDispatch) => ({
  validateToken: store.auth.validateTokenRequest,
});

export default connect(mapState, mapDispatch)(RootRoutes);
