import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles({
  rows: {
    display: 'flex',
    flexDirection: 'column',
  },
  auto: {
    flex: '1',
  },
  noAuto: {
    flex: 'none',
  },
  noWrap: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  autoFill: {
    flex: '1',
    overflow: 'auto',
  },
  withShadow: {
    boxShadow: '0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
    zIndex: 1,
  },
  fitSpace: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  fitSpaceSimple: {
    height: '100%',
    width: '100%',
  },
  fitHeight: {
    height: '100%',
    overflow: 'auto',
  },
  fitHeightSimple: {
    height: '100%',
  },
  frame: {
    position: 'relative',
    overflow: 'auto',
  },
  frameViewport: {
    height: '100%',
    left: '0',
    overflow: 'auto',
    position: 'absolute',
    top: '0',
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  minw232: {
    minWidth: '232px',
  },
  h100: {
    height: '100vh',
  },
  sidebar: {
    background: COLORS.white,
    boxShadow: '0px 0.0625rem 0.125rem rgba(0, 0, 0, 0.25)',
  },
  invertedTheme: {
    color: 'white',
    background: 'black',
  },
  logoSlot: {
    maxWidth: '140px',
  },
  headerContainerGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  headerSlotBase: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '72px',
  },
  headerSlotStretch: {
    alignItems: 'stretch',
  },
  headerSlotCenter: {
    alignItems: 'center',
  },
  timezoneSlot: {
    width: '140px',
    padding: '6px 8px',
  },
});
