import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const PerformanceAdvisoryIcon: FC<Props> = (props) => {
  const { className, color } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 20 20">
      <path
        d="M19.1 5H7.9C7.13 5 6.507 5.63 6.507 6.4L6.5 19L9.3 16.2H19.1C19.87 16.2 20.5 15.57 20.5
         14.8V6.4C20.5 5.63 19.87 5 19.1 5ZM14.2 11.3H12.8V7.1H14.2V11.3ZM14.2 14.1H12.8V12.7H14.2V14.1Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default PerformanceAdvisoryIcon;
