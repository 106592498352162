import React from 'react';
import BaseGrid from 'src/shared/base-grid';
import { ColData, StyledColDef } from 'src/shared/base-grid/base-grid';
import { createColumn } from 'src/shared/base-grid/column-creator';
import { COLORS } from 'src/styles/colors';
import ExportBtn from 'src/shared/button-export';
import { RowClassParams } from 'ag-grid-community';
import { Box } from '@material-ui/core';
import { HEADER_HEIGHTS } from 'src/shared/base-grid/base-grid.config';

interface OwnProps {
  autoSize: boolean;
  rows: unknown[] | null; //Note: Depending on the parent component rows type can be RegenerationsRow[] | RegenerationsStep[]
  columns: ColData[];
  isExact?: boolean;
}

interface State {
  colData: StyledColDef[];
}

type ComponentProps = OwnProps;

class PumpRegenerationGrid extends React.Component<ComponentProps, State> {
  public state: State = {
    colData: [],
  };

  public componentDidMount() {
    const { columns } = this.props;
    const colData: StyledColDef[] = columns.map((el) =>
      createColumn(el, {
        headerComponentParams: {
          dataType: el.units,
        },
      })
    );
    this.setState({ colData });
  }

  getRowStyle = ({ data }: RowClassParams) => ({
    color: data.type === 'Failed' ? COLORS.primary : COLORS.black,
  });

  render() {
    const { rows, autoSize, isExact } = this.props;
    const { colData } = this.state;

    const exportBtnProps = {
      url: '/regenerations.xlsx',
    };
    return (
      <React.Fragment>
        <Box display="flex" justifyContent="flex-end">
          {isExact && <ExportBtn {...exportBtnProps} />}
        </Box>
        <BaseGrid
          autoSizeColumns={autoSize}
          colData={colData}
          rows={rows}
          getRowStyle={this.getRowStyle}
          headerHeight={HEADER_HEIGHTS.threeRowsWithUnits}
        />
      </React.Fragment>
    );
  }
}

export default PumpRegenerationGrid;
