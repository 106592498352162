import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { AutocompleteOption } from 'src/shared/autocompletes-wtih-chips';
import AutocompleteWithInfiniteLoad from './infinite-loading';
import { Site, Tool } from 'src/store/api/sites.api';
import { FetchBySearchTerm } from 'src/store/api/search.api';

type ExtraFetchParams = {
  sites?: Site[];
  tools?: Tool[];
  siteId?: number[];
  toolId?: number[];
};

interface Props {
  value?: AutocompleteOption[];
  onChange?: (values: AutocompleteOption[]) => void;
  label?: string;
  onFetchMore: (
    params: FetchBySearchTerm
  ) => Promise<{ data: AutocompleteOption[]; total: number }>;
  error?: boolean;
  className?: string;
  extraFetchParams?: ExtraFetchParams;
  limitTags?: number;
}

export const LIMIT_TAGS = 5;
const OFFSET_LIMIT = 50;
const MAX_LIMIT = 50;
const ALL_OPTION = { label: 'All', value: 'all' };
const getHelperText = (count: number, max: number) =>
  `Selected ${count} from ${max} allowed`;

export const isAllOption = ({ value }: AutocompleteOption) => value === 'all';
const withoutAllOption = (options: AutocompleteOption[]) =>
  options.filter((entry) => !isAllOption(entry));

// eslint-disable-next-line max-lines-per-function, max-statements
const AutocompleteWithInfiniteLoading: FC<Props> = (props) => {
  const {
    value,
    onFetchMore,
    extraFetchParams = { sites: [], tools: [] },
    onChange,
    label,
    error,
    className,
    limitTags = LIMIT_TAGS,
  } = props;
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<AutocompleteOption[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [inputValue, setInputValue] = useState('');

  const isAllMode = useRef(false);
  const memoizedChangeHandler = useRef<typeof onChange>();
  const offset = useRef(0);
  const term = useRef('');
  const limit = useRef(OFFSET_LIMIT);
  const prevExtraFetchParams = useRef(extraFetchParams);
  const prevValue = useRef<AutocompleteOption[]>(); // cache prev value to set default value only once

  const handleFetch = useCallback(
    (params) => {
      setLoading(true);
      onFetchMore({
        ...extraFetchParams,
        ...params,
      }).then((res) => {
        setOptions((prevOptions) => [
          ...new Set([
            ...(res.data.length ? [ALL_OPTION] : []),
            ...(!isAllMode.current ? prevOptions : []),
            ...res.data,
          ]),
        ]);
        setHasMore(res.data.length < res.total);
        setLoading(false);
      });
    },
    [extraFetchParams, onFetchMore]
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        term.current = value;
        isAllMode.current = false;
        setSelectedOptions((prevOptions) =>
          prevOptions.filter((entry) => !isAllOption(entry))
        );
        setOptions([]);
        handleFetch({ term: value, limit: OFFSET_LIMIT, offset: 0 });
      }, 300),
    [handleFetch]
  );

  const handleOpen = useCallback(() => {
    if (!options.length) {
      handleFetch({ term: term.current, limit: OFFSET_LIMIT, offset: 0 });
      offset.current += OFFSET_LIMIT;
    }
  }, [handleFetch, options.length]);

  const handleClose = useCallback(() => {
    setInputValue('');
  }, []);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | Record<string, never>>,
      selectedValues: AutocompleteOption[]
    ) => {
      const values = withoutAllOption(selectedValues);
      if (values.length > MAX_LIMIT) {
        return;
      }

      const withoutAllOptionCount = withoutAllOption(options).length;
      const isAllClicked = event.currentTarget.textContent?.toLowerCase() === 'all';
      const isAll =
        (isAllClicked && selectedValues.some((entry) => isAllOption(entry))) ||
        (!isAllClicked &&
          !!withoutAllOptionCount &&
          withoutAllOptionCount === values.length);

      if (isAllClicked && !isAllMode.current) {
        handleFetch({ term: term.current, limit: MAX_LIMIT, offset: 0 });
        limit.current = MAX_LIMIT;
        offset.current = MAX_LIMIT;
      } else {
        const changedValues = isAllClicked
          ? []
          : isAll
          ? [ALL_OPTION, ...values]
          : values;
        setSelectedOptions(changedValues);
        onChange?.(values);
      }

      isAllMode.current = isAll;
    },
    [handleFetch, onChange, options]
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<unknown>, value: string, reason: 'input' | 'reset' | 'clear') => {
      switch (reason) {
        case 'input':
          setInputValue(value);
          debouncedSearch(value);
          break;
        case 'clear':
          setSelectedOptions([]);
          offset.current = 0;
          break;
      }
    },
    [debouncedSearch]
  );

  // set default value from the form
  useEffect(() => {
    if (!isAllMode.current && !prevValue.current && value?.length) {
      setSelectedOptions(value);
      prevValue.current = value;
    }
  }, [value]);

  // reset options on extraFetchParams change
  useEffect(() => {
    if (!isEqual(extraFetchParams, prevExtraFetchParams.current)) {
      prevExtraFetchParams.current = extraFetchParams;

      setOptions([]);
      isAllMode.current = false;
    }
  }, [extraFetchParams]);

  // fetch more options on scroll
  useEffect(() => {
    if (isScrollBottom && hasMore && !isAllMode.current) {
      handleFetch({
        term: term.current,
        limit: OFFSET_LIMIT,
        offset: offset.current,
      });
      offset.current += OFFSET_LIMIT;
    }
  }, [handleFetch, hasMore, isScrollBottom]);

  // handle selecting all options
  useEffect(() => {
    if (isAllMode.current) {
      setSelectedOptions(options);
      memoizedChangeHandler.current?.(options.filter((entry) => !isAllOption(entry)));
      prevValue.current = options;
    }
  }, [options]);

  useEffect(() => {
    memoizedChangeHandler.current = onChange;
  });

  useEffect(() => {
    setHelperText(getHelperText(withoutAllOption(selectedOptions).length, MAX_LIMIT));
  }, [selectedOptions]);

  return (
    <AutocompleteWithInfiniteLoad
      limitTags={limitTags}
      label={label}
      error={error}
      helperText={helperText}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleChange={handleChange}
      className={className}
      setIsScrollBottom={setIsScrollBottom}
      selectedOptions={selectedOptions}
      options={options}
      loading={loading}
      handleInputChange={handleInputChange}
      inputValue={inputValue}
    />
  );
};

export default memo(AutocompleteWithInfiniteLoading);
