import { getShiftedISOFromDate } from 'src/services/timezone.helper';
import BaseApi from '.';
import { EntityType } from 'src/types';

export type PredictiveStatus = 'normal' | 'warning' | 'critical';

export type SeriesMetadata = {
  tool_name?: string;
  station_name?: string;
  compressor_name?: string;
} | null;

export interface GroupedSeries {
  series: { [k: string]: number[] };
  timestamps: string[];
  site_id?: number;
  entity_id?: number;
  entity_type: EntityType;
  metadata?: SeriesMetadata; // depends on entity type
}

export interface StartEndDates {
  // selected start date
  start: Date;
  // selected end date
  end: Date;
}

export interface FetchSeriesParams extends StartEndDates {
  // query string that may include siteId + pumpId or siteId + toolId
  search?: string;
}

// pummp chart series api
const pumpCharts = new BaseApi('/charts/pumps/data');

// pump predictive chaty series api
const predictiveCharts = new BaseApi('/charts/failure_probability/data');

// chart builder series api
const chartBuilderSeries = new BaseApi('/charts');

export const makeQuery = (params: FetchSeriesParams, timeZoneOffset: number) => {
  const { search, start, end } = params;
  const query = new URLSearchParams(search);

  query.set('startTime', getShiftedISOFromDate(start, timeZoneOffset));
  query.set('endTime', getShiftedISOFromDate(end, timeZoneOffset));

  return query;
};

export const chartApi = {
  /**
   * Fetch pump t1, t2, rpm and controller/tool diff pressure(dp) series.
   * By siteId + toolId or by siteId + pumpId (single or multiple pumps)
   * Used on tool level page and on pump level page
   */
  fetchPumpsCommonMetrics: (params: FetchSeriesParams, timeZoneOffset: number) => {
    const query = makeQuery(params, timeZoneOffset);

    return pumpCharts
      .get<GroupedSeries[]>({ params: query })
      .then(({ data }) => data);
  },

  /**
   * Fetch pump failure probability prediction % and failure probability status
   * By siteId + pumpId (single pump only)
   * Used only on tool level page
   */
  fetchPumpFailureProbability: (params: FetchSeriesParams, timeZoneOffset: number) => {
    const query = makeQuery(params, timeZoneOffset);

    return predictiveCharts
      .get<GroupedSeries[]>({ params: query })
      .then(({ data }) => data);
  },

  fetchSeriesById: (id: number, params: StartEndDates, timeZoneOffset: number) => {
    const query = makeQuery(params, timeZoneOffset);

    chartBuilderSeries.url = `/charts/${id}/data`;

    return chartBuilderSeries
      .getLatest<GroupedSeries[]>({ params: query, id: '/charts' })
      .then(({ data }) => data);
  },
};
