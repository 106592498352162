import React, { FC, useCallback, useState } from 'react';
import { PasswordSecurityBtn } from '../password-security-button';
import { TextField, TextFieldProps, WithStyles, withStyles } from '@material-ui/core';
import { FieldError } from 'react-hook-form';
import styles from './pass-field.styles';

type ComponentProps = {
  error?: FieldError | boolean;
} & TextFieldProps &
  WithStyles<typeof styles>;

const PassField: FC<ComponentProps> = (props) => {
  const { classes, error, helperText, ...restProps } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const handleShowPassword = useCallback(() => {
    setPasswordShown(!passwordShown);
  }, [passwordShown]);

  return (
    <TextField
      type={passwordShown ? 'text' : 'password'}
      helperText={(typeof error !== 'boolean' && error?.message) || helperText}
      error={!!error}
      autoComplete="off"
      FormHelperTextProps={{
        classes: { root: classes.helperText },
      }}
      InputProps={{
        endAdornment: (
          <PasswordSecurityBtn onClick={handleShowPassword} isShown={passwordShown} />
        ),
      }}
      InputLabelProps={{ classes: { root: classes.inputLabel } }}
      {...restProps}
    />
  );
};

export default withStyles(styles)(PassField);
