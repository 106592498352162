import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { OptionsObject } from 'notistack';

interface SnackbarsState {
  list: Snackbar[];
}

export interface Snackbar {
  key: string;
  message: string;
  options?: OptionsObject;
  dismissAll?: boolean;
  dismissed?: boolean;
}

export const snackbars = createModel<RootModel>()({
  state: {
    list: [],
  } as SnackbarsState,

  reducers: {
    enqueueSnackbar: (
      state: SnackbarsState,
      payload: { key: string; message: string; options: OptionsObject }
    ) => ({
      ...state,
      list: [
        ...state.list,
        {
          ...payload,
        },
      ],
    }),
    closeSnackbar: (
      state: SnackbarsState,
      payload: { key?: string; dismissAll?: boolean }
    ) => ({
      ...state,
      list: state.list.map((snackbar) =>
        payload.dismissAll || snackbar.key === payload.key
          ? { ...snackbar, dismissed: true }
          : { ...snackbar }
      ),
    }),
    removeSnackbar: (state: SnackbarsState, key: string) => ({
      ...state,
      list: state.list.filter((snackbar) => snackbar.key !== key),
    }),
  },

  effects: (dispatch) => ({}),
});
