import { ColData } from 'src/shared/base-grid/base-grid';

const UNITS = {
  hrs: 'hrs',
};

export const chamberHistoryColumns: ColData[] = [
  {
    name: 'serial_number',
    label: 'Serial Number',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'string',
    flex: 4,
  },
  {
    name: 'pump_id',
    label: 'Pump ID',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 3,
  },
  {
    name: 'pump_age',
    label: 'Pump Age',
    sortDirection: null,
    units: UNITS.hrs,
    visibility: true,
    type: 'number',
    flex: 3,
  },
  {
    name: 'start_date',
    label: 'In Service',
    sortDirection: 'desc',
    units: null,
    visibility: true,
    type: 'date',
  },
  {
    name: 'end_date',
    label: 'Out of Service',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'date',
  },
];
