import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import React, { FC } from 'react';
import { SpinnerCentered } from 'src/shared/spinner/spinner.stories';
import { useStyles } from './details-page-styles';

type NumOrNull = number | null;

interface StationDataRow {
  label: string;
  value: string | NumOrNull;
}

interface Props {
  stationDataRows: StationDataRow[] | undefined;
  siteName: string | undefined;
  toolName: string | undefined;
  equipmentName: string;
}

export const DetailsPage: FC<Props> = ({
  stationDataRows,
  siteName,
  toolName,
  equipmentName,
}) => {
  const classes = useStyles();

  return stationDataRows ? (
    <Box className={classes.rootContainer}>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableBody>
            {stationDataRows.map((row) => (
              <TableRow key={row.label}>
                <TableCell className={classes.label}>{row.label}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <SpinnerCentered />
  );
};
