import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      base: {
        border: `1px solid ${COLORS.grey['300']}`,
        height: '32px',
        padding: '8px',
        lineHeight: '14px',
      },
      /* partially distribute width between 4 columns
      3 columns have equal width, and first column smaller(16%) */
      td: {
        '&:not(:first-child)': {
          width: '28%',
        },
      },
      tooltip: {
        fontSize: '12px',
      },
      highlighted: {
        background: COLORS.grey['100'],
      },
      help: {
        verticalAlign: 'top',
        color: COLORS.grey['300'],
      },
      positive: {
        color: COLORS.green['500'],
      },
      negative: {
        color: COLORS.red['500'],
      },
      icon: {
        color: COLORS.grey['500'],
        '&:hover': {
          color: COLORS.grey['700'],
        },
      },
    })
);
