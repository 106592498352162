import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import LineChart from 'src/shared/charts/line-chart';
import { FetchChartSeriesParams } from 'src/store/models/chart-builder-series.model';
import { GroupedSeries } from 'src/store/api/charts.api';
import {
  LineChartGridOptions,
  LineChartYAxis,
  tooltipConfig,
  yAxisLabelDefault,
} from 'src/shared/charts/line-chart/line-chart.config';
import { ChartData } from 'src/store/api/charts-builder.api';
import { getWeekRange } from 'src/shared/date-range-filter/filters-config';
import { Box } from '@material-ui/core';
import { ChartSeriesLine, ChartYAxisOption } from 'src/shared/charts/base-chart';
import { ChartDetailsHeader } from './chart-details-header';

interface Props {
  chartId: number;
  series: GroupedSeries[];
  fetchSeries: (params: FetchChartSeriesParams, meta: void) => Promise<void>;
  setSeries: (series: []) => void;
  chartData: ChartData | null;
  timezoneOffset: number;
}

const tooltip = {
  ...tooltipConfig,
  confine: true,
};

const [defaultStart, defaultEnd] = getWeekRange();

const ChartDetails: FC<Props> = (props) => {
  const { chartId, series, fetchSeries, setSeries, chartData, timezoneOffset } = props;

  const [dates, setDates] = useState<[Date, Date]>([defaultStart, defaultEnd]);

  const [start, end] = dates;

  const seriesConfig = useMemo(
    () =>
      chartData?.time_series.reduce((memo, entry) => {
        if (entry.label && !memo[entry.label]) {
          memo[entry.label] = {
            yAxisIndex: entry.axis === 'left' ? 0 : 1,
          };
        }
        return memo;
      }, {} as Record<string, Exclude<ChartSeriesLine, 'type'>>),
    [chartData]
  );

  // unset loaded chart series on unmount to avoid any visual confusion
  useEffect(() => () => setSeries([]), [setSeries]);

  useEffect(() => {
    fetchSeries({ id: chartId, dates: { start, end } });
  }, [chartId, start, end, fetchSeries]);

  const handleChange = (start: Date, end: Date) => setDates([start, end]);

  if (!chartData) return null;

  const yAxis: ChartYAxisOption[] = chartData.axes.map(({ side, name, id, min, max }) => {
    //identifies whether the left or the right y-axis will present the Power metric
    const hasPowerMetric = chartData.time_series.some(
      (el) => el.label === 'Power' && el.axis === side
    );
    const isLongerAxisValue = Number(max) > 1000 || hasPowerMetric;

    return {
      ...LineChartYAxis[0],
      id: id?.toString(),
      position: side,
      axisLabel: isLongerAxisValue
        ? { ...yAxisLabelDefault, margin: 3 }
        : yAxisLabelDefault,
      name,
      ...(typeof min === 'number' ? { min } : null),
      ...(typeof max === 'number' ? { max } : null),
    };
  });

  const offsets = {
    ...LineChartGridOptions,
    left: 56,
    right: 56 + 8, // take vertical scroll into consideration
  };

  const datesSelected = { start, end };
  const query = {
    dates: datesSelected,
    timezoneOffset: timezoneOffset || 0,
  };

  return (
    <>
      <ChartDetailsHeader
        chartId={chartId}
        dates={dates}
        query={query}
        handleChange={handleChange}
      />
      <Box mt={2} mx={-2}>
        <LineChart
          height={410}
          data={series}
          seriesConfig={seriesConfig}
          options={{
            tooltip,
            yAxis,
            grid: offsets,
          }}
          minX={start.getTime()}
          maxX={end.getTime() + 1000000}
        />
      </Box>
    </>
  );
};

export default memo(ChartDetails);
