import React from 'react';
import DateFormatter from './date-formatter';
import withTimezone from './hoc';

export interface DateFormatterProps {
  children: string;
  format?: string;
  formatType?: 'short' | 'full';
}

const DateInSelectedZone: React.FC<DateFormatterProps> = withTimezone(DateFormatter);

export default DateInSelectedZone;
