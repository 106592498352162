import { TimeSeries } from './../store/api/charts-builder.api';

const getRepeatingSeriesId = (
  oldTimeSeries: TimeSeries[],
  eb_metric_id: string | number,
  axis: string
) => {
  const repeatingSeries = oldTimeSeries.find(
    (series) => series.eb_metric_id === eb_metric_id && series.axis === axis
  );
  return repeatingSeries?.id;
};

export const getTimeSeries = (
  oldTimeSeries: TimeSeries[],
  newTimeSeries: TimeSeries[]
) => {
  return newTimeSeries.map((newSeries) => ({
    ...newSeries,
    id: getRepeatingSeriesId(oldTimeSeries, newSeries.eb_metric_id, newSeries.axis),
  }));
};
