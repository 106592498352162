import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import RoutingTabs from 'src/shared/routing/routing-tabs';
import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import { COMPRESSOR_TABS, CONTROLLER_TABS, PUMP_TABS, SITE_TABS, TOOL_TABS } from '.';
import {
  COMPRESSOR,
  CONTROLLER,
  PUMP,
  TOOL,
} from 'src/shared/routing/with-selected-equipment';

const DashboardContentNav: FC = (props) => {
  const location = useLocation();
  const { type } = useEquipmentIdentifiers();

  const tabs = useMemo(() => {
    switch (type) {
      case TOOL:
        return TOOL_TABS;
      case PUMP:
        return PUMP_TABS;
      case CONTROLLER:
        return CONTROLLER_TABS;
      case COMPRESSOR:
        return COMPRESSOR_TABS;
    }
    return SITE_TABS;
  }, [type]);

  return <RoutingTabs value={location.pathname} tabs={tabs} />;
};

export default DashboardContentNav;
