import { subMinutes } from 'date-fns';

/**
 * Convert passed js data object into ISO string making adjustments up to selected time zone in app
 *
 * Basically main usage is for sending to server side.
 *
 * @param value js date object in user timezone
 * @param offset negative or positive offset in minutes. (GMT +1 => -60, GMT +1 => +60)
 * @returns {string} ISO date string 2020-06-05T10:00:00.000Z
 */
export const getShiftedISOFromDate = (value: Date, offset = 0) => {
  return subMinutes(value, -offset + value.getTimezoneOffset()).toISOString();
};

/**
 * Convert passed ISO string (2020-06-05T10:00:00.000Z) to client date object by excluding client
 * time zone and adjusting date to the passed time zone (selected in app).
 *
 * Basically main usage is for render purpose on the client side.
 *
 * @param value ISO date string 2020-06-05T10:00:00.000Z
 * @param offset negative or positive offset in minutes. (GMT +1 => -60, GMT +1 => +60)
 * @returns {Date} adjusted date in client timezone - Fri Jun 05 2020 10:00:00 GMT+0300 (Eastern European Summer Time) (offset 0)
 */
export const getShiftedDateFromISO = (value: string, offset = 0) => {
  const dateInClientTimeZone = new Date(value); // date as it, including client time zone
  const clientTimeZoneOffset = dateInClientTimeZone.getTimezoneOffset(); // minutes

  // get rid off client time zone
  const withoutClientTimeZone =
    dateInClientTimeZone.getTime() + clientTimeZoneOffset * 60 * 1000;

  // adjust date up to passed offset
  const withOffset = withoutClientTimeZone - offset * 60 * 1000;

  return new Date(withOffset);
};
