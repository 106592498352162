import React, { ChangeEvent, FC } from 'react';
import { TextField } from '@material-ui/core';

export interface Props {
  comment: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const Comments: FC<Props> = (props) => {
  const { comment, onChange } = props;

  return (
    <TextField
      value={comment}
      onChange={onChange}
      multiline
      fullWidth
      rows={2}
      rowsMax={4}
      InputLabelProps={{
        shrink: true,
      }}
      label="Comment"
      variant="filled"
    />
  );
};

export default Comments;
