import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { ChartListItem } from 'src/store/api/charts-builder.api';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { useStyles } from './styles';
import SearchInput from 'src/shared/search-input';
import EmptyState from 'src/shared/empty-state';

interface Props {
  charts: ChartListItem[];
  setSelectedChart: (selectedChart: ChartListItem) => void;
}

const ChartsList: FC<Props> = (props) => {
  const { charts, setSelectedChart } = props;
  const classes = useStyles();

  const { pathname } = useLocation();
  const [searchValue, setSearchValue] = useState('');
  const [filteredCharts, setFilteredCharts] = useState(charts);

  const handleSearchChange = useCallback(
    (event) => {
      const searchStr = event?.target.value.toLowerCase() || '';
      setSearchValue(searchStr);
      const matchedCharts =
        charts.filter((chart) => chart.name.toLocaleLowerCase().includes(searchStr)) ||
        null;
      setFilteredCharts(matchedCharts);
    },
    [charts]
  );

  const handleSelectChart = useCallback(
    (chart: ChartListItem) => () => {
      setSelectedChart(chart);
    },
    [setSelectedChart]
  );

  useEffect(() => {
    const sortedCharts = charts.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    setFilteredCharts(sortedCharts);
  }, [charts]);

  if (!charts.length) {
    return null;
  }

  return (
    <div>
      <SearchInput value={searchValue} onChange={handleSearchChange} />
      <Divider />
      {!filteredCharts?.length ? (
        <EmptyState />
      ) : (
        <List component="nav" disablePadding>
          {filteredCharts.map((chart) => (
            <Fragment key={chart.id}>
              <ListItem
                onClick={handleSelectChart(chart)}
                component={Link}
                to={`/app/charts/${chart.id}`}
                className={classes.listItem}
                selected={!!matchPath(pathname, { path: `/app/charts/${chart.id}` })}>
                <ListItemText disableTypography primary={chart.name} />
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      )}
    </div>
  );
};

export default ChartsList;
