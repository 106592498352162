import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  typoH5: {
    fontSize: '24px',
    fontWeight: 400,
  },
  summaryCaption: {
    margin: '0',
    padding: '24px 0',
  },
  freeSpace: {
    margin: 'auto',
  },
  summaryRoot: {
    alignItems: 'center',
    display: 'flex',
    margin: '0',
  },
  summarySlot: {
    padding: '0 12px',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 500,
  },
  content: {
    fontSize: '14px',
  },
});
