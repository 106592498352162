import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export default makeStyles(
  (): StyleRules =>
    createStyles({
      root: {
        position: 'absolute',
        right: '8px',
      },
    })
);
