import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { DashboardRowResp, FetchByParams, pumpsApi } from 'src/store/api/pumps.api';
import { FetchSeriesParams, GroupedSeries, chartApi } from '../api/charts.api';

export type Series = GroupedSeries[] | null;

export type MaintenanceDashboardState = {
  rows: DashboardRowResp | null;
  selectedStatuses: string[];
  series: Series;
};

const initialState = {
  // list of pumps (paginated)
  rows: null,

  // list of pumps with their metrics/series (t1, t2, rpm, dp)
  series: null,

  // selected failure probability status (filter pumps by them)
  selectedStatuses: [],
};

export const pumps = createModel<RootModel>()({
  state: initialState as MaintenanceDashboardState,

  reducers: {
    setRows: (
      state: MaintenanceDashboardState,
      rows: DashboardRowResp
    ): MaintenanceDashboardState => ({
      ...state,
      rows,
    }),

    updateStatuses: (
      state: MaintenanceDashboardState,
      payload: string[]
    ): MaintenanceDashboardState => ({ ...state, selectedStatuses: payload }),

    replaceSeries: (state: MaintenanceDashboardState, series: Series) => ({
      ...state,
      series,
    }),
  },

  effects: (dispatch) => ({
    async fetchRows(params: FetchByParams) {
      try {
        const data = await pumpsApi.fetchRows(params);
        dispatch.pumps.setRows(data);

        const dataWithActions = data.data.map((el) => ({ ...el, feedbackAction: '' }));

        params.successCallback(dataWithActions, data.total);
      } catch {
        params.failCallback();
      }
    },

    /**
     * Fetch pumps time series for t1, t2, rpm and pump controller diff pressure by toolId(controllerId)
     * One tool includes multiple pumps (3-9 pumps) + 1 controller
     */
    async fetchPumpsSeriesByToolId(params: FetchSeriesParams, { timezone }) {
      const seriesByDeviceType = await chartApi.fetchPumpsCommonMetrics(
        params,
        timezone.selectedZone.offset
      );
      dispatch.pumps.replaceSeries(seriesByDeviceType);
    },
  }),
});
