import { Models } from '@rematch/core';

import { auth } from './auth.model';
import { users } from './users.model';
import { timezone } from './timezone.model';
import { siteEquipment } from './site-equipment';
import { views } from './views.model';

import { controllers } from './controllers.model';
import { compressors } from './compressors.model';
import { pumpSettings } from './pump-settings.model';
import { predictions } from './predictions.model';
import { pumps } from './pumps.model';

import { tool } from './tool.model';

import { pump } from './pump.model';
import { regenerations } from './regenerations.model';
import { anomalies } from './anomalies.model';
import { feedback } from './feedback.model';

import { ml } from './ml.model';

import { charts } from './charts-builder.model';
import { chartSeries } from './chart-builder-series.model';

import { snackbars } from './snackbars.model';

import { notifications } from './notifications.model';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  users: typeof users;
  timezone: typeof timezone;
  siteEquipment: typeof siteEquipment;
  views: typeof views;

  controllers: typeof controllers;
  compressors: typeof compressors;
  pumpSettings: typeof pumpSettings;
  predictions: typeof predictions;
  pumps: typeof pumps;

  tool: typeof tool;

  pump: typeof pump;
  regenerations: typeof regenerations;
  anomalies: typeof anomalies;
  feedback: typeof feedback;

  ml: typeof ml;
  charts: typeof charts;
  chartSeries: typeof chartSeries;
  snackbars: typeof snackbars;
  notifications: typeof notifications;
}

export const models: RootModel = {
  auth,
  users,
  timezone,
  siteEquipment,
  views,

  controllers,

  compressors,
  pumpSettings,
  predictions,
  pumps,

  tool,

  pump,
  regenerations,
  anomalies,
  feedback,

  ml,
  charts,
  chartSeries,
  snackbars,
  notifications,
};
