import { COLORS } from 'src/styles/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '18px',
    padding: '2px 3px',
    fontSize: theme.typography.fontSize,
    lineHeight: '16px',
    color: COLORS.white,
    backgroundColor: COLORS.red[500],
    borderRadius: '4px',
  },
}));
