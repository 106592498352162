import React, { ChangeEvent, FC, memo } from 'react';
import { Checkbox, Chip, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { AutocompleteOption } from 'src/shared/autocompletes-wtih-chips'; //todo: naming
import { FilterOptionsState } from '@material-ui/lab';

interface Props {
  value: AutocompleteOption[];
  options: AutocompleteOption[];
  filterOptions?: (
    options: AutocompleteOption[],
    state: FilterOptionsState<AutocompleteOption>
  ) => AutocompleteOption[];
  onChange?: (
    event: ChangeEvent<Record<string, never> | HTMLInputElement>,
    values: AutocompleteOption[]
  ) => void;
  label?: string;
  className?: string;
  error?: boolean;
  limitTags?: number;
}

const LIMIT_TAGS = 5;

const Autocomplete: FC<Props> = (props) => {
  const {
    value,
    onChange,
    options,
    filterOptions,
    label = '',
    className = '',
    error,
    limitTags = LIMIT_TAGS,
  } = props;

  return (
    <MuiAutocomplete
      multiple
      className={className} // ?
      onChange={onChange}
      value={value || []}
      options={options}
      filterOptions={filterOptions}
      disableCloseOnSelect
      limitTags={limitTags}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, selectedOption) =>
        option.value.toString() === selectedOption.value.toString()
      }
      popupIcon={<KeyboardArrowDownIcon />}
      renderTags={(value: AutocompleteOption[], getTagProps) =>
        value.map((option: AutocompleteOption, index: number) => (
          <Chip
            key={option.value}
            size="small"
            color="primary"
            label={option.label}
            deleteIcon={<Close />}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(option, { selected }) => (
        <>
          <Checkbox color="default" checked={selected} />
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          placeholder="..."
          error={error}
        />
      )}
    />
  );
};

export default memo(Autocomplete);
