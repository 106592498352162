import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { getQuerySearch } from 'src/shared/routing/routing.helper';
import PumpDiodesGrid from 'src/app/pump/pump-diodes/pump-diodes-grid/pump-diodes-grid';
import { diodesColumns } from 'src/app/pump/pump-diodes/pump-diodes-grid/columns';

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PumpDiodes: FC<Props> = (props: Props) => {
  const { diodes: data, fetchPumpDiodes, search, exportUrl, titleSpec } = props;
  useEffect(() => {
    const params = new URLSearchParams(search);
    const pumpId = params.getAll('pumpId')[0];
    const toolId = params.getAll('toolId')[0];
    const siteId = params.getAll('siteId')[0];
    fetchPumpDiodes(pumpId, toolId, siteId);
  }, [search, fetchPumpDiodes]);

  return (
    <PumpDiodesGrid
      exportUrl={exportUrl}
      autoSize={true}
      rows={data}
      columns={diodesColumns}
      titleSpec={titleSpec}
    />
  );
};

const mapState = (state: RootState) => {
  const { pump } = state;
  return {
    diodes: pump.diodes,
    search: getQuerySearch(),
    exportUrl: '/pumps/diodes.xlsx',
    titleSpec: 'diodes.xlsx',
  };
};

const mapDispatch = (dispatch: RootDispatch) => {
  const { pump } = dispatch;

  return {
    fetchPumpDiodes: (pumpId: string, toolId: string, siteId: string) =>
      pump.fetchPumpDiodes({ pumpId, toolId, siteId }),
  };
};

export default connect(mapState, mapDispatch)(PumpDiodes);
