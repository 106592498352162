import React, { FC, useState } from 'react';
import { AutoNotification } from 'src/store/api/notifications.api';
import Button from 'src/shared/button';
import { Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { RootDispatch } from 'src/store';
import Dialog from 'src/shared/dialog';
import { SwitchHistoryTable } from './switch-histories-table';
import { canAcknowledge } from '../../notifications.helper';
import DateInSelectedZone from 'src/shared/date-in-selected-zone';

interface Props {
  notification: AutoNotification;
  onAcknowledge: (notification: AutoNotification) => void;
}

export const AutoNotificationContent: FC<Props> = ({ notification, onAcknowledge }) => {
  const dispatch = useDispatch<RootDispatch>();
  const [open, setOpen] = useState<boolean>(false);

  const {
    switchHistories,
    notificationId,
    siteId,
    subject,
    comments,
    acknowledgedAt,
    acknowledgedBy: { fullName, email },
  } = notification;

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onSubmit = () => {
    dispatch.notifications.acknowledge({
      siteId,
      notificationId,
      successCallback: (notification: AutoNotification) => {
        onAcknowledge(notification);
        toggleOpen();
      },
    });
  };

  return (
    <>
      <Box mb={1}>
        {acknowledgedAt && (
          <Article
            label="Acknowledged at"
            value={<DateInSelectedZone>{acknowledgedAt}</DateInSelectedZone>}
          />
        )}
        {fullName && <Article label="Acknowledged by" value={fullName} />}
        {email && <Article label="Email" value={email} />}
      </Box>

      {canAcknowledge(notification) && (
        <Box mb={1}>
          <Button onClick={toggleOpen} color="primary">
            Acknowledge
          </Button>
          <Dialog
            title={`Do you really want to acknowledge ${subject}?`}
            open={open}
            onClose={toggleOpen}
            submitLabel="Confirm"
            onSubmit={onSubmit}
          />
        </Box>
      )}

      <Typography style={{ marginBottom: '1rem' }}>{comments}</Typography>
      <SwitchHistoryTable switchHistories={switchHistories} />
    </>
  );
};

interface ArticleProps {
  label: string;
  value: string | React.ReactNode | undefined;
}

const Article: FC<ArticleProps> = ({ label, value }) => {
  return (
    <Box display="inline" marginRight="2rem">
      <Typography variant="subtitle2" component="span">{`${label}: `}</Typography>
      <Typography variant="body2" component="span">
        {value}
      </Typography>
    </Box>
  );
};
