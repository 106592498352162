import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { getQuerySearch } from 'src/shared/routing/routing.helper';
import { settingsHistoryColumns } from './settings-history-grid/columns';
import SettingsHistoryGrid from './settings-history-grid/settings-history-grid';

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PumpChangeHistory: FC<Props> = (props) => {
  const {
    pumpSettingHistory: data,
    fetchPumpSettings,
    search,
    exportUrl,
    titleSpec,
  } = props;

  useEffect(() => {
    const params = new URLSearchParams(search);
    const pumpId = params.getAll('pumpId')[0];
    const toolId = params.getAll('toolId')[0];
    const siteId = params.getAll('siteId')[0];
    fetchPumpSettings(pumpId, toolId, siteId);
  }, [search, fetchPumpSettings]);

  return (
    <SettingsHistoryGrid
      exportUrl={exportUrl}
      autoSize={true}
      rows={data}
      columns={settingsHistoryColumns}
      titleSpec={titleSpec}
    />
  );
};

const mapState = (state: RootState) => {
  const { pump } = state;
  return {
    pumpSettingHistory: pump.settingHistory,
    search: getQuerySearch(),
    exportUrl: '/setting_changes.xlsx',
    titleSpec: 'change-history.xlsx',
  };
};

//fetchPumpSettings will require pumpId, siteId, toolId as parameters to fetch from the server api
const mapDispatch = (dispatch: RootDispatch) => {
  const { pump } = dispatch;
  return {
    fetchPumpSettings: (pumpId: string, toolId: string, siteId: string) =>
      pump.fetchPumpSettingsHistory({ pumpId, toolId, siteId }),
  };
};
export default connect(mapState, mapDispatch)(PumpChangeHistory);
