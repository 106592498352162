import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      base: {
        border: `1px solid ${COLORS.grey['300']}`,
        width: '25%',
        height: '32px',
        padding: '8px',
        lineHeight: '14px',
      },
      th: {
        fontWeight: 'bold',
        background: COLORS.grey['100'],
      },
    })
);
