import { Box } from '@material-ui/core';
import React from 'react';

export const DefaultLevel = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center">
      Please select site
    </Box>
  );
};
