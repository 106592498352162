import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import ViewBasedGrid from 'src/shared/view-based-grid';
import { HEADER_HEIGHTS } from 'src/shared/base-grid/base-grid.config';

const Compressors: FC = () => {
  const dispatch = useDispatch<RootDispatch>();
  const rows = useSelector(({ compressors }: RootState) => compressors.rows);
  const columns = useSelector(({ views }: RootState) => views.compressors?.columns);
  const { siteId, toolId } = useEquipmentIdentifiers();

  const exportUrl = '/compressors.xlsx';
  const refetch = [siteId, toolId].join(':');

  const unsetRows = useCallback(() => dispatch.compressors.setRows(null), [
    dispatch.compressors,
  ]);

  return (
    <ViewBasedGrid
      fetchRows={dispatch.compressors.fetchRows}
      fetchColumns={dispatch.views.fetchCompressorsView}
      onCleanup={unsetRows}
      rows={rows}
      columns={columns}
      search={refetch}
      exportUrl={exportUrl}
      headerHeight={HEADER_HEIGHTS.threeRowsWithUnits}
    />
  );
};

export default Compressors;
