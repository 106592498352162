import React from 'react';
import { Spinner } from './Spinner';

const spinnerObj = {
  component: Spinner,
  title: 'Spinner',
};
export default spinnerObj;

const styles = {
  color: 'orange',
  width: '10%',
};

export const SpinnerBasics = () => (
  <div>
    <p>{'This spinner grabs all available space and inherits color.'}</p>
    <p>
      {'You need to control width of the parent element in order to control its size.'}
    </p>
    <div style={styles}>
      <Spinner />
    </div>
  </div>
);

SpinnerBasics.story = {
  name: 'basic usage',
};

const hcentered = {
  display: 'flex',
  margin: 'auto',
  width: '8%',
};

export const SpinnerHCentered = () => (
  <div style={hcentered}>
    <Spinner />
  </div>
);

SpinnerHCentered.story = {
  name: 'centered horizontally',
};

const slot = {
  display: 'flex',
  height: '100vh',
};

export const SpinnerCentered = () => (
  <div style={slot}>
    <div style={hcentered}>
      <Spinner />
    </div>
  </div>
);

SpinnerCentered.story = {
  name: 'centered absolutely',
};
