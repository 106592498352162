import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './edit-modal-form.styles';
import { Controller, FormContextValues } from 'react-hook-form';
import { User } from 'src/store/api/users.api';
import { Roles } from 'src/store/api/users.api';

import { KeyboardArrowDown } from '@material-ui/icons';

export interface Props {
  user?: User;
  formContext: FormContextValues;
}

const EditModalFormControls: FC<Props> = (props) => {
  const { user, formContext } = props;
  const { control } = formContext;
  const classes = useStyles();
  return (
    <FormControl variant="filled" className={classes.textField}>
      <InputLabel className={classes.inputLabel}>Role</InputLabel>
      <Controller
        as={Select}
        control={control}
        rules={{ required: true }}
        name="role"
        defaultValue={user?.role || Roles.cse}
        IconComponent={KeyboardArrowDown}>
        <MenuItem value={Roles.cse}>CSE</MenuItem>
        <MenuItem value={Roles.admin}>Admin</MenuItem>
      </Controller>
    </FormControl>
  );
};
export default EditModalFormControls;
