import { Box } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { useStyles } from './styles';

interface Props {
  count: number | undefined;
  style?: React.CSSProperties;
}

export const NotificationsCounter: FC<Props> = ({ count, style }) => {
  const classes = useStyles();

  const displayedCount = useMemo(() => {
    if (count) {
      return count < 100 ? count : '99+';
    }
  }, [count]);

  return count ? (
    <Box style={style} className={classes.root}>
      {displayedCount}
    </Box>
  ) : null;
};
