import React, { Component } from 'react';
import BaseHeader, { BaseHeaderProps } from 'src/shared/base-grid/base-header';
import FileIcon from 'src/shared/icons/file-icon';

export class FilesHeader extends Component<BaseHeaderProps> {
  // don't remove!!! ag-grid use this method for applying styles
  public getReactContainerStyle() {
    return {
      width: '100%',
      height: '100%',
    };
  }

  render() {
    const { ...restProps } = this.props;
    return (
      <BaseHeader {...restProps}>
        <FileIcon />
      </BaseHeader>
    );
  }
}
