import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const UnstablePredictionIcon: FC<Props> = (props) => {
  const { className, color = '#FF9800' } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 16 16">
      <rect width="16" height="16" fill={color} />
      <path d="M9 12.6667H7V14H9V12.6667ZM9 2H7V11.3334H9V2Z" fill="white" />
    </SvgIcon>
  );
};

export default UnstablePredictionIcon;
