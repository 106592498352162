import axios, {
  AxiosResponse as AR,
  AxiosRequestConfig as ARC,
  AxiosInstance,
} from 'axios';

import { AppStore } from '../store';

// Generic API error format
export interface Error {
  // The error name (e.g. "BadRequest", "ValidationError", etc.)
  name: string;
  // The error message string
  message: string;
  // HTTP status code
  code: number;
  // This is typically validation errors or if you want to group multiple errors together.
  errors: unknown;
  // Additional Context
  data: unknown;
}

// common interface for paginateable request
export interface PaginateableReq {
  limit?: number;
  offset?: number;
}

// common interface for paginateable response
export interface PaginateableRes<T> extends Required<PaginateableReq> {
  total: number;
  data: T[];
}

export interface Sort {
  colId: string;
  sort: 'asc' | 'desc';
}

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || '/',
});

// attach `Authorization` token to every http request
axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    config.headers = { ...config.headers, Authorization: accessToken };
  }
  return config;
});

// session expiration interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401)
      AppStore.dispatch({ type: 'auth/signOutRequest' });

    return Promise.reject(error);
  }
);

class BaseApi {
  public url: string;

  private config: ARC;

  private abortControllers: Map<string, AbortController>;

  constructor(url: string, config?: ARC) {
    this.abortControllers = new Map();
    this.url = url;
    this.config = config ? config : {};
  }

  public get = <Resp>(config?: ARC): Promise<AR<Resp>> =>
    axiosInstance.get<Resp>(this.url, { ...this.config, ...config });

  public getLatest = <Resp>(config?: ARC & { id?: string }): Promise<AR<Resp>> => {
    const identifier = config?.id || this.url;

    const last = this.abortControllers.get(identifier);
    if (last) last.abort();

    const current = new AbortController();
    this.abortControllers.set(identifier, current);

    const params = {
      ...this.config,
      ...config,
      signal: current.signal,
    };

    return axiosInstance
      .get<Resp>(this.url, params)
      .finally(() => this.abortControllers.delete(identifier));
  };

  public post = <Req, Resp>(data: Req, config?: ARC): Promise<AR<Resp>> =>
    axiosInstance.post<Resp>(this.url, data, { ...this.config, ...config });

  public put = <Req, Resp>(data: Req, config?: ARC): Promise<AR<Resp>> =>
    axiosInstance.put(this.url, data, { ...this.config, ...config });

  public delete = <Resp>(config?: ARC): Promise<AR<Resp>> =>
    axiosInstance.delete<Resp>(this.url, { ...this.config, ...config });
}

function getPagination(startRow: number, endRow: number) {
  return {
    limit: Math.max(endRow - startRow, 0),
    offset: startRow,
  };
}

function getSort(sortModel: Sort[]) {
  return sortModel.map((options: Sort) => {
    return `${options.colId} ${options.sort}`;
  });
}

export { getPagination, getSort };

export default BaseApi;
