import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import AlarmHistoryGrid from 'src/app/pump/alarm-history/alarm-history-grid/alarm-history-grid';
import { alarmHistoryColumns } from 'src/app/pump/alarm-history/alarm-history-grid/columns';
import { getQuerySearch } from 'src/shared/routing/routing.helper';

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const PumpAlarmHistory: FC<Props> = (props: Props) => {
  const { search, fetchAlarmData, alarmsHistory: data, exportUrl, titleSpec } = props;

  useEffect(() => {
    const params = new URLSearchParams(search);
    const pumpId = params.getAll('pumpId')[0];
    const toolId = params.getAll('toolId')[0];
    const siteId = params.getAll('siteId')[0];
    fetchAlarmData(pumpId, siteId, toolId);
  }, [search, fetchAlarmData]);

  return (
    <AlarmHistoryGrid
      exportUrl={exportUrl}
      autoSize={true}
      rows={data}
      columns={alarmHistoryColumns}
      titleSpec={titleSpec}
    />
  );
};

const mapState = (state: RootState) => {
  const { pump } = state;
  return {
    alarmsHistory: pump.alarmHistory,
    search: getQuerySearch(),
    exportUrl: '/pumps/alarms.xlsx',
    titleSpec: 'alarm-history.xlsx',
  };
};

const mapDispatch = (dispatch: RootDispatch) => {
  const { pump } = dispatch;
  return {
    fetchAlarmData: (pumpId: string, siteId: string, toolId?: string) =>
      pump.fetchPumpAlarmHistory({ pumpId, siteId, toolId }),
  };
};
export default connect(mapState, mapDispatch)(PumpAlarmHistory);
