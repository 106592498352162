import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import AddEditModal from './reset-pass-modal';

interface ComponentProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  isOpen: boolean;
  toggleDialog: (isOpen: boolean) => void;
  userId: number;
  loading: boolean;
}

const ResetPassModalContainer: FC<ComponentProps> = (props) => {
  const { isOpen, toggleDialog, userId, onSave, loading } = props;
  return (
    <AddEditModal
      isOpen={isOpen}
      toggleDialog={toggleDialog}
      userId={userId}
      onSave={onSave}
      loading={loading}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.loading.effects.users.editUser,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  onSave: dispatch.users.editUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassModalContainer);
