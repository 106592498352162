import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { PassedExportParams, exportToXlsx } from 'src/services/xlsx-export';
import { useStyles } from './styles';
import ExportIcon from '../icons/export-icon';

export interface PassedExportProps {
  url: string;
  params?: PassedExportParams;
  style?: CSSProperties;
  titleSpec?: string;
  onClick?: (url: string, title: string) => Promise<void>;
}

type Props = PassedExportProps & {
  gridTitle: string;
  chartTitle: string;
};

const ExportButton: FC<Props> = (props) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const isMounted = useRef(true);
  const classes = useStyles();
  const { url, params, gridTitle, chartTitle, style, titleSpec, onClick } = props;
  const gridExportName = titleSpec ? `${gridTitle}_${titleSpec}` : `${gridTitle}${url}`;
  const paramQuery = params || null;
  const documentTitle = params ? `${chartTitle}_${titleSpec}` : gridExportName; // url provides specifics with regards to grid export (ie. compressors, controllers, settings)

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const exportDataToExcel = async (
    url: string,
    title: string,
    query: PassedExportParams
  ) => {
    setIsDownloading(true);
    if (onClick) {
      await onClick(url, title);
    } else {
      await exportToXlsx(url, title, query);
    }
    if (isMounted.current) {
      setIsDownloading(false);
    }
  };

  return (
    <Button
      style={style}
      size="small"
      disabled={isDownloading}
      className={`${classes.exportBtn}`}
      onClick={() => exportDataToExcel(url, documentTitle, paramQuery)}>
      {!isDownloading && <ExportIcon className={classes.icon} />}
      {isDownloading && <CircularProgress color="secondary" size={22} />}
    </Button>
  );
};

export default ExportButton;
