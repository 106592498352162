import { createStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

const passwordFieldStyles = (): StyleRules =>
  createStyles({
    textField: {
      width: 'calc(50% - 8px)',
      marginBottom: '16px',
    },
    inputLabel: {
      fontSize: '0.875rem',
    },
    helperText: {
      marginLeft: '0.5rem',
      fontSize: '0.656rem',
    },
  });

export default passwordFieldStyles;
