import React, { FC } from 'react';
import { matchPath } from 'react-router';
import { Typography } from '@material-ui/core';
import { routesConstants } from 'src/shared/routing/routes.constants';
import { useStyles } from './styles';
import { checkPermission } from 'src/services/auth';
import RoutingTabs from 'src/shared/routing/routing-tabs';
import { useLocation } from 'react-router-dom';
import { RoutingTab } from 'src/shared/routing/routing-tabs/routing-tabs';
import { Roles } from 'src/store/api/users.api';

const Label: FC = (props) => {
  const classes = useStyles();

  return (
    <Typography variant="body2" component="span" className={classes.withoutTransform}>
      {props.children}
    </Typography>
  );
};

const TABS: RoutingTab[] = [
  {
    label: <Label>Home</Label>,
    path: routesConstants.DASHBOARD,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
    search: '',
  },
  {
    label: <Label>Charts</Label>,
    path: routesConstants.CHARTS_BASE,
    pathTo: routesConstants.CHARTS_NEW,
    exact: false,
    allowedRoles: [Roles.admin, Roles.cse],
    search: '',
  },
  {
    label: <Label>Machine Learning</Label>,
    path: routesConstants.MACHINE_LEARNING,
    pathTo: routesConstants.MACHINE_LEARNING_MONITORING,
    exact: false,
    allowedRoles: [Roles.admin],
    search: '',
  },
  {
    label: <Label>Admin</Label>,
    path: routesConstants.ADMIN_PAGE,
    exact: false,
    allowedRoles: [Roles.admin],
    search: '',
  },
];

const AppNavigation: FC = () => {
  const location = useLocation();
  const classes = useStyles();

  const value =
    TABS.find(
      (tab) => checkPermission(tab.allowedRoles) && matchPath(location.pathname, tab)
    )?.path || false;

  return (
    <RoutingTabs
      tabs={TABS}
      value={value}
      classes={{
        root: classes.tabsWithoutBorder,
        flexContainer: classes.tabsContainer,
      }}
    />
  );
};

export default AppNavigation;
