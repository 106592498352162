import React, { FC, memo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState, select } from 'src/store';
import DateInSelectedZone from 'src/shared/date-in-selected-zone';
import { formatTimezone } from '../format-timezone';
import { useStyles } from '../site-equipment-summary.styles'; // todo: extract styles
// import { ManualNotificationFlow } from 'src/shared/manual-notification';
// import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';

interface Props {
  siteId: number;
}

const SiteSummary: FC<Props> = ({ siteId }) => {
  const classes = useStyles();

  // const identifiers = useEquipmentIdentifiers();

  const site = useSelector((state: RootState) =>
    select.siteEquipment.selectSite(state, { siteId })
  );

  // site route, but no sites selected
  if (siteId === null) {
    return (
      <h1 className={clsx(classes.typoH5, classes.summaryCaption)}>
        <span>Status Dashboard</span>
      </h1>
    );
  }

  return (
    <div className={classes.summaryRoot}>
      <div className={classes.summarySlot}>
        <h1 className={[classes.typoH5, classes.summaryCaption].join(' ')}>
          {site?.name}
        </h1>
      </div>
      <div className={classes.freeSpace} />
      <div className={classes.summarySlot}>
        <span className={classes.subtitle}>Timezone: </span>
        <span className={classes.content}>{formatTimezone(site?.actualGmtOffset)}</span>
      </div>
      <div className={classes.summarySlot}>
        <span className={classes.subtitle}>Site ID: </span>
        <span className={classes.content}>{site?.siteId}</span>
      </div>
      <div className={classes.summarySlot}>
        <span className={classes.subtitle}>Tools: </span>
        <span className={classes.content}>{site?.toolCount}</span>
      </div>
      <div className={classes.summarySlot}>
        <span className={classes.subtitle}>Pumps: </span>
        <span className={classes.content}>{site?.pumpCount}</span>
      </div>
      <div className={classes.summarySlot}>
        <span className={classes.subtitle}>Last Data Load: </span>
        <span className={classes.content}>
          {site?.updatedAt && <DateInSelectedZone>{site?.updatedAt}</DateInSelectedZone>}
        </span>
      </div>
      {/* <ManualNotificationFlow siteName={site?.name} identifiers={identifiers} /> */}
    </div>
  );
};

export default memo(SiteSummary);
