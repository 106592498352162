import React, { FC, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { PassField } from 'src/shared/password-fields';
import { useStyles } from './edit-modal-form.styles';
import { FormContextValues } from 'react-hook-form';
import { User } from 'src/store/api/users.api';

export interface Props {
  user?: User;
  formContext: FormContextValues;
}

const NameTextFields: FC<Props> = (props) => {
  const { user, formContext } = props;
  const { register, errors, clearError } = formContext;
  const classes = useStyles();

  const handleInputChange = useCallback((field) => () => clearError(field), [clearError]);

  return (
    <>
      <TextField
        name="firstName"
        label="First Name"
        variant="filled"
        defaultValue={user?.firstName}
        inputRef={register({
          required: true,
          maxLength: 300,
        })}
        error={!!errors.firstName}
        autoComplete="off"
        InputLabelProps={{ classes: { root: classes.inputLabel } }}
        className={classes.textField}
      />
      <TextField
        name="lastName"
        label="Last Name"
        variant="filled"
        defaultValue={user?.lastName}
        inputRef={register({
          required: true,
          maxLength: 300,
        })}
        error={!!errors.lastName}
        autoComplete="off"
        InputLabelProps={{ classes: { root: classes.inputLabel } }}
        className={classes.textField}
      />
      <TextField
        name="email"
        label="Email"
        variant="filled"
        type="email"
        defaultValue={user?.email}
        helperText={errors.email?.message || errors.email?.types?.message}
        onChange={handleInputChange('email')}
        inputRef={register({
          required: true,
          maxLength: 300,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Invalid email format (email@example.com)',
          },
        })}
        error={!!errors.email}
        autoComplete="off"
        InputLabelProps={{ classes: { root: classes.inputLabel } }}
        FormHelperTextProps={{
          classes: { root: classes.helperText },
        }}
        className={classes.textField}
      />
      {!user && (
        <PassField
          name="password"
          label="Default Password"
          variant="filled"
          className={classes.textField}
          onChange={handleInputChange('password')}
          error={errors.password}
          inputRef={register({
            required: true,
            minLength: {
              value: 6,
              message: 'Error: At least 6 characters',
            },
            maxLength: 300,
          })}
        />
      )}
    </>
  );
};
export default NameTextFields;
