import { ICellRendererParams } from 'ag-grid-community';
import { canAcknowledge } from 'src/app/notification/notifications/notifications.helper';
import { EquipmentIdentifiers } from 'src/shared/routing/routing.helper';

export const fontWeight = (cell: ICellRendererParams) => {
  const isNotification = 'active' in cell?.data && 'acknowledged' in cell?.data;
  if (isNotification) return { fontWeight: canAcknowledge(cell.node.data) ? 800 : 400 };
};

//returns true if all needed ids are present
export const isLink = (linkParams: Omit<EquipmentIdentifiers, 'null'>) =>
  Object.values(linkParams).every((param) => param);
