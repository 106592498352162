import React, { FC, useCallback } from 'react';
import { UserPayload } from 'src/store/api/users.api';
import { Box, WithStyles, withStyles } from '@material-ui/core';
import Dialog from 'src/shared/dialog';
import { useForm } from 'react-hook-form';
import styles from './reset-pass-modal.styles';
import { ResetPassFields } from 'src/shared/password-fields/reset-pass-fields';
import { Sizes } from 'src/shared/dialog/dialog';

interface ComponentProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  toggleDialog: (isOpen: boolean) => void;
  userId: number;
  onSave: (user: UserPayload, meta: void) => Promise<void>;
  loading: boolean;
}

const ResetPassModal: FC<ComponentProps> = (props) => {
  const { isOpen, toggleDialog, userId, onSave, classes, loading } = props;

  const { register, errors, getValues, clearError, handleSubmit } = useForm();

  const handleClose = useCallback(() => {
    toggleDialog(!isOpen);
  }, [isOpen, toggleDialog]);

  const onSubmit = useCallback(
    (data: Record<string, void>) => {
      onSave({
        id: userId,
        user: {
          password: data.password,
        },
        onSuccess: () => toggleDialog(!isOpen),
      } as UserPayload);
    },
    [isOpen, onSave, toggleDialog, userId]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitBusy={loading}
      size={Sizes.small}
      title="Reset Password"
      submitLabel="Confirm">
      <Box className={classes.dialogContent}>
        <ResetPassFields
          className={classes.textField}
          fullWidth
          variant="filled"
          config={{
            password: {
              name: 'password',
              label: 'Default Password',
            },
            confirmPassword: {
              name: 'confirmedPassword',
              label: 'Confirm Default Password',
            },
          }}
          errors={errors}
          register={register}
          clearError={clearError}
          getValues={getValues}
        />
      </Box>
    </Dialog>
  );
};

export default withStyles(styles)(ResetPassModal);
