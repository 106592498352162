import { createModel } from '@rematch/core';
import { pumpsApi } from 'src/store/api/pumps.api';
import { Feedback } from 'src/store/api/feedbacks.api';
import { DashboardRowResp } from 'src/store/api/pumps.api';
import { RootModel } from '.';

export type Collection = DashboardRowResp['data'];

interface PredictionState {
  error: unknown;
  collection: Collection;
}

export const predictions = createModel<RootModel>()({
  state: {
    error: null,
    collection: [],
  } as PredictionState,

  reducers: {
    fail(state: PredictionState, error: unknown): PredictionState {
      return {
        ...state,
        error,
      };
    },

    fetch(state: PredictionState, records: Collection): PredictionState {
      return {
        ...state,
        collection: records,
      };
    },

    clear(state: PredictionState): PredictionState {
      return {
        ...state,
        collection: [],
        error: null,
      };
    },

    updateFeedbackStatus(state: PredictionState, feedback: Feedback): PredictionState {
      const collection = state.collection.map((entry) => ({
        ...entry,
        feedbackStatus:
          feedback.pump_id === entry.id ? feedback.feedback_status : entry.feedbackStatus,
      }));

      return { ...state, collection };
    },
  },

  effects: (dispatch) => ({
    fetchPredictions() {
      dispatch.predictions.clear();

      return pumpsApi
        .fetchPredictions()
        .then((response) => {
          dispatch.predictions.fetch(response.data);
        })
        .catch((failure) => {
          if (!failure.__CANCEL__) dispatch.predictions.fail(failure);
        });
    },
  }),
});
