import { connect } from 'react-redux';
import Admin from './admin';
import { RootDispatch, RootState } from 'src/store';

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: RootState) => ({
  list: state.users.list,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  fetchList: dispatch.users.fetchList,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
