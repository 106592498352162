import React, { FC } from 'react';
import { Autocomplete, AutocompleteOption } from 'src/shared/autocompletes-wtih-chips';
import { createLabel } from 'src/app/charts/chart-form/utils';
import { EntityType } from 'src/types';
import { DefaultChartValues } from 'src/app/charts/chart-form/chart-form';
import { Controller, FormContextValues } from 'react-hook-form';
import { useStyles } from 'src/app/charts/chart-form/chart-form.styles';
import { filterMetricOptions } from 'src/app/charts/chart-form/chart-form';

type MetricsByType = {
  Tool: AutocompleteOption[];
  Compressor: AutocompleteOption[];
  Pump: AutocompleteOption[];
};

export interface Props {
  side: 'left' | 'right';
  formContext: FormContextValues<DefaultChartValues>;
  metricsByType: MetricsByType;
  handleValidateMetrics: (
    resourceField: string,
    oppositeMetricsField: string
  ) => (value: AutocompleteOption[]) => boolean;
  handleChangeMetrics: (
    resourceField: string,
    field: string,
    oppositeMetricsField: string
  ) => ([, value]: any[]) => AutocompleteOption[];
}

const ChartFormTitle: FC<Props> = (props: Props) => {
  const {
    handleValidateMetrics,
    handleChangeMetrics,
    metricsByType,
    formContext,
    side,
  } = props;
  const { control, errors, getValues, watch } = formContext;
  const metrics = watch('metric');
  const styles = useStyles();

  const isLeftAxis = side === 'left' ? true : false;

  return (
    <Controller
      as={Autocomplete}
      name={isLeftAxis ? 'metrics.left.compressor' : 'metrics.right.compressor'}
      control={control}
      onChange={
        isLeftAxis
          ? handleChangeMetrics(
              'entities.compressors',
              'metrics.left.compressor',
              'metrics.right.compressor'
            )
          : handleChangeMetrics(
              'entities.compressors',
              'metrics.right.compressor',
              'metrics.left.compressor'
            )
      }
      rules={
        isLeftAxis
          ? {
              validate: handleValidateMetrics(
                'entities.compressors',
                'metrics.right.compressor'
              ),
            }
          : {
              validate: handleValidateMetrics(
                'entities.compressors',
                'metrics.left.compressor'
              ),
            }
      }
      className={styles.input}
      options={metricsByType[EntityType.Compressor]}
      filterOptions={filterMetricOptions(() =>
        getValues(isLeftAxis ? 'metrics.right.compressor' : 'metrics.left.compressor')
      )}
      value={[]}
      label={
        isLeftAxis
          ? createLabel('Compressor Metrics', metrics?.left.compressor.length)
          : createLabel('Compressor Metrics', metrics?.right.compressor.length)
      }
      error={
        isLeftAxis
          ? !!errors.metrics?.left?.compressor
          : !!errors.metrics?.right?.compressor
      }
      limitTags={2}
    />
  );
};
export default ChartFormTitle;
