import React, { useEffect, useState } from 'react';
import { RegenerationsStep } from 'src/store/api/regenerations.api';
import LineChart from 'src/shared/charts/line-chart';
import { LineChartYAxis } from 'src/shared/charts/line-chart/line-chart.config';
import { transform } from './utils';

import { useStyles } from './regeneration-chart.styles';

interface OwnProps {
  data: RegenerationsStep[];
}

// make 2 Y axis
const yAxis = {
  yAxis: [...LineChartYAxis, ...LineChartYAxis],
};

// assign right axis to TC series
const seriesConfig = {
  TC: {
    yAxisIndex: 1,
  },
};

export const PumpRegenerationChart: React.FC<OwnProps> = (props) => {
  const { data } = props;
  const styles = useStyles();

  const [series, setSeries] = useState(transform(data));

  useEffect(() => {
    setSeries(transform(data));
  }, [data]);

  return (
    <React.Fragment>
      <div className={styles.chartContainer}>
        <LineChart
          data={series}
          seriesConfig={seriesConfig}
          options={yAxis}
          hideLegendMetrics={true}
        />
      </div>
    </React.Fragment>
  );
};
