import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      root: {
        width: '100%',
        minHeight: '42px', // reserve minimum height to avoid screen jumps when switching between pumps
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
      },
      metricsContainer: {
        padding: '4px 0',
        marginBottom: '8px',
        borderBottom: `1px solid ${COLORS.secondary}`,
      },
      deviceMetricColumn: {
        '&:nth-child(odd)': {
          paddingRight: '24px',
        },
      },
      deviceMetricColoredBox: {
        '&:hover': {
          filter: 'brightness(0.7)',
        },
      },
      deviceMetricColoredBoxColor: {
        margin: '4px 8px 4px 6px;',
        display: 'inline-block',
        width: '16px',
      },
      deviceMetricColoredBoxLabelUnchecked: {
        color: COLORS.grey[500],
      },
      checkboxLabel: {
        marginLeft: 0,
        marginRight: 6,
      },
      checkboxIcon: {
        height: '22px',
        width: '22px',
      },
      checkboxRoot: {
        padding: '4px',
        marginRight: '4px',
      },
      checkboxWrapper: {
        borderBottom: `1px solid ${COLORS.secondary}`,
        paddingBottom: '4px',
      },
    })
);
