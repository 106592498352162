import React, { FC, useMemo } from 'react';
import { StyledColDef } from 'src/shared/base-grid/base-grid';
import BaseGrid from 'src/shared/base-grid';
import { createColumn } from 'src/shared/base-grid/column-creator';
import { columns } from './columns';
import { GridReadyEvent, IGetRowsParams, RowClassParams } from 'ag-grid-community';
import { HEADER_HEIGHTS } from 'src/shared/base-grid/base-grid.config';
import { canAcknowledge } from '../notifications.helper';

interface Props {
  onGridReady: (event: GridReadyEvent) => void;
  handleFetchRows: (params: IGetRowsParams) => void;
}

export const NotificationList: FC<Props> = React.memo(
  ({ onGridReady, handleFetchRows }) => {
    const colData: StyledColDef[] = useMemo(
      () =>
        columns.map((column) => {
          const isResizable = column.name !== 'notificationType';

          if (column.name === 'manualType') {
            return createColumn(column, {
              resizable: isResizable,
              //disabled default tooltip for manual type
              tooltipField: '',
            });
          }

          return createColumn(column, { resizable: isResizable });
        }),
      []
    );

    const getRowStyle = ({ data }: RowClassParams) => ({
      fontWeight: canAcknowledge(data) ? 800 : 400,
    });

    return (
      <BaseGrid
        autoSizeColumns={true}
        colData={colData}
        fetchRows={handleFetchRows}
        headerHeight={HEADER_HEIGHTS.oneRow}
        getRowStyle={getRowStyle}
        onGridReady={onGridReady}
      />
    );
  }
);
