import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import Button from '../../../shared/button';
import { routesConstants } from 'src/shared/routing/routes.constants';
import Dialog from 'src/shared/dialog';
import { Sizes } from 'src/shared/dialog/dialog';
import { RootDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
// import { ManualNotificationButton } from './manual-notification-button/manual-notification-button';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ToolbarEdit = (props: Props) => {
  const { charts, onRemove, loading } = props;

  const [isOpen, setOpen] = useState(false);
  const match = useRouteMatch<{ id: string; settings: string }>(
    routesConstants.CHARTS_DETAILS
  );

  const chart = useMemo(
    () => charts.find(({ id }) => match?.params.id && +match.params.id === id),
    [charts, match]
  );

  const handleClick = () => {
    setOpen(!isOpen);
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    chart?.id && onRemove(chart.id);
  };

  return (
    <Box p="24px 0" display="flex" justifyContent="space-between">
      <Typography variant="h5">{chart?.name}</Typography>
      <Box display="flex">
        <Box mr={2}>
          <Button color="secondary" onClick={handleClick} busy={loading}>
            Delete
          </Button>
          <Dialog
            open={isOpen}
            size={Sizes.small}
            title="Delete?"
            submitLabel="Delete"
            onClose={handleClose}
            onSubmit={handleSubmit}>
            <Typography variant="body2" component="span">
              Do you want to delete this chart?
            </Typography>
          </Dialog>
        </Box>
        {match?.params.settings && (
          <Box mr={2}>
            <Button form="chart-form" type="submit">
              Save
            </Button>
          </Box>
        )}
        <Box mr={2}>
          <Button component="link" componentProps={{ to: routesConstants.CHARTS_NEW }}>
            Create Chart
          </Button>
        </Box>
        {/* <ManualNotificationButton /> */}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({
  charts: state.charts.charts,
  loading: state.loading.effects.charts.removeChart,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  onRemove: dispatch.charts.removeChart,
});

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarEdit);
