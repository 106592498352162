import React from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { getQuerySearch } from 'src/shared/routing/routing.helper';
import { PumpDetails } from 'src/app/pump/pump-details/pump-details';
import { getWeekRange } from 'src/shared/date-range-filter/filters-config';
import { FetchSeriesParams } from 'src/store/api/charts.api';

type ComponentProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export class PumpDetailsWidget extends React.Component<ComponentProps> {
  render() {
    const { pump, lastDataChart, predictiveChart, isPredictiveChartLoading } = this.props;
    return pump ? (
      <PumpDetails
        onDateChange={this.onDateChange}
        pump={pump}
        lastDataChart={lastDataChart}
        predictiveChart={predictiveChart}
        isPredictiveChartLoading={isPredictiveChartLoading}
      />
    ) : null;
  }

  componentDidMount() {
    const { search, fetchLastDataCharts, fetchPredictiveCharts } = this.props;
    const [startTime, endTime] = getWeekRange();

    this.props.fetchPumpDetails(search || '');

    const fetchParams: FetchSeriesParams = {
      search,
      start: startTime,
      end: endTime,
    };

    fetchPredictiveCharts(fetchParams);
    fetchLastDataCharts(fetchParams);
  }

  private onDateChange = (start: Date, end: Date): void => {
    const { search, fetchLastDataCharts, fetchPredictiveCharts } = this.props;

    const fetchParams: FetchSeriesParams = {
      search,
      start,
      end,
    };

    fetchLastDataCharts(fetchParams);
    fetchPredictiveCharts(fetchParams);
  };
}

const mapState = (state: RootState) => {
  const { pump } = state;

  return {
    pump: pump.details,
    lastDataChart: pump.lastDataChart || [],
    predictiveChart: pump.predictiveChart || [],
    search: getQuerySearch(),
    isPredictiveChartLoading: state.loading.effects.pump.fetchPredictiveCharts,
  };
};

const mapDispatch = (dispatch: RootDispatch) => {
  const { pump } = dispatch;

  return {
    fetchPumpDetails: pump.fetchPumpDetails,
    fetchLastDataCharts: (params: FetchSeriesParams) => pump.fetchPumpCharts(params),
    fetchPredictiveCharts: (params: FetchSeriesParams) =>
      pump.fetchPredictiveCharts(params),
    clearCharts: () => pump.clearCharts(),
  };
};

export const PumpDetailsContainer = connect(mapState, mapDispatch)(PumpDetailsWidget);
