import React, { useCallback, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Link } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { RootDispatch } from 'src/store';
import { fontWeight, isLink } from './links-helper';
import { useStyles } from './styles';

export const EquipmentLinkRenderer = (cell: ICellRendererParams) => {
  const classes = useStyles();
  const { siteEquipment } = useDispatch<RootDispatch>();

  const getEquipmentId = useCallback(() => {
    const { equipmentId, equipmentType, id, pump_id } = cell?.data || {};

    if (equipmentType === 'pump') {
      return { pumpId: equipmentId };
    }

    if (equipmentType === 'controller') {
      return { controllerId: equipmentId };
    }

    if (equipmentType === 'compressor') {
      return { compressorId: equipmentId };
    }

    return { pumpId: id || pump_id };
  }, [cell?.data]);

  const linkParams = useMemo(() => {
    const { siteId, site_id, toolId, tool_id } = cell?.data || {};
    return {
      siteId: siteId || site_id,
      toolId: toolId || tool_id,
      ...getEquipmentId(),
    };
  }, [cell?.data, getEquipmentId]);

  const handleClick = useCallback(() => {
    siteEquipment.navigateToEquipment(linkParams);
  }, [linkParams, siteEquipment]);

  if (!cell.value) {
    return '';
  }

  return isLink(linkParams) ? (
    <Link
      style={fontWeight(cell)}
      className={classes.link}
      component="button"
      variant="body2"
      onClick={handleClick}
      color="inherit">
      {cell.value}
    </Link>
  ) : (
    cell.value
  );
};
