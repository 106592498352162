import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const styles = (): StyleRules =>
  createStyles({
    container: {
      position: 'relative',
      marginRight: '1.875rem',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      width: '12.5rem',
    },
    formRoot: {
      width: '100%',
      height: '2rem',
    },
    select: {
      fontSize: '0.875rem',
      padding: '0',
      paddingLeft: '0.5rem',
      height: '2rem',
    },
    filled: {
      lineHeight: '1.875rem',
    },
    menuItemRoot: {
      paddingLeft: '0.5rem',
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    img: {
      display: 'inline-block',
      marginRight: '0.5rem',
      width: '1rem',
      height: '1rem',
    },
  });

export const useStyles = makeStyles(styles);
