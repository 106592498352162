import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { Props } from '../date-formatter';

const mapStateToProps = (state: RootState) => ({
  selectedZone: state.timezone.selectedZone,
});

const withTimezone = (Component: (props: Props) => JSX.Element) => {
  const wrapper = (props: Props) => <Component {...props} />;
  return connect(mapStateToProps)(wrapper);
};

export default withTimezone;
