import { FC, ReactText, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { RootDispatch, RootState } from 'src/store';
import { connect } from 'react-redux';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

let displayed: (string | number)[] = [];

const Notifier: FC<Props> = (props) => {
  const { snackbars, removeSnackbar } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: ReactText) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: ReactText) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    snackbars.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (!displayed.includes(key)) {
        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            removeSnackbar(myKey as string);
            removeDisplayed(myKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    });
  }, [closeSnackbar, enqueueSnackbar, snackbars, removeSnackbar]);

  return null;
};

const mapStateToProps = (state: RootState) => ({
  snackbars: state.snackbars.list,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  removeSnackbar: (key: string) => dispatch.snackbars.removeSnackbar(key),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
