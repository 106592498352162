import React, { FC, useEffect, useMemo } from 'react';
import { createColumn } from 'src/shared/base-grid/column-creator';
import { ColData } from 'src/shared/base-grid/base-grid';
import BaseGrid from 'src/shared/base-grid';
import { Box } from '@material-ui/core';
import ExportBtn from 'src/shared/button-export';

interface Props {
  columns?: ColData[];
  rows: unknown[] | null;
  search: string | undefined;
  exportUrl: string;
  fetchColumns: () => void;
  fetchRows: () => void;
  onCleanup: () => void;
  headerHeight?: number;
}

const ViewBasedGrid: FC<Props> = (props) => {
  const {
    columns,
    rows,
    search,
    exportUrl,
    fetchColumns,
    fetchRows,
    onCleanup,
    headerHeight,
  } = props;

  const colData = useMemo(() => (columns ? columns.map((el) => createColumn(el)) : []), [
    columns,
  ]);

  // only fetch columns on component mount
  useEffect(() => {
    if (!columns) fetchColumns();
  }, [columns, fetchColumns]);

  // fetch rows whenever selected site/tool/pump changed
  useEffect(() => {
    fetchRows();
  }, [fetchRows, search]);

  // unset rows to clear previously displayed state to empty one
  // whenever selected site/tool/pump changed
  useEffect(() => {
    return () => onCleanup();
  }, [onCleanup, search]);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end">
        <ExportBtn url={exportUrl} />
      </Box>
      <BaseGrid
        autoSizeColumns={true}
        colData={colData}
        rows={rows}
        headerHeight={headerHeight}
      />
    </Box>
  );
};

export default ViewBasedGrid;
