import React, { FC, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { ApiToolParams } from 'src/store/api/tool.api';
import { RootDispatch, RootState } from 'src/store';
import { getQuerySearch } from 'src/shared/routing/routing.helper';
import { COLORS } from 'src/styles/colors';
import { useStyles } from './styles';
import EmptyState from 'src/shared/empty-state';
import Spinner from 'src/shared/spinner';

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

type AlarmConfigData = {
  alarm: string;
  enabled: boolean;
};

const AlarmConfig: FC<Props> = (props) => {
  const classes = useStyles();
  const { alarmData: data, search, fetchAlarmConfig, isLoadingAlarms } = props;
  const COLUMNS = ['Alarm', 'Enabled'];

  useEffect(() => {
    const params = new URLSearchParams(search);
    const toolId = params.getAll('toolId')[0];
    const siteId = params.getAll('siteId')[0];
    fetchAlarmConfig(toolId, siteId);
  }, [search, fetchAlarmConfig]);

  if (isLoadingAlarms) {
    return (
      <Box className={classes.spinnerContainer}>
        <Spinner />
      </Box>
    );
  }

  if (!data) {
    return <EmptyState>No rows to show</EmptyState>;
  }

  return (
    <Box style={{ padding: '1rem' }}>
      <TableContainer className={classes.alarmContainer} component={Paper}>
        <Table aria-label="tool alarms table" size="small">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {COLUMNS.map((col: string) => (
                <TableCell key={col}>{col}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((obj: AlarmConfigData) => (
                <TableRow key={obj.alarm} style={!obj.enabled ? { opacity: 0.5 } : {}}>
                  <TableCell>{obj.alarm}</TableCell>
                  <TableCell
                    style={
                      obj.enabled
                        ? { color: COLORS.green[500] }
                        : { fontStyle: 'italic', color: COLORS.red[200] }
                    }>
                    {obj.enabled ? 'true' : 'false'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
const mapState = (state: RootState) => {
  const { tool } = state;
  return {
    alarmData: tool.alarmConfigData,
    search: getQuerySearch(),
    isLoadingAlarms: state.loading.effects.tool.fetchToolAlarmConfig,
  };
};

const mapDispatch = (dispatch: RootDispatch) => {
  const { tool } = dispatch;
  return {
    fetchAlarmConfig: (toolId: string, siteId: string) =>
      tool.fetchToolAlarmConfig({ siteId, toolId } as ApiToolParams),
  };
};

export default connect(mapState, mapDispatch)(AlarmConfig);
