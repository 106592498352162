import { ColData } from 'src/shared/base-grid/base-grid';

export const diodesColumns: ColData[] = [
  {
    name: 'stage',
    label: 'Stage',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 3,
  },

  {
    name: 'created_at',
    label: 'Last Date',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'date',
  },
  {
    name: 'serial_number',
    label: 'Serial Number',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'string',
    flex: 1,
  },
  {
    name: 'type',
    label: 'Type',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'string',
    flex: 2,
  },
  {
    name: 'ch1',
    label: 'CH1',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 1,
  },
  {
    name: 'ch2',
    label: 'CH2',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 1,
  },
  {
    name: 'ch3',
    label: 'CH3',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 1,
  },
  {
    name: 'ch4',
    label: 'CH4',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 1,
  },
  {
    name: 'ch5',
    label: 'CH5',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 1,
  },
  {
    name: 'ch6',
    label: 'CH6',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 1,
  },
  {
    name: 'ch7',
    label: 'CH7',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 1,
  },
  {
    name: 'ch8',
    label: 'CH8',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 1,
  },
  {
    name: 'ch9',
    label: 'CH9',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'number',
    flex: 1,
  },
];
