import React, { FC } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Notifier from './notifier.container';
import { closeSnackbar } from 'src/services/snackbars';

const SnackbarProvider: FC = ({ children }) => {
  const onClose = (key: string) => closeSnackbar(key);

  return (
    <NotistackProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      action={(key: string) => [
        <IconButton key="close" color="inherit" onClick={() => onClose(key)}>
          <CloseIcon fontSize="small" />
        </IconButton>,
      ]}>
      <Notifier />
      {children}
    </NotistackProvider>
  );
};

export default SnackbarProvider;
