import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      layoutRoot: {
        display: 'flex',
      },
      centerContent: {
        margin: 'auto',
      },
      fitSpace: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
      },
    })
);
