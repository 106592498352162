import React from 'react';
import { Link } from '@material-ui/core';
import { RootDispatch } from 'src/store';
import { useDispatch } from 'react-redux';
import { ICellRendererParams } from 'ag-grid-community';
import { fontWeight } from './links-helper';
import { useStyles } from './styles';

export const SubjectLinkRenderer = (props: ICellRendererParams) => {
  const classes = useStyles();
  const dispatch = useDispatch<RootDispatch>();

  const onClick = () => {
    props.node.setSelected(true);
    if (props.data.notificationType === 'auto') {
      const { siteId, notificationId } = props.data;
      dispatch.notifications.getAutoNotification({ siteId, notificationId });
    } else {
      dispatch.notifications.setCurrentNotification(props.data);
    }
  };

  if (!props.value) {
    return '';
  }

  return (
    <Link
      style={fontWeight(props)}
      className={classes.link}
      component="button"
      variant="body2"
      onClick={onClick}>
      {props.value}
    </Link>
  );
};
