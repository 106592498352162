import {
  ChartVisualMapOption,
  ChartXAxisOption,
  ChartYAxisOption,
} from 'src/shared/charts/base-chart';
import { COLORS } from 'src/styles/colors';

export const ANOMALY_VALUE = 99999;

const tooltip = {
  textStyle: {
    fontSize: 11,
  },
  formatter(params: any) {
    const value = params.value[2] === ANOMALY_VALUE ? params.name : params.value[2];
    return `
       <div style="padding: 0 5px;">
          <div>${params.data.label}</div>
          <div style="display: flex;">
            <div
             style="width:16px;height:16px;background-color:${params.color};margin-right:8px;"></div>
            RPM: ${value}
          </div>
       </div>
    `;
  },
};

const visualMap: ChartVisualMapOption[] = [
  {
    show: false,
    type: 'piecewise',
    orient: 'horizontal',
    pieces: [
      {
        min: 40,
        max: 60,
        color: COLORS.success,
      },
      {
        min: 60,
        max: 70,
        color: COLORS.warning,
      },
      {
        min: 70,
        max: ANOMALY_VALUE - 1,
        color: COLORS.danger,
      },
      {
        min: ANOMALY_VALUE,
        max: ANOMALY_VALUE,
        label: 'Anomaly',
        color: COLORS.red['500'],
      },
    ],
    outOfRange: {
      color: COLORS.grey['400'],
    },
  },
];

const xAxisBase: ChartXAxisOption = {
  type: 'category',
  data: [],
  show: true,
  axisLine: {
    show: false,
  },
  axisLabel: {
    color: COLORS.grey['600'],
  },
  axisTick: {
    show: false,
  },
};

const yAxisBase: ChartYAxisOption = {
  type: 'category',
  data: [],
  show: true,
  axisLine: {
    show: false,
  },
  axisLabel: {
    color: COLORS.grey['600'],
  },
  axisTick: {
    show: false,
  },
};

const grid = {
  top: 16,
  bottom: 40,
  right: 16,
  left: 32,
};

export const chartConfig = {
  tooltip,
  visualMap,
  xAxisBase,
  yAxisBase,
  grid,
};
