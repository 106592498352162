import React, { ReactNode, useCallback } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { TextField, TextFieldProps, WithStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { isValid } from 'date-fns';
import { FORMAT_MAP } from 'src/shared/date-in-selected-zone/date-formatter';
import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  value: Date | null;
  onChange: (date: ParsableDate) => void;
  required?: boolean;
  format?: string;
  id?: string;
  label?: string;
  maxDate?: ParsableDate;
  minDate?: ParsableDate;
  onError?: (error: ReactNode) => void;
  isError?: boolean;
}

const DatePicker: React.FC<Props> = (props) => {
  const {
    label,
    format = FORMAT_MAP.short,
    id,
    value,
    maxDate,
    minDate,
    onError,
    onChange,
    isError,
    required,
    classes,
  } = props;

  const handleDateChange = (date: ParsableDate) => {
    if (value && isValid(value) && date instanceof Date) {
      date.setHours(value.getHours(), value.getMinutes(), 0, 0);
    }

    onChange(date);
  };

  const handleError = (error: ReactNode) => error && onError?.(error);

  const TextFieldComponent = useCallback(
    (textFieldProps: TextFieldProps) => {
      const base = textFieldProps.InputProps || {};

      const fieldProps = {
        ...base,
        classes: {
          ...base.classes,
          adornedEnd: classes.adornedEnd,
          input: classes.input,
          root: classes.root,
        },
      };
      return (
        <TextField
          {...textFieldProps}
          autoFocus={textFieldProps.error}
          error={isError || textFieldProps.error}
          InputProps={fieldProps}
        />
      );
    },
    [classes.adornedEnd, classes.input, classes.root, isError]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={value}
        onChange={handleDateChange}
        onError={handleError}
        required={required}
        minDate={minDate}
        maxDate={maxDate}
        minDateMessage={!isError && 'Date is less than the minimum threshold.'}
        maxDateMessage={!isError && 'Date is over than the maximum threshold.'}
        disableToolbar
        variant="inline"
        inputVariant="filled"
        fullWidth={true}
        id={id}
        label={label}
        format={format}
        KeyboardButtonProps={{
          'aria-label': 'change date',
          edge: 'end',
          classes: { root: classes.calendarIcon },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        TextFieldComponent={TextFieldComponent}
        autoOk
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
