import React from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { ModelSelector } from './model-selector';
import { Model } from 'src/store/api/ml.api';
import { FetchScoreQuery } from 'src/store/models/ml.model';
import { Site } from 'src/store/api/sites.api';

interface Props {
  models: Model[] | null;
  sites: Site[];
  pendingModels: boolean;
  pendingScore: boolean;
  onFetchSites: () => void;
  onFetchModels: (query?: unknown) => void;
  onFetchScore: (query?: FetchScoreQuery) => void;
}

class ModelSelectorWidget extends React.Component<Props> {
  render() {
    const { models, sites, pendingScore, pendingModels } = this.props;

    return (
      <ModelSelector
        sites={sites}
        models={models || []}
        pendingScore={pendingScore}
        pendingModels={pendingModels}
        onChange={this.onSelectorChange}
      />
    );
  }

  onSelectorChange = (query: FetchScoreQuery) => {
    this.props.onFetchScore?.(query);
  };

  componentDidMount() {
    const { models, sites } = this.props;

    if (!sites.length) {
      this.props.onFetchSites?.();
    }

    if (!models) {
      this.props.onFetchModels?.();
    }
  }
}

const mapState = (state: RootState) => {
  const { effects } = state.loading;

  return {
    sites: state.siteEquipment.sites,
    models: state.ml.models.list,
    pendingModels: effects.ml.fetchModels,
    pendingScore: effects.ml.fetchScore,
  };
};

const mapDispatch = (dispatch: RootDispatch) => ({
  onFetchSites: dispatch.siteEquipment.fetchSites,
  onFetchModels: dispatch.ml.fetchModels,
  onFetchScore: dispatch.ml.fetchScore,
});

export const ModelSelectorContainer = connect(mapState, mapDispatch)(ModelSelectorWidget);
