import { ColData } from 'src/shared/base-grid/base-grid';

export const alarmHistoryColumns: ColData[] = [
  {
    name: 'created_at',
    label: 'Date',
    sortDirection: 'desc',
    units: null,
    visibility: true,
    type: 'date',
  },
  {
    name: 'alarm',
    label: 'Alarm',
    sortDirection: null,
    units: null,
    visibility: true,
    type: 'string',
    flex: 4,
  },
];
