import React, { FC, SyntheticEvent } from 'react';
import { useStyles } from '../styles';
import IconButton from '@material-ui/core/IconButton';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

export interface Props {
  onChange: (event: SyntheticEvent) => void;
  expanded: boolean;
}

const NodeButton: FC<Props> = (props) => {
  const { onChange, expanded } = props;
  const classes = useStyles();
  return (
    <div className={classes.col}>
      <IconButton classes={{ root: classes.nodeButton }} onClick={onChange}>
        {expanded ? <ExpandLess fontSize="inherit" /> : <ExpandMore fontSize="inherit" />}
      </IconButton>
    </div>
  );
};
export default NodeButton;
