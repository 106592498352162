import React, { Children, FC, useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';
import debounce from 'lodash/debounce';

const defaultRatio = [5, 2];

export const ResizablePanels: FC = ({ children }) => {
  const styles = useStyles();
  const resizableEl = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<string>(`${defaultRatio[0]}`);

  useEffect(() => {
    const handleDrag = debounce((event) => {
      if (resizableEl?.current) {
        const clientY = event.clientY;
        const rect = resizableEl?.current.getBoundingClientRect();
        const delta = rect.bottom - clientY;
        const height = rect.height - delta;
        if (height) setSize(`0 0 ${height}px`);
      }
    });
    document.addEventListener('dragover', handleDrag);

    return () => {
      document.removeEventListener('dragover', handleDrag);
    };
  }, []);

  const [topPanel, bottomPanel] = Children.toArray(children);

  return (
    <div className={styles.outerContainer}>
      <Box className={styles.innerContainer} display="flex" flexDirection="column">
        <div
          className={styles.resizableTopPanel}
          style={{ flex: size }}
          ref={resizableEl}>
          {topPanel}
        </div>
        <Box draggable="true" className={styles.divider} />
        <Box className={styles.resizableBottomPanel} flex={defaultRatio[1]}>
          {bottomPanel}
        </Box>
      </Box>
    </div>
  );
};
