import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import xor from 'lodash/xor';
import { GroupedSeries } from 'src/store/api/charts.api';

import BaseChart, {
  BaseChartProps,
  ChartSeriesLine,
  defaultPalette,
} from 'src/shared/charts/base-chart';
import Wrapper from './wrapper';
import Legend from './legend';

import { getConfig, lineSeriesConfig } from './line-chart.config';
import {
  Color,
  GetColor,
  TransformedLineSeries,
  getUniqueIdentifers,
  transformLineSeries,
} from './series-transformer';

type axisConfig = {
  min?: number;
  max?: number;
  name?: string;
  series?: string[];
};

// todo: introduce loading attribute?
interface OwnProps {
  // original not transformed data (combines several series all together)
  data: GroupedSeries[];

  // original series config map where key is series name from `data`
  // todo: could be replaced with left/right axis config
  seriesConfig?: Record<string, Exclude<ChartSeriesLine, 'type'>>;

  getColor?: GetColor;

  title?: string;
  height?: number;

  leftAxis?: axisConfig;
  rightAxis?: axisConfig;

  minX?: number;
  maxX?: number;

  hideLegend?: boolean;
  hideLegendMetrics?: boolean;
}

interface ConnectedProps {
  timeZoneOffset: number;
}

type LineChartProps = OwnProps & ConnectedProps & Omit<BaseChartProps, 'series'>;

const defaultGetColor = (index: number): Color => {
  return {
    color: defaultPalette[index % defaultPalette.length],
  };
};

const LineChart: FC<LineChartProps> = (props) => {
  const {
    data,
    seriesConfig,
    getColor = defaultGetColor,
    options,
    timeZoneOffset,
    height,
    title,
    hideLegend,
    hideLegendMetrics,
    minX,
    maxX,
  } = props;

  // echart line config
  const config = getConfig(minX, maxX);

  // keep transformed data
  const [dataSeries, setDataSeries] = useState<TransformedLineSeries>([]);

  // keep track of all transformed echarts consumable series
  const [series, setSeries] = useState<ChartSeriesLine[]>([]);

  useEffect(() => {
    const dataSeries = transformLineSeries(data, timeZoneOffset, getColor);
    setDataSeries(dataSeries);

    const series = dataSeries.map((series) => {
      const config = seriesConfig ? seriesConfig[series.seriesName] : {};
      const color = series.color;
      return {
        ...lineSeriesConfig,
        // echarts data require value inside data to be number, but we use array of 2 elements [timestamp, value] / [x,y]
        data: series.data as any, // eslint-disable-line
        name: series.uid,
        seriesName: series.seriesName,
        lineStyle: {
          color: color?.color,
          type: color?.type,
        },
        color: color?.color,
        ...config, // alter series by any given static/dynamic configuration
      };
    });

    setSeries(series);
  }, [data, seriesConfig, timeZoneOffset, getColor]);

  // keep track of unselected series (legend state as well)
  const [unselectedSeries, setUnselectedSeries] = useState<string[]>([]);

  const visibleSeries = useMemo(
    () => series.filter(({ name }) => name && !unselectedSeries.includes(name)),
    [series, unselectedSeries]
  );

  const allSeriesIdentifiers = useMemo(() => getUniqueIdentifers(dataSeries), [
    dataSeries,
  ]);

  // update unselected
  const handleLegendSeriesCheckboxChange = useCallback(
    (selected: string[]) => {
      setUnselectedSeries(xor(allSeriesIdentifiers, selected));
    },
    [allSeriesIdentifiers]
  );

  return (
    <Wrapper title={title}>
      <BaseChart
        {...props}
        options={{
          ...config,
          ...options,
        }}
        series={visibleSeries}
        height={height}
      />
      {!hideLegend && (
        <Box ml={2} mr={2}>
          <Legend
            dataSeries={dataSeries}
            unselectedSeries={unselectedSeries}
            onChange={handleLegendSeriesCheckboxChange}
            hideMetrics={hideLegendMetrics}
          />
        </Box>
      )}
    </Wrapper>
  );
};

export default LineChart;
