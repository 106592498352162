import React, { FC, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { getQuerySearch } from 'src/shared/routing/routing.helper';
import { checkPermission } from 'src/services/auth';
import { Roles } from 'src/store/api/users.api';
import { Location } from 'history';
import { useStyles } from './styles';

type Icon = React.FC<{ style: React.CSSProperties }>;

export interface RoutingTab {
  label: string | ReactNode;
  path: string;
  pathTo?: string; // used when there're different paths for tab active state and Link's path
  exact: boolean;
  allowedRoles: Roles[];
  search?: string;
  icon?: Icon;
}

type ConnectedStateProps = ReturnType<typeof mapState>;

interface PassedProps {
  value: string | false; // false when there's no active tab
  tabs: RoutingTab[];
  classes?: Record<string, string>;
  onChange?: (event: React.ChangeEvent<unknown>, value: string) => void;
}

type Props = ConnectedStateProps & PassedProps;

const renderIcon = (TabIcon: Icon | undefined) => {
  if (TabIcon) {
    //in such a way(inline styles with higher priority) we override default material styles
    return <TabIcon style={{ marginBottom: '0.2rem', marginLeft: '0.2rem' }} />;
  }
};

const RoutingTabs: FC<Props> = (props) => {
  const classes = useStyles();

  // * This hook is needed for setting correct position of active tab underline after mounting
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);

  return (
    <Tabs
      value={props.value}
      indicatorColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      classes={props.classes}
      onChange={props.onChange}>
      {props.tabs.map((route) => {
        return (
          checkPermission(route.allowedRoles) && (
            <Tab
              classes={{
                wrapper: classes.wrapper,
                labelIcon: classes.labelIcon,
              }}
              key={route.path}
              label={route.label}
              component={Link}
              icon={renderIcon(route.icon)}
              to={(location: Location) => ({
                ...location,
                pathname: route.pathTo || route.path,
                exact: route.exact,
                search: route.search ?? location.search,
              })}
              value={route.path}
            />
          )
        );
      })}
    </Tabs>
  );
};

const mapState = () => ({
  search: getQuerySearch(),
});

export default connect(mapState)(RoutingTabs);
