import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: '1rem',
    },
    detailsContainer: {
      display: 'flex',
      paddingBottom: '1rem',
      marginBottom: '1rem',
      borderBottom: `1px solid ${COLORS.grey['300']}`,
    },
    chartContainer: {
      width: '100%',
    },
    spacer: {
      margin: 'auto',
    },
    tableRoot: {
      tableLayout: 'fixed',
    },
    headerCell: {
      border: 'none',
      color: COLORS.grey['600'],
    },
    tableCell: {
      border: 'none',
      color: COLORS.black,
    },
    chartSection: {
      maxWidth: '160px',
    },
    chartSlot: {
      height: '124px',
      margin: '0 auto',
      width: '124px',
    },
    textLinePxSmall: {
      paddingLeft: '4px',
      paddingRight: '4px',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    textLine: {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    pxSmall: {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
    ptop: {
      paddingTop: '8px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
      padding: '8px 4px',
    },
  });

export const useStyles = makeStyles(styles);
