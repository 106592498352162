import { connect } from 'react-redux';

import { RootState } from 'src/store';

import { ProfileMenu } from './ProfileMenu';

const mapState = (state: RootState) => ({
  user: state.auth.user,
});

export const ProfileMenuContainer = connect(mapState)(ProfileMenu);
