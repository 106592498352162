import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { Feedback, feedbacksApi } from '../api/feedbacks.api';
import { getNormalizedData } from '../normalize/feedback';
import { showSuccess } from 'src/services/snackbars';

export type FeedbackValidationErrors = Record<
  string,
  { error: string; restriction: string }[]
> | null;

export interface FeedbackState {
  feedbackData: Feedback | null;
  invalidFields: FeedbackValidationErrors;
}

interface FetchParams {
  pumpId: number;
  siteId: number;
}

interface CreateFeedbackParams {
  formValues: Feedback;
  onAfterSave?: (value: Feedback) => void;
}

export const feedback = createModel<RootModel>()({
  state: {
    feedbackData: null,
    invalidFields: null,
  } as FeedbackState,

  reducers: {
    setFeedback: (
      state: FeedbackState,
      feedbackData: Feedback | null
    ): FeedbackState => ({
      ...state,
      feedbackData,
    }),

    setInvalidFields: (
      state: FeedbackState,
      invalidFields: FeedbackValidationErrors
    ): FeedbackState => ({
      ...state,
      invalidFields,
    }),
  },

  effects: (dispatch) => ({
    async fetchFeedback({ pumpId, siteId }: FetchParams): Promise<void | FeedbackState> {
      const params = new URLSearchParams();
      params.set('site_id_eq', siteId.toString());
      params.set('pump_id_eq', pumpId.toString());

      const {
        data: { data },
      } = await feedbacksApi.fetchFeedback(params);
      const normalizedData = getNormalizedData(data[0]);

      dispatch.feedback.setFeedback(normalizedData);
    },

    async createFeedback({
      formValues,
      onAfterSave,
    }: CreateFeedbackParams): Promise<void | FeedbackState> {
      try {
        const { data } = await feedbacksApi.createFeedback(formValues);
        onAfterSave?.(data as Feedback);
        showSuccess('Saved');
      } catch (error) {
        dispatch.feedback.setInvalidFields(error.response.data.errors);
      }
    },
  }),
});
