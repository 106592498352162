import differenceInDays from 'date-fns/differenceInDays';
import toArray from 'lodash/toArray';
import get from 'lodash/get';
import {
  DATE_YEAR_MASK,
  formatDate,
} from 'src/shared/date-in-selected-zone/date-formatter';
import { COLORS } from 'src/styles/colors';
import {
  ChartOption,
  ChartTooltipOption,
  ChartXAxisOption,
  ChartYAxisOption,
} from 'src/shared/charts/base-chart';
import {
  LineChartGridOptions,
  yAxisLabelDefault,
} from 'src/shared/charts/line-chart/line-chart.config';

export const WEEK_LENGTH = 7;

const isLongerThanWeek = (min: number, max: number) => {
  return differenceInDays(max, min) > WEEK_LENGTH;
};

const getXAxisConfig = (min: number, max: number): ChartXAxisOption => {
  return {
    type: 'time',
    axisLabel: {
      showMinLabel: isLongerThanWeek(min, max),
      fontSize: 11,
      color: COLORS.grey['600'],
      formatter(date: number) {
        return formatDate(date, DATE_YEAR_MASK);
      },
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      lineStyle: {
        width: 1,
        color: COLORS.grey['200'],
      },
    },
    min: 'dataMin',
    max: 'dataMax',
  };
};

const yAxisConfig: ChartYAxisOption[] = [
  {
    min: 0,
    max: 100,
    axisLabel: yAxisLabelDefault,
    axisLine: {
      lineStyle: {
        color: COLORS.grey['200'],
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: COLORS.grey['200'],
      },
    },
    axisTick: {
      show: false,
    },
    nameGap: 30,
    nameLocation: 'middle',
    nameTextStyle: {
      lineHeight: 16,
      fontSize: 11,
      color: COLORS.grey['700'],
    },
  },
];

const getValue = (series: echarts.EChartOption.Tooltip.Format) => get(series, 'value[1]');

const tooltipConfig = {
  trigger: 'axis',
  padding: 8,
  appendToBody: true,
  formatter: (series) => {
    series = toArray(series);
    const actualSeries = series[0];

    return actualSeries
      ? `${formatDate(actualSeries.axisValue as number, DATE_YEAR_MASK)}<br/>
        ${actualSeries.marker}${actualSeries.data.name}: ${getValue(actualSeries)}`
      : '';
  },
} as ChartTooltipOption;

export const getConfig = (minX: number, maxX: number): ChartOption => {
  return {
    tooltip: tooltipConfig,
    grid: LineChartGridOptions,
    yAxis: yAxisConfig,
    xAxis: getXAxisConfig(minX, maxX),
  };
};
