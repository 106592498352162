import React from 'react';
import logo from './icons/logo.svg';
import useStyles from './login-header.styles';

export const LoginHeader = () => {
  const styles = useStyles();
  return (
    <header className={styles.header}>
      <img src={logo} alt="logo" />
    </header>
  );
};
