import React, { FC } from 'react';
import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import AddEditModal from './add-edit-modal';
import { User, UserErrors } from 'src/store/api/users.api';

interface ComponentProps
  extends ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {
  isOpen: boolean;
  toggleDialog: (isOpen: boolean) => void;
  user?: User;
  loading: boolean;
  errors: UserErrors;
}

const AddEditModalContainer: FC<ComponentProps> = (props) => {
  const {
    isOpen,
    toggleDialog,
    user,
    addUser,
    editUser,
    loading,
    errors,
    clearErrors,
  } = props;
  return (
    <AddEditModal
      isOpen={isOpen}
      toggleDialog={toggleDialog}
      user={user}
      onSave={user ? editUser : addUser}
      loading={loading}
      errors={errors}
      clearErrors={clearErrors}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.loading.effects.users.addUser || state.loading.effects.users.editUser,
  errors: state.users.errors,
});

const mapDispatchToProps = (dispatch: RootDispatch) => ({
  addUser: dispatch.users.addUser,
  editUser: dispatch.users.editUser,
  clearErrors: () => dispatch.users.setErrors({}),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditModalContainer);
