import React, { FC } from 'react';
import { Box, TableCell, TableHead, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import CheckboxIcon from 'src/shared/icons/checkbox-icon';
import WarningBoxIcon from 'src/shared/icons/warning-box-icon';
import CloseBoxIcon from 'src/shared/icons/close-box-icon';
import { useStyles } from './styles';

const StatusTableBody: FC = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={clsx(classes.cell, classes.headerCell)} />
        <TableCell className={clsx(classes.cell, classes.headerCell)}>
          <Box display="flex" alignItems="center">
            Normal
            <CheckboxIcon className={clsx(classes.icon, classes.normal)} />
          </Box>
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.headerCell)}>
          <Box display="flex" alignItems="center">
            Unstable
            <WarningBoxIcon className={clsx(classes.icon, classes.unstable)} />
          </Box>
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.headerCell)}>
          <Box display="flex" alignItems="center">
            Critical
            <CloseBoxIcon className={clsx(classes.icon, classes.critical)} />
          </Box>
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.headerCell)}>
          <Box display="flex" alignItems="center">
            Failed
            <CloseBoxIcon className={clsx(classes.icon, classes.failed)} />
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
export default StatusTableBody;
