import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  icon: {
    marginTop: '0.25rem',
    width: '1.75rem',
    height: '1.75rem',
  },
});
