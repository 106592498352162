import React, { FC } from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { useStyles } from './styles';

type StatusTableRows = {
  label: string;
  normal: JSX.Element;
  unstable: JSX.Element;
  critical: JSX.Element;
  failed: JSX.Element;
};

export interface Props {
  rows: StatusTableRows[];
}

const StatusTableBody: FC<Props> = (props) => {
  const classes = useStyles();
  const { rows } = props;
  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.label}>
          <TableCell component="th" scope="row" className={classes.cell}>
            {row.label}
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            {row.normal}
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            {row.unstable}
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            {row.critical}
          </TableCell>
          <TableCell align="center" className={classes.cell}>
            {row.failed}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
export default StatusTableBody;
