export const formatTimezone = (gmtOffset = 0): string => {
  const strGmtOffset = gmtOffset.toString();
  const sign = gmtOffset < 0 ? '-' : '+';

  const integerPart = Math.abs(+strGmtOffset.split('.')[0] || gmtOffset);
  const decimalPart = Math.abs(Math.abs(gmtOffset) - integerPart);
  const formattedInteger = integerPart < 10 ? '0' + integerPart : integerPart;

  const minutes = decimalPart * 60;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  return `UTC(${sign}${formattedInteger}:${formattedMinutes})`;
};
