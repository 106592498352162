import { Feedback, FeedbackStatus, Statuses } from '../api/feedbacks.api';

const getDefaultFeedback = (): Feedback => ({
  must_be_swapped: false,
  pump_state: Statuses.normal,
  compressor_state: Statuses.normal,
  controller_state: Statuses.normal,
  sa1_temp: false,
  sa2_temp: false,
  pump_rpm: false,
  pump_pressure: false,
  pump_heat: false,
  pump_vacuum: false,
  pump_array_contamination: false,
  compressor_fail: false,
  communication_fail: false,
  electronic_fail: false,
  he_contamination: false,
  reference_date: new Date(),
  pm_date: null,
  comment: null,
  tool_cust_fail: false,
  feedback_status: FeedbackStatus.none,
});

export const getNormalizedData = (data: Feedback): Feedback => {
  if (!data) {
    return getDefaultFeedback();
  }

  return {
    ...data,
    pm_date: data.pm_date ? new Date(data.pm_date) : null,
    reference_date: data.reference_date ? new Date(data.reference_date) : new Date(),
  };
};
