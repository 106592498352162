import React, { FC } from 'react';
import BaseChart, {
  ChartGridOption,
  ChartSeriesOption,
  ChartTooltipOption,
  ChartVisualMapOption,
  ChartXAxisOption,
  ChartYAxisOption,
  HeatMapDataObject,
} from '../base-chart';

interface Props {
  data: Record<string, HeatMapDataObject[]>;
  xAxis: ChartXAxisOption;
  yAxis: ChartYAxisOption;
  tooltip?: ChartTooltipOption;
  visualMap?: ChartVisualMapOption[];
  grid?: ChartGridOption;
}

const HeatMapChart: FC<Props> = (props) => {
  const { data, ...options } = props;
  const series: ChartSeriesOption = Object.entries(data).map((el) => ({
    name: el[0],
    data: el[1],
    type: 'heatmap',
  }));

  return <BaseChart options={options} series={series} />;
};

export default HeatMapChart;
