/* eslint-disable max-lines-per-function */
import { ManualIdentifiers } from 'src/shared/manual-notification';
import { getScopedQuery } from 'src/shared/routing/routing.helper';
import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  AttachmentIdentifiers,
  AutoNotification,
  DeviceSubtype,
  FetchNotificationListParams,
  Notification,
  NotificationIdentifiers,
  NotificationPayload,
  Range,
  notificationsApi,
} from '../api/notifications.api';
import { camelizeKeys } from 'humps';
import { showError, showSuccess } from 'src/services/snackbars';
import { stringifyError } from 'src/services/manual-notification.helper';
import {
  getRangesOverriddenPayload,
  getRangesOverriddenValues,
  isEmptyOverriddenPayload,
} from 'src/services/ranges-service';
import { sortSwitchHistoriesByLoggedAt } from 'src/services/notifications-service';

type SuccessCallback = {
  successCallback: () => void;
};

type AcknowledgeCallback = {
  successCallback: (notification: AutoNotification) => void;
};

type AcknowledgeParams = NotificationIdentifiers & AcknowledgeCallback;
type NotificationParams = NotificationPayload & SuccessCallback;

export type CurrentNotification = Notification | AutoNotification | null;

export type NotificationsState = {
  error: unknown;
  ranges: Range[];
  rangesSubtype: DeviceSubtype | null;
  currentNotification: CurrentNotification;
  emails: string[];
  siteId: string | null;
  notificationsListParams: FetchNotificationListParams | null;
};

export const notifications = createModel<RootModel>()({
  state: {
    error: null,
    emails: [],
    ranges: [],
    rangesSubtype: null,
    currentNotification: null,
    siteId: null,
    notificationsListParams: null,
  } as NotificationsState,

  selectors: (slice) => ({
    selectRanges() {
      return slice((state) => state.ranges);
    },
    currentNotification() {
      return slice((state) => state.currentNotification);
    },
    emails() {
      return slice((state) => state.emails);
    },
  }),

  reducers: {
    setRanges: (state: NotificationsState, ranges: Range[]) => ({
      ...state,
      ranges,
    }),

    setRangesSubtype: (
      state: NotificationsState,
      rangesSubtype: DeviceSubtype | null
    ) => ({
      ...state,
      rangesSubtype,
    }),

    setCurrentNotification: (
      state: NotificationsState,
      currentNotification: CurrentNotification
    ) => ({
      ...state,
      currentNotification,
    }),

    setEmails: (state: NotificationsState, emails: string[]) => ({
      ...state,
      emails,
    }),

    setSiteId: (state: NotificationsState, siteId: string) => ({
      ...state,
      siteId,
    }),

    setNotificationsListParams: (
      state: NotificationsState,
      notificationsListParams: FetchNotificationListParams | null
    ) => ({
      ...state,
      notificationsListParams,
    }),

    fail: (state: NotificationsState, failure: unknown) => ({
      ...state,
      error: failure,
    }),
  },

  effects: (dispatch) => ({
    async reFetchList(params: FetchNotificationListParams | null) {
      if (params) {
        dispatch.notifications.fetchNotificationList(params);
      }
    },
    async fetchNotificationList(params: FetchNotificationListParams) {
      try {
        dispatch.notifications.setNotificationsListParams(params);

        const { data, total } = await notificationsApi.fetchNotificationList(params);
        params.successCallback(camelizeKeys(data), total);
      } catch (err) {
        dispatch.notifications.fail(err);
        params.failCallback();
      }
    },
    async fetchRanges(deviceSubtype?: DeviceSubtype) {
      try {
        dispatch.notifications.setRanges([]);
        const { subtype, ranges } = await notificationsApi.fetchRanges(deviceSubtype);

        dispatch.notifications.setRangesSubtype(subtype);
        dispatch.notifications.setRanges(ranges);
      } catch (err) {
        console.error(err);
        showError('Cant load ranges, please try to refresh the page.');
      }
    },
    async overrideRanges(newRanges: Range[], rootState) {
      try {
        const { rangesSubtype, ranges } = rootState.notifications;

        if (!rangesSubtype) {
          throw new Error('Subtype is null.');
        }

        const rangesOverriddenPayload = getRangesOverriddenPayload(
          getRangesOverriddenValues(ranges),
          getRangesOverriddenValues(newRanges)
        );

        if (isEmptyOverriddenPayload(rangesOverriddenPayload)) {
          return;
        }

        const {
          subtype,
          ranges: overriddenRanges,
        } = await notificationsApi.overrideRanges(rangesSubtype, rangesOverriddenPayload);

        dispatch.notifications.setRangesSubtype(subtype);
        dispatch.notifications.setRanges(overriddenRanges);
        showSuccess('Saved');
      } catch (err) {
        console.error(err);
        showError('Cant override ranges.');
      }
    },
    async getEmails(identifiers: ManualIdentifiers | undefined) {
      const params = {
        siteId: identifiers?.siteId?.toString(),
        toolId: identifiers?.toolId?.toString(),
      };
      const { data } = await notificationsApi.getEmails(
        identifiers ? params : getScopedQuery()
      );
      dispatch.notifications.setEmails(data);
    },
    async sendNotification(notificationParams: NotificationParams, rootState) {
      try {
        const { successCallback, ...notification } = notificationParams;
        await notificationsApi.createNotification(notification);

        successCallback();
        showSuccess('Saved');

        //refetch after snack to fully block I/O flow
        //this way loading plugin works without window where we can request another post query
        const params = rootState.notifications.notificationsListParams;
        await this.reFetchList(params);
      } catch ({ response: { data } }) {
        showError(stringifyError(data), {
          style: { whiteSpace: 'pre-line' },
        });
      }
    },
    async downloadAttachment(attachmentIdentifiers: AttachmentIdentifiers) {
      notificationsApi.downloadAttachment(attachmentIdentifiers);
    },
    async getAutoNotification(identifiers: NotificationIdentifiers) {
      const autoNotificationPayload = await notificationsApi.getAutoNotification(
        identifiers
      );
      const autoNotification = camelizeKeys(autoNotificationPayload) as AutoNotification;

      dispatch.notifications.setCurrentNotification(
        sortSwitchHistoriesByLoggedAt(autoNotification)
      );
    },
    async acknowledge(params: AcknowledgeParams) {
      try {
        const autoNotificationData = await notificationsApi.acknowledge({
          siteId: params.siteId,
          notificationId: params.notificationId,
        });

        const autoNotification = camelizeKeys(autoNotificationData) as AutoNotification;

        dispatch.notifications.setCurrentNotification(autoNotification);
        params.successCallback(autoNotification);
      } catch (error) {
        showError('Something went wrong.');
      }
    },
  }),
});
