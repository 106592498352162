import BaseApi from '.';
import { getScopedQuery } from 'src/shared/routing/routing.helper';

type NumOrNull = number | null;
type ControllerParams = ReturnType<typeof getScopedQuery>;

export type ControllerRow = {
  avgDifferentialPressure: NumOrNull;
  avgReturnPressure: NumOrNull;
  avgSupplyPressure: NumOrNull;
  differentialPressure: NumOrNull;
  fullRoughCoord: 'False' | 'True';
  heliumFlowCorrection: NumOrNull;
  heliumMap: string;
  heliumState: NumOrNull;
  heliumTotalAllocation: NumOrNull;
  heliumTotalAvailable: NumOrNull;
  heliumTotalConsumption: NumOrNull;
  id: number;
  ip: string;
  online: boolean;
  powerFailCoord: 'False' | 'True';
  roughMap: string;
  serialNumber: string;
  siteId: number;
  softwareVersion: string;
  stabilizationSeconds: NumOrNull;
  toolId: number;
  toolName: string;
  updatedAt: string;
};

const controllers = new BaseApi('./controllers');

export const controllersApi = {
  // TODO: type of data
  fetchRows: (params: ControllerParams) => controllers.get<ControllerRow[]>({ params }),
};
