import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { COMPRESSOR } from 'src/shared/routing/with-selected-equipment';
import { RootState, select } from 'src/store';
import ToolLikeSummary from '../toolLikeSummary';

interface Props {
  siteId: number;
  toolId: number;
  compressorId: number;
}

export const CompressorSummary: FC<Props> = ({ siteId, toolId, compressorId }) => {
  const compressor = useSelector((state: RootState) =>
    select.siteEquipment.selectCompressor(state, { siteId, toolId, compressorId })
  );

  return (
    <ToolLikeSummary
      siteId={siteId}
      toolId={toolId}
      equipmentName={compressor?.compressorName}
      equipmentType={COMPRESSOR}
    />
  );
};
