import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { COLORS } from 'src/styles/colors';

// https://material-ui.com/components/about-the-lab/#typescript
// In order to benefit from the CSS overrides and default prop customization with the theme,
// TypeScript users need to import the following types.
// Internally, it uses module augmentation to extend the default theme structure
//  with the extension components available in the lab.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type from '@material-ui/lab/themeAugmentation';

const muiTheme = createMuiTheme({
  props: {
    MuiButton: {
      disableElevation: true, // disable shadows on hover
    },
  },
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          backgroundColor: COLORS.grey[200],
        },
        '#root': {
          height: '100%',
        },
      },
    },

    MuiInputLabel: {
      root: {
        color: COLORS.grey['600'],
      },

      //margin for input label when shrinking (standard input)
      shrink: {
        whiteSpace: 'nowrap',
        transform: 'translate(8px, 1.5px) scale(0.75)',
      },

      //margin for input label (filled input)
      filled: {
        transform: 'translate(8px, 20px) scale(1)',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(8px, 10px) scale(0.75)',
        },
      },

      //margin for input label (standard input)
      formControl: {
        transform: 'translate(8px, 24px) scale(1)',
      },
    },

    MuiInputBase: {
      input: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },

    MuiFilledInput: {
      root: {
        backgroundColor: COLORS.grey['200'],
        '&$focused': {
          backgroundColor: COLORS.grey['300'],
        },
      },

      underline: {
        '&:before': {
          borderBottom: `1px solid ${COLORS.grey['400']}`,
        },
        '&:after': {
          borderBottom: `2px solid ${COLORS.black}`,
        },
      },

      input: {
        padding: '28px 8px 8px',
        height: '20px',
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
        },
        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
        },
      },

      multiline: {
        padding: '28px 8px 8px',
      },

      adornedStart: {
        paddingLeft: '8px',
      },
    },

    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiFilledInput-root"]': {
          paddingLeft: '5px',
        },
      },
    },

    MuiSelect: {
      icon: {
        color: COLORS.black,
      },
    },

    MuiFormLabel: {
      root: {
        '&$focused': {
          color: COLORS.grey['600'],
        },
      },
    },

    MuiDialog: {
      paperWidthXs: {
        maxWidth: 400,
      },
      paperWidthMd: {
        maxWidth: 800,
      },
    },

    MuiDialogContent: {
      dividers: {
        padding: 24,
      },
    },

    MuiChip: {
      root: {
        borderRadius: '2px',
      },
    },

    MuiTabs: {
      root: {
        borderBottom: `1px solid ${COLORS.grey['300']}`,
      },
      indicator: {
        height: '0.25rem',
      },
    },

    MuiTab: {
      root: {
        minWidth: '0 !important', // need important in order to avoid overrides for all media queries
        padding: '0 16px',
        whiteSpace: 'nowrap',
      },
    },

    MuiIconButton: {
      root: {
        color: COLORS.black,
      },
    },

    MuiButton: {
      containedSizeSmall: {
        padding: '4px 8px',
      },
    },

    MuiFormControlLabel: {
      label: {
        fontSize: '0.875rem',
      },
    },
  },
});

export default muiTheme;
