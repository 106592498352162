import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DateRangeFilter from 'src/shared/date-range-filter';
import {
  getDefaultDateRange,
  getFilters,
} from 'src/app/pump/pump-anomalies/filters-config';
import {
  mapDispatchToProps,
  mapStateToProps,
} from 'src/app/pump/pump-anomalies/pump-anomalies.container';
import PumpAnomaliesChart from 'src/app/pump/pump-anomalies/pump-anomalies-chart';
import EmptyState from 'src/shared/empty-state';
import { isPredictable } from 'src/services/pump';
import { Box } from '@material-ui/core';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const quickFilters = getFilters();
const { start: defaultStart, end: defaultEnd } = getDefaultDateRange();

const PumpAnomalies: FC<Props> = (props) => {
  const { fetchData, anomalies, selectedZone, isLoading, pump, fetchPumpDetails } = props;
  const { search } = useLocation();

  const [dates, setDates] = useState<[Date, Date]>([defaultStart, defaultEnd]);

  useEffect(() => {
    fetchData({
      search,
      start: defaultStart,
      end: defaultEnd,
      timezone: selectedZone,
    });
  }, [fetchData, search, selectedZone]);

  useEffect(() => {
    fetchPumpDetails(search);
  }, [fetchPumpDetails, search]);

  const handleChange = (start: Date, end: Date) => {
    fetchData({
      search,
      start,
      end,
      timezone: selectedZone,
    });
    setDates([start, end]);
  };

  if (!isPredictable(pump?.model)) return <EmptyState />;

  return (
    <Box padding="24px 16px">
      <DateRangeFilter
        startDate={dates[0]}
        endDate={dates[1]}
        quickFilters={quickFilters}
        onChange={handleChange}
        isLoading={isLoading}
      />

      <PumpAnomaliesChart data={anomalies} dateRange={dates} />
    </Box>
  );
};

export default PumpAnomalies;
