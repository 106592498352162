import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      active: {
        width: '0.75rem',
        height: '0.75rem',
        borderRadius: '50%',
        outline: 'none',
        border: `1px solid ${COLORS.red['500']}`,
        backgroundColor: COLORS.red['500'],
      },
    })
);
