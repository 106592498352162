import React, { FC } from 'react';
import { BasicLayout } from 'src/shared/basic-layout';
import { TimezonePickerContainer } from './timezone-picker';
import { PersonaContainer } from 'src/app/persona';
import AppNavigation from 'src/app/app-navigation';
import RoutesList from 'src/shared/routing/routes-list';
import { ROUTES } from 'src/shared/routing/routes';

const App: FC = () => (
  <BasicLayout
    header={<AppNavigation />}
    persona={<PersonaContainer />}
    timezone={<TimezonePickerContainer />}>
    <RoutesList routes={ROUTES.app} />
  </BasicLayout>
);

export default App;
