import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { PumpSettingsRow, pumpSettingsApi } from './../api/pump-settings.api';
import { getScopedQuery } from 'src/shared/routing/routing.helper';

export type PumpSettingsState = {
  rows: PumpSettingsRow[] | null;
};

export const pumpSettings = createModel<RootModel>()({
  state: {
    rows: null,
  } as PumpSettingsState,

  reducers: {
    setRows: (
      state: PumpSettingsState,
      rows: PumpSettingsRow[] | null
    ): PumpSettingsState => ({
      ...state,
      rows,
    }),
  },

  effects: (dispatch) => ({
    async fetchRows() {
      const { data } = await pumpSettingsApi.fetchRows(getScopedQuery());
      dispatch.pumpSettings.setRows(data);
    },
  }),
});
