import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const styles = createStyles({
  dataType: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '36px',
  },
  subTitle: {
    color: COLORS.black,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '8px',
  },
  body1: {
    color: COLORS.grey['500'],
    fontSize: '18px',
  },
});

export const useStyles = makeStyles((): StyleRules => styles);
