import React, { FC } from 'react';
import PumpDetailsTableView from './pump-details-table';
import { formatRowsToNA } from './table-ui-formatters';
import { PumpDetails } from 'src/store/api/pumps.api';

interface Props {
  pump: PumpDetails;
}

export const RegenInfoTable: FC<Props> = (props) => {
  const { pump } = props;

  const rows = [
    {
      label: 'Delay Start',
      value: pump.pDelayStart,
    },
    {
      label: 'Delay Restart',
      value: pump.pDelayRestart,
    },
    {
      label: 'ROR Limit',
      value: pump.rorLimit,
    },
    {
      label: 'ROR Cycles',
      value: pump.rorCycles,
    },
    {
      label: 'Rough Valve Interlock',
      value: String(pump.pRoughValveInterlock),
    },
    {
      label: 'Fast Rough Test',
      value: pump.fastRoughTest,
    },
    {
      label: 'Extended Purge',
      value: pump.pExtendedPurge,
    },
    {
      label: 'Repurge Time',
      value: pump.pRepurgeTime,
    },
    {
      label: 'Repurge Cycles',
      value: pump.pRepurgeCycles,
    },
    {
      label: 'Base Pressure',
      value: pump.basePressure,
    },
  ];

  const formattedRows = formatRowsToNA(rows);
  return <PumpDetailsTableView rows={formattedRows} />;
};
