import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const EmptyState: FC = (props) => {
  const styles = useStyles();
  return (
    <Box pt={8} pb={2} textAlign="center">
      <Typography className={styles.text}>{props.children || 'No Results'}</Typography>
    </Box>
  );
};

export default EmptyState;
