import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState, select } from 'src/store';
import { useEquipmentIdentifiers } from '../routing/useEquipmentIdentifiers';
import { PUMP, SITE, TOOL } from '../routing/with-selected-equipment';
import ExportButton, { PassedExportProps } from './export-btn';

const ExportBtnContainer: FC<PassedExportProps> = (props) => {
  const { type, siteId, toolId, pumpId } = useEquipmentIdentifiers();

  const chartData = useSelector(({ charts }: RootState) => {
    return charts.chartData;
  });

  const site = useSelector((state: RootState) => {
    if (!siteId) return;
    return select.siteEquipment.selectSite(state, { siteId });
  });

  const tool = useSelector((state: RootState) => {
    if (!siteId || !toolId) return;
    return select.siteEquipment.selectTool(state, { siteId, toolId });
  });

  let gridTitle = '';
  let chartTitle = '';
  let pump;

  switch (type) {
    case SITE:
      gridTitle = site?.name ?? '';
      break;
    case TOOL:
      gridTitle = `${tool?.name ?? ''}_${tool?.oemName ?? ''}`;
      break;
    case PUMP:
      pump = tool?.pumps.find((pump) => pump.pumpId === pumpId);
      gridTitle = `${pump?.stationName ?? ''}_${tool?.name ?? ''}`;
      break;
    default:
      gridTitle = '';
  }

  if (chartData) {
    chartTitle = `${chartData.name}/chart`;
  }

  return <ExportButton {...props} chartTitle={chartTitle} gridTitle={gridTitle} />;
};

export default ExportBtnContainer;
