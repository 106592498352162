import { connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { TimezonePicker } from './TimezonePicker';

const mapState = (state: RootState) => ({
  selectedZone: state.timezone.selectedZone,
});

const mapDispatch = (store: RootDispatch) => ({
  onChange: store.timezone.updateTimezone,
});

export type ConnectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

export const TimezonePickerContainer = connect(mapState, mapDispatch)(TimezonePicker);
