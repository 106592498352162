import React from 'react';
import { Box, Grid, WithStyles, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Spinner } from 'src/shared/spinner';
import { When } from 'src/shared/when';

import { RootDispatch, RootState } from 'src/store';

import { ModelPerformance } from './model-performance';
import { styles } from './model-performance.styles';
import cover from './cover.svg';

import ModelSelectorContainer from './model-selector';
import { FetchScoreQuery, MlScoreDetails } from 'src/store/models/ml.model';

interface Props extends WithStyles<typeof styles> {
  details: MlScoreDetails;
  failed: boolean;
  pending: boolean;
  onInit?: (query: FetchScoreQuery) => void;
  onInitModels?: () => void;
}

class ModelPerformanceWidget extends React.Component<Props> {
  render() {
    const detailsExists = Boolean(this.props.details);

    return (
      <>
        {/* todo: extract styles into own file */}
        <Grid item style={{ borderRight: '1px solid #E0E0E0', width: '232px' }}>
          <ModelSelectorContainer />
        </Grid>

        <Grid item xs style={{ overflow: 'auto', minHeight: 0 }}>
          <When condition={detailsExists}>{() => this.renderDataSection()}</When>

          <When condition={!detailsExists}>{() => this.renderMetaSection()}</When>
        </Grid>
      </>
    );
  }

  renderMetaSection() {
    const { failed, pending } = this.props;

    return (
      <React.Fragment>
        <When condition={failed}>
          <Box pt={7} />
          <Box p={1} textAlign="center">
            <span className={this.props.classes.body1}>Error</span>
          </Box>
        </When>

        <When condition={pending}>
          <Box textAlign="center">
            <Box pt={7} />
            <Box p={1}>
              <Spinner />
            </Box>
            <Box p={2}>
              <span className={this.props.classes.body1}>Loading...</span>
            </Box>
          </Box>
        </When>

        <When condition={!pending && !failed}>
          <Box display="flex">
            <Box mx={'auto'} pt={12} textAlign="center">
              <img src={cover} alt="placeholder" />
              <Box p={3} maxWidth="340px" fontSize="18px" color="#9E9E9E">
                <span>
                  Select the model to evaluate model performance and press apply
                </span>
              </Box>
            </Box>
          </Box>
        </When>
      </React.Fragment>
    );
  }

  renderDataSection() {
    const { details } = this.props;

    return <ModelPerformance details={details} />;
  }
}

const mapState = (state: RootState) => {
  const { ml } = state;

  return {
    details: ml.score.details,
    failed: Boolean(ml.score.error),
    pending: state.loading.effects.ml.fetchScore,
  };
};

const mapDispatch = (dispatch: RootDispatch) => {
  const { ml } = dispatch;

  return {
    onInit: ml.fetchScore,
    onInitModels: ml.fetchModels,
  };
};

export const ModelPerformanceWidgetContainer = compose(
  withStyles(styles),
  connect(mapState, mapDispatch)
)(ModelPerformanceWidget) as React.ComponentType;
