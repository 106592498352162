import React, { useMemo } from 'react';
import { DataProp as ScoresProp } from '../bar-chart/config';
import { useStyles } from './total-info.styles';
import DateInSelectedZone from 'src/shared/date-in-selected-zone';

type ComponentProps = {
  scores: ScoresProp;
  date: {
    [key: string]: string;
  };
};

const calculateTotalPumps = (scores: ScoresProp): number => {
  let totalPumps = 0;
  const normalSwapped =
    scores.thresholds?.[Object.keys(scores.thresholds)[0]].alarm_class.normal_vs_swapped;

  if (normalSwapped) {
    Object.entries(normalSwapped).forEach((column) => {
      Object.entries(column[1]).forEach((bar) => {
        totalPumps += bar[1].unique_pumps;
      });
    });
  }
  return totalPumps;
};

const TotalInfo: React.FC<ComponentProps> = ({ scores, date }) => {
  const totalPumps = useMemo(() => calculateTotalPumps(scores), [scores]);
  const styles = useStyles();

  return (
    <div className={styles.totalInfoRow}>
      <span className={styles.totalInfoLabel}>Total Pumps: </span>
      <span className={styles.totalValue}>{totalPumps || ''}</span>
      <span className={styles.totalInfoLabel}>Start Date: </span>
      <span className={styles.dateValue}>
        {date.start_date && (
          <DateInSelectedZone formatType="short">{date.start_date}</DateInSelectedZone>
        )}
      </span>
      <span className={styles.totalInfoLabel}>End Date: </span>
      <span className={styles.dateValue}>
        {date.end_date && (
          <DateInSelectedZone formatType="short">{date.end_date}</DateInSelectedZone>
        )}
      </span>
    </div>
  );
};

export default TotalInfo;
