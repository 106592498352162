import React, { FC } from 'react';
import { connect } from 'react-redux';
import {
  WithSelectedEquipmentProps,
  withSelectedEquipmentIdentifiers,
} from '../../shared/routing/with-selected-equipment';
import Breadcrumbs from './breadcrumbs';
import SiteSummary from './site';
import { ToolSummary } from './tool';
import PumpSummary from './pump';
import { ControllerSummary } from './controller/controller-summary';
import { CompressorSummary } from './compressor/compressor-summary';

const EquipmentInfo: FC<WithSelectedEquipmentProps> = (props) => {
  const { type, siteId, toolId, pumpId, compressorId } = props;

  switch (type) {
    case 'site':
      return <SiteSummary siteId={siteId} />;
    case 'tool':
      return <ToolSummary siteId={siteId} toolId={toolId} />;
    case 'controller':
      return <ControllerSummary siteId={siteId} toolId={toolId} />;
    case 'compressor':
      return (
        <CompressorSummary siteId={siteId} toolId={toolId} compressorId={compressorId} />
      );
    case 'pump':
      return <PumpSummary siteId={siteId} toolId={toolId} pumpId={pumpId} />;
    default:
      return null;
  }
};

const SiteEquipmentSummary: FC<WithSelectedEquipmentProps> = (props) => {
  const { type, siteId, toolId, pumpId, controllerId, compressorId } = props;

  return (
    <>
      <Breadcrumbs type={type} siteId={siteId} toolId={toolId} />
      <EquipmentInfo
        type={type}
        siteId={siteId}
        toolId={toolId}
        pumpId={pumpId}
        controllerId={controllerId}
        compressorId={compressorId}
      />
    </>
  );
};

// disconnect from router to skip re-renders on every route change
export default connect(null, null, () => ({}))(
  withSelectedEquipmentIdentifiers(SiteEquipmentSummary)
);
