import React, { FC } from 'react';
import { CONTROLLER } from 'src/shared/routing/with-selected-equipment';
import ToolLikeSummary from '../toolLikeSummary';

interface Props {
  siteId: number;
  toolId: number;
}

export const ControllerSummary: FC<Props> = ({ siteId, toolId }) => {
  return (
    <ToolLikeSummary
      siteId={siteId}
      toolId={toolId}
      equipmentName={'Controller'}
      equipmentType={CONTROLLER}
    />
  );
};
