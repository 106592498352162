import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const WarningBoxIcon: FC<Props> = (props) => {
  const { className, color } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 16 16">
      <path d="M0 0h16v16H0V0z" fill={color} />
      <path d="M9 12.667H7V14h2v-1.333zM9 2H7v9.333h2V2z" fill="#fff" />
    </SvgIcon>
  );
};

export default WarningBoxIcon;
