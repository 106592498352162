import React, { useCallback, useMemo, useState } from 'react';
import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';
import { SITE, TOOL } from 'src/shared/routing/with-selected-equipment';
import { Devices } from 'src/store/api/notifications.api';
import { DefaultLevel } from './default-level';
import { NotificationRanges } from './notification-ranges';
import { NotificationTabs } from './notification-tabs';
import { isDefaultLevel } from './ranges.helper';

export type TabSubtype = keyof Devices;

const TABS: TabSubtype[] = ['Cryopumps', 'Waterpumps', 'Compressors', 'Controllers'];

export const NotificationRangesContainer = () => {
  const { type } = useEquipmentIdentifiers();
  const [tabValue, setTabValue] = useState<TabSubtype>(TABS[0]);

  const onChange = useCallback(
    (event: React.ChangeEvent<unknown>, newValue: TabSubtype) => {
      setTabValue(newValue);
    },
    []
  );

  const isToolOrSite = useMemo(() => type === SITE || type === TOOL, [type]);

  const tabSubtype = useMemo(() => (isToolOrSite ? tabValue : undefined), [
    isToolOrSite,
    tabValue,
  ]);

  const renderRanges = () => {
    return (
      <>
        {isToolOrSite && (
          <NotificationTabs value={tabValue} tabs={TABS} onChange={onChange} />
        )}
        <NotificationRanges tabSubtype={tabSubtype} />
      </>
    );
  };

  return isDefaultLevel() ? <DefaultLevel /> : renderRanges();
};
