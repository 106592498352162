import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import BarChart, {
  engineerFeedbackPaletteMap,
  normalSwappedPaletteMap,
} from './bar-chart';
import ConfusionMatrixContainer from './confusion-matrix/confusion-matrix.container';
import TotalInfo from './total-info';
import MetricsScore from './metrics-score';
import { useStyles } from './model-performance.styles';
import { MlScoreDetails } from 'src/store/models/ml.model';

interface Props {
  details: MlScoreDetails;
}

export const ModelPerformance: FC<Props> = (props) => {
  const { details } = props;
  const styles = useStyles();

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <span className={styles.dataType}>Historical Data</span>
          <TotalInfo scores={details.scores.hist} date={details.dataset.hist} />
        </Grid>
        <Grid item xs={6}>
          <span className={styles.dataType}>Live Data</span>
          <TotalInfo scores={details.scores.live} date={details.dataset.live} />
        </Grid>

        <Grid item xs={12} className={styles.subTitle}>
          Normal vs Swapped
        </Grid>

        <Grid item xs={6}>
          <BarChart
            data={details.scores.hist}
            type="normal_vs_swapped"
            paletteMap={normalSwappedPaletteMap}
          />
        </Grid>
        <Grid item xs={6}>
          <BarChart
            data={details.scores.live}
            type="normal_vs_swapped"
            paletteMap={normalSwappedPaletteMap}
          />
        </Grid>

        <Grid item xs={12} className={styles.subTitle}>
          Swap Reason
        </Grid>

        <Grid item xs={6}>
          <BarChart data={details.scores.hist} type="swap_reason" />
        </Grid>
        <Grid item xs={6}>
          <BarChart data={details.scores.live} type="swap_reason" />
        </Grid>

        <Grid item xs={12} className={styles.subTitle}>
          Engineer Feedback
        </Grid>

        <Grid item xs={6}>
          <BarChart
            data={details.scores.hist}
            type="engineer_feedback"
            paletteMap={engineerFeedbackPaletteMap}
          />
        </Grid>
        <Grid item xs={6}>
          <BarChart
            data={details.scores.live}
            type="engineer_feedback"
            paletteMap={engineerFeedbackPaletteMap}
          />
        </Grid>

        <Grid item xs={12} className={styles.subTitle}>
          Confusion Matrix
        </Grid>

        <Grid item xs={6}>
          <ConfusionMatrixContainer type="hist" />
        </Grid>
        <Grid item xs={6}>
          <ConfusionMatrixContainer type="live" />
        </Grid>

        <Grid item xs={12} className={styles.subTitle}>
          Metrics Score
        </Grid>

        <Grid item xs={6}>
          <MetricsScore scores={details.scores.hist} />
        </Grid>
        <Grid item xs={6}>
          <MetricsScore scores={details.scores.live} />
        </Grid>
      </Grid>
    </Box>
  );
};
