import { ColData, StyledColDef } from 'src/shared/base-grid/base-grid';
import columns, { ColumnName, isCritical } from './column.config';

export const createColumn = (col: ColData, config?: StyledColDef): StyledColDef => {
  const colConfig = config ?? {};
  const { headerComponentParams, ...agGridColConfig } = colConfig;

  const column: StyledColDef = {
    colId: col.name,
    field: col.name,
    headerName: col.label,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    tooltipField: col.type === 'string' ? col.name : undefined,
    hide: !col.visibility,
    sortable: true,
    headerComponentParams: {
      ...headerComponentParams,
      dataType: col.units,
    },
    sort: col.sortDirection,
    resizable: true,
    width: col.width,
    minWidth: col.minWidth || 60,
    maxWidth: col.maxWidth,
    flex: col.flex,
    cellStyle: (params) => isCritical(params.value, col.name as ColumnName),
    lockPinned: true,
    valueGetter: col.valueGetter,
    valueFormatter: col.valueFormatter,
  };

  // erase `undefined` properties since base-grid applying default column properties as well
  let key: keyof typeof column;
  for (key in column) {
    if (column[key] === undefined) delete column[key];
  }

  const customColDef = columns[col.name as ColumnName];
  const customProps = customColDef ? customColDef : columns.default;

  return {
    ...column,
    ...agGridColConfig,
    ...customProps,
  };
};
