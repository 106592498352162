import { SubjectLinkRenderer } from './cell-renderers/resource-link/subject-link-renderer';
import { ActiveStatusRenderer } from './cell-renderers/active/active-status-renderer';
import { FilesRenderer } from './cell-renderers/files/files-renderer';
import { NotificationInputRenderer } from './cell-renderers/notification-input/notification-input-renderer';
import { StyledColDef } from 'src/shared/base-grid/base-grid';
import { selectCellStyles } from './cell-styles';
import { OnlineStatusRenderer } from './cell-renderers/online-status/online-status-renderer';
import OnlineStatusHeader from './cell-headers/online-status';
import { FailurePredictionRenderer } from './cell-renderers/failure-prediction/failure-prediction-renderer';
import FeedbackActionRenderer from 'src/shared/base-grid/cell-renderers/actions/feedback-action-renderer';
import GoToActionRenderer from './cell-renderers/actions/go-to-action-renderer';
import AdminActionRenderer from 'src/shared/base-grid/cell-renderers/actions/admin-action-renderer';
import { DatetimeRenderer } from './cell-renderers/datetime/datetime-renderer';
import { EquipmentLinkRenderer } from './cell-renderers/resource-link/equipment-link-renderer';
import { ToolLinkRenderer } from './cell-renderers/resource-link/tool-link-renderer';
import { NOT_APPLICABLE, formatToFixed } from './value-formatters';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import AdminCheckboxCell from 'src/shared/base-grid/cell-renderers/admin-checkbox-cell';
import { SubscriptionRenderer } from './cell-renderers/subscription/subscription-renderer';
import { FilesHeader } from './cell-headers/files-header';
import { ManualTypeRenderer } from './cell-renderers/manual-type/manual-type-renderer';

interface MinMax {
  min: number;
  max: number;
}

export type ColumnName = keyof typeof columnNames;

type Columns = {
  [key in ColumnName]?: StyledColDef;
};

type AllowedValues = {
  [key in ColumnName]?: MinMax;
};

const columnNames = {
  online: 'online',
  updatedAt: 'updatedAt',
  completedAt: 'completedAt',
  createdAt: 'createdAt',
  created_at: 'created_at',
  start_date: 'start_date',
  end_date: 'end_date',
  lastDate: 'lastDate',
  changed_at: 'changed_at',
  duration: 'duration',
  toolName: 'toolName',
  tool_name: 'tool_name',
  stationName: 'stationName',
  station_name: 'station_name',
  failureProbability: 'failureProbability',
  t1: 't1',
  t2: 't2',
  tcPressure: 'tcPressure',
  rpm: 'rpm',
  heater1: 'heater1',
  heater2: 'heater2',
  regenStep: 'regenStep',
  t1Max24h: 't1Max24h',
  t2Max24h: 't2Max24h',
  rpmMax24h: 'rpmMax24h',
  avgCooldown: 'avgCooldown',
  hoursSinceLastFullRegeneration: 'hoursSinceLastFullRegeneration',
  hoursSinceLastFastRegeneration: 'hoursSinceLastFastRegeneration',
  ageInHours: 'ageInHours',
  description: 'description',
  partNumber: 'partNumber',
  model: 'model',
  softwareVersion: 'softwareVersion',
  heater1Max24h: 'heater1Max24h',
  heater2Max24h: 'heater2Max24h',
  t1ControllerSetpoint: 't1ControllerSetpoint',
  t2ControllerSetpoint: 't2ControllerSetpoint',
  differentialPressure: 'differentialPressure',
  differentialPressureMin24h: 'differentialPressureMin24h',
  serialNumber: 'serialNumber',
  heliumMap: 'heliumMap',

  currentSignInAt: 'currentSignInAt',
  deactivated: 'deactivated',
  email: 'email',
  currentSignInIp: 'currentSignInIp',
  role: 'role',

  feedbackAction: 'feedbackAction',
  goToActions: 'goToActions',
  adminActions: 'adminActions',
  subscription: 'subscription',
  notificationMinimum: 'notificationMinimum',
  notificationMaximum: 'notificationMaximum',
  attachments: 'attachments',
  active: 'active',
  subject: 'subject',
  manualType: 'manualType',
};

const allowedValues: AllowedValues = {
  t1: {
    min: 63,
    max: 102,
  },
  t2: {
    min: 7,
    max: 18,
  },
  tcPressure: {
    min: 0,
    max: 2000,
  },
  rpm: {
    min: 40,
    max: 100,
  },
  t1Max24h: {
    min: 63,
    max: 102,
  },
  t2Max24h: {
    min: 7,
    max: 18,
  },
  rpmMax24h: {
    min: 40,
    max: 100,
  },
  avgCooldown: {
    min: 20,
    max: 160,
  },
  hoursSinceLastFullRegeneration: {
    min: 0,
    max: 2184,
  },
  hoursSinceLastFastRegeneration: {
    min: 0,
    max: 2184,
  },
  ageInHours: {
    min: 0,
    max: 43680,
  },
  failureProbability: {
    min: 0,
    max: 50,
  },
  heater1: {
    min: 0,
    max: 100,
  },
  heater2: {
    min: 0,
    max: 100,
  },
  heater1Max24h: {
    min: 0,
    max: 100,
  },
  heater2Max24h: {
    min: 0,
    max: 100,
  },
  t1ControllerSetpoint: {
    min: 65,
    max: 100,
  },
  t2ControllerSetpoint: {
    min: 10,
    max: 13.5,
  },
  differentialPressure: {
    min: 0,
    max: 190,
  },
  differentialPressureMin24h: {
    min: 0,
    max: 190,
  },
};

const isAllowedValue = (
  value: number | undefined,
  allowed: MinMax | undefined
): boolean => {
  // value might be undefined while grid loading new rows
  // so treat initially value as allowed
  if (value === undefined) return true;
  if (allowed === undefined) return false;

  return value >= allowed.min && value <= allowed.max;
};

export const isCritical = (value: number, field: ColumnName): CSSProperties => {
  if (allowedValues[field]) {
    // Do not apply any thresholds for not applicable numbers
    if (value === NOT_APPLICABLE) {
      return {};
    }

    if (!isAllowedValue(value, allowedValues[field])) {
      return selectCellStyles(field).critical
        ? (selectCellStyles(field).critical as CSSProperties)
        : {};
    }

    return selectCellStyles(field).default;
  }

  return {};
};

const dateTimeCol = {
  minWidth: 146,
  maxWidth: 146,
  cellRendererFramework: DatetimeRenderer,
  resizable: false,
};

const columns: Columns & { default: StyledColDef } = {
  online: {
    cellStyle: selectCellStyles('online').default,
    cellRendererFramework: OnlineStatusRenderer,
    headerComponentFramework: OnlineStatusHeader,
    lockPosition: true,
    lockPinned: true,
    pinned: 'left',
    lockVisible: true,
    filter: false,
    resizable: false,
    maxWidth: 44,
    minWidth: 44,
  },

  updatedAt: dateTimeCol,
  completedAt: dateTimeCol,
  createdAt: dateTimeCol,
  currentSignInAt: dateTimeCol,
  created_at: dateTimeCol,
  start_date: dateTimeCol,
  end_date: dateTimeCol,
  lastDate: dateTimeCol,
  changed_at: dateTimeCol,

  duration: {
    width: 162,
    minWidth: 140,
    maxWidth: 208,
  },
  toolName: {
    minWidth: 70,
    maxWidth: 100,
    cellRendererFramework: ToolLinkRenderer,
  },
  tool_name: {
    minWidth: 70,
    maxWidth: 100,
    cellRendererFramework: ToolLinkRenderer,
  },
  stationName: {
    width: 100,
    cellRendererFramework: EquipmentLinkRenderer,
  },
  station_name: {
    width: 100,
    cellRendererFramework: EquipmentLinkRenderer,
  },
  failureProbability: {
    width: 74,
    cellRendererFramework: FailurePredictionRenderer,
  },
  heliumMap: {
    flex: 1,
  },
  t1: {
    valueFormatter: formatToFixed,
    width: 48,
  },
  t2: {
    valueFormatter: formatToFixed,
    width: 48,
  },
  tcPressure: {
    valueFormatter: formatToFixed,
    width: 48,
  },
  rpm: {
    valueFormatter: formatToFixed,
    width: 48,
  },
  heater1: {
    width: 40,
  },
  heater2: {
    width: 48,
  },
  regenStep: {
    width: 70,
  },
  t1Max24h: {
    valueFormatter: formatToFixed,
    width: 80,
  },
  t2Max24h: {
    valueFormatter: formatToFixed,
    width: 80,
  },
  hoursSinceLastFastRegeneration: {
    width: 88,
  },
  hoursSinceLastFullRegeneration: {
    width: 88,
  },
  ageInHours: {
    minWidth: 72,
    maxWidth: 75,
  },
  description: {
    minWidth: 162,
  },
  partNumber: {
    minWidth: 120,
  },
  model: {
    minWidth: 50,
    maxWidth: 175,
  },
  softwareVersion: {
    width: 90,
  },
  default: {
    width: 60,
    filter: true,
  },
  email: {
    maxWidth: 260,
  },
  role: {
    maxWidth: 58,
  },
  currentSignInIp: {
    maxWidth: 130,
  },
  deactivated: {
    minWidth: 64,
    maxWidth: 64,
    cellStyle: selectCellStyles().default,
    cellRendererFramework: AdminCheckboxCell,
  },
  serialNumber: {
    minWidth: 130,
    maxWidth: 200,
  },

  // Action renderers
  feedbackAction: {
    cellRendererFramework: FeedbackActionRenderer,
    width: 64,
    cellStyle: selectCellStyles('pinnedRight').default,
    pinned: 'right',
    sortable: false,
    lockPinned: true,
    lockPosition: true,
    resizable: false,
  },
  goToActions: {
    cellRendererFramework: GoToActionRenderer,
    width: 56,
    minWidth: 56,
    maxWidth: 56,
    cellStyle: selectCellStyles('pinnedRight').default,
    pinned: 'right',
    sortable: false,
    lockPinned: true,
    lockPosition: true,
    resizable: false,
  },
  adminActions: {
    cellRendererFramework: AdminActionRenderer,
    width: 72,
    minWidth: 72,
    maxWidth: 72,
    cellStyle: selectCellStyles('pinnedRight').default,
    pinned: 'right',
    sortable: false,
    lockPinned: true,
    lockPosition: true,
    resizable: false,
  },
  subscription: {
    cellRendererFramework: SubscriptionRenderer,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    cellStyle: selectCellStyles('centered').default,
    resizable: false,
  },
  notificationMinimum: {
    cellRendererFramework: NotificationInputRenderer,
    width: 132,
    minWidth: 132,
    maxWidth: 132,
    cellStyle: selectCellStyles('input').default,
    resizable: false,
  },
  notificationMaximum: {
    cellRendererFramework: NotificationInputRenderer,
    width: 132,
    minWidth: 132,
    maxWidth: 132,
    cellStyle: selectCellStyles('input').default,
    resizable: false,
  },
  attachments: {
    cellRendererFramework: FilesRenderer,
    headerComponentFramework: FilesHeader,
    resizable: false,
    maxWidth: 33,
    minWidth: 33,
  },
  active: {
    cellStyle: selectCellStyles('centered').default,
    cellRendererFramework: ActiveStatusRenderer,
    resizable: false,
    maxWidth: 33,
    minWidth: 33,
  },
  subject: {
    cellStyle: selectCellStyles('subject').default,
    cellRendererFramework: SubjectLinkRenderer,
  },
  manualType: {
    cellStyle: selectCellStyles('centered').default,
    cellRendererFramework: ManualTypeRenderer,
    resizable: false,
    maxWidth: 100,
    minWidth: 100,
  },
};

export default columns;
