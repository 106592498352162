import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';
import { SitePicker } from 'src/shared/site-picker';

const SiteSelector: FC = () => {
  const sites = useSelector(({ siteEquipment }: RootState) => {
    return siteEquipment.sites;
  });

  const selectedSites = useSelector(({ siteEquipment }: RootState) => {
    return siteEquipment.selectedSites;
  });

  const { siteEquipment } = useDispatch<RootDispatch>();

  return (
    <SitePicker
      sites={sites}
      selectedSites={selectedSites}
      onChange={siteEquipment.changeSelectedSites}
    />
  );
};

export default SiteSelector;
