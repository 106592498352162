import React, { FC } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { EdwardsLogoIcon } from 'src/shared/icons';
import { useStyles } from './basic-layout.styles';

export interface Props {
  header?: React.ReactNode;
  persona?: React.ReactNode;
  timezone?: React.ReactNode;
  footer?: React.ReactNode;
}

const HeaderLayout: FC<Props> = (props) => {
  const styles = useStyles();
  const logoSlotClasses = clsx(styles.headerSlotBase, styles.minw232);
  const { header, timezone, persona } = props;

  return (
    <div className={[styles.noAuto, styles.invertedTheme, styles.withShadow].join(' ')}>
      <Container maxWidth={false} disableGutters classes={{ root: styles.fitHeight }}>
        <Grid container className={styles.noWrap}>
          <Grid item xs={2} classes={{ root: logoSlotClasses }}>
            <Box px={2} className={styles.logoSlot}>
              <EdwardsLogoIcon />
            </Box>
          </Grid>
          <Grid item xs>
            <Box px={2}>
              <Grid container className={styles.headerContainerGrid}>
                <Grid
                  item
                  xs
                  classes={{
                    root: clsx(styles.headerSlotStretch, styles.headerSlotBase),
                  }}>
                  {header}
                </Grid>
                <Grid
                  item
                  classes={{
                    root: clsx(styles.headerSlotCenter, styles.headerSlotBase),
                  }}>
                  <Box px={1}>
                    <div className={styles.timezoneSlot}>{timezone}</div>
                  </Box>
                </Grid>
                <Grid item classes={{ root: clsx(styles.headerSlotBase) }}>
                  <Box pl={1} height="100%">
                    {persona}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default HeaderLayout;
