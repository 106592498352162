import BaseApi from '.';

export type RegenerationsRow = {
  id: number;
  siteId: number;
  pumpId: number;
  type: 'Full' | 'Failed';
  completedAt: string;
  timeBetween: number;
  roughTime: number;
  t1WarmupTime: number;
  t2WarmupTime: number;
  cooldownTime: number;
  rePurgeCycles: number;
  coldToCold: number;
  basePressure: number;
  purgeTime: number;
  rorLimit: number;
  rorCycles: number;
  duration: number;
};
export type RegenerationsStep = {
  createdAt: string;
  elapsedTime: number;
  t1: number;
  t2: number;
  rpm: number;
  tcPressure: number;
  step: string;
  stepId: number;
  stepExplanation: string;
};

const regenerations = new BaseApi('/regenerations');

export const regenerationsAPI = {
  fetchRegenerations(params: URLSearchParams) {
    return regenerations.get<RegenerationsRow[]>({ params });
  },
  fetchRegenerationSteps(id: string, params: URLSearchParams) {
    const regenerationSteps = new BaseApi(`/regenerations/${id}`);
    return regenerationSteps.get<RegenerationsStep[]>({ params });
  },
};
