import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RootDispatch, RootState } from 'src/store';
import { ControllerRow } from 'src/store/api/controllers.api';
import { DetailsPage } from '../../details-page';

const getGeneralData = (controller: ControllerRow | null) => {
  if (!controller) {
    return;
  }

  return [
    {
      label: 'Tool name',
      value: controller.toolName,
    },
    {
      label: 'Serial number',
      value: controller.serialNumber,
    },
    {
      label: 'Software version',
      value: controller.softwareVersion,
    },
    {
      label: 'Stabilization seconds',
      value: controller.stabilizationSeconds,
    },
    {
      label: 'Average diff pressure',
      value: controller.avgDifferentialPressure,
    },
    {
      label: 'Average return pressure',
      value: controller.avgReturnPressure,
    },
    {
      label: 'Average supply pressure',
      value: controller.avgReturnPressure,
    },
    {
      label: 'Full rough coord',
      value: controller.fullRoughCoord,
    },
    {
      label: 'Power fail coord',
      value: controller.powerFailCoord,
    },
    {
      label: 'Rough map',
      value: controller.roughMap,
    },
    {
      label: 'Helium map',
      value: controller.heliumMap,
    },
    {
      label: 'Helium state',
      value: controller.heliumState,
    },
    {
      label: 'Helium total allocation',
      value: controller.heliumTotalAllocation,
    },
    {
      label: 'Helium total available',
      value: controller.heliumTotalAvailable,
    },
    {
      label: 'Helium total consumption',
      value: controller.heliumTotalConsumption,
    },
    {
      label: 'Helium flow correction',
      value: controller.heliumFlowCorrection,
    },
  ];
};

export const ControllerDetails: React.FC = () => {
  const dispatch = useDispatch<RootDispatch>();
  const location = useLocation();

  const controller = useSelector(({ controllers }: RootState) =>
    controllers.rows ? controllers.rows[0] : null
  );

  const siteName = useSelector((state: RootState) => {
    return state.siteEquipment.sites.find((site) => controller?.siteId === site.id)?.name;
  });

  useEffect(() => {
    dispatch.controllers.fetchRows();
  }, [dispatch.controllers, location]);

  const controllerData = getGeneralData(controller);

  return (
    <DetailsPage
      stationDataRows={controllerData}
      siteName={siteName}
      toolName={controller?.toolName}
      equipmentName={`Controller/${controller?.id}`}
    />
  );
};
