import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from '@material-ui/core';
import { ICellRendererParams } from 'ag-grid-community';
import { RootDispatch } from 'src/store';
import { fontWeight, isLink } from './links-helper';
import { useStyles } from './styles';

export const ToolLinkRenderer = (cell: ICellRendererParams) => {
  const classes = useStyles();
  const { siteEquipment } = useDispatch<RootDispatch>();

  const linkParams = useMemo(() => {
    const { siteId, site_id, toolId, tool_id } = cell?.data || {};
    return {
      siteId: siteId || site_id,
      toolId: toolId || tool_id,
    };
  }, [cell.data]);

  const handleClick = useCallback(() => {
    siteEquipment.navigateToEquipment(linkParams);
  }, [linkParams, siteEquipment]);

  if (!cell.value) {
    return '';
  }

  return isLink(linkParams) ? (
    <Link
      style={fontWeight(cell)}
      className={classes.link}
      component="button"
      variant="body2"
      onClick={handleClick}>
      {cell.value}
    </Link>
  ) : (
    cell.value
  );
};
