import React, { FC, ReactNode, memo, useEffect, useState } from 'react';
import DatePicker from 'src/shared/date-range-filter/datepicker';
import { Box } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { isValid, subYears } from 'date-fns';
import { FeedbackValidationErrors } from 'src/store/models/feedback.model';

export interface FeedbackDates {
  pm_date: Date;
  reference_date: Date;
}

interface Props {
  pmDate: Date;
  referenceDate: Date;
  onChange: (value: FeedbackDates) => void;
  onError?: (error: ReactNode) => void;
  invalidFields: FeedbackValidationErrors;
}

const Dates: FC<Props> = (props) => {
  const { onChange, pmDate, referenceDate, onError, invalidFields } = props;
  const today = new Date();
  const referenceMinDate = subYears(today, 1);
  const [dates, setDates] = useState<FeedbackDates>({
    pm_date: pmDate,
    reference_date: referenceDate,
  });

  useEffect(() => {
    setDates({
      pm_date: pmDate,
      reference_date: referenceDate,
    });
  }, [pmDate, referenceDate]);

  const handleMaintenanceChange = (date: ParsableDate) => {
    const newDates = {
      ...dates,
      pm_date: date as Date,
    };
    setDates(newDates);

    if (isValid(date) || !date) {
      onChange(newDates);
    }
  };
  const handleReferenceChange = (date: ParsableDate) => {
    const newDates = {
      ...dates,
      reference_date: date as Date,
    };
    setDates(newDates);

    if (isValid(date) || !date) {
      onChange(newDates);
    }
  };

  const handleError = (error: ReactNode) => {
    onError?.(error);
  };

  return (
    <Box display="flex">
      <Box pr={1} flex="auto">
        <DatePicker
          label="Next PM"
          minDate={today}
          onChange={handleMaintenanceChange}
          onError={handleError}
          isError={!!invalidFields?.pm_date}
          value={dates.pm_date}
        />
      </Box>

      <Box pl={1} flex="auto">
        <DatePicker
          required
          label="Reference Date"
          minDate={referenceMinDate}
          maxDate={today}
          onChange={handleReferenceChange}
          onError={handleError}
          isError={!!invalidFields?.reference_date}
          value={dates.reference_date}
        />
      </Box>
    </Box>
  );
};

export default memo(Dates);
