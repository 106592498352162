import React from 'react';

import { CircularProgress } from '@material-ui/core';

interface Props {
  color?: 'inherit' | 'primary' | 'secondary';
  size?: string | number;
}

export const Spinner: React.FunctionComponent<Props> = function Spinner(props) {
  const { color = 'primary', size = '42px' } = props;
  return <CircularProgress color={color} size={size} />;
};
