import React, { FC } from 'react';
import PumpDetailsTableView from './pump-details-table';
import { formatRowsToNA } from './table-ui-formatters';
import { Pump, PumpDetails } from 'src/store/api/pumps.api';

interface Props {
  pump: Pump | PumpDetails;
}

export const ChartInfoTable: FC<Props> = (props) => {
  const { pump } = props;

  const rows = [
    {
      label: 'T1',
      value: pump.t1,
    },
    {
      label: 'T1 Max',
      value: pump.t1Max24h,
    },
    {
      label: 'T2',
      value: pump.t2,
    },
    {
      label: 'T2 Max',
      value: pump.t2Max24h,
    },
    {
      label: 'RPM',
      value: pump.rpm,
    },
    {
      label: 'RPM Max',
      value: pump.rpmMax24h,
    },
    {
      label: 'Ctrl DP',
      value: pump.avgDiffPressure,
    },
    {
      label: 'Ctrl RP',
      value: pump.avgReturnPressure,
    },
    {
      label: 'Pump Age',
      value: pump.ageInHours,
    },
  ];

  const formattedRows = formatRowsToNA(rows);
  return <PumpDetailsTableView applyTableRootStyle={true} rows={formattedRows} />;
};
