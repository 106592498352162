import React, { FC, memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import FeedbackAction from 'src/shared/feedback-action';
import { useSelector } from 'react-redux';
import { RootState, select } from 'src/store';
// import { ManualNotificationFlow } from 'src/shared/manual-notification';
// import { useEquipmentIdentifiers } from 'src/shared/routing/useEquipmentIdentifiers';

interface Props {
  siteId: number;
  toolId: number;
  pumpId: number;
}

const PumpSummary: FC<Props> = ({ siteId, toolId, pumpId }) => {
  // const identifiers = useEquipmentIdentifiers();

  const site = useSelector((state: RootState) =>
    select.siteEquipment.selectSite(state, { siteId })
  );

  const tool = useSelector((state: RootState) =>
    select.siteEquipment.selectTool(state, { siteId, toolId })
  );

  const pump = useSelector((state: RootState) =>
    select.siteEquipment.selectPump(state, { siteId, toolId, pumpId })
  );

  return (
    <Box display="flex" justifyContent="space-between" py={3}>
      <Typography variant="h5" component="h3">
        {pump?.stationName}
      </Typography>
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          {site && pump && tool && (
            <FeedbackAction
              type="button"
              toolName={tool.name}
              pumpName={pump.stationName}
              pumpId={pump.pumpId}
              siteId={site.siteId}>
              Feedback
            </FeedbackAction>
          )}
        </Box>
        {/* <ManualNotificationFlow
          siteName={site?.name}
          toolName={tool?.name}
          equipmentName={pump?.stationName}
          identifiers={identifiers}
        /> */}
      </Box>
    </Box>
  );
};

export default memo(PumpSummary);
