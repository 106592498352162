import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

interface Props {
  className?: string;
  color?: string;
}

const CriticalPredictionIcon: FC<Props> = (props) => {
  const { className, color = '#CF202E' } = props;
  return (
    <SvgIcon className={className} fontSize="inherit" viewBox="0 0 16 16">
      <path d="M0 0H16V16H0V0Z" fill={color} />
      <path
        d="M13 4.50219L11.4977 3L7.99996 6.49777L4.5022 3L2.99999 4.50221L6.49775 7.99998L2.99997
        11.4978L4.50218 13L7.99996 9.50219L11.4978 13L13 11.4978L9.50217 7.99998L13 4.50219Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default CriticalPredictionIcon;
