import BaseApi from '.';
import { ScopedQueryParams } from 'src/shared/routing/routing.helper';

const pumpSettings = new BaseApi('/pumps/settings');

export interface PumpSettingsRow {
  toolName: string;
  // todo: columns
}

export const pumpSettingsApi = {
  fetchRows: (params: ScopedQueryParams) => {
    return pumpSettings.get<PumpSettingsRow[]>({ params });
  },
};
