import { ColData } from 'src/shared/base-grid/base-grid';

const UNITS = {
  min: 'min',
  mT: 'mT',
  mTPerMin: 'mT/min',
};

export const columns: ColData[] = [
  {
    name: 'completedAt',
    label: 'Date Completed',
    sortDirection: 'desc',
    units: null,
    visibility: true,
    type: 'date',
  },
  {
    name: 'type',
    label: 'Type',
    units: null,
    visibility: true,
    type: 'number',
  },
  {
    name: 'duration',
    label: 'Duration',
    units: UNITS.min,
    visibility: true,
    type: 'number',
  },
  {
    name: 'timeBetween',
    label: 'Time Between',
    units: UNITS.min,
    visibility: true,
    type: 'number',
  },
  {
    name: 'roughTime',
    label: 'Rough Time',
    units: UNITS.min,
    visibility: true,
    type: 'number',
  },
  {
    name: 't1WarmupTime',
    label: 'T1 Warmup Time',
    units: UNITS.min,
    visibility: true,
    type: 'number',
  },
  {
    name: 't2WarmupTime',
    label: 'T2 Warmup Time',
    units: UNITS.min,
    visibility: true,
    type: 'number',
  },
  {
    name: 'cooldownTime',
    label: 'Cool Down Time',
    units: UNITS.min,
    visibility: true,
    type: 'number',
  },
  {
    name: 'rePurgeCycles',
    label: 'Repurge Cycles',
    units: null,
    visibility: true,
    type: 'number',
  },
  {
    name: 'coldToCold',
    label: 'Cold To Cold',
    units: UNITS.min,
    visibility: true,
    type: 'number',
  },
  {
    name: 'basePressure',
    label: 'Base Press',
    units: UNITS.mT,
    visibility: true,
    type: 'number',
  },
  {
    name: 'purgeTime',
    label: 'Purge Time',
    units: UNITS.min,
    visibility: true,
    type: 'number',
  },
  {
    name: 'rorLimit',
    label: 'ROR Limit',
    units: UNITS.mTPerMin,
    visibility: true,
    type: 'number',
  },
  {
    name: 'rorCycles',
    label: 'ROR Cycles',
    units: null,
    visibility: true,
    type: 'number',
  },
  {
    label: 'Actions',
    name: 'goToActions',
    type: null,
    units: null,
    visibility: true,
  },
];
