import React, { FC, useEffect, useMemo } from 'react';
import {
  ChartListItem,
  CreateChartPayload,
  ChartPayload as UpdateChartPayload,
} from 'src/store/api/charts-builder.api';
import { Box } from '@material-ui/core';
import { AutocompleteOption } from 'src/shared/autocompletes-wtih-chips';
import { useStyles } from './chart-form.styles';
import { Site } from 'src/store/api/sites.api';
import { generatePayload } from './utils';
import { Spinner } from 'src/shared/spinner';
import { useForm } from 'react-hook-form';
import { EntityType } from 'src/types';
import { ChartsFormData } from 'src/store/models/charts-builder.model';
import ChartFormTitle from './chart-form-components/general/title-input';
import ChartFormSitesController from './chart-form-components/general/site-controller';
import EntityController from './chart-form-components/general/entity-controller-view.container';
import AxisEntitiesControls from './chart-form-components/axis-specific/index';

export type DefaultChartValues = any;

type Props = {
  formData?: ChartsFormData | null;
  charts: ChartListItem[];
  sites: Site[];
  metrics: Record<EntityType, AutocompleteOption[]> | null;
  onSave: (payload: CreateChartPayload | UpdateChartPayload, meta: void) => Promise<void>;
  isLoading?: boolean;
};

export const filterMetricOptions = (
  getOppositeMetricValues: () => AutocompleteOption[]
) => {
  return (
    metricOptions: AutocompleteOption[],
    { inputValue = '' }: { inputValue: string }
  ) => {
    const oppositeMetricValues = getOppositeMetricValues();
    return metricOptions.filter(({ label = '' }) => {
      return (
        !oppositeMetricValues.some((opposite) => opposite.label === label) &&
        label.toLowerCase().includes(inputValue.toLowerCase())
      );
    });
  };
};

type EntityListType = 'Tool' | 'Pump' | 'Compressor';

const entitiesList = ['Tool', 'Pump', 'Compressor'] as EntityListType[];

const ChartForm: FC<Props> = (props) => {
  const { formData, sites, onSave, metrics: metricsData, isLoading, charts } = props;
  const styles = useStyles();

  const defaultValues = useMemo(
    () => ({
      chartTitle: formData?.title,
      sites: formData?.sites,
      'entities.tools': formData?.groupedEntities?.[EntityType.Tool] ?? [],
      'entities.pumps': formData?.groupedEntities?.[EntityType.Pump] ?? [],
      'entities.compressors': formData?.groupedEntities?.[EntityType.Compressor] ?? [],
      'metrics.left.tool': formData?.groupedMetrics?.[`left.${EntityType.Tool}`] ?? [],
      'metrics.right.tool': formData?.groupedMetrics?.[`right.${EntityType.Tool}`] ?? [],
      'metrics.left.pump': formData?.groupedMetrics?.[`left.${EntityType.Pump}`] ?? [],
      'metrics.right.pump': formData?.groupedMetrics?.[`right.${EntityType.Pump}`] ?? [],
      'metrics.left.compressor':
        formData?.groupedMetrics?.[`left.${EntityType.Compressor}`] ?? [],
      'metrics.right.compressor':
        formData?.groupedMetrics?.[`right.${EntityType.Compressor}`] ?? [],
      ...formData?.groupedAxes,
    }),
    [formData]
  );

  const formContext = useForm<DefaultChartValues>({ defaultValues }); // todo: type (line: 26)
  const { handleSubmit, reset } = formContext;

  const metricsByType = {
    [EntityType.Tool]: metricsData ? metricsData[EntityType.Tool] : [],
    [EntityType.Compressor]: metricsData ? metricsData[EntityType.Compressor] : [],
    [EntityType.Pump]: metricsData ? metricsData[EntityType.Pump] : [],
  };

  useEffect(() => {
    if (formData && defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, formData, reset]);

  const onSubmit = (data: Record<string, any>) => {
    const payload = generatePayload(data);
    onSave(payload);
  };

  if (isLoading) {
    return (
      <Box pt={8} textAlign="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <form id="chart-form" onSubmit={handleSubmit(onSubmit)}>
      {/*======= Title =======*/}
      <ChartFormTitle formContext={formContext} formData={formData} charts={charts} />

      {/*======= Sites =======*/}
      <ChartFormSitesController sites={sites} formContext={formContext} />
      {/*======= Tools - Pumps - Compressors =======*/}
      {entitiesList.map((el) => (
        <EntityController key={el} entityType={el} formContext={formContext} />
      ))}

      {/*======= Metrics =======*/}
      <div className={styles.axisContainer}>
        <AxisEntitiesControls
          side="left"
          formContext={formContext}
          metricsByType={metricsByType}
          formData={formData}
        />

        <AxisEntitiesControls
          side="right"
          formContext={formContext}
          metricsByType={metricsByType}
          formData={formData}
        />
      </div>
    </form>
  );
};

export default ChartForm;
