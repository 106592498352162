import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      dialog: {
        borderRadius: 0,
      },
      dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 24px',
      },
      dialogContent: {
        padding: '8px 24px',
      },
      dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 24px',
      },
      dialogCloseBtn: {
        color: COLORS.black,
      },
    })
);
