import { Box, Chip, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { RootDispatch } from 'src/store';
import { Notification } from 'src/store/api/notifications.api';

interface Props {
  notification: Notification;
}

interface ArticleProps {
  label: string;
  value: string | undefined;
}

export const ManualNotificationContent: FC<Props> = ({
  notification: {
    notificationId,
    siteId,
    comments,
    recommendations,
    attachments,
    recipients,
  },
}) => {
  const dispatch = useDispatch<RootDispatch>();

  const downloadFile = (attachmentId: number, filename: string) => {
    dispatch.notifications.downloadAttachment({
      siteId,
      notificationId,
      attachmentId,
      filename,
    });
  };

  return (
    <>
      {recommendations && <Article label="Recommendations" value={recommendations} />}
      {comments && <Article label="Comments" value={comments} />}
      {!!recipients.length && (
        <Article label="Recipients" value={recipients.join(', ')} />
      )}
      {attachments && (
        <Box>
          {attachments.map(({ id, filename }) => (
            <Chip
              style={{ marginRight: '1rem' }}
              key={id}
              label={filename}
              onClick={() => downloadFile(id, filename)}
            />
          ))}
        </Box>
      )}
    </>
  );
};

const Article: FC<ArticleProps> = ({ label, value }) => {
  return (
    <Box marginBottom="2rem">
      <Typography variant="subtitle2">{`${label}:`}</Typography>
      <Typography variant="body2" component="p" style={{ whiteSpace: 'pre-line' }}>
        {value}
      </Typography>
    </Box>
  );
};
