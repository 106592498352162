import { Site } from 'src/store/api/sites.api';
import { CreateChartPayload, Side } from 'src/store/api/charts-builder.api';
import { AutocompleteOption } from 'src/shared/autocompletes-wtih-chips'; // todo: naming
import { EntityType } from 'src/types';

export type ResourceField = 'entities.tools' | 'entities.pumps' | 'entities.compressors';
type AxisConfig = {
  title: string;
  min: string;
  max: string;
};

type FormEntitiesType = 'tools' | 'pumps' | 'compressors';
type EntitiesInputs = Record<FormEntitiesType, AutocompleteOption[]>;

export const resourceMetricsMap = {
  'entities.tools': ['metrics.left.tool', 'metrics.right.tool'],
  'entities.pumps': ['metrics.left.pump', 'metrics.right.pump'],
  'entities.compressors': ['metrics.left.compressor', 'metrics.right.compressor'],
};

const entitiesTypeMap: Record<FormEntitiesType, EntityType> = {
  tools: EntityType.Tool,
  pumps: EntityType.Pump,
  compressors: EntityType.Compressor,
};

export const formatSites = (sites: Site[]): AutocompleteOption[] =>
  sites.map(({ siteId, name, ...rest }) => ({
    value: siteId,
    label: name,
    extraFields: rest,
  }));

export const createLabel = (str: string, optionsLength: number): string =>
  `${str} ${optionsLength || ''}`;

export const generatePayload = (formData: Record<string, any>) => {
  const payload: CreateChartPayload = {
    name: formData.chartTitle,
    site_ids: [],
    time_series_attributes: [],
    time_series_entities_attributes: [],
    axes_attributes: [],
  };

  // generate sites
  payload.site_ids = (formData.sites as AutocompleteOption[])?.map(
    ({ value, label }) => value as number
  );

  // generate entities
  Object.keys(formData.entities as EntitiesInputs).forEach((key) =>
    formData.entities[key].forEach((option: AutocompleteOption) => {
      payload.time_series_entities_attributes.push({
        id: option.extraFields?.id,
        site_id: +option.extraFields?.siteId,
        entity_type: entitiesTypeMap[key as FormEntitiesType],
        entity_id: +option.value,
      });
    })
  );

  // generate metrics
  Object.keys(formData.metrics).forEach((side) => {
    Object.keys(formData.metrics[side]).forEach((entityKey) => {
      formData.metrics[side][entityKey].forEach((option: AutocompleteOption) => {
        payload.time_series_attributes.push({
          id: option.extraFields?.id,
          eb_metric_id: option.value,
          axis: side,
        });
      });
    });
  });

  // generate axes
  const axes = Object.keys(formData.axes) as Side[];
  axes.forEach((axis) => {
    const [side, id] = axis.split('-');
    const axisData: AxisConfig = formData.axes[axis];
    payload.axes_attributes.push({
      ...(id !== 'new' ? { id: +id } : {}),
      name: axisData.title,
      side: side as Side,
      min: axisData.min === '' ? null : +axisData.min,
      max: axisData.max === '' ? null : +axisData.max,
    });
  });

  return payload;
};
