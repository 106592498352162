import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';

import {
  getDefaultFilters,
  getWeekRange,
} from 'src/shared/date-range-filter/filters-config';
import DateRangeFilter from 'src/shared/date-range-filter';
import LineChart from 'src/shared/charts/line-chart';
import { tooltipConfig } from 'src/shared/charts/line-chart/line-chart.config';
import { without } from 'src/shared/charts/base-chart/palette';
import { FetchSeriesParams, GroupedSeries } from 'src/store/api/charts.api';
import { EntityType } from 'src/types';
import { GetColor } from 'src/shared/charts/line-chart/series-transformer';
import { Series } from 'src/store/models/pumps.model';

type Prop = {
  fetchData: (params: FetchSeriesParams, meta: void) => Promise<void>;
  seriesData: Series;
  onClean: () => void;
};

const [defaultStart, defaultEnd] = getWeekRange();
const quickFilters = getDefaultFilters();

const RED = '#CF202E';
const paletteWithoutRed = without(RED);

const tooltip = {
  tooltip: {
    ...tooltipConfig,
    confine: true,
  },
};

export function MaintenanceDashboardByTool(props: Prop) {
  const { fetchData, seriesData, onClean } = props;

  const { search } = useLocation();

  const [dates, setDates] = useState<[Date, Date]>([defaultStart, defaultEnd]);

  /*
   * Fetch data every time on url search params change
   * (i.e. when user navigates between different tools by clicking link to tool or by clicking on tool from tree)
   */
  useEffect(() => {
    fetchData({
      search,
      start: defaultStart,
      end: defaultEnd,
    });
  }, [fetchData, search]);

  useEffect(() => {
    return () => onClean();
  }, [onClean]);

  const handleChange = (start: Date, end: Date) => {
    fetchData({
      search,
      start,
      end,
    });
    setDates([start, end]);
  };

  const getUID = (id?: number, siteId?: number) => [id, siteId].join('__');

  // same devices should use same color
  const deviceColors = useMemo(() => {
    const colors = new Map();

    (seriesData || []).forEach((series: GroupedSeries) => {
      const uid = getUID(series.entity_id, series.site_id);
      const getNextColor = () =>
        paletteWithoutRed[colors.size % paletteWithoutRed.length];
      const color = series.entity_type === EntityType.Tool ? RED : getNextColor();
      colors.set(uid, color);
    });

    return colors;
  }, [seriesData]);

  const getColor: GetColor = useCallback(
    (index, seriesName, deviceType, deviceId, siteId) => {
      return {
        color: deviceColors.get(getUID(deviceId, siteId)),
        type: seriesName === 'T1' ? 'dashed' : seriesName === 'T2' ? 'dotted' : 'solid',
      };
    },
    [deviceColors]
  );

  const [start, end] = dates;

  return (
    <Box p="24px 16px">
      <DateRangeFilter
        quickFilters={quickFilters}
        startDate={start}
        endDate={end}
        onChange={handleChange}
      />
      <Box p="16px 0 0">
        <LineChart
          data={seriesData || []}
          getColor={getColor}
          options={tooltip}
          minX={start.getTime()}
          maxX={end.getTime()}
        />
      </Box>
    </Box>
  );
}
