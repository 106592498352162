import React, { memo, useCallback } from 'react';
import { CellRenderer } from 'src/shared/base-grid/base-grid';
import FeedbackAction from '../../../feedback-action';
import { connect } from 'react-redux';
import { Feedback } from 'src/store/api/feedbacks.api';

// Tech debt. Think about another decision
const FeedbackComponent = connect()(FeedbackAction);

const FeedbackActionRenderer: React.FC<CellRenderer<never>> = (props) => {
  const handleRowUpdate = useCallback(
    (feedback: Feedback) =>
      props.node.setDataValue('feedbackAction', feedback.feedback_status),
    [props.node]
  );

  if (!props.data) {
    return null;
  }

  const status = props.data.feedbackAction || props.data.feedbackStatus || undefined;

  return (
    <FeedbackComponent
      toolName={props.data.toolName}
      pumpName={props.data.stationName}
      pumpId={props.data.id}
      siteId={props.data.siteId}
      status={status}
      onAfterSave={handleRowUpdate}
    />
  );
};

export default memo(FeedbackActionRenderer);
