import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ColumnName } from './column.config';
import { COLORS } from 'src/styles/colors';

export interface StyleDef {
  default: CSSProperties;
  critical?: CSSProperties;
}

type ExtraColNames = 'pinnedRight' | 'input' | 'centered';

export const selectCellStyles = (name?: ColumnName | ExtraColNames): StyleDef => {
  switch (name) {
    case 'online':
      return {
        default: {
          padding: 0,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: '0.5rem',
        },
      };

    case 'pinnedRight':
      return {
        default: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderLeft: `1px solid ${COLORS.grey['300']}`,
        },
      };

    case 'centered':
      return {
        default: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      };

    case 'input':
      return {
        default: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: '0.1rem',
        },
      };

    default:
      return {
        default: {},
        critical: {
          color: COLORS.red['500'],
        },
      };
  }
};
