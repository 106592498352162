import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { decamelizeAndCapitalize } from 'src/services/notifications-service';
import DateInSelectedZone from 'src/shared/date-in-selected-zone';
import { AutoNotification, Notification } from 'src/store/api/notifications.api';
import { useStyles } from './styles';

interface Props {
  notification: Notification | AutoNotification;
}

interface HeaderArticleProps {
  label: string;
  value: string | undefined;
}

export const NotificationHeader: FC<Props> = ({ notification }) => {
  const classes = useStyles();
  const {
    subject,
    notificationType,
    createdAt,
    sender,
    siteName,
    toolName,
    stationName,
    equipmentType,
  } = notification;

  return (
    <Box className={classes.headerRoot}>
      <Box display="flex" style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" component="span">
          {subject}
        </Typography>
        <Typography variant="caption" color="textSecondary" component="span">
          <DateInSelectedZone>{createdAt}</DateInSelectedZone>
        </Typography>
      </Box>
      <Box>
        <HeaderArticle label="Category" value={notificationType} />
        <HeaderArticle label="From" value={sender} />
        {siteName && <HeaderArticle label="Site" value={siteName} />}
        {toolName && <HeaderArticle label="Tool" value={toolName} />}
        {stationName && equipmentType && (
          <HeaderArticle
            label={decamelizeAndCapitalize(equipmentType)}
            value={stationName}
          />
        )}
        {notificationType === 'auto' && (
          <HeaderArticle label="Active" value={notification.active?.toString()} />
        )}
      </Box>
    </Box>
  );
};

const HeaderArticle: FC<HeaderArticleProps> = ({ label, value }) => {
  return (
    <Box display="inline" marginRight="2rem">
      <Typography variant="subtitle2" component="span">{`${label}: `}</Typography>
      <Typography variant="body2" component="span">
        {value}
      </Typography>
    </Box>
  );
};
