import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { CompressorRow, compressorsApi } from './../api/compressors.api';
import { getScopedQuery } from 'src/shared/routing/routing.helper';

export type CompressorsState = {
  rows: CompressorRow[] | null;
  current: CompressorRow | null;
};

export const compressors = createModel<RootModel>()({
  state: {
    rows: null,
    current: null,
  } as CompressorsState,

  reducers: {
    setRows: (
      state: CompressorsState,
      rows: CompressorRow[] | null
    ): CompressorsState => ({
      ...state,
      rows,
    }),
    setCompressor: (
      state: CompressorsState,
      current: CompressorRow | null
    ): CompressorsState => ({
      ...state,
      current,
    }),
  },

  effects: (dispatch) => {
    const { compressors } = dispatch;

    return {
      async fetchRows() {
        const { data } = await compressorsApi.fetchRows(getScopedQuery());
        compressors.setRows(data);
      },
      async fetchCompressor() {
        const data = await compressorsApi.fetchCompressor(getScopedQuery());
        compressors.setCompressor(data);
      },
    };
  },
});
