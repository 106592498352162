import { ConnectedProps, connect } from 'react-redux';
import { RootDispatch, RootState } from 'src/store';

import Monitoring from './Monitoring';

const mapState = (state: RootState) => {
  const { ml } = state;

  return {
    pipelines: ml.pipelines,
    pending: state.loading.effects.ml.fetchPipelineExecutions,
  };
};

const mapDispatch = (dispatch: RootDispatch) => {
  const { ml } = dispatch;

  return {
    onMount: ml.fetchPipelineExecutions,
  };
};

const connector = connect(mapState, mapDispatch);

export type MonitoringContainerProps = ConnectedProps<typeof connector>;

const MonitoringContainer = connector(Monitoring);

export { MonitoringContainer };
