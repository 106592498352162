import React, { FC } from 'react';
import {
  Box,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import criticalIcon from 'src/shared/icons/critical.svg';

export interface Props {
  onDurationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  aboveThresholdDuration: number;
}

const AboveThresholdDuration: FC<Props> = (props) => {
  const { onDurationChange, aboveThresholdDuration } = props;

  const criticalSign = (
    <InputAdornment position="start">
      <img src={criticalIcon} alt="critical state" />
    </InputAdornment>
  );

  return (
    <Box py={1}>
      <FormControl variant="filled" fullWidth={true} onChange={onDurationChange}>
        <InputLabel htmlFor="duration-ctrl" shrink>
          Above Threshold Duration (Days)
        </InputLabel>
        <FilledInput
          id="duration-ctrl"
          inputProps={{
            min: 0,
            step: 'any',
          }}
          startAdornment={criticalSign}
          type="number"
          defaultValue={aboveThresholdDuration}
        />
      </FormControl>
    </Box>
  );
};
export default AboveThresholdDuration;
