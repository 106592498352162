import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles({
  root: {
    height: '100% !important',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  slot: {
    border: 'none',
    minHeight: '50px',
    outline: `1px solid ${COLORS.grey['300']}`,
    width: '50%',
  },
  '@media screen and (min-width: 640px)': {
    slot: {
      width: '50%',
    },
  },
  '@media screen and (min-width: 800px)': {
    slot: {
      width: '33.33333333333%',
    },
  },
  '@media screen and (min-width: 960px)': {
    slot: {
      width: '25%',
    },
  },
  '@media screen and (min-width: 1120px)': {
    slot: {
      width: '20%',
    },
  },
  '@media screen and (min-width: 1280px)': {
    slot: {
      width: '16.6666666666%',
    },
  },
  '@media screen and (min-width: 1440px)': {
    slot: {
      width: '14.285714285714286%',
    },
  },
  '@media screen and (min-width: 1600px)': {
    slot: {
      width: '12.5%',
    },
  },
  '@media screen and (min-width: 1760px)': {
    slot: {
      width: '11.1111111111%',
    },
  },
  '@media screen and (min-width: 1920px)': {
    slot: {
      width: '10%',
    },
  },
  toolbarSlot: {
    alignItems: 'center',
    display: 'flex',
  },
  chartSlot: {
    paddingBottom: '8px',
    paddingTop: '8px',
  },
  chart: {
    margin: 'auto',
    height: '72px',
    width: '72px',
  },
  tableRoot: {
    tableLayout: 'fixed',
  },
  headerCell: {
    border: 'none',
    color: COLORS.grey['600'],
    width: '60%',
  },
  tableCell: {
    border: 'none',
    color: COLORS.grey['600'],
  },
  spinner: {
    height: '24px',
    width: '24px',
  },
  textLinePxSmall: {
    paddingLeft: '4px',
    paddingRight: '4px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textLineCenter: {
    textAlign: 'center',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textLine: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  pseudoLink: {
    cursor: 'default',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  actionIcons: {
    '& img': {
      height: '24px',
    },
  },
  pDefault: {
    padding: '8px',
  },
  pDouble: {
    padding: '16px',
  },
  pySmall: {
    paddingBottom: '4px',
    paddingTop: '4px',
  },
  pxSmall: {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  mAuto: {
    margin: 'auto',
  },
  mxSmallNegative: {
    marginLeft: '-4px',
    marginRight: '-4px',
  },
  textCenter: {
    textAlign: 'center',
  },
  body1: {
    color: COLORS.grey['500'],
    fontSize: '18px',
  },
});
