import React, { FC, useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core';
import { ConnectedProps, connect } from 'react-redux';

import { RootDispatch, RootState } from 'src/store';
import CentricLayout from 'src/shared/centric-layout';
import LoginForm, { LoginFields, UpdatePassForm } from 'src/app/login/login-form';
import { LoginError } from './login-error';
import { LoginHeader } from './login-header';
import { ERROR_MESSAGES } from './constants';

import styles from './login.styles';

type ComponentProps = ConnectedProps<typeof connector> & WithStyles<typeof styles>;

export const Login: FC<ComponentProps> = (props) => {
  const {
    classes,
    loading,
    error,
    isPasswordChangeRequired,
    cleanupError,
    onSignIn,
    updatePassword,
  } = props;

  const [credentials, setCredentials] = useState({});

  const onErrorDismiss = useCallback(() => cleanupError(), [cleanupError]);

  const handleSignIn = useCallback(
    (values: LoginFields): void => {
      setCredentials(values);
      onSignIn(values);
    },
    [onSignIn]
  );

  const handleUpdatePassword = useCallback(
    (newPassword: string): void => {
      updatePassword({
        user: {
          password: newPassword,
          currentPassword: (credentials as LoginFields).password,
        },
      });
    },
    [credentials, updatePassword]
  );

  return (
    <div className={classes.loginContainer}>
      <LoginHeader />
      <CentricLayout>
        <div className={classes.errorContainer}>
          {!isPasswordChangeRequired && error && (
            <LoginError
              type="error"
              errorMessage={ERROR_MESSAGES.login}
              onClose={onErrorDismiss}
            />
          )}
          {isPasswordChangeRequired && (
            <LoginError type="warn" errorMessage={ERROR_MESSAGES.updatePassWarn} />
          )}
        </div>
        <div className={classes.formContainer}>
          {!isPasswordChangeRequired ? (
            <LoginForm error={error} loading={loading} onSubmit={handleSignIn} />
          ) : (
            <UpdatePassForm loading={loading} onSubmit={handleUpdatePassword} />
          )}
        </div>
      </CentricLayout>
    </div>
  );
};

const mapState = (state: RootState) => ({
  loading: state.loading.effects.auth.signInRequest,
  error: Boolean(state.auth.signInError),
  isPasswordChangeRequired: state.auth.isPasswordChangeRequired,
});

const mapDispatch = (dispatch: RootDispatch) => {
  return {
    onSignIn: dispatch.auth.signInRequest,
    cleanupError: () => dispatch.auth.setSignInError(null),
    updatePassword: dispatch.auth.updatePassword,
  };
};

const connector = connect(mapState, mapDispatch);

export default connector(Login);
