import React from 'react';
import { useStyles } from './centric-layout.styles';

export const CentricLayout: React.FC = (props) => {
  const styles = useStyles();
  return (
    <div className={[styles.layoutRoot, styles.fitSpace].join(' ')}>
      <div className={styles.centerContent}>{props.children}</div>
    </div>
  );
};
