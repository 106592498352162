import React, { useCallback, useState } from 'react';
import { Close, Cancel as Error, Error as Warn } from '@material-ui/icons';
import { useStyles } from './login-error.styles';

interface OwnProps {
  type: 'error' | 'warn';
  errorMessage: string;
  onClose?: () => void;
}

export const LoginError: React.FC<OwnProps> = (props) => {
  const { type, errorMessage, onClose } = props;
  const styles = useStyles();
  const [closed, setClosed] = useState(false);
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      setClosed(true);
    }
  }, [onClose]);
  if (closed) return null;
  return (
    <div className={type === 'error' ? styles.errorContent : styles.warnContent}>
      <div className={styles.messageContainer}>
        {type === 'error' ? (
          <Error className={styles.icon} />
        ) : (
          <Warn className={styles.icon} />
        )}
        <div className={styles.msg}>{errorMessage}</div>
      </div>
      <Close className={styles.closeBtn} onClick={handleClose} />
    </div>
  );
};
