import React, { FC } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Timezone } from 'src/store/models/timezone.model';
import timezones from 'src/services/timezones.json';
import { ConnectedProps } from './TimezonePicker.container';
import { useStyles } from './timezone-picker.styles';

export const TimezonePicker: FC<ConnectedProps> = (props) => {
  const { selectedZone } = props;
  const styles = useStyles();

  const onChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const zone = timezones.find((item) => item.name === event.target.value);
    if (zone) props.onChange(zone);
  };

  return (
    <FormControl classes={{ root: styles.pickerRoot }}>
      <Select
        IconComponent={ExpandMore}
        classes={{ root: styles.controlUnderline }}
        onChange={onChange}
        value={selectedZone.name}>
        {timezones.map((zone: Timezone) => (
          <MenuItem value={zone.name} key={zone.name}>
            <span className={styles.typoBody2}>{zone.name}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
