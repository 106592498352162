import { ColData } from 'src/shared/base-grid/base-grid';

export const columns: ColData[] = [
  {
    name: 'firstName',
    label: 'First Name',
    sortDirection: 'desc',
    units: null,
    visibility: true,
    type: 'text',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    units: null,
    visibility: true,
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    units: null,
    visibility: true,
    type: 'text',
  },
  {
    name: 'createdAt',
    label: 'Created On',
    units: null,
    visibility: true,
    type: 'date',
  },
  {
    name: 'currentSignInAt',
    label: 'Current Sign In At',
    units: null,
    visibility: true,
    type: 'date',
  },
  {
    name: 'currentSignInIp',
    label: 'Current Sign In IP',
    units: null,
    visibility: true,
    type: 'text',
  },
  {
    name: 'signInCount',
    label: 'Sign In Count',
    units: null,
    visibility: true,
    type: 'number',
  },
  {
    name: 'roleLabel',
    label: 'Role',
    units: null,
    visibility: true,
    type: 'text',
  },
  {
    name: 'deactivated',
    label: 'Active',
    units: null,
    visibility: true,
    type: 'boolean',
  },
  {
    label: 'Actions',
    name: 'adminActions',
    type: null,
    units: null,
    visibility: true,
  },
];
