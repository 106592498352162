import { getScopedQuery } from 'src/shared/routing/routing.helper';
import { makeQuery } from 'src/store/api/charts.api';
import BaseApi from 'src/store/api/index';
import { downloadFile } from './download-service';

export type ExportTableParams = ReturnType<typeof getScopedQuery>;
export type ChartExportParams = ReturnType<typeof makeQuery>;

type PassedChartExportParams = {
  dates: {
    start: Date;
    end: Date;
  };
  timezoneOffset: number;
};
export type PassedExportParams = PassedChartExportParams | null;

const fetchXlsxFile = (url: string, params: unknown) => {
  return new BaseApi(url, { responseType: 'arraybuffer' }).get<ArrayBuffer>({ params });
};

const xlsxExportGrid = (url: string, params: unknown, title: string) => {
  return fetchXlsxFile(url, params).then(({ data }) => downloadFile(data, title));
};
const xlsxExportChart = (url: string, params: ChartExportParams, title: string) => {
  return fetchXlsxFile(url, params).then(({ data }) => downloadFile(data, title));
};

export const exportToXlsx = (url: string, title: string, params: PassedExportParams) => {
  if (params) {
    const { timezoneOffset, dates } = params;
    const chartQuery = makeQuery(dates, timezoneOffset);
    return xlsxExportChart(url, chartQuery, title);
  }
  const gridQuery = getScopedQuery();
  return xlsxExportGrid(url, gridQuery, title);
};

export const exportXlsxCallback = (url: string, params: unknown, title: string) => {
  return xlsxExportGrid(url, params, title);
};
