import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyleRules } from '@material-ui/styles/withStyles';
import { COLORS } from 'src/styles/colors';

export const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      gaugeChartContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
      },
      tooltip: {
        fontSize: '0.625rem',
        fontWeight: 'normal',
        padding: '0.375rem 0.5rem',
        width: 'auto',
        maxWidth: '13.5rem',
      },
      gaugeChart: {
        color: COLORS.grey['100'],
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      gaugeChartLabel: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 3,
      },
    })
);
