import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useStyles } from './styles';

interface Props<T> {
  label: string;
  value: string;
  onChange: (selected: T) => void;
  options: ReactNode[];
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const NotificationsSelect = <T extends unknown>({
  label,
  value,
  onChange,
  options,
  disabled,
  style,
}: Props<T>) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as T);
  };

  return (
    <FormControl disabled={disabled} style={style} className={classes.select}>
      <InputLabel variant="filled">{label}</InputLabel>
      <Select variant="filled" value={value} onChange={handleChange}>
        {options}
      </Select>
    </FormControl>
  );
};
